import React from 'react';

interface SwitcherProps {
  segment: any;
  obj: any;
  id?: number | undefined;
  phasesData?: any;
  cashedIMGs?: any;
  loading?: boolean;
  setIsOpenDetailed?: () => void;
}

function Switcher(props: SwitcherProps) {
  const { segment, obj, ...otherProps } = props;
  const Handler = obj[segment];

  if (!Handler) {
    return <></>;
  }

  return <Handler {...otherProps} />;
}

export default Switcher;
