import { notification } from 'antd';

import { TABLE_CSV_SEPARATOR } from '../../../../constants/constants';
import downloadFile from '../../../../helpers/downloadFile';
import getNormalizeDateTime from '../../../../helpers/getNormalizeDateTime';
import { DataType, ITableColumn } from '../../../../ts/models/table.model';

interface IExportData {
  dataRows: DataType[];
  headerItems: ITableColumn[];
}

const useExport = (data: IExportData) => {
  const exportData = () => {
    if (!data) return;

    const rows = data.dataRows;
    const headers = data.headerItems;

    let result = '';

    if (rows.length === 0) {
      notification.error({
        message: 'Ошибка экспорта',
        description: 'Нельзя экспортировать пустую таблицу',
      });

      return;
    }

    headers.forEach((el) => {
      if (el.referens === 'id') {
        result += el.referens;
      }
    });

    rows.forEach((dataItem) => {
      let dataItemStr = '';

      for (const fieldName in dataItem) {
        const columnItem = headers.find((item) => fieldName === item.referens);

        if (!columnItem) {
          continue;
        }

        dataItemStr +=
          TABLE_CSV_SEPARATOR + dataItem[fieldName as keyof typeof dataItem];
      }

      result += `\n${dataItemStr}`;
    });

    notification.success({
      message: 'Экспорт выполнен',
      description: 'Данные из таблицы успешно экспортированы в файл',
    });

    downloadFile(result, `${getNormalizeDateTime()} Таблица.csv`);
  };

  return { exportData };
};

export default useExport;
