import { System } from '../ts/enums/enums';

import {
  DEFAULT_MAP_ICON_SIZE,
  EVENTS,
  PARAMS,
  SYSTEM,
  LOGO_NAMES,
  OTHER,
  HINTS,
  DISPLAY_INFO,
  CLUSTERS_INFO,
} from './constants';

type InitialMarkers =
  | 'isLights'
  | 'isDetectors'
  | 'isCameras'
  | 'isSpecialTransport'
  | 'isPublicTransport'
  | 'isMeteo'
  | 'isStreams'
  | 'isRta'
  | 'isRoadWorks'
  | 'isActivities'
  | 'isTrafficRestrictions';

export const INITIAL_MARKERS: Record<InitialMarkers, boolean> = {
  // Subsystems
  isLights: true,
  isDetectors: true,
  isCameras: true,
  isSpecialTransport: false,
  isPublicTransport: false,
  isMeteo: false,
  // Params
  isStreams: false,
  // Events
  isRta: false,
  isRoadWorks: false,
  isActivities: false,
  isTrafficRestrictions: false,
};

export const INITIAL_SETTINGS = {
  isWidgets: false,
  isClusters: true,
  isAdjacentPhaseCircles: true,
  isShowPhaseCircle: true,
  isPhaseCircleLabel: false,
  isUnderCircleFill: false,
  interfaceTipsDelay: 1,
  mapTipsDelay: 1,
  mapButtonsTipsDelay: 2,
  profileTipsDelay: 2,
  isSummaryInfo: false,
  mapIconsSize: DEFAULT_MAP_ICON_SIZE,
  logo: LOGO_NAMES.sber,
  isCenterDependsOnPanels: false,
  isOpenInfoPanel: true,
  isInfoBtn: true,
  isAlarmAlert: true,
  isDebugMode: false,
  isMinificMap: true,
  isScaleLine: true,
  isNeedOpenedCluster: true,
};

export const POPOVER_CONTENT_DISPLAY = {
  CENTER_DEPENDS_ON_PANEL: (
    <div>
      Вкл./откл. смещения центра карты <br />
      при открытии/ закрытии панелей.
    </div>
  ),
  INFO_PANEL: (
    <div>
      Вкл./откл. открытии инфопанели
      <br />
      при закрытии вкладки <b>подробнее</b>.
    </div>
  ),
  INFO_BTN: <div>Вкл./откл. блока информации</div>,
  ALARM_ALERT: <div>Вкл./откл. мигающих уведомлений</div>,
  MINIFIC_MAP: <div>Вкл./откл. минифицированную карту</div>,
  SCALE_LABEL: <div>Вкл./откл. отображение масштаба карты</div>,
  SLIDER: 'Изменить размер иконок',
};

export const POPOVER_CONTENT_CLUSTERS = {
  CLUSTERIZATION: (
    <div>
      Вкл./откл. объединения иконок <br />
      на карте при масштабировании.
    </div>
  ),
  OPENED: (
    <div>
      Вкл./откл. распадение кластера <br />
      на объекты при клике.
    </div>
  ),
};

export const POPOVER_CONTENT_OTHER = {
  WIDJETS: (
    <div>
      Вкл./откл. отображения виджетов в нижней <br />
      части экрана <b>при загрузке приложения.</b>
    </div>
  ),
  DEBUG: 'Вкл./откл. режима отладки',
};

export const POPOVER_CONTENT_HELPERS = {
  INTERFACE: 'Время появления подсказки в интерфейсе',
  MAP: 'Время появления подсказки на карте',
  BTNS_MAP: 'Время появления подсказки на кнопках карты',
  PROFILE: 'Время появления подсказки в профиле',
};

const SUBSYSTEMS_DATA = [
  {
    LABEL: SYSTEM.LIGHTS,
    NAME: 'isLights',
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. иконок «Светофорные объекты» <br /> на карте{' '}
        <b>при загрузке приложения.</b>
      </div>
    ),
  },

  {
    LABEL: SYSTEM.DETECTORS,
    NAME: 'isDetectors',
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. иконок «Детекторы транспорта» <br /> на карте{' '}
        <b>при загрузке приложения.</b>
      </div>
    ),
  },

  {
    LABEL: SYSTEM.CAMERAS,
    NAME: 'isCameras',
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. иконок «Камеры» <br /> на карте{' '}
        <b>при загрузке приложения.</b>
      </div>
    ),
  },
  {
    LABEL: SYSTEM.SPECIAL_TRANSPORT,
    NAME: 'isSpecialTransport',
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. иконок «Спец.техника» <br /> на карте{' '}
        <b>при загрузке приложения.</b>
      </div>
    ),
  },
  {
    LABEL: SYSTEM.PUBLIC_TRANSPORT,
    NAME: 'isPublicTransport',
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. иконок «Общественный транспорт» <br /> на карте{' '}
        <b>при загрузке приложения.</b>
      </div>
    ),
  },
  {
    LABEL: System.Streams,
    NAME: 'isStreams',
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. линий загруженности участков <br />
        дорог на карте <b>при загрузке приложения.</b>
      </div>
    ),
  },
  {
    LABEL: EVENTS.RTA,
    NAME: 'isRta',
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. иконок «ДТП» <br /> на карте <b>при загрузке приложения.</b>
      </div>
    ),
  },
  {
    LABEL: EVENTS.ROAD_WORKS,
    NAME: 'isRoadWorks',
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. иконок «Дорожные работы» <br /> на карте{' '}
        <b>при загрузке приложения.</b>
      </div>
    ),
  },
  {
    LABEL: SYSTEM.METEO,
    NAME: 'isMeteo',
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. иконок «Метеостанции» <br /> на карте{' '}
        <b>при загрузке приложения.</b>
      </div>
    ),
  },
] as const;

const PARAMS_DATA = [
  {
    LABEL: PARAMS.STREAMS,
    NAME: 'isStreams',
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: <div>Вкл./откл. отображения потоков на карте.</div>,
  },
  {
    LABEL: PARAMS.SUMMARY_INFO,
    NAME: 'isSummaryInfo',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. отображения сводной <br />
        информации в окне «Параметры»
      </div>
    ),
  },
] as const;

const EVENTS_DATA = [
  {
    LABEL: EVENTS.RTA,
    NAME: 'isRta',
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. иконок «ДТП» <br /> на карте
        <b> при загрузке приложения.</b>
      </div>
    ),
  },
  {
    LABEL: EVENTS.ROAD_WORKS,
    NAME: 'isRoadWorks',
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. иконок «Дорожные работы» <br /> на карте{' '}
        <b>при загрузке приложения.</b>
      </div>
    ),
  },
  {
    LABEL: EVENTS.ACTIVITIES,
    NAME: 'isActivities',
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. иконок «Мероприятия» <br /> на карте{' '}
        <b>при загрузке приложения.</b>
      </div>
    ),
  },
  {
    LABEL: EVENTS.TRAFFIC_RESTRICTIONS,
    NAME: 'isTrafficRestrictions',
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. иконок «Ограничения в движении» <br /> на карте{' '}
        <b>при загрузке приложения.</b>
      </div>
    ),
  },
] as const;

const TRAFFIC_LIGHTS_PHASES_DATA = [
  {
    LABEL: 'фазовый круг',
    NAME: 'isShowPhaseCircle',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: (
      <div>Вкл./откл. отображения светофорного круга на карте.</div>
    ),
  },
  {
    LABEL: 'фазы соседних светофоров',
    NAME: 'isAdjacentPhaseCircles',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. отображения работы <br /> фаз соседних светофоров на карте.
      </div>
    ),
  },
  {
    LABEL: 'продолжительность фаз',
    NAME: 'isPhaseCircleLabel',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. отображения времени <br />
        работы фаз светофора на карте.
      </div>
    ),
  },
  {
    LABEL: 'элементы под заливкой',
    NAME: 'isUnderCircleFill',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: (
      <div>
        Отображение стрелок, переходов, картинки перекрестка <br />
        под/над заливкой временной шкалы фазового круга.
      </div>
    ),
  },
] as const;

const OTHER_DATA = [
  {
    LABEL: OTHER.WIDJETS,
    NAME: 'isWidgets',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_OTHER.WIDJETS,
  },
  {
    LABEL: OTHER.DEBUG,
    NAME: 'isDebugMode',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_OTHER.DEBUG,
  },
] as const;

export const HINTS_DATA = [
  {
    LABEL: HINTS.INTERFACE,
    NAME: 'interfaceTipsDelay',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_HELPERS.INTERFACE,
  },
  {
    LABEL: HINTS.MAP,
    NAME: 'mapTipsDelay',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_HELPERS.MAP,
  },
  {
    LABEL: HINTS.BTNS_MAP,
    NAME: 'mapButtonsTipsDelay',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_HELPERS.BTNS_MAP,
  },
  {
    LABEL: HINTS.PROFILE,
    NAME: 'profileTipsDelay',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_HELPERS.PROFILE,
  },
] as const;

const CLUSTERS_DATA = [
  {
    LABEL: CLUSTERS_INFO.CLUSTERIZATION,
    NAME: 'isClusters',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_CLUSTERS.CLUSTERIZATION,
  },
  {
    LABEL: CLUSTERS_INFO.OPENED,
    NAME: 'isNeedOpenedCluster',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_CLUSTERS.OPENED,
  },
] as const;

const DISPLAY_DATA = [
  {
    LABEL: DISPLAY_INFO.CENTER,
    NAME: 'isCenterDependsOnPanels',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DISPLAY.CENTER_DEPENDS_ON_PANEL,
  },
  {
    LABEL: DISPLAY_INFO.INFO_PANEL,
    NAME: 'isOpenInfoPanel',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DISPLAY.INFO_PANEL,
  },
  {
    LABEL: DISPLAY_INFO.INFO_BTN,
    NAME: 'isInfoBtn',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DISPLAY.INFO_BTN,
  },
  {
    LABEL: DISPLAY_INFO.ALARM_ALERT,
    NAME: 'isAlarmAlert',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DISPLAY.ALARM_ALERT,
  },
  {
    LABEL: DISPLAY_INFO.MINIFIC_MAP,
    NAME: 'isMinificMap',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DISPLAY.MINIFIC_MAP,
  },
  {
    LABEL: DISPLAY_INFO.SCALE_LABEL,
    NAME: 'isScaleLine',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DISPLAY.SCALE_LABEL,
  },
  {
    LABEL: DISPLAY_INFO.SLIDER,
    NAME: 'mapIconsSize',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DISPLAY.SLIDER,
  },
] as const;

export const PROFILE_SETTINGS_MAIN = [
  { TITLE: 'подсистемы', DATA: SUBSYSTEMS_DATA },
  { TITLE: 'параметры', DATA: PARAMS_DATA },
  { TITLE: 'события', DATA: EVENTS_DATA },
  { TITLE: 'светофорные фазы', DATA: TRAFFIC_LIGHTS_PHASES_DATA },
  { TITLE: 'кластеризация', DATA: CLUSTERS_DATA },
  { TITLE: 'отображение', DATA: DISPLAY_DATA },
  { TITLE: 'другое', DATA: OTHER_DATA },
];
