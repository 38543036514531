import { useEffect, useState } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { MapActions } from '../../../stores/uiStore/uiStore';
import { System } from '../../../ts/enums/enums';
import { UseCrosshairPopupProps } from '../Map.model';

const useCrosshairPopup = ({
  isInfoPanel,
  clusters,
  infoData,
  isClusterOpen,
}: UseCrosshairPopupProps) => {
  const { setInfoData } = rootStore.uiStore;

  const [isCrosshairPopup, setIsCrosshairPopup] = useState(true);

  useEffect(() => {
    if (!infoData || !isInfoPanel) return;

    if (!clusters.length) {
      setIsCrosshairPopup(true);
    }
  }, [clusters.length, infoData, isInfoPanel]);

  useEffect(() => {
    if (!isInfoPanel) {
      setIsCrosshairPopup(false);
    }
  }, [isInfoPanel]);

  useEffect(() => {
    if (!infoData || !isInfoPanel || !clusters.length) return;

    const { id, system, coordFeatureOnCluster } = infoData;

    if (!isClusterOpen && coordFeatureOnCluster) {
      setInfoData(
        { ...infoData, coordFeatureOnCluster: undefined },
        MapActions.NoActions
      );
    }

    if (coordFeatureOnCluster)
      return setIsCrosshairPopup(system !== System.Clusters);

    const isClusterFeatureOpen = clusters.reduce((acc: boolean, item) => {
      const isOnCluster = item.some((el) => el.get('id') === id);

      if (isOnCluster) {
        return false;
      }

      return acc;
    }, true);

    setIsCrosshairPopup(system !== System.Clusters && isClusterFeatureOpen);
  }, [clusters, infoData, isInfoPanel, isClusterOpen, setInfoData]);

  return { isCrosshairPopup, setIsCrosshairPopup };
};

export default useCrosshairPopup;
