import { CiImageOff } from 'react-icons/ci';

import { ReactComponent as CameraIco } from '../../../assets/icons/map/ic_camera.svg';
import { ReactComponent as DetectorsIco } from '../../../assets/icons/map/ic_detectors.svg';
import { ReactComponent as MeteoIco } from '../../../assets/icons/map/ic_meteo.svg';
import { ReactComponent as PublickTrIco } from '../../../assets/icons/map/ic_public_transport.svg';
import { ReactComponent as SpecialEquipIco } from '../../../assets/icons/map/ic_special_transport.svg';
import { ReactComponent as TrafficLightIco } from '../../../assets/icons/map/ic_tr_light.svg';
import { SYSTEM } from '../../../constants/constants';
import { TL_STATUS_COLORS_HEX } from '../../../constants/tlStatusConstants';
import parseTLMode from '../../../helpers/parseTLMode';
import { TlMode } from '../../../ts/models/tl.model';

export const switchIco = (_system: any, mode?: TlMode) => {
  const { color } = parseTLMode(mode ?? null);

  switch (_system) {
    case SYSTEM.LIGHTS:
      return (
        <TrafficLightIco
          style={{
            color: color ? color : TL_STATUS_COLORS_HEX.GREY,
          }}
        />
      );
    case SYSTEM.DETECTORS:
      return <DetectorsIco />;
    case SYSTEM.CAMERAS:
      return <CameraIco />;
    case SYSTEM.SPECIAL_TRANSPORT:
      return <SpecialEquipIco />;
    case SYSTEM.PUBLIC_TRANSPORT:
      return <PublickTrIco />;
    case SYSTEM.METEO:
      return <MeteoIco />;
    default:
      return <CiImageOff style={{ color }} />;
  }
};
