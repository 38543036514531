import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { RiErrorWarningLine } from 'react-icons/ri';
import { VscSettings } from 'react-icons/vsc';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { IQueryParamItem } from '../../../../../ts/models/table.model';
import Popover from '../../../Popover/Popover';
import { getSearchCount } from '../../helpers/getSearchCout';
import Counter from '../Counter/Counter';

import PopoverContent from './PopoverContent/PopoverContent';

import styles from './OpenBtn.module.scss';

interface IOpenBtns {
  showDrawer: () => void;
  queryParams: IQueryParamItem[];
  limitIsExceeded: U<boolean>;
}

const OpenBtn: FC<IOpenBtns> = ({
  showDrawer,
  queryParams,
  limitIsExceeded,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const popoverProps = {
    placement: 'left',
    tipsDelay: interfaceTipsDelay,
  };

  const btnPopover = {
    ...popoverProps,
    content: <PopoverContent queryParams={queryParams} />,
  };
  const warningPopover = {
    ...popoverProps,
    content: 'Превышен лимит получаемых данных. Скорректируйте запрос!',
  };

  return (
    <div className={styles.openBtn}>
      {limitIsExceeded && (
        <Popover {...warningPopover}>
          <RiErrorWarningLine className={styles.warning} />
        </Popover>
      )}
      <Popover {...btnPopover}>
        <Button type="primary" onClick={showDrawer}>
          <VscSettings />
          <Counter count={getSearchCount(queryParams)} />
        </Button>
      </Popover>
    </div>
  );
};

export default observer(OpenBtn);
