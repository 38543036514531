import { FC } from 'react';

import { SYSTEM } from '../../../../../../constants/constants';
import { TL_STATUS_COLORS_HEX } from '../../../../../../constants/tlStatusConstants';
import parseTLMode from '../../../../../../helpers/parseTLMode';
import { System } from '../../../../../../ts/enums/enums';
import { TlMode } from '../../../../../../ts/models/tl.model';

import styles from './StatusColor.module.scss';

const { LIGHTS } = SYSTEM;

interface IStatusColor {
  mode: TlMode;
  system: System;
}

const StatusColor: FC<IStatusColor> = ({ mode, system }) => {
  if (system !== LIGHTS) return null;

  const { color } = parseTLMode(mode);

  return (
    <div
      className={styles.wrapper}
      style={{ background: color ? color : TL_STATUS_COLORS_HEX.GREY }}
    ></div>
  );
};

export default StatusColor;
