import Feature from 'ol/Feature';

import addMarkers from './addMarkers';

const updateLights = (
  tls: any,
  system: any,
  infoData: any,
  isAdjacentPhaseCircles: boolean
) => {
  const { data, title } = system;
  const newLightsFeatures = addMarkers(data, title);
  const adjacentIds = tls.find((el: any) => el.id === infoData?.id)
    ?.linkedDevices?.trafficLights;

  return newLightsFeatures.filter(
    (feature: Feature) =>
      infoData?.id !== feature.get('id') &&
      (adjacentIds && isAdjacentPhaseCircles
        ? !adjacentIds.some((id: number) => id === feature.get('id'))
        : true)
  );
};

export default updateLights;
