import { observer } from 'mobx-react-lite';
import React, { MouseEventHandler } from 'react';
import type { IconType } from 'react-icons/lib';
import { MdSettings } from 'react-icons/md';

import { ReactComponent as ArrowBack } from '../../../assets/icons/ic_arrow_back.svg';
import rootStore from '../../../stores/rootStore/rootStore';
import ButtonIco from '../../ui-kit/ButtonIco/ButtonIco';

import styles from './CollapseBtn.module.scss';

interface CollapseBtnProps {
  onAction?: MouseEventHandler<HTMLButtonElement>;
  ActionIco?: IconType;
}

const CollapseBtn: React.FC<CollapseBtnProps> = ({
  onAction,
  ActionIco = () => <MdSettings />,
}) => {
  const { setIsNot } = rootStore.uiStore;

  return (
    <div className={styles.container}>
      <div className={styles.rollUp}>
        <button
          className={styles.backBtn}
          onClick={() => setIsNot('isPanel', false)}
        >
          <ArrowBack />
        </button>
        <span>свернуть панель</span>
      </div>
      {onAction && (
        <ButtonIco isRound isSmall onClick={onAction}>
          <ActionIco />
        </ButtonIco>
      )}
    </div>
  );
};

export default observer(CollapseBtn);
