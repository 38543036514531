import { MapObject } from '../../../ts/enums/enums';
import mapObjectsApi from '../mapObjectsApi';

const camerasApi = {
  fetchAll: async (regionId: number) =>
    await mapObjectsApi.fetchFullObject(MapObject.cm, regionId),

  fetchItem: async (id: number) =>
    await mapObjectsApi.fetchObjectItem(MapObject.cm, id),
};

export default camerasApi;
