import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import Control from 'ol/control/Control';
import OverviewMap from 'ol/control/OverviewMap';
import OLTileLayer from 'ol/layer/Tile';
import { FC, useContext, useEffect } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import MapContext from '../../Map/MapContext';

import styles from './OverviewMapControl.module.scss';

interface OverviewMapProps {
  source: any;
}

const OverviewMapControl: FC<OverviewMapProps> = ({ source }) => {
  const { isMinificMapOpen, isPanel, isMinificMap, isWidgets } =
    rootStore.uiStore;

  const { map }: any = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    map.getControls().forEach((interaction: Control) => {
      if (interaction instanceof OverviewMap) {
        map.removeControl(interaction);
      }
    });

    if (!isMinificMapOpen || !isMinificMap || isWidgets) return;

    const overviewMapStyle = classNames({
      [styles.olCustomOverviewmap]: true,
      [styles.olCustomOverviewmapDef]: isPanel,
    });

    const overviewMapContrl = new OverviewMap({
      className: overviewMapStyle,
      layers: [
        new OLTileLayer({
          source,
        }),
      ],
      collapseLabel: '',
      label: '',
      rotateWithView: true,
    });

    map.addControl(overviewMapContrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMinificMapOpen, map, isPanel, isMinificMap, isWidgets]);

  return null;
};

export default observer(OverviewMapControl);
