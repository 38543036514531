import { TL_NAME_STORE } from '../../../constants/mapConstants';
import { LinkedDivices } from '../../../stores/mapDataStore/mapDataStore.model';
import rootStore from '../../../stores/rootStore/rootStore';
import { MapObjectFull } from '../../../ts/enums/enums';
import { ICamera } from '../../../ts/models/camera.model';
import { Detector } from '../../../ts/models/mapObject.model';
import { TL } from '../../../ts/models/tl.model';

type TObj = TL | ICamera | Detector;

export const getFilteredLinkedDevices = (
  currentObject: U<TObj>
): N<LinkedDivices> => {
  if (!currentObject?.linkedDevices) return null;

  const arrLinks = Object.entries(currentObject.linkedDevices) as [
    string,
    number[]
  ][];

  if (!arrLinks.length) return null;

  const { getById } = rootStore.mapDataStore;
  const filtered: LinkedDivices = arrLinks.reduce((acc: any, [key, value]) => {
    const systemKey = key === MapObjectFull.trafficLights ? TL_NAME_STORE : key;

    //@ts-ignore
    const newLinks = value.filter((id) => getById(id, systemKey));

    acc[key] = newLinks;

    return acc;
  }, {});

  return filtered;
};
