import { Tabs } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import tradeMarksApi from '../../../api/catalogs/trademarks/trademarks';
import { DataType, ITableColumn } from '../../../ts/models/table.model';
import Table from '../../ui-kit/Table/Table';
import { parsingTableData } from '../helpers/parsingTableData';

import styles from './Equipments.module.scss';

interface IData {
  dataRows: DataType[];
  headerItems: ITableColumn[];
}

const Equipments = () => {
  const [standartsData, setStandartData] = useState<IData>({
    dataRows: [],
    headerItems: [],
  });
  const [extendedData, setExtendedData] = useState<IData>({
    dataRows: [],
    headerItems: [],
  });

  const getStandartData = useCallback(async () => {
    const data = await tradeMarksApi.fetchStandartData('admin.tradeMarks');

    setStandartData(parsingTableData(data));
  }, []);

  const getExtendedData = useCallback(async () => {
    const data = await tradeMarksApi.fetchExtendedData('admin.tradeMarks');

    setExtendedData(parsingTableData(data));
  }, []);

  useEffect(() => {
    getStandartData();
    getExtendedData();
  }, [getExtendedData, getStandartData]);

  const tabsItems = [
    {
      label: 'Стандарт',
      key: 'item-1',
      children: (
        <Table
          data={standartsData?.dataRows}
          headerItems={standartsData?.headerItems}
          tableConfigType="fullScreen"
        />
      ),
    },
    {
      label: 'Производитель+',
      key: 'item-2',
      children: (
        <Table
          data={extendedData?.dataRows}
          headerItems={extendedData?.headerItems}
          tableConfigType="fullScreen"
        />
      ),
    },
  ];

  return (
    <div className={styles.tabs}>
      <Tabs items={tabsItems} type="card" />
    </div>
  );
};

export default Equipments;
