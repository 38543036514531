import { Dayjs } from 'dayjs';

import { getSubtractDay } from './getSubtractDay';

export enum SliceTypes {
  hour = 'hour',
  day = 'day',
}

export const getSliceDates = (
  day: number,
  sliceType: SliceTypes = SliceTypes.hour
): [Dayjs, Dayjs] => {
  const selectDates = [...getSubtractDay(day)];

  const dateFrom = selectDates[0].startOf(sliceType);
  const dateTo = selectDates[1].endOf(sliceType);

  return [dateFrom, dateTo];
};
