/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Popover } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { RiCloseCircleFill } from 'react-icons/ri';

import rootStore from '../../stores/rootStore/rootStore';
import WidgetSlider from '../WidgetSlider/WidgetSlider';

import styles from './Widgets.module.scss';

const Widgets: FC = () => {
  const {
    isWidgets,
    isInfoPanel,
    isCollapseInfoPanel,
    interfaceTipsDelay,
    setIsNot,
  } = rootStore.uiStore;

  if (!isWidgets) {
    return null;
  }

  const closeWidgets = () => {
    setIsNot('isWidgets', false);
  };

  const containerStyle = classNames({
    [styles.container]: true,
    [styles.shortVersion]: !isCollapseInfoPanel && isInfoPanel,
  });

  const popOverProps: any = {
    placement: 'left',
    tipsDelay: interfaceTipsDelay,
  };

  return (
    <aside className={containerStyle}>
      <WidgetSlider />
      <Popover content="Скрыть виджеты" {...popOverProps}>
        <div className={styles.closeBtn} onClick={closeWidgets}>
          <RiCloseCircleFill />
        </div>
      </Popover>
    </aside>
  );
};

export default observer(Widgets);
