import { useState, useEffect } from 'react';

import api from '../../../api';
import { IUserData } from '../../../stores/userDataStore/userDataStore';

const useUserInfo = (userId: number) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [user, setUser] = useState<IUserData | null>(null);

  const fetchUserByID = async (id: number) => {
    try {
      setIsLoading(true);
      const res = await api.user.info(id, 'admin.users.table');

      setUser(res.data.user);
    } catch (e) {
      setError('Ошибка загрузки данных пользователя');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserByID(userId);
  }, [userId]);

  return { user, isLoading, error };
};

export default useUserInfo;
