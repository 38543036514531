import { isNumber } from 'lodash';
import { useMemo } from 'react';

import { IInfoPanelData } from '../../../stores/uiStore/uiStore';
import { System } from '../../../ts/enums/enums';
import { TL } from '../../../ts/models/tl.model';

interface IUseLinkedTlsIds {
  tls: TL[];
  infoData: N<IInfoPanelData>;
  isAdjacentPhaseCircles: boolean;
  isPhaseCircle: boolean;
}

const useLinkedTlsIds = ({
  tls,
  infoData,
  isAdjacentPhaseCircles,
  isPhaseCircle,
}: IUseLinkedTlsIds) => {
  const idCurrentObj = useMemo(
    () =>
      infoData && infoData.system === System.Lights && isNumber(infoData.id)
        ? infoData.id
        : null,
    [infoData]
  );

  const activeTl = useMemo(
    () => idCurrentObj && (tls.find(({ id }) => idCurrentObj === id) ?? null),
    [tls, idCurrentObj]
  );

  const linkedDevicesTlsIds = useMemo(() => {
    if (!activeTl) return [];

    const isNeedSecWithoutPhaseCircle =
      isAdjacentPhaseCircles && !isPhaseCircle;

    if (!isAdjacentPhaseCircles || isNeedSecWithoutPhaseCircle)
      return [activeTl.id];

    const linkedDevicesIds = activeTl.linkedDevices?.trafficLights ?? [];

    const cloneLinks = [...linkedDevicesIds];

    const filtered = cloneLinks.find((elId) => elId === activeTl.id);

    !filtered && cloneLinks.push(activeTl.id);

    return cloneLinks;
  }, [activeTl, isAdjacentPhaseCircles, isPhaseCircle]);

  return { linkedDevicesTlsIds };
};

export default useLinkedTlsIds;
