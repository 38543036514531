export interface IMessageAdditional {
  pcgCmdUid: string;
  comment: string;
  eventId: number;
  eventType: number;
  msgUid: string;
}

export interface IMessage {
  additional: N<IMessageAdditional>;
  message: string;
  timestamp: number;
  type: EMessage;
  userData: {
    id: number;
    login: string;
    name: string;
  };
}

export enum EMessage {
  Default = 'default',
  Primary = 'primary',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}
