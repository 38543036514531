import { Form, Slider, Switch, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';

import { COLORS } from '../../../constants/colorsConstants';
import { DEFAULT_CIRCLE } from '../../../constants/constructorConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import CircularSlider from '../../ui-kit/CircularSlider/CircularSlider';
import getSliderProps from '../helpers/getSliderProps';

import styles from './CircleParamsConstructor.module.scss';

const { DIAMETER_RATE, BORDER_WIDTH, OPACITY } = DEFAULT_CIRCLE;

function CircleCenterConstructor() {
  const {
    view,
    circleOpacity,
    setConstructorData,
    setIsNotConstructor,
    circleDiameterRate,
    circleBorderWidth,
    isBlinkingAnimation,
    circleHue,
  } = rootStore.constructorStore;

  const { isBigZoom } = rootStore.mapDataStore;

  const handleDiameter = (val: number) =>
    setConstructorData('circleDiameterRate', val);
  const handleOpacity = (val: number) =>
    setConstructorData('circleOpacity', val);
  const handleBlinkingAnimation = () =>
    setIsNotConstructor('isBlinkingAnimation');
  const handleBorderWidth = (val: number) =>
    setConstructorData('circleBorderWidth', val);
  const handleHue = (val: number) => setConstructorData('circleHue', val);

  const diameterTitle = !isBigZoom
    ? 'Необходимо увеличить масштаб для активации настройки'
    : '';

  return (
    <div className={styles.settingsContent}>
      <div className={styles.row}>
        <span>Анимация - мигание:</span>

        <Switch
          checked={isBlinkingAnimation}
          onClick={handleBlinkingAnimation}
          size="small"
        />
      </div>

      <Tooltip placement="left" title={diameterTitle}>
        <Form.Item label="Коэффициент диаметра круга:">
          <Slider
            {...getSliderProps(15, 25, DIAMETER_RATE, 1)}
            onChange={handleDiameter}
            value={circleDiameterRate}
            disabled={!isBigZoom}
          />
        </Form.Item>
      </Tooltip>

      <Form.Item label="Ширина границы:">
        <Slider
          {...getSliderProps(0, 50, BORDER_WIDTH, 1)}
          onChange={handleBorderWidth}
          value={circleBorderWidth}
        />
      </Form.Item>

      <Form.Item label="Прозрачность заливки круга:">
        <Slider
          {...getSliderProps(0, 100, OPACITY, 1)}
          onChange={handleOpacity}
          value={circleOpacity}
          disabled={!view.isCircleFill}
        />
      </Form.Item>

      <Form.Item label="Оттенок:">
        <div className={styles.circularSingle}>
          <CircularSlider
            color={COLORS.TURQUOISE}
            label="УГОЛ"
            onChange={handleHue}
            value={circleHue}
          />
        </div>
      </Form.Item>
    </div>
  );
}

export default observer(CircleCenterConstructor);
