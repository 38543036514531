/*
Attention, we deliberately added the return type of the object 
(I hope it's temporary) 
because we are sure that the object is exactly contained in the array
*/

type FindObject = Record<string, any>;

export const findById = <
  T extends FindObject,
  V extends T[K],
  K extends keyof T
>(
  arr: T[],
  val: V,
  key: 'id' = 'id'
) => arr.find((el) => el[key] === val) ?? ({} as T);

export const findBy = <T extends FindObject, V extends T[K], K extends keyof T>(
  arr: T[],
  val: V,
  key: K
) => arr.find((el) => el[key] === val) ?? ({} as T);
