import { TArr, getArrSum } from './getArrSum';
import { getDateRangeStr } from './getDateRangeStr';
import { ICalcData } from './getFormatDataDt';
export interface IData {
  time: string;
  dateTo: string;
  dateFrom: string;
}

export const getAverage = (
  data: IData[],
  chartNames: string[]
): ICalcData[] => {
  const calcData = data.map((item: IData) => {
    const dataArr = Object.values(item) as TArr;
    const avgValue = getArrSum(dataArr);

    const comparableValue = Math.ceil(avgValue / chartNames.length);

    const { dateFrom, dateTo } = item;

    const { normalDateTime } = getDateRangeStr(dateFrom, dateTo);

    return { comparableValue, date: normalDateTime };
  });

  return calcData;
};
