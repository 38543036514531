import DOMPurify from 'dompurify';
import { CSSProperties, FC } from 'react';

import PhaseCircleImgWrapper from '../PhaseCircleImgWrapper/PhaseCircleImgWrapper';

interface ImgProps {
  data: string;
  style: CSSProperties;
}

const PhaseCircleImg: FC<ImgProps> = ({ data, style }) => (
  <PhaseCircleImgWrapper style={style}>
    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data) }} />
  </PhaseCircleImgWrapper>
);

export default PhaseCircleImg;
