import { z } from 'zod';

export const InfoSchema = z.object({
  message: z.string(),
});

export const RegionDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  extent: z.tuple([z.number(), z.number(), z.number(), z.number()]),
  region: z.string(),
  initialZoom: z.number(),
  mapCenterCoords: z.tuple([z.number(), z.number()]),
  timeZone: z.number(),
});

export type IRegionData = z.infer<typeof RegionDataSchema>;
