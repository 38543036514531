import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { DEFAULT_CIRCLE } from '../../../../constants/constructorConstants';
import rootStore from '../../../../stores/rootStore/rootStore';
import Cameras from '../../Cameras/Cameras';
import {
  useTLTacts,
  usePhaseData,
  useFormattedData,
  useCircleDiameter,
} from '../../hooks';
import PhaseCircleNoData from '../../PhaseCircleNoData/PhaseCircleNoData';
import TrafficLanesBlock from '../../TrafficLanesBlock/TrafficLanesBlock';
import getPhaseNumber from '../helpers/getPhaseNumber';
import PhaseCircle from '../PhaseCircle/PhaseCircle';
import PhaseCircleHeader from '../PhaseCircleHeader/PhaseCircleHeader';
import PhaseCircleItems from '../PhaseCircleItems/PhaseCircleItems';
import PhaseCircleLayout from '../PhaseCircleLayout/PhaseCircleLayout';
import { IPhaseCircleOverlay } from '../PhaseCircleOverlay.model';

const { BORDER_WIDTH } = DEFAULT_CIRCLE;

const PhaseCircleMain: FC<IPhaseCircleOverlay> = ({ selectedTL, color }) => {
  const { secTls } = rootStore.uiStore;
  const { currentZoom, isCrossroadBorder } = rootStore.mapDataStore;
  const { isTrafficLanes } = rootStore.uiStore;

  const {
    tlStatus,
    extId,
    linkedDevices,
    finalArrayFromProfiles: TLanes,
  } = selectedTL;
  const len = tlStatus?.cycle;
  const cmd = tlStatus?.command?.full;

  const [tlTacts, tlZoomTact, tlTactImages] = useTLTacts({
    selectedTL,
    currentZoom,
  });

  const sec = secTls.find(({ id }) => id === selectedTL.id)?.sec ?? 0;

  const { rate } = useCircleDiameter();
  const formattedData = useFormattedData({ tlTacts });
  const phaseData = usePhaseData({ timer: sec, formattedData });

  if (!len || !tlZoomTact || phaseData?.phase === undefined || !formattedData) {
    return <PhaseCircleNoData />;
  }

  const invertedTLTacts = [...tlTacts].reverse();
  const phaseNumber = getPhaseNumber(phaseData.phase + 1);
  const phaseIdx = phaseData.phase;
  const restTime = (formattedData[phaseIdx]?.stop ?? sec) - sec;
  const circleBorderWidth = tlZoomTact.borderWidth ?? BORDER_WIDTH;

  const phaseCircleProps = {
    timer: sec,
    len,
    circleBorderWidth,
    data: invertedTLTacts,
    color: phaseData.color,
  };

  const phaseCircleHeaderProps = {
    len,
    phaseNumber,
    restTime,
    amount: tlTacts.length,
    color,
    cmd,
  };

  const itemsProps = {
    tlTactImages,
    tlTacts,
    phaseIdx,
    selectedTL,
  };

  return (
    <PhaseCircleLayout id={extId}>
      <PhaseCircleItems {...itemsProps} />
      {isCrossroadBorder && (
        <>
          <Cameras rate={rate} linkedCams={linkedDevices?.cameras ?? []} />

          {isTrafficLanes &&
            TLanes &&
            TLanes.map((lane) => (
              <TrafficLanesBlock
                rate={rate}
                trafficLane={lane}
                key={`lane_${lane.id}`}
              />
            ))}
        </>
      )}
      <PhaseCircle {...phaseCircleProps} />
      <PhaseCircleHeader {...phaseCircleHeaderProps} />
    </PhaseCircleLayout>
  );
};

export default observer(PhaseCircleMain);
