import CircularSliderReact from '@fseehawer/react-circular-slider';

import { COLORS } from '../../../constants/colorsConstants';

interface CircularSliderProps {
  onChange: (e: number) => void;
  label: string;
  width?: number;
  trackColor?: any;
  color?: string;
  colorStart?: any;
  labelColor?: string;
  value: number;
}

const CircularSlider: React.FC<CircularSliderProps> = (
  props: CircularSliderProps
) => {
  const {
    onChange,
    label,
    width = 100,
    trackColor = COLORS.WHITE600,
    color = COLORS.PRIMARY,
    colorStart = COLORS.BLUE100,
    labelColor = '',
    value = 0,
  } = props;

  return (
    <CircularSliderReact
      min={0}
      max={360}
      knobPosition="top"
      appendToValue="°"
      valueFontSize="2rem"
      width={width}
      label={label}
      labelFontSize="1.2rem"
      labelColor={labelColor || color}
      knobColor={color}
      progressColorFrom={colorStart}
      progressColorTo={color}
      progressSize={5}
      knobSize={24}
      trackColor={trackColor}
      trackSize={2}
      onChange={onChange}
      dataIndex={value}
    />
  );
};

export default CircularSlider;
