export const TL_DETAILED = {
  MODE: {
    Off: {
      com: 0,
      dsc: 'Отключен',
    },
    Work: {
      com: 1,
      dsc: 'Рабочий штатный режим',
    },
    Critical: {
      com: 2,
      dsc: 'Критическая неисправность',
    },
    ManualControlVPU: {
      com: 3,
      dsc: 'Ручное управление с ВПУ',
    },
    TechnologicalMode: {
      com: 4,
      dsc: 'Технологический режим',
    },
    YellowFlash: {
      com: 5,
      dsc: 'Желтое мигание (ЖМ)',
    },
    AllRed: {
      com: 6,
      dsc: 'Всем красный',
    },
    RemoteControl: {
      com: 7,
      dsc: 'Удаленное управление из АСУДД',
    },
    AdaptiveControl: {
      com: 9,
      dsc: 'Адаптивное управление с сервера',
    },
    CoordinatedManagement: {
      com: 10,
      dsc: 'Координированное управление',
    },
    LocalAdaptiveControl: {
      com: 11,
      dsc: 'Локальное адаптивное управление',
    },
    Unknown: {
      com: 255,
      dsc: 'Режим неизвестен',
    },
  },
  SOURCE: {
    FB: {
      com: 0,
      dsc: 'ФБ - Физический Блок/Неисправность',
    },
    RU: {
      com: 1,
      dsc: 'РУ – Ручное Управление/Выносной Пульт Управления',
    },
    TVP: {
      com: 2,
      dsc: 'ТВП – Табло Вызова Пешеходом (кнопка вызова пешеходной фазы)',
    },
    SA: {
      com: 3,
      dsc: 'СА – Сетевой Адаптер/Центр',
    },
    DT: {
      com: 4,
      dsc: 'ДТ – Детектор Транспорта/МГР (Местное Гибкое/Адаптивное Регулирование)',
    },
    IP: {
      com: 5,
      dsc: 'ИП – Инженерный Пульт/Пульт диагностики контроллера',
    },
    APP: {
      com: 6,
      dsc: 'АПП – Автоматический Переключатель Программ (ЛР – Локальный Режим)',
    },
    DU: {
      com: 7,
      dsc: 'ДУ – Диспетчерское Управление',
    },
    PS: {
      com: 254,
      dsc: 'ПС – Потеря Связи/Отсутствие связи',
    },
    UNKNOWN: {
      com: 255,
      dsc: 'неизвестно',
    },
  },
  STATE: {
    Work: {
      com: 1,
      dsc: 'Работает',
    },
    NotWork: {
      com: 2,
      dsc: 'Не работает',
    },
    Error: {
      com: 3,
      dsc: 'Ошибка',
    },
    NotConnection: {
      com: 4,
      dsc: 'Нет связи',
    },
    Undefined: {
      com: 5,
      dsc: 'Неизвестно',
    },
    Delete: {
      com: 6,
      dsc: 'Удалено',
    },
    NotAvailable: {
      com: 7,
      dsc: 'Недоступен',
    },
  },
  PHASE_ID: {
    SWITCHED_OUT: 'off',
    CMD_SWITCHED_OUT: 0,
    AROUND_RED: 'ar',
    CMD_AROUND_RED: 1,
    YELLOW_FLASHING: 'yf',
    CMD_YELLOW_FLASHING: 2,
    EDITED: 'edt',
    COMMON: 'off',
  },
} as const;
