/* eslint-disable jsx-a11y/no-static-element-interactions */
import { message } from 'antd';
import classNames from 'classnames';
import { FC, useState } from 'react';

import Copy from '../Copy/Copy';

import Chunk from './Chunk/Chunk';
import Quote from './Quote';

import styles from './JsonView.module.scss';

interface JsonViewProps {
  object: object;
  editSetObject?: SetState<object>;
}

const JsonView: FC<JsonViewProps> = ({ object, editSetObject }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string>('');
  const [isValide, setIsValide] = useState<boolean>(true);

  const pickIsEditHandler = () => {
    setIsEdit(true);

    setTextValue(JSON.stringify(object, null, 2));
  };

  const onTabHandle = (event: any) => {
    if (event.code === 'Tab') event.preventDefault();
  };

  const saveEditHandle = () => {
    try {
      const newObject = JSON.parse(textValue);

      if (!editSetObject) return;
      editSetObject(newObject);
      setIsEdit(false);
    } catch (e) {
      message.error('JSON строка не валидна');

      setIsValide(false);

      setTimeout(() => {
        setIsValide(true);
      }, 1000);
    }
  };

  const getTextArea = (): JSX.Element => {
    return (
      <>
        <code className={styles.code}>
          <textarea
            rows={textValue.split('\n').length}
            className={styles.textArea}
            value={textValue}
            onChange={(e) => setTextValue(e.target.value)}
            onKeyDown={onTabHandle}
            spellCheck={false}
          />
          {isEdit && (
            <div className={styles.editBtn} onClick={saveEditHandle}>
              Сохранить
            </div>
          )}
        </code>
      </>
    );
  };

  const getPretyJson = (): JSX.Element => {
    return (
      <code className={styles.code}>
        <Copy
          copyString={JSON.stringify(object, null, 2)}
          className={styles.copyButton}
        />
        {editSetObject && (
          <div className={styles.editBtn} onClick={pickIsEditHandler}>
            Редактировать
          </div>
        )}
        <Quote parantObj={object} position="start" />
        {Object.entries(object).map((entrie, i, array) => (
          <Chunk
            key={i}
            entrie={entrie}
            parent={object}
            isLastElement={array.length - 1 !== i}
            indentLevel={1}
          />
        ))}
        <Quote parantObj={object} position="end" />
      </code>
    );
  };

  const mainStyle = classNames({
    [styles.main]: true,
    [styles.redBorder]: !isValide,
  });

  return (
    <div className={mainStyle}>
      {editSetObject && isEdit ? getTextArea() : getPretyJson()}
    </div>
  );
};

export default JsonView;
