import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { ScaleLine } from 'ol/control';
import Control from 'ol/control/Control';
import { useContext, useEffect } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import MapContext from '../../Map/MapContext';

import styles from './ScaleLineControl.module.scss';

const ScaleLineControl = () => {
  const { isMinificMapOpen, isPanel, isWidgets, isScaleLine, isMinificMap } =
    rootStore.uiStore;

  const { map }: any = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    map.getControls().forEach((interaction: Control) => {
      if (interaction instanceof ScaleLine) {
        map.removeControl(interaction);
      }
    });

    if (isWidgets || !isScaleLine) return;

    const overviewMapStyle = classNames({
      [styles.scaleLineContainer]: true,
      [styles.scaleLineContainerIsPanel]: isPanel && isMinificMapOpen,
      [styles.scaleLineContainerIsMinificClose]: !isMinificMapOpen && !isPanel,
      [styles.scaleLineContainerIsMinificCloseIsPanel]:
        !isMinificMapOpen && isPanel,
      [styles.scaleLineContainerIsMinificSettingClose]:
        !isMinificMap && !isPanel,
      [styles.scaleLineContainerIsMinificSettingCloseIsPanel]:
        !isMinificMap && isPanel,
    });

    const mapScaleLine = new ScaleLine({
      className: overviewMapStyle,
    });

    map.addControl(mapScaleLine);
  }, [isMinificMapOpen, isPanel, isWidgets, isScaleLine, isMinificMap, map]);

  return null;
};

export default observer(ScaleLineControl);
