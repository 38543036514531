export const ZOOM = {
  INITIAL: 10,
  MAX: 22,
  MIN: 9,
  BIG: 16,
  FAR: 12.5,
  MEDIUM: 15.5,
  CLOSE: 17,
  LARGE: 19.5,
  CLUSTERS_BORDER: 19.5,
  DURATION: 500,
  STEP: 1,
  STEP_CONSTRUCTOR: 0.25,
  WHEEL_DURATION: 300,
  LESS_THEN_WHEEL_STEP: 0.99,
  CROSSROAD_BORDER: 18,
};

export const MAP_INITIAL_OPTIONS = {
  layers: [],
  controls: [],
  overlays: [],
};

export const CENTERING = {
  ANIMATION_DURATION: 500,
};

export const CURSOR_MAP = {
  POINTER: 'pointer',
  GRAB: 'grab',
  GRABBING: 'grabbing',
  EMPTY: '',
};

export const TL_NAME_STORE = 'tls';
