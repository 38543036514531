import { Checkbox } from 'antd';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { HiOutlineVideoCamera } from 'react-icons/hi';
import { MdOutlineDragIndicator } from 'react-icons/md';

import { cropHeaderByParams } from '../../../../../helpers/cropHeaderByParams';
import { findById } from '../../../../../helpers/findBy';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { IVideoLIstItem } from '../../../../../stores/videoWallPandelStore/videoWallPanelStore.model';
import Popover from '../../../../ui-kit/Popover/Popover';

import styles from './CamerasItem.module.scss';

const TITLE_LENGTH = 28;

interface ICamerasItem {
  camera: IVideoLIstItem;
}

const CamerasItem: FC<ICamerasItem> = ({ camera }) => {
  const { setVideoKeysValues, videoList } = rootStore.videoWallPanelStore;
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { cameras } = rootStore.mapDataStore;
  const [isAddtoVideolist, setIsAddtoVideolist] = useState(false);
  const currentCameraData = findById(cameras, camera.id);

  useEffect(() => {
    if (!videoList) return;
    setIsAddtoVideolist(false);

    videoList.forEach((item) => {
      if (item.id === camera?.id) {
        setIsAddtoVideolist(true);
      }
    });
  }, [camera?.id, videoList]);

  const svgStyles = classNames({
    [styles.active]: isAddtoVideolist,
  });

  const handleChange = () => {
    if (!isAddtoVideolist) {
      let isAdd = false;

      const newVideoList = videoList.map((item) => {
        if (!isAdd && !item.caption) {
          isAdd = true;

          return camera;
        }

        return item;
      });

      setVideoKeysValues({
        videoList: newVideoList,
        isChanged: true,
      });
      setIsAddtoVideolist(true);

      return;
    }

    const newVideoList = videoList.map((item) => {
      if (item.id === camera?.id) {
        const emptyElement = {
          id: uniqueId(),
        };

        return emptyElement;
      }

      return item;
    });

    setVideoKeysValues({
      videoList: newVideoList,
      isChanged: true,
    });
    setIsAddtoVideolist(false);
  };

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'item',
      item: camera,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [camera]
  );

  const wrapperStyles = classNames({
    [styles.wrapper]: true,
    [styles.active]: isAddtoVideolist,
    [styles.isDragging]: isDragging,
  });

  return (
    <li className={wrapperStyles} ref={!isAddtoVideolist ? drag : null}>
      <Checkbox checked={isAddtoVideolist} onChange={handleChange} />
      <HiOutlineVideoCamera className={svgStyles} />
      {currentCameraData.caption &&
      currentCameraData.caption.length > TITLE_LENGTH ? (
        <Popover
          content={currentCameraData.caption}
          tipsDelay={interfaceTipsDelay}
        >
          <div>
            {cropHeaderByParams(currentCameraData?.caption, TITLE_LENGTH)}
          </div>
        </Popover>
      ) : (
        <div>
          {currentCameraData.caption &&
            cropHeaderByParams(currentCameraData?.caption, TITLE_LENGTH)}
        </div>
      )}
      <MdOutlineDragIndicator className={svgStyles} />
    </li>
  );
};

export default observer(CamerasItem);
