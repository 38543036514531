type ArrayObjectElement = Record<string, any>;

export const filterArrayByKey = <T extends ArrayObjectElement>(
  array: T[],
  key: string,
  value?: unknown
) =>
  array.reduce(
    (acc: T[][], item) => {
      if (value) {
        item[key] === value ? acc[0].push(item) : acc[1].push(item);

        return acc;
      }

      item[key] ? acc[0].push(item) : acc[1].push(item);

      return acc;
    },
    [[], []]
  );
