import type { Map } from 'ol';

import { ZOOM } from '../../../constants/mapConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { getFeaturesExtent } from '../../Overlays/InfoPopup/helpers/getFeaturesExtent';

const DURATION_CORRECTION = 50;

export const setClustersZoomChange = (
  map: Map,
  animationDuration = ZOOM.DURATION
) => {
  const { setClusters } = rootStore.mapDataStore;

  const durationAnim = animationDuration + DURATION_CORRECTION;

  setTimeout(() => {
    const clusters = getFeaturesExtent(map);

    clusters && setClusters(clusters.featuresCl);
  }, durationAnim);
};
