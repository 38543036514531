import { FC } from 'react';

import { IRequestItem } from '../../ts/TestingPanel.model';
import RequestBlock from '../RequestBlock/RequestBlock';

import styles from './RequestComponent.module.scss';

interface RequestComponentProps {
  request: IRequestItem | null;
}

const RequestComponent: FC<RequestComponentProps> = ({ request }) => {
  const getNotSelected = (): JSX.Element => {
    return (
      <div className={styles.notSelected}>
        <h3 className={styles.title}>Запрос не выбран</h3>
        <p className={styles.text}>Выберите запрос из меню в боковой панели</p>
      </div>
    );
  };

  return (
    <div className={styles.main}>
      <h2 className={styles.title}>Тестирование запроса</h2>
      {request ? <RequestBlock request={request} /> : getNotSelected()}
    </div>
  );
};

export default RequestComponent;
