/* eslint-disable camelcase */
import { z } from 'zod';

const DirectionsScheme = z.object({
  avSpeed: z.number(),
  vehicles: z.number(),
});

const DataScheme = z.object({
  date: z.string(),
  date_from: z.string(),
  date_to: z.string(),
  interval: z.number(),
  directions: DirectionsScheme.array(),
});

export const DetectorsDataSchema = z.object({
  jver: z.string(),
  directionsNames: z.array(z.string()),
  data: DataScheme.array(),
});
