import { FC } from 'react';

import styles from './InfoRow.module.scss';

interface IInfoRow {
  statusMode: any;
  currentId: number;
  isLights: boolean;
}

const InfoRow: FC<IInfoRow> = ({ statusMode, currentId, isLights }) => {
  if (!isLights) return null;

  return (
    <div className={styles.infoRow}>
      <p className={styles.infoTitle}>Режим СО: {statusMode.modeName}</p>
      <p className={styles.infoTitle}> ЕПУТС ID: {currentId} </p>
    </div>
  );
};

export default InfoRow;
