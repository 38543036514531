import { Map } from 'ol';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { toLonLat } from 'ol/proj';

import { ANIMATION_DURATION_CLUSTER } from '../../../../../constants/mapClusterConstants';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { MapActions } from '../../../../../stores/uiStore/uiStore';
import { System } from '../../../../../ts/enums/enums';
import { getFeatureOnClusterCoord } from '../../../../Map/helpers/getFeatureOnClusterCoord';

import { getFeatureById } from './getFeatureById';

export const setCenteredCluster = (
  map: Map,
  id: number,
  system: System,
  featuresOnCluster: Feature<Point>[],
  isClickedOnSameCluster: boolean
) => {
  const { setInfoData } = rootStore.uiStore;

  const featureSelect = getFeatureById(featuresOnCluster, id);

  const coordFeature = featureSelect?.getGeometry()?.getFlatCoordinates();

  if (!coordFeature || !featureSelect) return;

  const animationDuration = isClickedOnSameCluster
    ? 0
    : ANIMATION_DURATION_CLUSTER;

  setTimeout(() => {
    const coordFeatureOnCluster = getFeatureOnClusterCoord(map, featureSelect);

    setInfoData(
      {
        id,
        system,
        coordFeatureOnCluster,
        coordinate: toLonLat(coordFeature),
      },
      MapActions.NoActions
    );
  }, animationDuration);
};
