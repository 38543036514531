export const checkFilters = (filters: object) => {
  let count = 0;

  Object.values(filters).forEach((filter: N<[]>) => {
    if (!filter?.length) {
      count += 1;
    }
  });

  return count === Object.keys(filters).length;
};
