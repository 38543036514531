import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import { TLDetailedCode } from '../../../../ts/enums/userData';
import { PhaseCircleLayoutProps } from '../PhaseCircleOverlay.model';

import styles from './PhaseCircleLayout.module.scss';

const PhaseCircleLayout: FC<PhaseCircleLayoutProps> = ({ children, id }) => {
  const { isPhaseCircleLabel, setKeyValue, infoData, setIsNot } =
    rootStore.uiStore;
  const { hasAccess } = rootStore.userDataStore;

  const footerStyles = classNames({
    [styles.trafficLightId]: true,
    [styles.trafficLightIdOffset]: isPhaseCircleLabel,
  });

  const isDetailed = hasAccess(TLDetailedCode.isDetailed);

  const openDetails = () => {
    if (!infoData) return;

    setIsNot('isDetailedForm', true);
  };

  return (
    <div className={styles.phaseCircleContainer}>
      {children}

      <span className={footerStyles}>
        <Tooltip placement="left" title={'Открыть подробную информацию о СО'}>
          <Button
            type="primary"
            onClick={openDetails}
            className={isDetailed ? styles.detailedBtn : ''}
            disabled={!isDetailed}
          >
            {`№ ${Number(id)}`}
          </Button>
        </Tooltip>

        <Tooltip placement="right" title={'Закрыть круг'}>
          <Button
            className={styles.backPopupBtn}
            onClick={() => setKeyValue('isShowPhaseCircle', false)}
            type="primary"
            danger
          >
            X
          </Button>
        </Tooltip>
      </span>
    </div>
  );
};

export default observer(PhaseCircleLayout);
