import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { CameraExtendedProps } from '../../models/camera.model';

import ProjectionEl from './ProjectionEl';

import styles from './CameraExtended.module.scss';

const OFFSET = 1; //px
const PRE_COLOR = 'white';
const PRE_OPACITY_RATE = 0.6;
const PRE_ARC_RATE = 0.15;

const CameraExtended: FC<CameraExtendedProps> = ({ calcVal, camera }) => {
  const {
    distance,
    widthRate,
    arcHeight,
    preDistanceRate,
    color,
    opacity,
    angle,
  } = camera;

  const calcArcTop = (distance: number, arcHeight: number) =>
    `${-distance * 2 - arcHeight + OFFSET}px`;

  const curDistance = calcVal(distance);
  const preDistance = Math.round(curDistance * preDistanceRate);
  const curArcHeight = calcVal(arcHeight);
  const preArcHeight = Math.round(curArcHeight * PRE_ARC_RATE);
  const preArcHalfWidth = Math.round(preDistance * widthRate);
  const arcHalfWidth = Math.round(curDistance * widthRate);
  const preArcWidth = preArcHalfWidth * 2;
  const arcWidth = (arcHalfWidth - OFFSET) * 2;
  const preBorderSide = `${preArcHalfWidth}px solid transparent`;
  const borderSide = `${arcHalfWidth}px solid transparent`;
  const preOpacity = opacity * PRE_OPACITY_RATE;

  const preProjectionStyle = {
    borderLeft: preBorderSide,
    borderRight: preBorderSide,
    borderTop: `${preDistance * 2}px solid ${PRE_COLOR}`,
    opacity: preOpacity,
  };

  const preArcStyle = {
    top: calcArcTop(preDistance, preArcHeight),
    left: `${-preArcHalfWidth}px`,
    height: `${preArcHeight}px`,
    width: `${preArcWidth}px`,
    backgroundColor: PRE_COLOR,
    opacity: preOpacity,
  };

  const projectionStyle = {
    borderLeft: borderSide,
    borderRight: borderSide,
    borderTop: `${curDistance * 2}px solid ${color}`,
    opacity,
  };

  const arcStyle = {
    top: calcArcTop(curDistance, curArcHeight),
    left: `${-arcHalfWidth + OFFSET}px`,
    height: `${curArcHeight}px`,
    width: `${arcWidth}px`,
    backgroundColor: color,
    opacity,
    boxShadow: `0px 0px 3px ${color}`,
  };

  const cssStyles = [
    {
      className: styles.projection,
      style: projectionStyle,
    },
    {
      className: styles.projection,
      style: preProjectionStyle,
    },
    {
      className: styles.arc,
      style: arcStyle,
    },
    {
      className: styles.arc,
      style: preArcStyle,
    },
  ];

  return (
    <>
      {cssStyles.map((styles) => (
        <ProjectionEl
          key={uniqueId('projectionEL_')}
          {...styles}
          angle={angle}
        />
      ))}
    </>
  );
};

export default observer(CameraExtended);
