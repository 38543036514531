import { FC } from 'react';

import { findBy } from '../../../../../../helpers/findBy';
import { System } from '../../../../../../ts/enums/enums';
import { TlMode } from '../../../../../../ts/models/tl.model';
import { MODES_INFO } from '../../../../../TrafficLightDetailed/TlManagement/constants/constants';
import { getDeviceTitle } from '../helpers';

import styles from './PopoverContent.module.scss';

interface IPopoverContent {
  system: System;
  mode?: TlMode;
  currentObject: any;
  id: number;
}

const PopoverContent: FC<IPopoverContent> = ({
  system,
  mode,
  currentObject,
  id,
}) => {
  const title = getDeviceTitle(currentObject, system, id, true);

  if (!mode)
    return (
      <div className={styles.wrapper}>
        <p>Связанные {system}</p>
        <p>{title}</p>
      </div>
    );

  const status =
    findBy(Object.values(MODES_INFO), mode, 'com')?.dsc ??
    MODES_INFO.Unknown.dsc;

  return (
    <div className={styles.wrapper}>
      <p>Светофорный объект</p>
      <p>{title}</p>
      <hr />
      <p>Статус: {status}</p>
    </div>
  );
};

export default PopoverContent;
