import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { MdExitToApp } from 'react-icons/md';

import rootStore from '../../../../stores/rootStore/rootStore';
import { IUserData } from '../../../../stores/userDataStore/userDataStore';

import styles from './Buttons.module.scss';

interface IButtons {
  user: IUserData;
}
const EXIT = 'выход';
const CHANGE_REGION = 'выбор региона';

const Buttons: FC<IButtons> = ({ user }) => {
  const { resetUserData } = rootStore.userDataStore;

  const { clearDataAsRegion } = rootStore.uiStore;

  const handleLogout = () => {
    clearDataAsRegion();
    resetUserData();
  };

  return (
    <div className={styles.btnWrapper}>
      <Button className={styles.btn} onClick={handleLogout}>
        {EXIT}
        <MdExitToApp />
      </Button>

      {user.regions_id.length > 1 && (
        <Button className={styles.btn} onClick={clearDataAsRegion}>
          {CHANGE_REGION}
        </Button>
      )}
    </div>
  );
};

export default observer(Buttons);
