import { USERS_GROUPS_NAME } from '../../../../constants/adminConstants';
import { ITableColumn } from '../../../../ts/models/table.model';
import { IGroups } from '../../../Admin/models/adminTypes.model';

enum IFilters {
  access = 'access',
  active = 'active',
  groups = 'groups',
}

export const showFilterTagText = (
  filter: string,
  filteredInfo: any,
  headers: ITableColumn[],
  groups?: IGroups
) => {
  let columnName;

  headers.forEach((item) => {
    if (item.dataIndex === filter) {
      columnName = item.title;
    }
  });

  if (filter === IFilters.access) {
    const value = filteredInfo[filter][0] ? 'Разрешено' : 'Запрещено';

    return `${columnName}: ${value}`;
  }

  if (filter === IFilters.active) {
    const value = filteredInfo[filter][0] ? 'Активен' : 'Не активен';

    return `${columnName}: ${value}`;
  }

  if (filter === IFilters.groups) {
    const values = filteredInfo[filter];
    let valueString = '';

    values.forEach((value: number, i: number) => {
      if (groups) {
        valueString += groups[value] || USERS_GROUPS_NAME[value];
      } else {
        valueString += USERS_GROUPS_NAME[value];
      }

      if (i < values.length - 1) {
        valueString += ', ';
      }
    });

    return `${columnName}: ${valueString}`;
  }

  return `${columnName}: ${filteredInfo[filter][0]}`;
};
