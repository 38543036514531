import { observer } from 'mobx-react-lite';
import React, { Suspense, lazy, useEffect } from 'react';

import setDisplayStyle from '../../../helpers/setDisplayStyle';
import rootStore from '../../../stores/rootStore/rootStore';

import styles from './ConstructorOverlays.module.scss';

const ConstructorCircle = lazy(
  () => import('../../Overlays/ConstructorCircle/ConstructorCircle')
);

interface ConstructorOverlaysProps {
  constructorCircleRef: any;
  constructorCirclePopup: any;
}

function ConstructorOverlays(props: ConstructorOverlaysProps) {
  const { constructorCircleRef, constructorCirclePopup } = props;

  const {
    setConstructorData,
    isCircleConstructor,
    isConstructorLoaded,
    isCenteredByClick,
  } = rootStore.constructorStore;

  const { coordinates } = rootStore.mapDataStore;

  useEffect(() => {
    if (isCircleConstructor) {
      isCenteredByClick &&
        setConstructorData('circleCenter', coordinates as XY);
      setDisplayStyle([constructorCircleRef], 'block');
    }
  }, [coordinates]); // eslint-disable-line

  return (
    <div className={styles.circleContainer} ref={constructorCircleRef}>
      {isConstructorLoaded && isCircleConstructor ? (
        <Suspense>
          <ConstructorCircle constructorCirclePopup={constructorCirclePopup} />
        </Suspense>
      ) : null}
    </div>
  );
}

export default observer(ConstructorOverlays);
