import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { COLORS } from '../../../../constants/colorsConstants';
import { CameraCompactProps } from '../../models/camera.model';

import styles from './CameraCompact.module.scss';

const TRIANGLE_RATE = 0.36;
const PEAK_RATE = 3;
const TRIANGLE_CORRECTION = 2;

const CameraCompact: FC<CameraCompactProps> = ({ camera, size }) => {
  const { angle } = camera;

  const triangleSize =
    Math.round(TRIANGLE_RATE * (size - TRIANGLE_CORRECTION)) + PEAK_RATE;

  const triangleStyle = {
    top: `${-triangleSize * 2}px`,
    left: `${-triangleSize + TRIANGLE_CORRECTION}px`,
    borderRightWidth: `${triangleSize - TRIANGLE_CORRECTION}px`,
    borderLeftWidth: `${triangleSize - TRIANGLE_CORRECTION}px`,
    borderBottom: `${triangleSize * 2}px solid ${COLORS.PRIMARY}`,
  };

  const triangleContainerStyle = {
    transform: `translate(-50%, -50%) rotate(${angle}deg)`,
  };

  return (
    <div style={triangleContainerStyle} className={styles.triangleWrapper}>
      <div style={triangleStyle} className={styles.triangle} />
    </div>
  );
};

export default observer(CameraCompact);
