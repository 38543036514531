export const COLORS = {
  PRIMARY: '#1573FF',
  SUCCESS: '#37B571',
  WARNING: '#FA9E14',
  ERROR: '#E34136',
  CAMERAS: '#A226DD',
  DISABLED: 'grey',
  BLUE900: '#345187',
  BLUE500: '#1573FF',
  BLUE300: '#408cff',
  BLUE100: '#91d5ff',
  BLUE0: 'rgba(23, 143, 255, 0.16)',
  GREY100: '#84879b',
  GREY300: '#414354',
  WHITE600: '#bcbcbc',
  TURQUOISE: '#3bb4bc',
  BLACK100: '#2f313e',
  WHITY: '#f1f1f1',
  NONE: 'none',
  BG_MASK: 'rgba(0, 0, 0, 0.9)',
};

export enum BarColors {
  Dangerous = '#DD5849',
  Average = '#E7AF2D',
  Normal = '#44B573',
}

export const PHASE_CIRCLE_COLORS = {
  GREEN: COLORS.SUCCESS,
  ORANGE: COLORS.WARNING,
  RED: '#E35744',
};

export const MAP_SYSTEMS_COLORS = {
  DEFAULT: '#37B571',
  LIGHTS: '#37B571',
  DETECTORS: '#E35744',
  CAMERAS: '#AB50F6',
};

export const MAP_ICONS_COLORS = {
  LIGHTS_COLOR: '#37B571',
  DETECTORS_COLOR: '#E35744',
  CAMERAS_COLOR: '#AB50F6',
  STREAMS: '#FFFFFF',
  STATIC_OBJECT: '#1573FF',
  TRANSPORT: '#3621B8',
  ACIDENT: '#EC7A12',
} as const;

export const DEFAULT_CROSSROAD_COLORS = {
  MAIN: 'yellowgreen',
  MAIN_TRANSITION: 'orange',
  CROSSWALK: 'blue',
  CROSSWALK_TRANSITION: 'purple',
};
