import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { FiInfo } from 'react-icons/fi';

import rootStore from '../../../stores/rootStore/rootStore';
import ButtonIco from '../../ui-kit/ButtonIco/ButtonIco';
import Popover from '../../ui-kit/Popover/Popover';

import ContentInfo from './ContentInfo/ContentInfo';

import styles from './InfoBtn.module.scss';

const InfoBtn = () => {
  const {
    isInfoPanel,
    isWidgets,
    isInfoBtn,
    isCollapseInfoPanel,
    mapButtonsTipsDelay,
  } = rootStore.uiStore;

  const [isInfo, setIsInfo] = useState<boolean>(false);

  const handleIsInfo = () => setIsInfo(!isInfo);

  if ((isInfoPanel && !isCollapseInfoPanel) || isWidgets || !isInfoBtn)
    return null;

  const tooltipText = `${
    !isInfo ? 'Показать' : 'Скрыть'
  } информацию об обозначении иконок карты?`;

  const popOverProps = {
    tipsDelay: mapButtonsTipsDelay,
    content: tooltipText,
    placement: 'left',
  };

  return (
    <>
      <Popover {...popOverProps}>
        <div className={styles.mapInfoBtn}>
          <ButtonIco isRound isHover onClick={handleIsInfo}>
            <FiInfo />
          </ButtonIco>
        </div>
      </Popover>
      {isInfo && <ContentInfo />}
    </>
  );
};

export default observer(InfoBtn);
