import Feature from 'ol/Feature';
import type { Point } from 'ol/geom';

import { System } from '../../../../../ts/enums/enums';

export const getClusterSystems = (features: Feature<Point>[]) => {
  const newSystems = features
    .reduce((acc: System[], feature) => {
      const systemOnCluster: System = feature.get('system');

      if (!acc.includes(systemOnCluster)) {
        acc.push(systemOnCluster);
      }

      return acc;
    }, [])
    .sort();

  return newSystems;
};
