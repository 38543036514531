import { observer } from 'mobx-react-lite';
import React from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import Accordion from '../../../ui-kit/Accordion/Accordion';

function EventsTab() {
  const { setIsMarkers, infoData, handlePopupFromTab } = rootStore.uiStore;
  const { events } = rootStore.mapDataStore;

  return (
    <>
      {events.map(({ title, data, isMarkers }) => (
        <Accordion
          activeId={infoData?.id as number}
          data={data}
          isDisabled={isMarkers}
          key={title}
          //@ts-ignore
          onClick={(e: any) => handlePopupFromTab(e, title)}
          setIsMarkers={setIsMarkers}
          title={title}
        />
      ))}
    </>
  );
}

export default observer(EventsTab);
