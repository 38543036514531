import { AiOutlineForm, AiOutlineVideoCamera } from 'react-icons/ai';
import { RiCompasses2Line, RiAdminLine } from 'react-icons/ri';
import { VscDebugAlt } from 'react-icons/vsc';

import { ReactComponent as Cartography } from '../../../../assets/icons/ic_dashboard.svg';
import { ReactComponent as Reports } from '../../../../assets/icons/ic_docs.svg';
import { ReactComponent as Statistics } from '../../../../assets/icons/ic_statistics.svg';
import { PanelType } from '../../../../ts/enums/enums';
import { SidebarCode } from '../../../../ts/enums/userData';

export const MAIN_BTNS = [
  {
    name: PanelType.Cartography,
    tip: 'Картография',
    code: SidebarCode.Cartography,
    className: 'ico',
    Component: Cartography,
  },
  {
    name: PanelType.VideoWall,
    tip: 'Видеонаблюдение',
    code: SidebarCode.Video,
    className: 'reactIco',
    Component: AiOutlineVideoCamera,
  },
  {
    name: PanelType.Statistics,
    tip: 'Свод статистики',
    code: SidebarCode.Statistics,
    className: 'ico',
    Component: Statistics,
  },
  {
    name: PanelType.Reports,
    tip: 'Отчеты',
    code: SidebarCode.Reports,
    className: 'ico',
    Component: Reports,
  },
  {
    name: PanelType.Constructor,
    tip: 'Конструктор',
    code: SidebarCode.Constructor,
    className: 'reactIco',
    Component: RiCompasses2Line,
  },
  {
    name: PanelType.Modules,
    tip: 'Модули',
    code: SidebarCode.Modules,
    className: 'reactIco',
    Component: AiOutlineForm,
  },
  {
    name: PanelType.Admins,
    tip: 'Панель Администратора',
    code: SidebarCode.Admin,
    className: 'reactIco',
    Component: RiAdminLine,
  },
  {
    name: PanelType.Testing,
    tip: 'Тестирование',
    code: SidebarCode.Testing,
    className: 'reactIco',
    Component: VscDebugAlt,
  },
];
