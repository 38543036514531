import { useState } from 'react';
export interface LoginData {
  userName: string;
  password: string;
  isRemember: boolean;
}

const useLoginForm = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isRemember, setIsRemember] = useState(true);

  const handleForm = (e: any) => {
    const { type, value } = e.target;

    switch (type) {
      case 'text':
        return setUserName(value);
      case 'password':
        return setPassword(value);
      case 'checkbox':
        return setIsRemember(!isRemember);
      case 'button':
        return;
      default:
        return;
    }
  };

  return [{ userName, password, isRemember }, handleForm];
};

export default useLoginForm;
