import { FC } from 'react';

import styles from './Note.module.scss';

interface INote {
  text: string;
}

const Note: FC<INote> = ({ text }) => {
  return (
    <div className={styles.noteBox}>
      <span className={styles.note}>{text}</span>
    </div>
  );
};

export default Note;
