import { VERSION } from '../../../constants/versions';
import tryCatchWrapper from '../../../helpers/tryCatchWrapper';
import http from '../../http';

import { InfoSchema, RegionDataSchema } from './info.zod';

const SERVER = VERSION + '/server';
const PING = SERVER + '/ping';
const GROUPS = SERVER + '/groups'
const REFRESH = SERVER + '/data/refresh';
const DYNAMIC_BUTTONS = SERVER + '/web/dynamicButtons';
const REGION = SERVER + '/web/region';
const ERROR = SERVER + '/error';

const infoApi = {
  fetchPing: tryCatchWrapper(async () => {
    const res = await http.get(PING);

    return InfoSchema.parse(res.data);
  }),
  fetchGroups: async() => {
    const groupsData = await http.get(GROUPS);

    return groupsData
  },
  fetchRefreshServerData: tryCatchWrapper(async () => {
    const res = await http.get(REFRESH);

    return InfoSchema.parse(res.data);
  }),
  fetchDynamicButtons: tryCatchWrapper(async () => {
    const res = await http.get(DYNAMIC_BUTTONS);

    return res.data;
  }),
  fetchRegionById: async (regionId: number) => {
    const url = REGION + '/' + regionId;
    const res = await http.get(url);

    return RegionDataSchema.parse(res.data);
  },
  sendError: async (body: unknown) => {
    const res = await http.post(ERROR, body);

    return res;
  },
};

export default infoApi;
