import { Circle, Style } from 'ol/style';

import { CIRCLE_RADIUS, fillEmpty } from '../constants/constants';

export const getEmptyCluster = () => {
  return new Style({
    image: new Circle({
      fill: fillEmpty,
      radius: CIRCLE_RADIUS,
    }),
    fill: fillEmpty,
  });
};
