import { Form, Image, Input } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import ButtonAdd from '../../../../ui-kit/ButtonAdd/ButtonAdd';
import Img from '../../../../ui-kit/Img/Img';
import translateCode from '../helpers/translateCode';
import InputDeleteBtn from '../InputDeleteBtn/InputDeleteBtn';

import styles from './ImageFormItems.module.scss';

interface IImageFormItems {
  folder: string;
}

const ImageFormItems: FC<IImageFormItems> = ({ folder }) => {
  const { phases, addPhase, setPhase } = rootStore.constructorStore;

  return (
    <Image.PreviewGroup>
      {phases.map(({ image, phaseNumber, code, name }, i) => {
        const { label } = translateCode(code, phaseNumber);

        return (
          <Form.Item
            key={`phaseImg${i}`}
            label={label}
            className={styles.formItem}
          >
            <Input.Search
              addonBefore={folder}
              placeholder="Sg1219_1.png"
              enterButton
              defaultValue={name}
              onSearch={(val) => setPhase(folder, val, i)}
              value={name}
              onChange={(e) => setPhase(folder, e.target.value, i, false)}
              suffix={!phaseNumber && <InputDeleteBtn idx={i} />}
            />
            <Img
              alt="Изображение по данному пути не найдено"
              src={image}
              className={styles.img}
            />
          </Form.Item>
        );
      })}

      <ButtonAdd content="Добавить путь" onClick={() => addPhase(null)} />
    </Image.PreviewGroup>
  );
};

export default observer(ImageFormItems);
