import classNames from 'classnames';
import React from 'react';

import WidgetFooter from './WidgetFooter/WidgetFooter';
import WidgetHeader from './WidgetHeader/WidgetHeader';

import styles from './Widget.module.scss';

interface WidgetProps {
  title: string;
  total: number;
  children: any;
}

const Widget: React.FC<WidgetProps> = ({ title, total, children }) => {
  return (
    <div className={styles.wrapper}>
      <section
        className={classNames({
          [styles.container]: true,
          [styles.disabled]: !total,
        })}
      >
        <WidgetHeader title={title} />
        <hr />
        {children}
        <hr />
        <WidgetFooter total={total} />
      </section>
    </div>
  );
};

export default Widget;
