import { observer } from 'mobx-react-lite';

import useDayInfo from '../../../hooks/useDayInfo';
import rootStore from '../../../stores/rootStore/rootStore';

import styles from './InfoBlock.module.scss';

interface IInfoItems {
  firstRaw: string[] | string;
  secondRaw: string;
  style: string;
}

function InfoBlock() {
  const { initialMapCenter } = rootStore.uiStore,
    infoItems: IInfoItems[] = useDayInfo(initialMapCenter);

  return (
    <section className={styles.infoWrapper}>
      {infoItems.map(({ firstRaw, secondRaw, style }) => (
        <div key={style} className={styles[style]}>
          {style === 'infoTop' ? (
            <span>
              {firstRaw[0]}
              <span className={styles.colon}>:</span>
              {firstRaw[1]}
            </span>
          ) : (
            <span>{firstRaw}</span>
          )}

          <span className={styles.secondRaw}>{secondRaw}</span>
        </div>
      ))}
    </section>
  );
}

export default observer(InfoBlock);
