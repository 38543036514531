const getCircleSec = (
  lsec: None | number,
  lcycle: number,
  timeRcv: None | number
) => {
  if (!timeRcv || lsec === undefined || lsec === null) return 0;

  const datesDiff = Date.now() / 1000 - timeRcv - lsec,
    iterAmount = Math.floor(datesDiff / lcycle);

  return Math.round(datesDiff - iterAmount * lcycle);
};

export default getCircleSec;
