import { ReactNode } from 'react';

import { ReactComponent as TLDefault } from '../assets/icons/map/ic_tr_light_default.svg';
import { ReactComponent as TLGreen } from '../assets/icons/map/ic_tr_light_green.svg';
import { ReactComponent as TLGrey } from '../assets/icons/map/ic_tr_light_grey.svg';
import { ReactComponent as TLRed } from '../assets/icons/map/ic_tr_light_red.svg';
import { ReactComponent as TLYellow } from '../assets/icons/map/ic_tr_light_yellow.svg';
import { TlMode } from '../ts/models/tl.model';

export const TL_STATUS_COLORS = {
  DEFAULT: 'default',
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'red',
  GREY: 'grey',
};

const { DEFAULT, GREEN, YELLOW, RED, GREY } = TL_STATUS_COLORS;

type TCom = Record<string, TlMode>;

export const COMMANDS_MODE_WORK: TCom = {
  Work: 1,
  ManualControlVPU: 3,
  TechnologicalMode: 4,
  YellowFlash: 5,
  AllRed: 6,
  AdaptiveControl: 9,
  CoordinatedManagement: 10,
  localAdaptiveControl: 11,
} as const;

export const COMMANDS_MODE_NOT_ACTIVE: TCom = {
  Off: 0,
} as const;

export const COMMANDS_MODE_ERRORS: TCom = {
  Critical: 2,
  UnknownMode: 255,
} as const;

export const COMMANDS_MODE_REMOTE_CONTROL: TCom = {
  RemoteControl: 7,
} as const;

export const COMMANDS_MODE_NUM: TCom = {
  ...COMMANDS_MODE_WORK,
  ...COMMANDS_MODE_NOT_ACTIVE,
  ...COMMANDS_MODE_ERRORS,
  ...COMMANDS_MODE_REMOTE_CONTROL,
} as const;

type TTL_STATUS = Readonly<Record<TlMode, string>>;

export const TL_STATUS: TTL_STATUS = {
  0: GREY,
  1: GREEN,
  2: GREY,
  3: GREEN,
  4: GREEN,
  5: YELLOW,
  6: RED,
  7: DEFAULT,
  9: DEFAULT,
  10: GREEN,
  11: GREEN,
  255: GREY,
};

export const TL_STATUS_ICO: Record<TlMode, ReactNode> = {
  0: <TLGrey />,
  1: <TLGreen />,
  2: <TLGrey />,
  3: <TLDefault />,
  4: <TLDefault />,
  5: <TLYellow />,
  6: <TLRed />,
  7: <TLDefault />,
  9: <TLDefault />,
  10: <TLDefault />,
  11: <TLDefault />,
  255: <TLGrey />,
};

export const TL_STATUS_COLORS_HEX = {
  DEFAULT: '#37B571',
  GREEN: '#37B571',
  YELLOW: '#ECAF12',
  RED: '#E35744',
  GREY: '#84879b',
  BLUE: '#1573FF',
};

export const TL_STATUS_HEX: TTL_STATUS = {
  0: TL_STATUS_COLORS_HEX.GREY,
  1: TL_STATUS_COLORS_HEX.GREEN,
  2: TL_STATUS_COLORS_HEX.GREY,
  3: TL_STATUS_COLORS_HEX.BLUE,
  4: TL_STATUS_COLORS_HEX.BLUE,
  5: TL_STATUS_COLORS_HEX.YELLOW,
  6: TL_STATUS_COLORS_HEX.RED,
  7: TL_STATUS_COLORS_HEX.BLUE,
  9: TL_STATUS_COLORS_HEX.BLUE,
  10: TL_STATUS_COLORS_HEX.BLUE,
  11: TL_STATUS_COLORS_HEX.BLUE,
  255: TL_STATUS_COLORS_HEX.GREY,
};
