import { Map } from 'ol';
import { useState, useContext, useEffect } from 'react';

import { DEFAULT_CIRCLE } from '../../../constants/constructorConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import MapContext from '../../Map/MapContext';

const { DIAMETER } = DEFAULT_CIRCLE;

interface OLMap {
  map: U<Map>;
}

const useCircleDiameter = () => {
  const { currentZoom } = rootStore.mapDataStore;
  const { circleDiameterRate } = rootStore.constructorStore;

  const { map }: OLMap = useContext(MapContext);
  const [diameter, setDiameter] = useState(circleDiameterRate);

  const resolution = map?.getView()?.getResolution() ?? 1;
  const rate = Number((diameter / DIAMETER).toFixed(2));

  useEffect(() => {
    setDiameter((currentZoom * circleDiameterRate) / resolution);
  }, [currentZoom, resolution, circleDiameterRate]);

  return { diameter, rate };
};

export default useCircleDiameter;
