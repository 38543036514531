import { BarColors } from '../../../../../constants/colorsConstants';

export const getDetectorsBarsStatus = (
  comparableValue: U<number>,
  averageCalcData: number
) => {
  if (!comparableValue) return BarColors.Normal;

  const percent = (comparableValue / averageCalcData) * 100 - 100;

  if (percent >= 20) return BarColors.Dangerous;
  if (percent <= -20) return BarColors.Normal;

  return BarColors.Average;
};
