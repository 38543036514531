import dayjs, { Dayjs, UnitType } from 'dayjs';
import 'dayjs/locale/ru';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { DAY_NAMES } from '../constants/constants';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = (date: number) => (date > 9 ? date : `0${date}`);

const getTimezone = (date: any) => {
  const timeOffset = date.getTimezoneOffset() / 60;

  if (timeOffset === 0) {
    return timeOffset;
  }

  return timeOffset < 0
    ? `+${timeOffset.toString().slice(1)}`
    : `-${timeOffset}`;
};

export const getDayInfo = () => {
  const date = new Date(),
    day = formatDate(date.getDate()),
    month = formatDate(date.getMonth() + 1);

  return {
    time: date.toLocaleTimeString('ru').slice(0, -3).split(':'),
    timezone: getTimezone(date),
    day: `${day}.${month}`,
    dayName: DAY_NAMES[date.getDay()],
  };
};

export const getTimeFormat = (time?: number) => {
  if (!time) {
    return '00:00';
  }

  const minutes = formatDate(Math.floor(time / 60));
  const seconds = formatDate(time - Number(minutes) * 60);

  return `${minutes}:${seconds}`;
};

export const getFormattedTimeToDayJS = (
  { h = 0, m = 0, s = 0 },
  date = dayjs(0)
) => {
  const intervalInfo = Object.entries({ h, m, s }) as [UnitType, number][];

  return intervalInfo.reduce(
    (acc: Dayjs, [time, value]) => acc.set(time, value),
    date
  );
};

export const getSecByDayJS = (time: N<Dayjs>) => {
  if (!time) return 0;

  return Math.abs(time.tz('Africa/Abidjan', true).unix());
};
