import React from 'react';

import styles from './WidgetFooter.module.scss';

interface WidgetFooterProps {
  total: number;
}

function WidgetFooter(props: WidgetFooterProps) {
  const { total } = props;

  return (
    <footer className={styles.footer}>
      <span> Общее кол-во:</span>
      <span className={styles.total}>{total}</span>
    </footer>
  );
}

export default WidgetFooter;
