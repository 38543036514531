import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import { Interaction } from 'ol/interaction';
import SelectCluster from 'ol-ext/interaction/SelectCluster';
import { useEffect, useState } from 'react';

import { ANIMATION_DURATION_CLUSTER } from '../../../constants/mapClusterConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { System } from '../../../ts/enums/enums';
import {
  getFeaturesCluster,
  IFeaturesArr,
} from '../helpers/getFeaturesCluster';
import { UseClustersProps } from '../Map.model';

const useClusters = ({
  map,
  markers,
  infoData,
  clusterFeatures,
  isConstructor,
  isOpenInfoPanel,
  isDetailedForm,
  isClusterOpen,
  clickedCartographyObj,
}: UseClustersProps) => {
  const { setMapData } = rootStore.mapDataStore;

  const [featuresSelect, setFeaturesSelect] = useState<N<IFeaturesArr[]>>(null);
  const [hitFeatures, setHitFeatures] = useState<U<Feature<Point>[]>>([]);

  useEffect(() => {
    (!isOpenInfoPanel || isDetailedForm) && setMapData('isClusterOpen', false);
  }, [isOpenInfoPanel, isDetailedForm, setMapData]);

  useEffect(() => {
    if (infoData) return;
    setMapData('isClusterOpen', false);
  }, [infoData, markers, setMapData]);

  useEffect(() => {
    if (!map) return;

    if (!clusterFeatures.length || !infoData) {
      setFeaturesSelect([]);

      return;
    }

    if (isClusterOpen && infoData.system !== System.Clusters) return;

    setFeaturesSelect([]);

    //the solution is due to the fact that features are not drawn immediately, but with the end of the animation
    const idTimeout = setTimeout(() => {
      setFeaturesSelect(getFeaturesCluster(map, infoData));
    }, ANIMATION_DURATION_CLUSTER);

    return () => {
      clearTimeout(idTimeout);
    };
  }, [map, clusterFeatures, infoData, isClusterOpen]);

  useEffect(() => {
    if (!hitFeatures) return;

    if (isClusterOpen && hitFeatures.length === 1 && clusterFeatures.length) {
      const featureInCluster = clusterFeatures.find(
        (el) => hitFeatures[0].get('id') === el.get('id')
      );

      if (!featureInCluster) {
        setMapData('isClusterOpen', false);
        setMapData('clusterFeatures', []);
      }
    }
  }, [clusterFeatures, hitFeatures, isClusterOpen, setMapData]);

  useEffect(() => {
    if (isClusterOpen) return;
    setMapData('clusterFeatures', []);
    setHitFeatures([]);
  }, [isClusterOpen, setMapData]);

  useEffect(() => {
    if (clickedCartographyObj) return;

    if (!hitFeatures?.length) {
      setMapData('isClusterOpen', false);
      setMapData('clusterFeatures', []);

      return;
    }

    if (hitFeatures.length > 1) {
      setMapData('isClusterOpen', true);
      setMapData('clusterFeatures', hitFeatures);
    }
  }, [hitFeatures, setMapData, clickedCartographyObj]);

  useEffect(() => {
    if (!map) return;

    const interactions = map.getInteractions().getArray();
    const selectCluster = interactions.find((interaction: Interaction) =>
      interaction instanceof SelectCluster ? interaction : null
    ) as N<SelectCluster>;

    if (selectCluster && isConstructor) {
      isConstructor && setMapData('isClusterOpen', false);
      selectCluster.clear();
    }
  }, [isConstructor, map, setMapData]);

  return { featuresSelect, setHitFeatures };
};

export default useClusters;
