/* eslint-disable camelcase */
import { z } from 'zod';

import { LinkedDevicesSchema } from './../mapObjects/mapObjects.zod';

const ViewSettingsSchema = z.object({
  distance: z.number(),
  viewingAngle: z.number(),
  directionAngle: z.number(),
});

export const VideoListItemSchema = z.object({
  id: z.number().or(z.string()),
  srcId: z.string().optional(),
  ant_url: z.string().optional(),
  caption: z.string().optional(),
  latitude: z.number().optional(),
  regionId: z.number().optional(),
  longitude: z.number().optional(),
  viewSettings: ViewSettingsSchema.optional(),
  linkedDevices: LinkedDevicesSchema.optional(),
});

const JprofileSchema = z.object({
  videoList: VideoListItemSchema.array(),
});

export const ProfileSchema = z.object({
  id: z.number(),
  dsc: z.string(),
  name: z.string(),
  isChange: z.boolean(),
  isShared: z.boolean(),
  jprofile: JprofileSchema,
  region_id: z.number(),
  sc_user_id: z.number(),
});

export const ProfilesSchema = z.object({
  profiles: ProfileSchema.array(),
});
