import { Feature } from 'ol';
import { MultiLineString } from 'ol/geom';
import { Style, Stroke, Icon } from 'ol/style';
import FlowLine from 'ol-ext/style/FlowLine';

import camerasIcon from '../../../assets/icons/map/ic_camera.svg';
import detectorsIcon from '../../../assets/icons/map/ic_detectors.svg';
import meteoIcon from '../../../assets/icons/map/ic_meteo.svg';
import publicTransportIcon from '../../../assets/icons/map/ic_public_transport.svg';
import roadWorksIcon from '../../../assets/icons/map/ic_road_works.svg';
import rtaIcon from '../../../assets/icons/map/ic_rta.svg';
import specialTransportIcon from '../../../assets/icons/map/ic_special_transport.svg';
import tlIcon from '../../../assets/icons/map/ic_tr_light_default.svg';
import tlGreenIcon from '../../../assets/icons/map/ic_tr_light_green.svg';
import tlGreyIcon from '../../../assets/icons/map/ic_tr_light_grey.svg';
import tlRedIcon from '../../../assets/icons/map/ic_tr_light_red.svg';
import tlYellowIcon from '../../../assets/icons/map/ic_tr_light_yellow.svg';
import { MAP_ICONS_COLORS } from '../../../constants/colorsConstants';
import { EVENTS, SYSTEM } from '../../../constants/constants';

const {
  LIGHTS,
  DETECTORS,
  CAMERAS,
  PUBLIC_TRANSPORT,
  METEO,
  SPECIAL_TRANSPORT,
} = SYSTEM;

const { ROAD_WORKS, RTA } = EVENTS;
const { STATIC_OBJECT, TRANSPORT, ACIDENT } = MAP_ICONS_COLORS;

export const setIcoStyle = (ico: string, scale = 1) => {
  return new Style({
    image: new Icon({
      src: ico,
      scale,
      anchor: [0.43, 0.43],
    }),
  });
};

export const getColor = (set: string) => {
  switch (set) {
    case LIGHTS:
      return STATIC_OBJECT;
    case DETECTORS:
      return STATIC_OBJECT;
    case CAMERAS:
      return STATIC_OBJECT;
    case PUBLIC_TRANSPORT:
      return TRANSPORT;
    case SPECIAL_TRANSPORT:
      return TRANSPORT;
    case METEO:
      return STATIC_OBJECT;
    case ROAD_WORKS:
      return ACIDENT;
    case RTA:
      return ACIDENT;
    default:
      return STATIC_OBJECT;
  }
};

export const getStyle = (set: string, scale = 1) => {
  switch (set) {
    case LIGHTS:
      return setIcoStyle(tlIcon, scale);
    case `${LIGHTS}_default`:
      return setIcoStyle(tlIcon, scale);
    case `${LIGHTS}_green`:
      return setIcoStyle(tlGreenIcon, scale);
    case `${LIGHTS}_yellow`:
      return setIcoStyle(tlYellowIcon, scale);
    case `${LIGHTS}_red`:
      return setIcoStyle(tlRedIcon, scale);
    case `${LIGHTS}_grey`:
      return setIcoStyle(tlGreyIcon, scale);
    case `${LIGHTS}_undefined`:
      return setIcoStyle(tlGreyIcon, scale);
    case DETECTORS:
      return setIcoStyle(detectorsIcon, scale);
    case CAMERAS:
      return setIcoStyle(camerasIcon, scale);
    case SPECIAL_TRANSPORT:
      return setIcoStyle(specialTransportIcon, scale);
    case PUBLIC_TRANSPORT:
      return setIcoStyle(publicTransportIcon, scale);
    case METEO:
      return setIcoStyle(meteoIcon, scale);
    case ROAD_WORKS:
      return setIcoStyle(roadWorksIcon, scale);
    case RTA:
      return setIcoStyle(rtaIcon, scale);
    default:
      return setIcoStyle(rtaIcon, scale);
  }
};

export const lineStyleFunction = function (
  feature: Feature,
  color: string,
  color2: string,
  width: number
) {
  const styles = [
    new Style({
      stroke: new Stroke({
        color,
        width,
      }),
    }),
    new FlowLine({
      color,
      color2,
      width,
      width2: width,
      offset0: 100,
      offset1: 0,
      lineCap: 'round',
      // @ts-expect-error
      geometry(feature: Feature) {
        if (feature.getGeometry()?.getType() === 'MultiLineString') {
          return (feature.getGeometry() as MultiLineString).getLineString(0);
        }

        return feature.getGeometry();
      },
    }),
  ];

  return styles;
};

const getColorFromDensity = (density: number) => {
  const level = Math.floor((255 * 2 * density) / 100);

  return level <= 255 ? [level, 255, 0, 1] : [255, 255 * 2 - level, 0, 1];
};

export const getGeoStyle = function (feature: Feature) {
  const density = feature.get('density'),
    density2 = feature.get('density2'),
    width = feature.get('width') ?? 4,
    color = density ? getColorFromDensity(density) : feature.get('color'),
    color2 = density2 ? getColorFromDensity(density2) : feature.get('color2');

  return lineStyleFunction(feature, color, color2, width);
};
