import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import type { InputRef } from 'antd/lib';
import { FilterConfirmProps, Key } from 'antd/lib/table/interface';
import React, { FC, useEffect, useRef } from 'react';

import { ITableColumn } from '../../../../ts/models/table.model';

import styles from './TableSearch.module.scss';

interface DataType {
  [key: string]: string | number;
}

export type DataIndex = keyof DataType;

interface ITableSearch {
  column: ITableColumn;
  selectedKeys: Key[];
  setSelectedKeys: (param: [] | string[]) => void;
  clearFilters: undefined | (() => void);
  confirm: (param?: FilterConfirmProps) => void;
  title: string | undefined;
}

const TableSearch: FC<ITableSearch> = ({
  column,
  selectedKeys,
  setSelectedKeys,
  clearFilters,
  confirm,
  title,
}) => {
  const searchInput = useRef<InputRef>(null);

  column.onFilterDropdownOpenChange = (visible: boolean) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
  };

  useEffect(() => {
    if (searchInput.current) searchInput.current.focus();
  }, [searchInput]);

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void
  ) => {
    confirm();
  };

  return (
    <div className={styles.wrapper}>
      <Input
        ref={searchInput}
        placeholder={`Поиск по ${title}`}
        name={column.dataIndex}
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
          confirm({ closeDropdown: false });
        }}
        onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
        className={styles.input}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys as string[], confirm)}
          icon={<SearchOutlined />}
          size="small"
          className={styles.search}
        >
          Поиск
        </Button>
        <Button
          onClick={() => {
            clearFilters && handleReset(clearFilters);
            setSelectedKeys([]);
            confirm({ closeDropdown: false });
          }}
          size="small"
          className={styles.search}
        >
          Сбросить
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: true });
          }}
        >
          Закрыть
        </Button>
      </Space>
    </div>
  );
};

export default TableSearch;
