import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState, Suspense, lazy } from 'react';

import {
  ADMIN_TITLE,
  ADMIN_TYPES,
  TABLE_TITLE,
} from '../../constants/adminConstants';
import rootStore from '../../stores/rootStore/rootStore';
import Detailed from '../ui-kit/Detailed/Detailed';

import AdminModal from './AdminModal/AdminModal';
import BreadCrumbs, { IBreadCrumbsItem } from './BreadCrumbs/BreadCrumbs';
import Equipments from './Equipments/Equipments';
import { parsingNavigationPath } from './helpers/helpers';
import Manufacturers from './Manufacturers/Manufacturers';
import { IAdminTypes } from './models/adminTypes.model';
import NJInfo from './NJSInfo/NJInfo';

const AdminInfo = lazy(() => import('./AdminInfo/AdminInfo'));
const AdminSettingUsers = lazy(
  () => import('./AdminSettingUsers/AdminSettingUsers')
);
const AdminsSettingGroups = lazy(
  () => import('./AdminsSettingGroups/AdminsSettingGroups')
);
const AdminsSettingsConfig = lazy(
  () => import('./AdminsSettingsConfig/AdminsSettingsConfig')
);
const AdminSystemObject = lazy(
  () => import('./AdminSystemObject/AdminSystemObject')
);
const ASOSettings = lazy(
  () => import('./AdminSystemObject/ASOSettings/ASOSettings')
);
const ASOTable = lazy(() => import('./AdminSystemObject/ASOTable/ASOTable'));

const adminTypes: IAdminTypes = {
  accessNJSGroups: (
    <>
      <AdminsSettingGroups type={ADMIN_TYPES.njsGroups} />
      <AdminsSettingsConfig />
    </>
  ),
  accessWebUsers: <AdminSettingUsers />,
  infoSystem: <AdminInfo type={ADMIN_TYPES.systems} />,
  infoVersions: <AdminInfo type={ADMIN_TYPES.versions} />,
  accessWebGroups: (
    <>
      <AdminsSettingGroups type={ADMIN_TYPES.webGroups} />
      <AdminsSettingsConfig />
    </>
  ),
  njsSettings: <ASOSettings />,
  eputsSystem: <ASOTable />,
  region: <ASOTable />,
  trafficLight: <ASOTable />,
  video: <ASOTable />,
  detector: <ASOTable />,
  meteo: <ASOTable />,
  system: <AdminSystemObject />,
  njsInfo: <NJInfo />,
  equipment: <Equipments />,
  manufacturers: <Manufacturers />,
};

const Admin = () => {
  const {
    setAdminsData,
    adminTableType,
    adminPanelKeys,
    menu,
    isModalOpen,
    modalContent,
  } = rootStore.adminPanelStore;
  const { panelType } = rootStore.uiStore;
  const [breadCrumbs, setBreadCrumbs] = useState<IBreadCrumbsItem[]>();

  const onClose = () => {
    setAdminsData('adminTableType', null);
  };

  useEffect(() => {
    const displayheight = window.innerHeight;

    setAdminsData('displayHeight', displayheight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (menu && adminPanelKeys) {
      const newBreadCrumbsItems: IBreadCrumbsItem[] = [];

      adminPanelKeys.reverse().forEach((key) => {
        parsingNavigationPath(key, menu, newBreadCrumbsItems);
      });

      setBreadCrumbs(newBreadCrumbsItems);
    }
  }, [menu, adminPanelKeys]);

  if (!adminTableType) return <h2>Ошибка</h2>;

  if (panelType !== 'admins') return null;

  const type = adminTableType?.split('#')[0];

  return (
    <Suspense fallback={<Spin />}>
      <Detailed
        header={
          ADMIN_TITLE[adminTableType] ||
          TABLE_TITLE[adminTableType.split('#')[0]] ||
          'Управление Объектами'
        }
        onClose={onClose}
      >
        {breadCrumbs && <BreadCrumbs breadCrumbsItems={breadCrumbs} />}
        {adminTypes[type]}
      </Detailed>
      {isModalOpen && modalContent && (
        <AdminModal title={modalContent.title}>
          {modalContent.content}
        </AdminModal>
      )}
    </Suspense>
  );
};

export default observer(Admin);
