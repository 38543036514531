import { ITimePoints } from '../../../ts/models/signalPrograms.model';

export const getPhases = (phases: ITimePoints[], isPhases = true) => {
  const resPhases = phases.map((item: ITimePoints) => {
    item.length = item.stop - item.start;

    return item;
  });

  const res = resPhases.reduce(
    (accumulator: ITimePoints[], currentValue: ITimePoints) => {
      const curVal = { ...currentValue };
      const {
        phase,
        length,
        start,
        stop,
        color,
        tactType,
        main,
        tact,
        time,
        direction,
        durationMin,
      } = curVal;

      const last = accumulator[accumulator.length - 1];
      const prev = accumulator[accumulator.length - 2];
      const startTacts = prev?.stop ?? start;
      const initial = {
        stop,
        color,
        tactType,
        main,
        tact,
        time,
        direction,
        durationMin,
      };

      if (
        isPhases &&
        accumulator.length &&
        last.phase === phase &&
        length &&
        last.length
      ) {
        return [
          ...accumulator.slice(0, -1),
          {
            ...initial,
            phase: phase,
            length: length + last.length,
            start: last.start,
          },
        ];
      }

      if (!isPhases && (last?.phase !== phase || !last?.phase)) {
        return [
          ...accumulator.slice(0, -1),
          {
            ...initial,
            phase: phase,
            length: stop - startTacts,
            start: startTacts,
          },
        ];
      }

      accumulator.push(currentValue);

      return accumulator;
    },
    []
  );

  return res;
};
