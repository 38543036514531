import { ITimePoints } from '../../../../../ts/models/signalPrograms.model';

export const getCardData = (phase: ITimePoints) => {
  return [
    {
      label: 'Т осн',
      value: phase.length + ' сек',
    },
    {
      label: 'Т пром',
      value: phase.time + ' сек',
    },
    {
      label: 'Т мин',
      value: phase.stop,
    },
  ];
};
