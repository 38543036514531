import { SYSTEM } from '../../../constants/constants';
import { Detector } from '../../../ts/models/mapObject.model';
import { TL } from '../../../ts/models/tl.model';

import { ICamera } from './../../../ts/models/camera.model';

const { LIGHTS, CAMERAS, DETECTORS } = SYSTEM;

type MapObject = {
  tls: TL[];
  cameras: ICamera[];
  detectors: Detector[];
};

export const getCurrentObject = (
  system: string,
  id: number,
  { tls, cameras, detectors }: MapObject
) => {
  switch (system) {
    case LIGHTS:
      return tls.find((el) => el.id === id);
    case CAMERAS:
      return cameras.find((el) => el.id === id);
    case DETECTORS:
      return detectors.find((el) => el.id === id);
    default:
      return undefined;
  }
};
