import OLTileLayer from 'ol/layer/Tile';
import { useContext, useEffect } from 'react';

import MapContext from '../../Map/MapContext';

interface TileLayerProps {
  source: any;
  zIndex?: number;
  context?: any;
}

function TileLayer(props: TileLayerProps) {
  const { source, zIndex = 0, context = MapContext } = props;
  const { map }: any = useContext(context);

  useEffect(() => {
    if (!map) {
      return;
    }

    const tileLayer = new OLTileLayer({
      source,
      zIndex,
    });

    map.addLayer(tileLayer);
    tileLayer.setZIndex(zIndex);

    return () => {
      if (map) {
        map.removeLayer(tileLayer);
      }
    };
  }, [map]); // eslint-disable-line

  return null;
}

export default TileLayer;
