import { Stroke, Style } from 'ol/style';
import Chart from 'ol-ext/style/Chart';

import { MAP_ICONS_COLORS } from '../../../../../constants/colorsConstants';
import { TRANSPARENT_FEATURE } from '../constants/constants';

const WIDTH_TRANSPARENT_FEATURE = 30;

export const getEmptyFeature = () =>
  new Style({
    image: new Chart({
      type: 'pie',
      radius: 1,
      data: [],
      //@ts-ignore
      colors: [MAP_ICONS_COLORS.LIGHTS_COLOR],
      stroke: new Stroke({
        color: TRANSPARENT_FEATURE,
        width: WIDTH_TRANSPARENT_FEATURE,
      }),
    }),
  });
