import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { useState, useEffect } from 'react';

import { CONFIG } from '../../constants/configConstants';
import rootStore from '../../stores/rootStore/rootStore';
import { System } from '../../ts/enums/enums';
import { TL } from '../../ts/models/tl.model';
import OverviewMapControl from '../Controls/OverviewMapControl/OverviewMapControl';
import ScaleLineControl from '../Controls/ScaleLineControl/ScaleLineControl';
import useCrosshairPopup from '../Map/hooks/useCrosshairPopup';
import Map from '../Map/Map';
import AlarmPopup from '../Overlays/AlarmPopup/AlarmPopup';
import InfoPopup from '../Overlays/InfoPopup/InfoPopup';
import MarkerPopup from '../Overlays/MarkerPopup/MarkerPopup';
import Tooltip from '../Overlays/Tooltip/Tooltip';
import { TileLayer } from '../ui-kit/MapLayers';
import { osm } from '../ui-kit/MapSource';

import ClusterLayers from './ClusterLayers/ClusterLayers';
import ConstructorOverlays from './ConstructorOverlays/ConstructorOverlays';
import { handleActiveLights } from './helpers';
import { useMapOverlays } from './hooks';
import PhaseCircleOverlays from './PhaseCircleOverlays/PhaseCircleOverlays';
import StreamsLayers from './SteamsLayers/StreamsLayers';

const Mapper = () => {
  const { tls, clusters, isClusterOpen } = rootStore.mapDataStore;
  const { isInfoPanel, infoData, isAdjacentPhaseCircles, isPhaseCircle } =
    rootStore.uiStore;

  const [selectedTL, setSelectedTL] = useState<N<TL>>(null);
  const [adjacentTLs, setAdjacentTLs] = useState<N<TL[]>>([]);
  const { refs, popups } = useMapOverlays(5);
  const [newFeatures, setNewFeatures] = useState<Feature<Point>[]>([]);
  const [isTooltip, setIsTooltip] = useState<boolean>(false);

  const { isCrosshairPopup, setIsCrosshairPopup } = useCrosshairPopup({
    isInfoPanel,
    clusters,
    infoData,
    isClusterOpen,
  });

  const {
    tooltipRef,
    crosshairPopupRef,
    phaseCircleRef,
    adjacentPhaseCircleRefs,
    constructorCircleRef,
  } = refs;

  useEffect(() => {
    if (
      isPhaseCircle &&
      infoData?.system === System.Lights &&
      isNumber(infoData?.id)
    ) {
      handleActiveLights({
        tls,
        id: infoData.id,
        adjacentPhaseCirclePopups: popups.adjacentPhaseCirclePopups,
        adjacentPhaseCircleRefs,
        phaseCircleRef,
        setAdjacentTLs,
        setSelectedTL,
        isAdjacentPhaseCircles,
      });
    } else {
      setSelectedTL(null);
      setAdjacentTLs(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPhaseCircle,
    setSelectedTL,
    setAdjacentTLs,
    tls,
    infoData,
    isAdjacentPhaseCircles,
  ]);

  const map = {
    refs,
    popups,
    setIsTooltip,
    setIsCrosshairPopup,
  };

  const popup = {
    crosshairPopupRef,
    isPhaseCircle,
    isCrosshairPopup,
  };

  const phaseCircleOverlays = {
    selectedTL,
    phaseCircleRef,
    adjacentTLs,
    adjacentPhaseCircleRefs,
  };

  const constructorOverlays = {
    constructorCircleRef,
    constructorCirclePopup: popups.constructorCirclePopup,
  };

  const clusterLayers = {
    newFeatures,
    setNewFeatures,
    setIsCrosshairPopup,
  };

  const tooltip = {
    tooltipRef,
    isTooltip,
  };

  return (
    <Map {...map}>
      <Tooltip {...tooltip} />
      <TileLayer source={osm(CONFIG.OSM_SOURCE)} />
      <ClusterLayers {...clusterLayers} />
      <StreamsLayers />
      <MarkerPopup {...popup} />
      <PhaseCircleOverlays {...phaseCircleOverlays} />
      <ConstructorOverlays {...constructorOverlays} />
      <AlarmPopup />
      <InfoPopup isCrosshairPopup={isCrosshairPopup} />
      <OverviewMapControl source={osm(CONFIG.OSM_SOURCE)} />
      <ScaleLineControl />
    </Map>
  );
};

export default observer(Mapper);
