import { uniqueId } from 'lodash';
import type { Map } from 'ol';
import type { FeatureLike } from 'ol/Feature';
import RenderFeature from 'ol/render/Feature';

import { System } from '../../../ts/enums/enums';

import { handlePointer, TGetTooltip } from './handlers';

interface ISetTooltipOnClusterProps {
  map: Map;
  feature: FeatureLike;
  isSameCluster: boolean;
  setIsTooltip: SetState<boolean>;
  getTooltip: TGetTooltip;
}

export const setTooltipOnCluster = ({
  map,
  feature,
  isSameCluster,
  setIsTooltip,
  getTooltip,
}: ISetTooltipOnClusterProps) => {
  const features = feature.get('features') ?? [feature];

  const geom = feature.getGeometry() as RenderFeature;

  if (!geom) return false;

  if (isSameCluster) {
    setIsTooltip(false);
    handlePointer(map, { isPoint: false });

    return false;
  }

  const coord = geom.getFlatCoordinates();

  getTooltip(Number(uniqueId()), System.Clusters, coord, features);
  setIsTooltip(true);

  return false;
};
