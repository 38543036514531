import { observer } from 'mobx-react-lite';

import rootStore from '../../../stores/rootStore/rootStore';
import ButtonIco from '../../ui-kit/ButtonIco/ButtonIco';
import ButtonsBlock from '../../ui-kit/ButtonsBlock/ButtonsBlock';
import Popover from '../../ui-kit/Popover/Popover';
import { getMapBtns } from '../constants';

import styles from '../styles/BtnsBlock.module.scss';

const PLACEMENT = 'left';

const SystemsBtns = () => {
  const { markers, setIsMarkers, mapButtonsTipsDelay } = rootStore.uiStore;
  const { isConstructor } = rootStore.constructorStore;
  const { hasAccess } = rootStore.userDataStore;

  const mapBtns = getMapBtns(markers);

  const popOverProps = {
    placement: PLACEMENT,
    mouseEnterDelay: 2.2,
    tipsDelay: mapButtonsTipsDelay,
  };

  if (isConstructor) return null;

  return (
    <ButtonsBlock borderRadius={6} className={styles.btnsBlock}>
      {mapBtns.map(({ system, isActive, component, code }) => {
        const isAccess = hasAccess(code);

        if (!isAccess) return null;

        return (
          <ButtonIco
            onClick={() => setIsMarkers(system)}
            isActive={isActive}
            key={`mapBtn_${system}`}
          >
            <Popover content={`Вкл./откл. ${system}`} {...popOverProps}>
              {component}
            </Popover>
          </ButtonIco>
        )
      })}
    </ButtonsBlock>
  );
};

export default observer(SystemsBtns);
