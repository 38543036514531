import { Form, Segmented } from 'antd';
import { uniqueId } from 'lodash';
import { FC } from 'react';

import { HINTS_DATA } from '../../../constants/initialSettingsConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { TipType } from '../../../stores/uiStore/uiStore';
import {
  IhandleProfileHybrid,
  IPopoverProps,
} from '../../../ts/models/profileSettings.model';
import { TOOLTIP_OPTIONS } from '../../Panel/ProfilePanel/constants/constants';
import Popover from '../../ui-kit/Popover/Popover';

import styles from './HintsSetting.module.scss';

interface IHintsSetting {
  popOverProps: IPopoverProps;
  handleProfileHybrid: IhandleProfileHybrid;
  tipsValues?: any;
}

const HintsSetting: FC<IHintsSetting> = ({
  popOverProps,
  handleProfileHybrid,
  tipsValues,
}) => {
  const { setKeyValue } = rootStore.uiStore;

  return (
    <div className={styles.settingsContent}>
      {HINTS_DATA.map(
        ({ LABEL, NAME, IS_RELOAD_REQUIRED, POPOVER_CONTENT }) => (
          <Popover
            {...popOverProps}
            content={POPOVER_CONTENT}
            key={uniqueId('%')}
          >
            <Form.Item
              className={styles.settingsItem}
              label={`${LABEL}${IS_RELOAD_REQUIRED ? '*' : ''}`}
              name={NAME}
              valuePropName="string"
            >
              <Segmented
                className={styles.segmented}
                onChange={(val) => {
                  handleProfileHybrid(NAME, val);
                  setKeyValue(NAME, val as TipType);
                }}
                options={TOOLTIP_OPTIONS}
                size="small"
                value={tipsValues[NAME]}
              />
            </Form.Item>
          </Popover>
        )
      )}
    </div>
  );
};

export default HintsSetting;
