import { observer } from 'mobx-react-lite';

import rootStore from '../../../stores/rootStore/rootStore';
import AdminModal from '../../Admin/AdminModal/AdminModal';

import CatalogMenu from './CatalogMenu/CatalogMenu';
import SettingMenu from './SettingMenu/SettingMenu';
import SystemMenu from './SystemMenu/SystemMenu';

const AdminPanel = () => {
  const { isModalOpen, modalContent } = rootStore.adminPanelStore;

  return (
    <aside>
      <SettingMenu />
      <hr />
      <SystemMenu />
      <hr />
      <CatalogMenu />
      {isModalOpen && modalContent && (
        <AdminModal title={modalContent.title}>
          {modalContent.content}
        </AdminModal>
      )}
    </aside>
  );
};

export default observer(AdminPanel);
