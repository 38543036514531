import { Collapse } from 'antd';
import { FC } from 'react';

import { SYSTEM } from '../../../../constants/constants';
import { System } from '../../../../ts/enums/enums';
import Switcher from '../../../ui-kit/Switcher/Switcher';
import { getFilteredLinkedDevices } from '../../helpers/getFilteredLinkedDevices';
import CameraInfo from '../CameraInfo/CameraInfo';
import CamerasSwitcher from '../CamerasSwitcher/CamerasSwitcher';
import LinkedsInfo from '../LinkedsInfo/LinkedsInfo';
import PopupDetector from '../PopupDetector/PopupDetector';
import TrafficLightInfo from '../TrafficLightInfo/TrafficLightInfo';

import styles from './InfoMain.module.scss';

const { Panel } = Collapse;

type TMAP_ITEM = { [key: string]: any };

const { LIGHTS, CAMERAS, DETECTORS } = SYSTEM;
const MAP_ITEM = {} as TMAP_ITEM;

MAP_ITEM[LIGHTS] = TrafficLightInfo;
MAP_ITEM[CAMERAS] = CameraInfo;
MAP_ITEM[DETECTORS] = PopupDetector;

interface IInfoMain {
  currentObject: any;
  system: System;
  systemItem: any;
  id: number;
}

const InfoMain: FC<IInfoMain> = ({ currentObject, system, systemItem, id }) => {
  const filteredLinkedDevices = getFilteredLinkedDevices(currentObject);
  const filteredlinkedCameras = filteredLinkedDevices?.cameras ?? [];

  return (
    <div className={styles.wrapper}>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Основная Информация" key="1">
          <Switcher segment={system} id={id} obj={MAP_ITEM} />
        </Panel>
        {systemItem?.info && (
          <div className={styles.information}>{systemItem.info}</div>
        )}
        <Panel header="Связанное видеонаблюдение" key="3">
          {filteredlinkedCameras.length ? (
            <CamerasSwitcher title={''} camerasIdList={filteredlinkedCameras} />
          ) : (
            <p className={styles.linkedText}>Нет привязанных камер</p>
          )}
        </Panel>
        <Panel header="Связанные устройства" key="2">
          {filteredLinkedDevices ? (
            <LinkedsInfo linkedDevices={filteredLinkedDevices} />
          ) : (
            <p className={styles.linkedText}>Нет привязанных устройств</p>
          )}
        </Panel>
      </Collapse>
    </div>
  );
};

export default InfoMain;
