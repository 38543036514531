interface IGetZodMessageCustomProps {
  nameParent?: string;
  nameValue: string;
  rangeArr?: number[];
}

export const getZodMessageCustom = ({
  nameParent = 'TlStatus',
  nameValue,
  rangeArr = [],
}: IGetZodMessageCustomProps) => {
  const startMessage = `${nameParent} -> ${nameValue}`;

  switch (nameValue) {
    case 'last':
      return { message: `${startMessage} need be > 0` };
    case 'mode':
    case 'state':
    case 'source':
    case 'deviceTypeId':
      return { message: `${startMessage} need be on array: [${rangeArr}]` };
    default:
      return { message: `${startMessage} not access null` };
  }
};
