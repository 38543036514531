import { notification } from 'antd';
import { Dayjs } from 'dayjs';

import { getFormattedDate } from '../../../../components/TrafficLightDetailed/TlDetectors/IntancyGraph/helpers/getFormattedDate';
import { formatedDataOnUtc } from '../../../../components/TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/formatedDataOnUtc';
import NOTICE from '../../../../constants/notificationConstants';
import { VERSION } from '../../../../constants/versions';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

import { DetectorsDataSchema } from './detectorsData.zod';

const OBJECTS = VERSION + '/objects';
const DT = OBJECTS + '/dt/';

interface IGetDetectorsListProps {
  ids: number[];
  interval: number;
  selectedDates: Dayjs[];
  isNeedUtcFormatted: boolean;
  timeZone: number;
}

const detectorsDataApi = {
  getDetectorsList: ({
    ids,
    interval,
    selectedDates,
    isNeedUtcFormatted,
    timeZone,
  }: IGetDetectorsListProps) =>
    tryCatchWrapper(
      async () => {
        const formatedDates = formatedDataOnUtc(selectedDates, timeZone);

        const dates = isNeedUtcFormatted
          ? formatedDates
          : getFormattedDate(selectedDates);

        const request = await http.get(`${DT}list/data`, {
          params: {
            interval,
            dateFrom: dates[0],
            dateTo: dates[1],
            timeZone,
            dtIds: JSON.stringify(ids),
          },
        });

        const validate = DetectorsDataSchema.safeParse(request?.data);

        if (!validate.success) {
          ids.map((id) => console.error(`id:${id}`, validate.error.issues));

          notification.error(NOTICE.DETECTORS_DATA);

          return null;
        }

        return request;
      },
      {
        ignoreCodes: [404],
      }
    ),
};

export default detectorsDataApi;
