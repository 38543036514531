import { Button, notification, Popconfirm } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useState } from 'react';
import {
  AiOutlineDelete,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from 'react-icons/ai';

import videoWallApi from '../../../../../../../../api/videoWall/videoWallApi';
import NOTICE from '../../../../../../../../constants/notificationConstants';
import rootStore from '../../../../../../../../stores/rootStore/rootStore';
import { IVideoLIstItem } from '../../../../../../../../stores/videoWallPandelStore/videoWallPanelStore.model';
import { Role } from '../../../../../../../../ts/enums/userData';
import { getChangeVideoListParams } from '../../../../../../../VideoWall/helpers/helpers';
import CaptionList from '../CaptionList/CaptionList';
import ListKeyButton from '../ListKeyButton/ListKeyButton';

import styles from './FormCamerasLIstItem.module.scss';

interface IFormCamerasLIst {
  cameras: IVideoLIstItem[];
  listKey: string;
  id: number;
}

const FormCamerasLIst: FC<IFormCamerasLIst> = ({ cameras, listKey, id }) => {
  const { setVideoKeysValues, selectedVideoId, savedVideoLists } =
    rootStore.videoWallPanelStore;
  const { role } = rootStore.userDataStore;
  const [isShowList, setIsShowList] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { isChange } = getChangeVideoListParams(savedVideoLists, id);

  const fetchVideoList = useCallback(async () => {
    const res = await videoWallApi.getVideoList();

    if (!res?.profiles) return;

    setVideoKeysValues({ savedVideoLists: res.profiles });
  }, [setVideoKeysValues]);

  const handleShowList = () => {
    setIsShowList(!isShowList);
  };

  const handleDelete = async () => {
    if (!isChange && role !== Role.admin) {
      notification.error(NOTICE.DEL_PROFILE_ER);

      return;
    }

    setConfirmLoading(true);
    await videoWallApi.deleteVideoWallProfile(id, 'videoWall.delete');

    if (id === selectedVideoId) {
      setVideoKeysValues({ videoList: [] });
    }

    setConfirmLoading(false);
    notification.success(NOTICE.DEL_PROFILE);

    await fetchVideoList();
  };

  const handleCancel = () => {};
  const isActive = id === selectedVideoId;
  const btnType = isActive ? 'primary' : 'default';

  const dangerStyles = classNames({
    [styles.danger]: true,
    [styles.isActive]: isActive,
  });

  return (
    <li className={styles.listItem}>
      <div className={styles.btnsRow}>
        <Button
          icon={isShowList ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
          onClick={handleShowList}
          className={styles.eyeIcon}
        ></Button>
        <ListKeyButton listKey={listKey} cameras={cameras} id={id} />
        <Popconfirm
          title={`Вы действительно хотите удалить: ${listKey}?`}
          onConfirm={handleDelete}
          onCancel={handleCancel}
          okText="Да"
          cancelText="Нет"
          placement="right"
          okButtonProps={{ loading: confirmLoading }}
        >
          <Button
            type={btnType}
            danger
            icon={<AiOutlineDelete />}
            className={dangerStyles}
          ></Button>
        </Popconfirm>
      </div>
      <CaptionList cameras={cameras} isShowList={isShowList} />
    </li>
  );
};

export default observer(FormCamerasLIst);
