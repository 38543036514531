import { Button, Popover } from 'antd';
import type { TooltipPlacement } from 'antd/es/tooltip';
import { FC, CSSProperties } from 'react';

import { TipType } from '../../../stores/uiStore/uiStore';

import styles from './ButtonAdd.module.scss';

interface IBtnAdd {
  content?: string;
  placement?: TooltipPlacement;
  tipsDelay?: TipType;
  onClick: () => void;
  style?: CSSProperties;
}

const ButtonAdd: FC<IBtnAdd> = ({
  content = 'Добавить',
  placement = 'right',
  tipsDelay = '1s',
  onClick,
  style,
}) => {
  const popOverProps = { content, placement, tipsDelay };

  return (
    <div className={styles.addBtnContainer} style={style}>
      <Popover {...popOverProps}>
        <Button className={styles.addBtn} onClick={onClick}>
          +
        </Button>
      </Popover>
    </div>
  );
};

export default ButtonAdd;
