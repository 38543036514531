import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';

import DetailedHeader, {
  DetailedHeaderProps,
} from './DetailedHeader/DetailedHeader';

import styles from './Detailed.module.scss';

interface DetailedProps {
  children: any;
  isRightPanel?: boolean;
  header: string;
  onClose?: (value: any) => void;
}

const Detailed: FC<DetailedProps> = ({
  children,
  isRightPanel,
  ...headerProps
}) => {
  const { isPanel } = rootStore.uiStore;

  const containerStyle = classNames({
    [styles.container]: true,
    [styles.isPanel]: !isRightPanel && isPanel,
    [styles.isRightPanel]: isRightPanel,
  });

  return (
    <div className={containerStyle}>
      <div className={styles.contentWrapper}>
        <DetailedHeader {...(headerProps as DetailedHeaderProps)} />
        <main className={styles.content}>{children}</main>
      </div>
    </div>
  );
};

export default observer(Detailed);
