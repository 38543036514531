import { AliasToken, OverrideToken } from 'antd/lib/theme/interface';

import { COLORS } from './colorsConstants';

const {
  PRIMARY,
  BLUE0,
  BLACK100,
  GREY300,
  NONE,
  WHITY,
  BLUE100,
  BG_MASK,
  GREY100,
} = COLORS;

const PADDING = 10;
const BORDER_R_XS = 6;
const MARGIN = 10;
const ICONS_SIZE = 17;

const darkToken: Partial<AliasToken> | undefined = {
  colorBgContainer: BLACK100,
  borderRadiusXS: BORDER_R_XS,
  colorPrimaryBg: PRIMARY,
  colorPrimary: PRIMARY,
};

const darkComponentsToken: OverrideToken = {
  Table: {
    colorBgContainer: BLACK100,
    padding: PADDING,
    margin: MARGIN,
  },
  Button: {
    controlOutline: NONE,
    colorPrimary: PRIMARY,
  },
  Menu: {
    colorPrimary: PRIMARY,
    colorPrimaryBg: BLUE0,
    colorBgLayout: BLACK100,
    colorFillQuaternary: BLACK100,
    colorItemTextSelected: WHITY,
    colorItemBg: GREY300,
    colorItemBgSelected: PRIMARY,
    radiusItem: 0,
  },
  Segmented: {
    colorBgElevated: PRIMARY,
    colorBgLayout: BLACK100,
  },
  Switch: {
    colorPrimary: PRIMARY,
  },
  Slider: {
    colorPrimary: WHITY,
    colorPrimaryBorder: WHITY,
    colorPrimaryBorderHover: BLUE100,
    colorBgElevated: PRIMARY,
  },
  Image: {
    colorBgMask: BG_MASK,
    fontSizeIcon: ICONS_SIZE,
  },
};

const lightToken: Partial<AliasToken> | undefined = {};

const lightComponentsToken: OverrideToken = {
  Menu: {
    colorItemBg: WHITY,
    colorActiveBarBorderSize: 0,
  },
  Table: {
    margin: MARGIN,
    padding: PADDING,
  },
  Segmented: {
    colorTextDisabled: GREY100,
    colorBgElevated: PRIMARY,
    colorTextLabel: PRIMARY,
    colorText: WHITY,
  },
  Image: {
    colorBgMask: BG_MASK,
    fontSizeIcon: ICONS_SIZE,
  },
  DatePicker: {
    colorTextPlaceholder: GREY100,
  },
};

export { darkToken, darkComponentsToken, lightComponentsToken, lightToken };
