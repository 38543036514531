import { useCallback, useEffect, useState } from 'react';

import producersApi from '../../../api/catalogs/producers/producers';
import { DataType, ITableColumn } from '../../../ts/models/table.model';
import Table from '../../ui-kit/Table/Table';
import { parsingTableData } from '../helpers/parsingTableData';

interface IData {
  dataRows: DataType[];
  headerItems: ITableColumn[];
}

const Manufacturers = () => {
  const [data, setData] = useState<IData>();

  const getProducersTableData = useCallback(async () => {
    const data = await producersApi.fetchProducers('admin.manufacturers');

    const newData = parsingTableData(data);

    setData(newData);
  }, []);

  useEffect(() => {
    getProducersTableData();
  }, [getProducersTableData]);

  if (!data) return null;

  return (
    <Table
      data={data.dataRows}
      headerItems={data.headerItems}
      tableConfigType="fullScreen"
    />
  );
};

export default Manufacturers;
