function getNestedByKey<T>(object: T, childKey: keyof T) {
  return object[childKey];
}

// Path example ["details","description","size"], void
function setObjectProperty(
  targetObj: object,
  path: string[],
  value: any
): void {
  let obj: any = targetObj;

  for (let i = 0; i < path.length - 1; i++) {
    obj = getNestedByKey(obj, path[i]);
    if (!obj) {
      return;
    }
  }

  if (Array.isArray(obj) && value === undefined) {
    return obj.pop();
  }

  const lastElOfPath = path.at(-1);

  if (!lastElOfPath) return;

  obj[lastElOfPath] = value;
}

export default setObjectProperty;
