import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import { TactsImagesEl, TL, TLTact } from '../../../../ts/models/tl.model';
import { useCircleDiameter } from '../../hooks';
import PhaseCircleCrossroad from '../PhaseCircleCrossroad/PhaseCircleCrossroad';
import PhaseCircleImages from '../PhaseCircleImages/PhaseCircleImages';

interface Items {
  phaseIdx: number;
  tlTactImages: TactsImagesEl[][];
  tlTacts: TLTact[];
  selectedTL: TL;
}

const PhaseCircleItems: FC<Items> = ({
  tlTactImages,
  tlTacts,
  phaseIdx,
  selectedTL,
}) => {
  const { isCrossroadBorder } = rootStore.mapDataStore;
  const {
    tlDetails: { tlCrossroad },
    id,
  } = selectedTL;

  const { rate } = useCircleDiameter();

  const isBlinkingAnimation = Boolean(tlTacts[phaseIdx]?.animation?.opacity);

  if (isCrossroadBorder && tlCrossroad) {
    const crossroadProps = { id, rate, phaseIdx, tlCrossroad };

    return <PhaseCircleCrossroad {...crossroadProps} />;
  }

  return (
    <PhaseCircleImages
      data={tlTactImages[phaseIdx]}
      rate={rate}
      isBlinkingAnimation={isBlinkingAnimation}
    />
  );
};

export default observer(PhaseCircleItems);
