import { uniqueId } from 'lodash';

import { IVideoLIstItem } from '../../../stores/videoWallPandelStore/videoWallPanelStore.model';
import { ISavedVideoLIst } from '../../../ts/models/videoWall.model';
import { checkRemovedList } from '../../Panel/VideoWallPanel/VideoPanelTabs/TabsContent/LayoutVariables/helpers';

export const calcWidth = (value: number, isFullScreen: boolean) => {
  if (isFullScreen) return Math.round(100 / value) + '%';

  return Math.round(100 / value - 1) + '%';
};

export const createVideoList = (length: number) => {
  const list: IVideoLIstItem[] = [];

  for (let i = 0; i < length; i++) {
    const item: IVideoLIstItem = {
      id: uniqueId('_'),
    };

    list.push(item);
  }

  return list;
};

const LAYOT_LENGTH: { [key: number]: number } = {
  2: 50,
  3: 45,
  4: 30,
  5: 25,
  6: 20,
};

export const getTitleText = (title: string, layoutValue: number) => {
  const length = LAYOT_LENGTH[layoutValue];
  const newTitle = title;

  if (length)
    return newTitle.length > length
      ? newTitle.slice(0, length) + '...'
      : newTitle;

  return newTitle.length > 15 ? newTitle.slice(0, 15) + '...' : newTitle;
};

export const createNewCurrentLists = (
  layoutValue: number,
  videoList: IVideoLIstItem[]
) => {
  const maxLength = layoutValue ** 2;

  let count = 0;
  const newCurrentList: IVideoLIstItem[][] = [];
  let list: IVideoLIstItem[] = [];

  videoList.forEach((item, i) => {
    if (count < maxLength) {
      count++;
      list.push(item);
    }

    if (count === maxLength || i === videoList.length - 1) {
      count = 0;
      if (i < maxLength) newCurrentList.push(list);
      if (checkRemovedList(list) && i >= maxLength) {
        newCurrentList.push(list);
      }
      list = [];
    }
  });

  return newCurrentList;
};

export const checkIsEmptyVideoLIst = (videoList: IVideoLIstItem[]) => {
  const newlist = videoList.filter((item) => item.caption);

  return newlist.length === 0;
};

export const getChangeVideoListParams = (
  savedVideoLists: ISavedVideoLIst[],
  id: number
) => {
  const profile = savedVideoLists.filter((item) => item.id === id)[0];

  return profile;
};

export const getOptions = (savedVideoLists: ISavedVideoLIst[]) => {
  const options = savedVideoLists.map((item) => {
    const option = {
      label: item.name,
      value: item.id,
    };

    return option;
  });

  return options;
};
