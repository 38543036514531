import { Events, System } from '../ts/enums/enums';
import { Role } from '../ts/enums/userData';

import { ZOOM } from './mapConstants';
import {
  COMMANDS_MODE_ERRORS,
  COMMANDS_MODE_NOT_ACTIVE,
  COMMANDS_MODE_REMOTE_CONTROL,
  COMMANDS_MODE_WORK,
} from './tlStatusConstants';

export const TRANSITION_TIME = 500;
export const SECOND = 1000;
export const MINUTE = 60 * SECOND;

export const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'refresh';

export const HOUR = 60 * MINUTE;
export const UPDATE_WEATHER_TIME = 15 * MINUTE;
export const DEFAULT_MAP_ICON_SIZE = 0.85;

export const DAY_NAMES = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

export const COPYRIGHT = 'ООО «ОБЪЕДИНЕННЫЕ СИСТЕМЫ УПРАВЛЕНИЯ ТРАНСПОРТОМ»';

export const ALL_RIGHTS_RESERVED = 'Все права защищены';

export const TABLE_CSV_SEPARATOR = '_$_';

export const SIZE = { CHART: 360 };

const { BIG, MAX, STEP_CONSTRUCTOR } = ZOOM;

export const TL_CIRCLE_ZOOM = {
  START: BIG,
  END: MAX + STEP_CONSTRUCTOR,
  STEP: STEP_CONSTRUCTOR,
};

export const THEME = {
  LIGHT: 'light',
  DARK: 'dark',
};

export const THEME_PROPERTIES = [
  '--bg',
  '--bg-tint',
  '--bg-tint-contrast',
  '--bg-light-scheme',
  '--font-color',
  '--font-color-scheme',
  '--font-color-lite',
  '--font-primary',
  '--sidebar-btn',
  '--sidebar-profile',
  '--logo-sign-itc',
  '--logo-sign-sber',
  '--disabled',
  '--disabled-tint',
  '--disabled-text',
  '--primary-log',
  '--success-log',
  '--warning-log',
  '--json-key',
];

export const SYSTEM: Record<string, System> = {
  LIGHTS: System.Lights,
  DETECTORS: System.Detectors,
  CAMERAS: System.Cameras,
  SPECIAL_TRANSPORT: System.SpecialTransport,
  PUBLIC_TRANSPORT: System.PublicTransport,
  METEO: System.Meteo,
};

export const EVENTS: Record<keyof typeof Events, Events> = {
  RTA: Events.RTA,
  ROAD_WORKS: Events.ROAD_WORKS,
  ACTIVITIES: Events.ACTIVITIES,
  TRAFFIC_RESTRICTIONS: Events.TRAFFIC_RESTRICTIONS,
};

export const DEVICES: Record<string, string> = {
  ...SYSTEM,
  UNKNOWN: 'Не определено',
  CAMERA_PTZ: 'Видеокамера уличная поворотная (PTZ)',
  PHOTO_VIDEO_FVF: 'Фотовидеофиксация Комплекс фотовидеофиксации  ФВФ',
  DIT: 'Динамическое информационное табло (ДИТ)',
  ZPI: 'Знак переменной информации (ЗПИ)',
  TPI: 'Табло переменной информации (ТПИ)',
  PVK: 'Пункт весового контроля (ПВК)',
  UO: 'Умные остановки (УО)',
  BUS: 'Автобус',
  TROLLEYBUS: 'Троллейбус',
  TRAM: 'Трамвай',
  MINIBUS: 'Маршрутка',
  TAXI: 'Такси',
  BIKE: 'Велосипеды',
  SCOOTER: 'Самокаты',
  CAR: 'Автомобили',
  ROAD_EQUIPMENT: 'Дорожная техника',
  VGK: 'ВГК (весогабаритный контроль)',
  ST: 'Специализированный транспорт (СТ)',
  PARKING: 'Парковки',
  JDP: 'Железнодорожный переезд',
  STATIONARY_COMPLEXES_FVF: 'Стационарные комплексы ФВФ',
  TRANSPORTATION_COMPLEXES_FVF: 'Передвижные комплексы ФВФ',
  MOBILE_COMPLEXES_FVF: 'Мобильные комплексы ФВФ',
  ACTIVE_DUMMIES_FVF: 'Активные муляжи ФВФ',
  INTEGRATION_DT: 'Интегрирующий ДТ',
  RADIO_FREQUENCY_DT_SECTION: 'Радиочастотный ДТ- сечение',
  RADIO_FREQUENCY_DT_BAND: 'Радиочастотный ДТ- полоса',
  RADIO_FREQUENCY_DT_AREAL: 'Радиочастотный ДТ- площадной',
  VIDEO_DT: 'Видеодетектор транспорта',
};

type TDevicesTypesIdKeys = keyof typeof DEVICES;
export type TDevicesIds = IntRange<1, 35>;

export const DEVICE_TYPES_ID: Record<TDevicesIds, TDevicesTypesIdKeys> = {
  1: DEVICES.UNKNOWN,
  2: DEVICES.LIGHTS,
  3: DEVICES.CAMERAS,
  4: DEVICES.DETECTORS,
  5: DEVICES.CAMERA_PTZ,
  6: DEVICES.METEO,
  7: DEVICES.PHOTO_VIDEO_FVF,
  8: DEVICES.DIT,
  9: DEVICES.ZPI,
  10: DEVICES.TPI,
  11: DEVICES.PVK,
  12: DEVICES.UO,
  13: DEVICES.BUS,
  14: DEVICES.TROLLEYBUS,
  15: DEVICES.TRAM,
  16: DEVICES.MINIBUS,
  17: DEVICES.TAXI,
  18: DEVICES.BIKE,
  19: DEVICES.SCOOTER,
  20: DEVICES.CAR,
  21: DEVICES.ROAD_EQUIPMENT,
  22: DEVICES.VGK,
  23: DEVICES.ST,
  24: DEVICES.PARKING,
  25: DEVICES.JDP,
  26: DEVICES.STATIONARY_COMPLEXES_FVF,
  27: DEVICES.TRANSPORTATION_COMPLEXES_FVF,
  28: DEVICES.MOBILE_COMPLEXES_FVF,
  29: DEVICES.ACTIVE_DUMMIES_FVF,
  30: DEVICES.INTEGRATION_DT,
  31: DEVICES.RADIO_FREQUENCY_DT_SECTION,
  32: DEVICES.RADIO_FREQUENCY_DT_BAND,
  33: DEVICES.RADIO_FREQUENCY_DT_AREAL,
  34: DEVICES.VIDEO_DT,
};

export const DEVICES_TYPES = Object.keys(DEVICE_TYPES_ID).map((el) =>
  Number(el)
) as TDevicesIds[];

export type TStatusCategory =
  | 'WORK'
  | 'NOT_ACTIVE'
  | 'ERRORS'
  | 'REMOTE_CONTROL';

interface IStatusSets {
  mode: number[];
  isAlarm: boolean;
}

export const CONTROL_ACCESS_DEVICES = {
  OnlyRead: 0,
  ReadAndControl: 1,
  Unknown: -1,
  NotSupportControl: 3,
} as const;

const STATUS_WORK_ARRAY = Object.values(COMMANDS_MODE_WORK);
const STATUS_NOT_ACTIVE_ARRAY = Object.values(COMMANDS_MODE_NOT_ACTIVE);
const STATUS_ERRORS_ARRAY = Object.values(COMMANDS_MODE_ERRORS);
const STATUS_REMOTE_CONTROL_ARRAY = Object.values(COMMANDS_MODE_REMOTE_CONTROL);

export const TRAFFIC_LIGHTS_STATUS_SETS: Record<TStatusCategory, IStatusSets> =
  {
    WORK: {
      mode: STATUS_WORK_ARRAY,
      isAlarm: false,
    },
    NOT_ACTIVE: {
      mode: STATUS_NOT_ACTIVE_ARRAY,
      isAlarm: false,
    },
    ERRORS: {
      mode: STATUS_ERRORS_ARRAY,
      isAlarm: true,
    },
    REMOTE_CONTROL: {
      mode: STATUS_REMOTE_CONTROL_ARRAY,
      isAlarm: true,
    },
  };

export const OTHER = {
  WIDJETS: 'виджеты',
  DEBUG: 'отладка',
} as const;

export const CLUSTERS_INFO = {
  CLUSTERIZATION: 'кластеризация',
  OPENED: 'раскрытие кластера',
} as const;

export const DISPLAY_INFO = {
  CENTER: 'центрирование',
  INFO_PANEL: 'открывать инфо панель',
  INFO_BTN: 'блок с информацией',
  ALARM_ALERT: 'мигающие уведомления',
  MINIFIC_MAP: 'минифицированная карта',
  SCALE_LABEL: 'масштаб карты',
  SLIDER: 'размер иконок карты',
} as const;

type HintsTypeVal = 'INTERFACE' | 'MAP' | 'BTNS_MAP' | 'PROFILE';

export const HINTS: Record<HintsTypeVal, string> = {
  INTERFACE: 'интерфейс',
  MAP: 'карта',
  BTNS_MAP: 'кнопки карты',
  PROFILE: 'профиль',
};

export const PARAMS = {
  STREAMS: System.Streams,
  SUMMARY_INFO: 'сводная информация',
};

export const BREAK_POINTS = {
  XL: 1600,
  L: 1280,
  M: 850,
  S: 650,
};

export const ROLE_RUSSIAN = {
  [Role.admin]: 'Администратор',
  [Role.dispatcher]: 'Диспетчер',
  [Role.operator]: 'Оператор',
  [Role.engineer]: 'Инженер',
  [Role.admin_root]: 'Суперпользователь',
};

export const ROLE_TABS = {
  [Role.admin]: 'Администратор - пользователь с полным функционалом',
  [Role.dispatcher]: 'Диспетчер - пользователь с расширенным функционалом',
  [Role.operator]: 'Оператор - пользователь со стандартным функционалом',
};

export const CACHED_IMAGES = {
  MAX_AMOUNT: 100,
  TO_REMOVE_AMOUNT: 30,
};

export const PHASES_TEMPLATE = {
  FILLED: 'FILLED',
  LIST: 'LIST',
  GRID: 'GRID',
};

export const CAMERAS_TEMPLATE = {
  EQUAL: 'EQUAL',
  ONE_TO_MANY: 'ONE_TO_MANY',
};

export const SIGNAL_GRAPH_COLORS = {
  RED: 'rgb(204 0 0)',
  GREEN: 'rgb(89 179 0)',
  YELLOW: 'rgb(230 230 0)',
  ORANGE: 'rgb(255 128 0)',
};

export const LOGO_NAMES = {
  sber: 'Sber',
  osut: 'Osut',
};

export const LOGO_URLS = {
  SBER: 'assets/ic_logo_sber.ico',
  OSUT: 'assets/ic_logo_osut.ico',
};

export const ADMIN_USERS_COLLOBORATION = {
  title: 'Взаимодействие',
  dataIndex: 'collaboration',
  visible: true,
};

export const LOGIN_METHODS = [
  {
    type: 'isFace',
    isActive: false,
    title: 'Распознование лиц',
    info: 'Вход с помощью камеры',
  },
  {
    type: 'isFingerprint',
    isActive: false,
    title: 'Распознование отпечатков пальцев',
    info: 'Вход с помощью сканера отпечатков пальцев',
  },
  {
    type: 'isKey',
    isActive: false,
    title: 'Ключ безопасности',
    info: 'Вход с помощью физического ключа безопасности',
  },
  {
    type: 'isPassword',
    isActive: true,
    title: 'Пароль',
    info: 'Вход с помощью пароля учетной записи',
  },
];

export enum WS_SEND_EVENTS {
  checkTimestamp = 'CHECK_TIMESTAMP',
  subReq = 'SUB_REQ',
  subExit = 'SUB_EXIT',
  ping = 'PING',
}

export enum WS_RECEIVE_EVENTS {
  changeField = 'CHANGE_FIELD',
  addItem = 'ADD_ITEM',
  deleteItem = 'DELETE_ITEM',
  responseTimestamp = 'RESPONSE_TIMESTAMP',
  checkTimestamp = 'CHECK_TIMESTAMP',
  unauthorized = 'UNAUTHORIZED',
  error = 'ERROR',
  subRes = 'SUB_RES',
  subData = 'SUB_DATA',
  updateEntity = 'UPDATE_ENTITY',
  pong = 'PONG',
  sessionQuit = 'SESSION_QUIT',
}

export const MONTHS = [
  'янв',
  'фев',
  'мар',
  'апр',
  'май',
  'июн',
  'июл',
  'авг',
  'сен',
  'окт',
  'ноя',
  'дек',
];

export const WIDTH = {
  SIDEBAR: 80,
  PANEL_MAIN: 312,
  PANEL_INFO: 500,
};

export const IS_DEV = process.env.NODE_ENV === 'development';
