import { useCallback, useState } from 'react';

import adminApi from '../../../../../api/adminObjects/admin';
import rootStore from '../../../../../stores/rootStore/rootStore';
interface ILoadStaateItem {
  id: number;
  name: string;
}

export interface ILoadState {
  regions: N<ILoadStaateItem[]>;
  subsystems: N<ILoadStaateItem[]>;
  systemtypes: N<ILoadStaateItem[]>;
}

export const useAbout = (appPath: string) => {
  const { setAdminsData } = rootStore.adminPanelStore;
  const [isLoading, setIsloading] = useState(false);

  const [loadState, setLoadState] = useState<ILoadState>({
    regions: null,
    subsystems: null,
    systemtypes: null,
  });

  const getSubSystems = useCallback(
    async (id: number) => {
      setIsloading(true);

      const res = await adminApi.about.getAboutSubSystems(id, appPath);

      if (!res) {
        setAdminsData('isModalOpen', false);
        setIsloading(false);

        return;
      }

      setLoadState({
        ...loadState,
        subsystems: res.data,
        systemtypes: null,
      });

      setIsloading(false);
    },
    [appPath, loadState, setAdminsData]
  );

  const getSystemTypes = useCallback(
    async (id: number) => {
      setIsloading(true);
      const res = await adminApi.about.getAboutSystemType(id, appPath);

      if (!res) {
        setAdminsData('isModalOpen', false);
        setIsloading(false);

        return;
      }

      setLoadState({
        ...loadState,
        systemtypes: res.data,
      });

      setIsloading(false);
    },
    [appPath, loadState, setAdminsData]
  );

  const getRegions = useCallback(async () => {
    setIsloading(true);

    const res = await adminApi.about.getAboutRegions(appPath);

    if (!res) {
      setAdminsData('isModalOpen', false);
      setIsloading(false);

      return;
    }

    setLoadState({
      ...loadState,
      regions: res.data,
    });
    setIsloading(false);
  }, [appPath, loadState, setAdminsData]);

  return {
    getSubSystems,
    getSystemTypes,
    getRegions,
    isLoading,
    setIsloading,
    loadState,
    setLoadState,
  };
};
