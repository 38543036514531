import { Button } from 'antd';
import { FC, useState, useEffect } from 'react';

import ConeImage from '../../assets/icons/images/cone.png';

import styles from './AppError.module.scss';

const AppError: FC = () => {
  const [timer, setTimer] = useState<number>(10);
  const [isCancel, setIsCancel] = useState<boolean>(false);

  useEffect(() => {
    if (isCancel) return;
    setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
  }, [isCancel]);

  useEffect(() => {
    if (timer < 1 && !isCancel) {
      window.location.reload();
      setIsCancel(true);
    }
  }, [timer, isCancel]);

  return (
    <div className={styles.errorBox}>
      <div className={styles.wraper}>
        <h2 className={styles.title}>Ошибка</h2>
        <p className={styles.text}>
          Произошла непредвиденная ошибка, наши разработчики уже решают эту
          проблему.
        </p>
        {!isCancel && (
          <div className={styles.timerBox}>
            <p className={styles.timerText}>
              Перезагрузка приложения через {timer} секунд
            </p>
            <Button onClick={() => setIsCancel(true)}>Отмена</Button>
          </div>
        )}
      </div>
      <div className={styles.wraper}>
        <img className={styles.image} alt="Ошибка" src={ConeImage} />
      </div>
    </div>
  );
};

export default AppError;
