interface INoticeItem {
  message: string;
  description: string;
  duration?: number;
}

const NOTICE: Record<string, INoticeItem> = {
  LOGIN_SUCCESSFUL: {
    message: 'Вход выполнен',
    description: 'Вы успешно вошли в аккаунт',
  },
  SETTINGS_SAVED: {
    message: 'Настройки успешно сохранены.',
    description: 'Они вступят в силу после перезагрузки страницы.',
  },
  ERROR_TLS_FETCHING: {
    message: 'Ошибка сервера',
    description: 'Не удалось получить полные данные светофорных объектов.',
  },
  LOAD_CONSTRUCTOR_PROFILE: {
    message: 'Удаление не произведено',
    description:
      'Необходимо выбрать набор данных в выпадающем списке вверху панели.',
  },
  NOTHING_TO_SAVE: {
    message: 'Не сохранено',
    description: 'Вы не внесли никаких изменений для сохранения.',
  },
  PUT_CIRCLE_CENTER: {
    message: 'Невозможно выполнить.',
    description:
      'Пожалуйста, сначала установите координаты центра круга и значение масштаба.',
  },
  JSON_ERROR: {
    message: 'Ошибка в JSON файле',
    description: 'Неправильный JSON файл',
  },
  CREATE_USER: {
    message: 'Выполнено',
    description: 'Пользователь успешно создан',
  },
  DELETE_USER: {
    message: 'Успешно',
    description: 'Пользователь успешно удален',
  },
  CREATE_USER_ERROR: {
    message: 'Ошибка создания пользователя',
    description: 'Проверьте правильность заполнения полей формы.',
  },
  USER_UPDATE: {
    message: 'Успешно',
    description: 'Данные пользователя успешно обновлены',
  },
  DATE_ERROR_PLAN: {
    message: 'Некорректная дата',
    description:
      'Включение режима выбора плана доступно не раньше, чем через 2 минуты',
    duration: 5,
  },
  DETECTORS_DATA: {
    message: 'Некорректные данные',
    description:
      'Данные по детекторам некорректные, возможно неправильное отображение графика',
  },
  SYSTEM_OBJECT_UPDATE: {
    message: 'Выполнено',
    description: 'Настройки системы успешно сохранены',
  },
  USER_ACCESS_ERROR: {
    message: 'Отсутствует доступ',
    description:
      'Для данного пользователя отсутствуют разрешения на доступ к элементам интерфейса. Пожалуйста, обратитесь к администратору.',
  },
  SYSTEM_GROUPS_ERROR: {
    message: 'Ошибка сервера',
    description: 'Группы доступа системы недоступны.',
  },
  ADD_DEVICE: {
    message: 'Выполнено',
    description: 'Устройство успешно добавлено в систему ЕПУТС',
  },
  UP_DEVICE: {
    message: 'Выполнено',
    description: 'Данные устройства обновлены',
  },
  ADD_PROFILE: {
    message: 'Выполнено',
    description: 'Профиль успешно сохранен',
  },
  UP_PROFILE: {
    message: 'Выполнено',
    description: 'Профиль успешно обновлен',
  },
  DEL_PROFILE: {
    message: 'Выполнено',
    description: 'Профиль успешно удален',
  },
  DEL_PROFILE_ER: {
    message: 'В доступе отказано',
    description: 'Общедоступные профили может удалять только администратор',
  },
  ERR_ADD_DEVICE: {
    message: 'Ошибка',
    description: 'Ошибка добавления в систему ЕПУТС',
  },
  INCORRECT_DATA_TYPES: {
    message: 'Ошибка',
    description: 'Получен некорректный тип данных. Данные не будут отображены.',
  },
  INCORRECT_TYPES_FLEX: {
    message: 'Ошибка',
    description:
      'Получен некорректный тип данных. Возможно, функционал будет доступен не полностью. Мы уже работаем над решением проблемы.',
  },
  INCORRECT_COMMAND: {
    message: 'Ошибка',
    description: 'Отправленная команда не найдена.',
  },
  INCORRECT_DATE: {
    message: 'Ошибка',
    description: 'Выбранное время не может быть позже текущего времени',
  },
  NEW_KEY: {
    message: 'Предупреждение',
    description: 'Получен новый ключ, неописанный на WEB',
  },
  INCORRECT_COMPLETED_FORMS: {
    message: 'Ошибка',
    description: 'Неправильно заполненные поля формы',
  },
  CONSTRUCTOR_PHASE_ERROR: {
    message: 'Ошибка изменения профиля',
    description: 'Попробуйте выбрать корректную фазу.',
  },
  USER_AVALIBLE_REGIONS_UNDEFINED: {
    message: 'Ошибка получения списка доступных регионов',
    description: 'У пользователя нет доступа к регионам',
  },
  REGIONS_LOADING_ERROR: {
    message: 'Ошибка загрузки списка доступных регионов',
    description:
      'При загрузки списка регионов один или несколько были загружены с ошибкой',
  },
  OUTDATED_PROFILE: {
    message: 'Ошибка загрузки профиля',
    description:
      'Профиль использует устаревшую версию данных и не может быть загружен.',
  },
  DELETE_SYSTEM: {
    message: 'Выполнено',
    description: 'Система удалена',
  },
};

export default NOTICE;
