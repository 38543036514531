import { Form, Input, InputNumber } from 'antd';
import React from 'react';

interface EditableCellProps {
  editing: any;
  dataIndex: any;
  title: string;
  inputType: any;
  children: any;
}

const EditableCell = (props: EditableCellProps) => {
  const { editing, dataIndex, title, inputType, children, ...restProps } =
    props;
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  if (!editing) return <td {...restProps}>{children}</td>;

  return (
    <td {...restProps}>
      <Form.Item
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `Введите ${title}`,
          },
        ]}
      >
        {inputNode}
      </Form.Item>
    </td>
  );
};

export default EditableCell;
