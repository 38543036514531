import { isNumber } from 'lodash';

export type TArr = (number | string)[];

export const getArrSum = (arr: TArr) => {
  const numberArr = Object.values(arr).filter((val) =>
    isNumber(val)
  ) as number[];

  return numberArr.reduce((acc: number, value) => acc + value, 0);
};
