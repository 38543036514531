import { FC } from 'react';

import { ITimePoints } from '../../../../../../ts/models/signalPrograms.model';
import { getPhaseInfo } from '../../helpers/getPhaseInfo';

import styles from './PhaseContent.module.scss';

interface PhaseContentProps {
  curPhase: ITimePoints;
}

const PhaseContent: FC<PhaseContentProps> = ({ curPhase }) => {
  const content = getPhaseInfo(curPhase);

  return (
    <ul className={styles.listContainer}>
      {content.map(({ label, value }, i) => (
        <li key={i} className={styles.listValue}>
          {label}: {value}
        </li>
      ))}
    </ul>
  );
};

export default PhaseContent;
