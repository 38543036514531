import { InputNumber, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';

import styles from './CircleCoordinatesModal.module.scss';

interface CircleCoordinatesModalProps {
  setIsModalOpen: (bool: boolean) => void;
  isModalOpen: boolean;
}

function CircleCoordinatesModal(props: CircleCoordinatesModalProps) {
  const { setIsModalOpen, isModalOpen } = props;
  const { setConstructorData } = rootStore.constructorStore;
  const [longitude, setLongitude] = useState<number | null>();
  const [latitude, setLatitude] = useState<number | null>();

  const handleCancel = () => setIsModalOpen(false);
  const handleLongitude = (val: number | null) => setLongitude(val);
  const handleLatitude = (val: number | null) => setLatitude(val);

  const handleOk = () => {
    setIsModalOpen(false);
    longitude &&
      latitude &&
      setConstructorData('circleCenter', [longitude, latitude]);
    setConstructorData('isApplyCenter', true);
    setLongitude(null);
    setLatitude(null);
  };

  return (
    <Modal
      cancelText="Отмена"
      centered
      className={styles.modal}
      okButtonProps={{
        disabled: !longitude && !latitude,
      }}
      okText="Применить"
      onCancel={handleCancel}
      onOk={handleOk}
      open={isModalOpen}
      title="Введите координаты центра:"
    >
      <div>
        <span>Долгота:</span>

        <InputNumber
          className={styles.input}
          onChange={handleLongitude}
          value={longitude}
        />
      </div>

      <div>
        <span>Широта:</span>

        <InputNumber
          className={styles.input}
          onChange={handleLatitude}
          value={latitude}
        />
      </div>
    </Modal>
  );
}

export default observer(CircleCoordinatesModal);
