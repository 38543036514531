import { AiOutlineLayout } from 'react-icons/ai';
import { BsCardChecklist, BsSave2 } from 'react-icons/bs';

import CamerasList from '../CamerasList/CamerasList';

import LayoutVariables from './TabsContent/LayoutVariables/LayoutVariables';
import SavedForms from './TabsContent/SavedForms/SavedForms';

export const VIDEO_TABS_OPTIONS = [
  {
    label: <BsSave2 />,
    value: 'saveSettings',
    disabled: false,
    tabName: 'Сохраненные профили',
  },
  {
    label: <BsCardChecklist />,
    value: 'list',
    disabled: false,
    tabName: 'Список камер',
  },
  {
    label: <AiOutlineLayout />,
    value: 'layout',
    disabled: false,
    tabName: 'Настройка раскладки',
  },
];

export const TABS = {
  list: CamerasList,
  layout: LayoutVariables,
  saveSettings: SavedForms,
};

interface ILayoutV {
  label: string;
  value: string | number | null | undefined;
}

export const LAYOUT_VARIABLES: ILayoutV[] = [
  {
    label: '2 x 2',
    value: 2,
  },
  {
    label: '3 x 3',
    value: 3,
  },
  {
    label: '4 x 4',
    value: 4,
  },
  {
    label: '5 x 5',
    value: 5,
  },
  {
    label: '6 x 6',
    value: 6,
  },
  {
    label: '7 x 7',
    value: 7,
  },
];
