import { Popover as AntdPopover } from 'antd';
import React from 'react';

import { TipType } from '../../../stores/uiStore/uiStore';

export type ITrigger = 'hover' | 'focus' | 'click' | 'contextMenu';

type ITriggerType = ITrigger | Array<ITrigger> | undefined;

interface PopoverProps {
  children: any;
  content: any;
  placement?: string;
  mouseEnterDelay?: any;
  tipsDelay: TipType;
  overlayClassName?: string;
  trigger?: ITriggerType;
  zIndex?: number;
  open?: U<boolean>;
}

const Popover: React.FC<PopoverProps> = (props) => {
  const {
    children,
    content,
    placement,
    tipsDelay,
    trigger = 'hover',
    overlayClassName = '',
    zIndex,
    open,
  } = props;

  if (tipsDelay === 'Отк') {
    return <>{children}</>;
  }

  return (
    <AntdPopover
      // @ts-ignore
      placement={placement}
      content={content}
      trigger={trigger}
      mouseEnterDelay={Number(tipsDelay)}
      overlayClassName={overlayClassName}
      zIndex={zIndex}
      open={open}
    >
      {children}
    </AntdPopover>
  );
};

export default Popover;
