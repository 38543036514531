import { FC } from 'react';

import { ReactComponent as CameraIco } from '../../../../assets/icons/map/ic_camera_round.svg';
import { CameraIconProps } from '../../models/camera.model';
import CameraWrapper from '../CameraWrapper/CameraWrapper';
import { MIN_ICO_SIZE } from '../constants/constants';

import styles from './CameraIcon.module.scss';

const CameraIcon: FC<CameraIconProps> = ({ camera, rate, onClick }) => {
  const calcVal = (val: number) => Math.round(Number(val) * rate);
  const curSize = calcVal(camera.size);
  const size = curSize < MIN_ICO_SIZE ? MIN_ICO_SIZE : curSize;

  const wrapperProps = {
    camera,
    calcVal,
    size,
  };

  return (
    <CameraWrapper {...wrapperProps}>
      <CameraIco
        width={size}
        height={size}
        className={styles.cameraIco}
        onClick={onClick}
      />
    </CameraWrapper>
  );
};

export default CameraIcon;
