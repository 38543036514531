import { Image } from 'antd';
import { FC, ReactNode, useState } from 'react';
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineRotateLeft,
  AiOutlineRotateRight,
  AiOutlineZoomIn,
  AiOutlineZoomOut,
  AiOutlineClose,
} from 'react-icons/ai';

import styles from './ImageGallery.module.scss';

interface ImgGallery {
  children: ReactNode;
}

const DEFAULT_SIZE = 30;

const ImageGallery: FC<ImgGallery> = ({ children }) => {
  const [current, setCurrent] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const icons = {
    left: (
      <AiOutlineLeft
        size={DEFAULT_SIZE}
        className={current !== 1 ? styles.icon : styles.iconDisabled}
      />
    ),
    right: (
      <AiOutlineRight
        size={DEFAULT_SIZE}
        className={current !== total ? styles.icon : styles.iconDisabled}
      />
    ),
    rotateLeft: (
      <AiOutlineRotateLeft size={DEFAULT_SIZE} className={styles.icon} />
    ),
    rotateRight: (
      <AiOutlineRotateRight size={DEFAULT_SIZE} className={styles.icon} />
    ),
    zoomIn: <AiOutlineZoomIn size={DEFAULT_SIZE} className={styles.icon} />,
    zoomOut: <AiOutlineZoomOut size={DEFAULT_SIZE} className={styles.icon} />,
    close: <AiOutlineClose size={DEFAULT_SIZE} className={styles.icon} />,
  };

  const countChange = (newCurrent: number, newTotal: number) => {
    setTimeout(() => {
      setCurrent(newCurrent);
      setTotal(newTotal);
    }, 0);

    return `${newCurrent} / ${newTotal}`;
  };

  return (
    <Image.PreviewGroup
      preview={{
        countRender: countChange,
      }}
      icons={icons}
    >
      {children}
    </Image.PreviewGroup>
  );
};

export default ImageGallery;
