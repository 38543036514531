import { Menu, notification, Spin } from 'antd';
import { SpinProps, MenuProps } from 'antd/lib';
import { observer } from 'mobx-react-lite';
import { useState, useEffect, useCallback } from 'react';

import { getModuleItem, getModulesList } from '../../../api_';
import rootStore from '../../../stores/rootStore/rootStore';

import styles from './ModulesPanel.module.scss';

const ModulesPanel = () => {
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { setKeyValue, selectedModulItems } = rootStore.uiStore;

  const getList = useCallback(async () => {
    const newGroups = await getModulesList();

    setIsLoading(false);
    setGroups(newGroups);
  }, []);

  useEffect(() => {
    getList();

    return () => {
      setKeyValue('moduleData', null);
      setKeyValue('selectedModulItems', []);
    };
  }, [getList, setKeyValue]);

  const onSelect = async ({ key }: { key: string }) => {
    setKeyValue('isModalLoading', true);
    setKeyValue('selectedModulItems', [key]);
    try {
      const newTableData = await getModuleItem(key);

      newTableData && setKeyValue('moduleData', newTableData);
    } catch (e: any) {
      const message = e?.response?.data?.message;

      setKeyValue('moduleData', null);
      notification.error({ message: 'Ошибка сервера', description: message });
    }
    setKeyValue('isModalLoading', false);
  };

  const menuProps: MenuProps = {
    items: groups,
    mode: 'inline',
    className: styles.menu,
    onSelect,
    selectedKeys: selectedModulItems,
  };

  const spinProps: SpinProps = {
    size: 'large',
    className: styles.spinner,
    spinning: isLoading,
  };

  return (
    <section className={styles.container}>
      <Spin {...spinProps} />
      <Menu {...menuProps} />
    </section>
  );
};

export default observer(ModulesPanel);
