/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Radio, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';

import detectorsDataApi from '../../../../api/mapObjects/detectors/data/detectorsData';
import { ReactComponent as NoDataIcon } from '../../../../assets/icons/noData.svg';
import rootStore from '../../../../stores/rootStore/rootStore';
import { DATE_FORMAT_TO_SHOW } from '../../../TrafficLightDetailed/TlDetectors/constants/constants';
import { normilizeData } from '../../../TrafficLightDetailed/TlDetectors/helpers/normilizeData';
import { getSliceDates } from '../../../TrafficLightDetailed/TlDetectors/IntancyGraph/helpers/getSliceDates';
import { getTimeZones } from '../../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeZone';
import { RadioValue } from '../../../TrafficLightDetailed/TlDetectors/model/graph.model';
import Popover from '../../../ui-kit/Popover/Popover';

import ChartBar from './Chart/ChartBar';
import { CHART_NAMES, DAY_INTERVAL } from './Chart/constants/constants';
import DefaultComponent from './DefaultComponent/DefaultComponent';
import TitleInfo from './DefaultComponent/TitleInfo/TitleInfo';
import { getFormatDataDt } from './helpers/getFormatDataDt';
import {
  IDataGraph,
  PopupDetectorProps,
  TDataDetectors,
} from './PopupDetector.model';

import styles from './PopupDetector.module.scss';

const { vehicles, avSpeed } = CHART_NAMES;

const PopupDetector: FC<PopupDetectorProps> = ({ id }) => {
  const { tipsValues } = rootStore.uiStore;

  const [radioValue, setRadioValue] = useState<RadioValue>(vehicles);
  const [dataRes, setDataRes] = useState<TDataDetectors>();
  const [dateInterval, setDateInterval] = useState<string[]>([]);
  const [dataGraph, setDataGraph] = useState<N<IDataGraph[]>>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState(false);
  const [isNullableData, setIsNullableData] = useState(false);
  const [timeUpdate, setTimeUpdate] = useState<string>();

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const titleTooltip =
    radioValue === vehicles ? `Интенсивность ТС` : `средняя скорость ТС`;

  const units = radioValue === vehicles ? '' : 'км/ч';

  const popoverProps = {
    tipsDelay: tipsValues.interfaceTipsDelay,
    content: dataGraph
      ? `${titleTooltip}: ${dataGraph[activeIndex].comparableValue} ${units}`
      : 'нет данных',
    placement: 'left',
  };

  const getData = useCallback(
    async (selectedDate: Dayjs[]) => {
      setIsError(false);
      setLoading(true);
      const timeZone = getTimeZones()?.timeZoneRegion;

      if (!timeZone) return;

      const dataResponse = await detectorsDataApi.getDetectorsList({
        ids: [id],
        interval: DAY_INTERVAL,
        selectedDates: selectedDate,
        isNeedUtcFormatted: true,
        timeZone,
      });

      setTimeUpdate(dayjs().locale('ru').format(DATE_FORMAT_TO_SHOW));

      if (dataResponse.status !== 200) {
        setIsError(true);
        setLoading(false);

        return;
      }

      setDataRes(dataResponse.data);
    },
    [id]
  );

  useEffect(() => {
    const sliceDates = [...getSliceDates(1)];

    setDateInterval(
      sliceDates.map((date) => date.locale('ru').format('DD MMMM YYYY'))
    );

    getData(sliceDates);
  }, [getData]);

  useEffect(() => {
    if (dataRes) {
      const { data } = normilizeData(CHART_NAMES[radioValue], dataRes, true);

      if (!data.length) {
        setLoading(false);
        setIsError(true);

        return;
      }

      const chartsNames = Object.keys(data[0]).filter(
        (item) => item !== 'time' && item !== 'averageSpeed'
      );

      chartsNames.unshift('average');

      const { formattedData, datePeriod, isNullableData } = getFormatDataDt(
        data,
        chartsNames,
        CHART_NAMES[radioValue]
      );

      setDataGraph(formattedData);
      setDateInterval(datePeriod);
      setLoading(false);
      setIsNullableData(isNullableData);
    }
  }, [dataRes, radioValue]);

  if (loading) {
    return (
      <div className={styles.container}>
        <DefaultComponent
          propsTitleInfo={{ dateInterval, timeUpdate, radioValue }}
        />
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className={styles.container}>
        <DefaultComponent
          propsTitleInfo={{ dateInterval, timeUpdate, radioValue }}
        />
        <div className={styles.error}>
          <NoDataIcon />
          <p>Нет данных</p>
        </div>
      </div>
    );
  }

  return (
    <>
      {dataGraph && (
        <div className={styles.container}>
          <div className={styles.containerInfo}>
            <div className={styles.title}>
              <TitleInfo
                dateInterval={dateInterval}
                timeUpdate={timeUpdate}
                radioValue={radioValue}
              />
              {!isNullableData && (
                <>
                  <p className={styles.timeTitle}>Промежуток времени: </p>
                  <p className={styles.timeTitle}>
                    {dataGraph[activeIndex].date}
                  </p>
                </>
              )}
            </div>
            <Popover {...popoverProps}>
              <p
                className={styles.infoBlock}
                style={{ backgroundColor: dataGraph[activeIndex].status }}
              >
                {dataGraph[activeIndex].comparableValue}
              </p>
            </Popover>
          </div>
          {!isNullableData && (
            <div className={styles.containerChart}>
              <ChartBar
                dataGr={dataGraph}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
              />
            </div>
          )}
          <Radio.Group
            onChange={(e) => setRadioValue(e.target.value)}
            value={radioValue}
            className={styles.radioGroup}
            buttonStyle="solid"
          >
            <Radio.Button value={vehicles}>Интенсивность</Radio.Button>
            <Radio.Button value={avSpeed}>Ср. скорость</Radio.Button>
          </Radio.Group>
        </div>
      )}
    </>
  );
};

export default observer(PopupDetector);
