import { Button } from 'antd';
import { FormInstance } from 'antd/lib';
import React, { FC } from 'react';

import styles from './BtnsBlock.module.scss';

interface IBtnsBlock {
  form: FormInstance;
  handleSumbitServerSearch: (form: FormInstance) => Promise<void>;
  onClose: () => void;
}

const TEXT = {
  sumbit: 'Отправить',
  close: 'Закрыть',
  cancel: 'Сбросить',
};

const BtnsBlock: FC<IBtnsBlock> = ({
  form,
  handleSumbitServerSearch,
  onClose,
}) => {
  const handleresetForm = () => {
    form.resetFields();

    handleSumbitServerSearch(form);
    onClose();
  };

  const handleSubmit = () => {
    handleSumbitServerSearch(form)
      .then(() => {
        onClose();

        form.resetFields();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={styles.btnsWrap}>
      <Button type="primary" onClick={handleSubmit}>
        {TEXT.sumbit}
      </Button>
      <Button onClick={handleresetForm}>{TEXT.cancel}</Button>
      <Button type="primary" danger onClick={onClose}>
        {TEXT.close}
      </Button>
    </div>
  );
};

export default BtnsBlock;
