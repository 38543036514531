import { MapBrowserEvent } from 'ol';
import type { FeatureLike } from 'ol/Feature';
import { DoubleClickZoom, Interaction } from 'ol/interaction';
import { toLonLat } from 'ol/proj';
import { useEffect, useState } from 'react';

import { CURSOR_MAP, ZOOM } from '../../../constants/mapConstants';
import setDisplayStyle from '../../../helpers/setDisplayStyle';
import rootStore from '../../../stores/rootStore/rootStore';
import { System } from '../../../ts/enums/enums';
import {
  addOverlays,
  getHit,
  handleInfoDataByClick,
  handlePointer,
  handleTooltip,
} from '../helpers/handlers';
import { openDetailedForm } from '../helpers/openDetailedForm';
import { UseMapEventHandlersProps } from '../Map.model';

const { GRAB, GRABBING } = CURSOR_MAP;

const useMapEventHandlers = ({
  map,
  initialMapCenter,
  closeTooltip,
  getTooltip,
  clusterFeatures,
  setIsTooltip,
  popups,
  infoData,
  handleInfoPanel,
  refs,
  isInfoPanel,
}: UseMapEventHandlersProps) => {
  const [isInfo, setIsInfo] = useState(false);

  const { setMapData, isClusterOpen } = rootStore.mapDataStore;
  const { setIsNot, setInfoData } = rootStore.uiStore;

  useEffect(() => {
    if (!map) return;

    const setTooltip = handleTooltip(closeTooltip, getTooltip);

    function listener(e: MapBrowserEvent<PointerEvent>) {
      if (!map) return;

      setTooltip(e, map, clusterFeatures, setIsTooltip, infoData);
    }

    map.on('pointermove', listener);

    return () => {
      map.un('pointermove', listener);
    };
  }, [map, getTooltip, closeTooltip, clusterFeatures, infoData, setIsTooltip]);

  useEffect(() => {
    if (!isInfo) {
      setIsNot('isInfoPanel', false);
    }
  }, [isInfo, setIsNot]);

  useEffect(() => {
    if (!map) {
      return;
    }

    addOverlays(map, popups);

    const dblClickHandler = (e: any) => {
      const features: FeatureLike[] = [];

      map.forEachFeatureAtPixel(e.pixel, (feature) => features.push(feature));

      const featureArr = features[0]?.get('features');
      const isCluster = featureArr?.length > 1;

      if (isCluster) return setInfoData(null);

      if (featureArr?.length === 1) {
        openDetailedForm(featureArr[0], e);
        setIsInfo((prev) => true);
      }
    };

    const moveEndHandler = () => {
      const currZoom = map.getView().getZoom();

      setMapData('currentZoom', currZoom ?? ZOOM.INITIAL);
      const center = map.getView().getCenter();

      map.getTargetElement().style.cursor = GRAB;

      initialMapCenter && setMapData('center', center ?? initialMapCenter);
    };

    map.on('dblclick', dblClickHandler);
    map.on('moveend', moveEndHandler);

    return () => {
      map.un('dblclick', dblClickHandler);
      map.un('moveend', moveEndHandler);
    };
  }, [map]); // eslint-disable-line

  useEffect(() => {
    if (!map) return;
    const currZoom = map.getView().getZoom();

    const handleZoom = () => {
      const newZoom = map.getView().getZoom();

      if (currZoom !== newZoom && isClusterOpen) {
        setMapData('isClusterOpen', false);
        closeTooltip();
        infoData?.system === System.Clusters && setIsNot('isInfoPanel', false);

        return;
      }
    };

    map.on('movestart', handleZoom);

    return () => {
      map.un('movestart', handleZoom);
    };
  }, [
    closeTooltip,
    isClusterOpen,
    map,
    setInfoData,
    setMapData,
    infoData,
    setIsNot,
  ]);

  useEffect(() => {
    if (!map) return;

    const handleClick = (e: MapBrowserEvent<any>) => {
      const features: FeatureLike[] = [];

      map.forEachFeatureAtPixel(e.pixel, (feature: FeatureLike) =>
        features.push(feature)
      );

      if (!features.length) setMapData('selectedFeature', null);

      const featureArr = features[0]?.get('features') ?? null;
      const featureId = featureArr ? featureArr[0]?.get('id') : null;
      const isCluster = featureArr?.length > 1;
      const isClusterLine = features[0]?.get('selectclusterlink');
      const isFeatureOnCluster = !!features[0]?.get('selectclusterfeature');
      const isClickedOnSelectedFeatureInCluster =
        isFeatureOnCluster && infoData?.id === featureId;

      if (isClusterLine || isClickedOnSelectedFeatureInCluster) return;

      !isCluster &&
        setIsInfo((prev) => (featureId ? infoData?.id !== featureId : false));

      const doubleClick = map
        .getInteractions()
        .getArray()
        .find((interaction: Interaction) => {
          if (interaction instanceof DoubleClickZoom) return interaction;

          return null;
        });

      setMapData('coordinates', toLonLat(e.coordinate));
      const newIsInfoPanel = handleInfoDataByClick(e, map, handleInfoPanel);

      const hit = getHit(e, map);

      if (hit) {
        setIsTooltip(false);
        handlePointer(map, { isPoint: false });
      }

      doubleClick?.setActive(!newIsInfoPanel || isCluster);

      if (isInfoPanel === newIsInfoPanel) return;

      !newIsInfoPanel && setDisplayStyle(Object.values(refs).flat());
      setIsNot('isInfoPanel', newIsInfoPanel);
    };

    map.on('click', handleClick);

    return () => {
      map.un('click', handleClick);
    };
  }, [
    handleInfoPanel,
    infoData,
    isInfoPanel,
    map,
    refs,
    setIsNot,
    setIsTooltip,
    setMapData,
  ]);

  useEffect(() => {
    if (!map) return;

    const onPointerDrag = () => {
      map.getTargetElement().style.cursor = GRABBING;
    };

    map.on('pointerdrag', onPointerDrag);

    return () => {
      map.un('pointerdrag', onPointerDrag);
    };
  }, [map]);

  return null;
};

export default useMapEventHandlers;
