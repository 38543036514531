/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { SYSTEM } from '../../../../constants/constants';
import getSystemTitle from '../../../../helpers/getSystemTitle';
import { LinkedDivices } from '../../../../stores/mapDataStore/mapDataStore.model';
import rootStore from '../../../../stores/rootStore/rootStore';
import { TL } from '../../../../ts/models/tl.model';

import Crossroad from './Crossroad/Crossroad';
import { getIsEmpty, getSortedTlsArray, getTlObjects } from './helpers';

import styles from './Crossroads.module.scss';

interface ICrossroads {
  linkedDevices: LinkedDivices;
}

const EMPTY_TEXT = 'Нет связанных устройств';

const Crossroads: FC<ICrossroads> = ({ linkedDevices }) => {
  const { tls } = rootStore.mapDataStore;
  const { trafficLights } = linkedDevices;
  const { setInfoData } = rootStore.uiStore;

  const handleTitleClick = (currentObject: TL) => {
    setInfoData({
      id: currentObject.id,
      coordinate: [
        Number(currentObject.longitude),
        Number(currentObject.latitude),
      ],
      system: SYSTEM.LIGHTS,
    });
  };

  const currentTls = getSortedTlsArray(trafficLights, tls);

  return (
    <div className={styles.wrapper}>
      {currentTls.map((tl) => {
        const tlObjects = getTlObjects(tl);

        if (!tlObjects) return null;

        const isEmpty = getIsEmpty(tlObjects);

        return (
          <div className={styles.row} key={tl.id}>
            <h3 className={styles.title} onClick={() => handleTitleClick(tl)}>
              {getSystemTitle(SYSTEM.LIGHTS, tl.extId)}
            </h3>
            {!isEmpty && <p className={styles.empty}>{EMPTY_TEXT}</p>}
            {isEmpty &&
              tlObjects.map((item) => {
                return <Crossroad crossroad={item} key={uniqueId()} />;
              })}
            <hr />
          </div>
        );
      })}
    </div>
  );
};

export default observer(Crossroads);
