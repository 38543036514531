import { observer } from 'mobx-react-lite';

import rootStore from '../../stores/rootStore/rootStore';
import { PanelType } from '../../ts/enums/enums';

import AdminPanel from './AdminPanel/AdminPanel';
import ConstructorPanel from './ConstructorPanel/ConstructorPanel';
import MainPanel from './MainPanel/MainPanel';
import ModulesPanel from './ModulesPanel/ModulesPanel';
import PanelLayout from './PanelLayout/PanelLayout';
import ProfilePanel from './ProfilePanel/ProfilePanel';
import ReportsPanel from './ReportsPanel/ReportsPanel';
import StatisticsPanel from './StatisticsPanel/StatisticsPanel';
import TestingPanel from './TestPanel/TestingPanel';
import VideoWallPanel from './VideoWallPanel/VideoWallPanel';


const PANEL: Record<PanelType, JSX.Element> = {
  cartography: <MainPanel />,
  statistics: <StatisticsPanel />,
  reports: <ReportsPanel />,
  profile: <ProfilePanel />,
  constructor: <ConstructorPanel />,
  modules: <ModulesPanel />,
  admins: <AdminPanel />,
  videoWall: <VideoWallPanel />,
  testing: <TestingPanel />,
};

const Panel = () => {
  const { panelType } = rootStore.uiStore;

  if (!panelType) return null;

  return <PanelLayout>{PANEL[panelType]}</PanelLayout>;
};

export default observer(Panel);
