/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { TbMap2, TbMapOff } from 'react-icons/tb';

import rootStore from '../../../../stores/rootStore/rootStore';
import Popover from '../../../ui-kit/Popover/Popover';

import styles from './ButtonCloseOverviewMap.module.scss';

const ButtonCloseOverviewMap = () => {
  const {
    isMinificMapOpen,
    setIsNot,
    isPanel,
    isMinificMap,
    tipsValues,
    isWidgets,
  } = rootStore.uiStore;

  const popoverContent =
    (isMinificMapOpen ? 'Свернуть' : 'Развернуть') + ' минифицированную карту?';

  const popOverProps = {
    content: popoverContent,
    placement: 'right',
    tipsDelay: tipsValues.profileTipsDelay,
  };

  const btnStyle = classNames({
    [styles.btnClose]: true,
    [styles.panelOpen]: isPanel,
  });

  const closeMinificMap = () => {
    setIsNot('isMinificMapOpen', !isMinificMapOpen);
  };

  if (!isMinificMap || isWidgets) return null;

  return (
    <Popover {...popOverProps}>
      <div className={btnStyle} onClick={closeMinificMap}>
        {isMinificMapOpen ? (
          <TbMapOff className={styles.collapseIcon} size={20} />
        ) : (
          <TbMap2 className={styles.collapseIcon} size={20} />
        )}
      </div>
    </Popover>
  );
};

export default observer(ButtonCloseOverviewMap);
