import { z } from 'zod';

import {
  IRequestItem,
  TMethods,
} from '../../components/Panel/TestPanel/ts/TestingPanel.model';

export const BasicRequestsTreeSchema = z.object({
  key: z.string(),
  label: z.string(),
  title: z.string(),
  href: z.string().nullable(),
  method: z.custom<TMethods>().nullable(),
  status: z.number().nullable(),
  body: z.custom<object>().nullable(),
});

const RequestsTreeSchema: z.ZodType<IRequestItem> =
  BasicRequestsTreeSchema.extend({
    children: z.lazy(() => RequestsTreeSchema.array().nullable()),
  });

export const menuTreeSchema = z.object({
  menu: z.array(RequestsTreeSchema),
});
