import React, { useEffect, useState } from 'react';

import styles from './ProgressBar.module.scss';

interface ProgressBarProps {
  color: string;
  completed: boolean;
}

function ProgressBar(props: ProgressBarProps) {
  const { color, completed } = props;
  const [fillerStyles, setFillerStyles] = useState({});

  useEffect(() => {
    setFillerStyles({
      width: `${completed}%`,
      backgroundColor: color,
      transition: 'all 1.5s ease-out',
    });
  }, [color, completed]);

  return (
    <div className={styles.container}>
      <div className={styles.filler} style={fillerStyles} />
    </div>
  );
}

export default ProgressBar;
