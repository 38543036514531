const getTLZoomTact = (tl: any, currentZoom: any) => {
  const idx: number = tl?.tlDetails?.tlZoomsTacts?.findIndex(
    (el: any) => el?.range[0] <= currentZoom && el?.range[1] > currentZoom
  );

  if (idx === undefined || idx < 0) return [[], []];

  return [tl?.tlDetails?.tlZoomsTacts[idx], tl?.tlDetails?.tlTactsImages[idx]];
};

export default getTLZoomTact;
