import { IFilteredInfo, ITableColumn } from '../../../../ts/models/table.model';

export const addFilters = (column: ITableColumn) => {
  const filters = column.filterSettings.filters;

  if (!filters) return null;

  column.filters = filters;

  column.onFilter = (value, record) => record[column.dataIndex] === value;

  return true;
};

export const addSystemFiltered = (column: ITableColumn) => {
  column.onFilter = (value, record) => {
    if (column.dataIndex && record[column.dataIndex]) {
      return record[column.dataIndex]
        .toString()
        .toLowerCase()
        .includes(String(value).toLowerCase());
    }

    return false;
  };
};

export const setFilteredValue = (
  filteredInfo: IFilteredInfo,
  dataIndex: string
) => {
  if (filteredInfo) {
    return filteredInfo[dataIndex] || null;
  }

  return null;
};
