import { Popconfirm, Space, Typography, notification } from 'antd';
import { observer } from 'mobx-react-lite';
import { AiOutlineEdit } from 'react-icons/ai';
import { RiDeleteBinLine } from 'react-icons/ri';

import { deleteTableRow } from '../../../api_';
import tryCatchWrapper from '../../../helpers/tryCatchWrapper';
import rootStore from '../../../stores/rootStore/rootStore';

import styles from './RowActionBtns.module.scss';

interface RowActionBtnsProps {
  setDeletingKey: (el: any) => void;
  setEditingKey: (el: any) => void;
  form: any;
  record: any;
  isDisabled: boolean;
  importedData: any;
}

function RowActionBtns(props: RowActionBtnsProps) {
  const {
    setDeletingKey,
    setEditingKey,
    form,
    record,
    isDisabled,
    importedData,
  } = props;
  const { moduleData, setKeyValue } = rootStore.uiStore,
    handleDelete = tryCatchWrapper(async (id: number) => {
      if (!moduleData) return;

      let res = null;

      if (id.toString().includes('importRow_')) {
        const newData = importedData.data.filter((item: any) => item.id !== id);

        notification.success({
          message: 'Выполнено',
          description: 'Cтрока успешно удалена из импорта',
        });

        setKeyValue('importedData', { ...importedData, data: newData });

        return;
      }

      if (!id.toString().includes('newRow_')) {
        res = await deleteTableRow(moduleData.code, id);
      }
      const newData = moduleData.data.filter((item: any) => item.id !== id);

      notification.success({
        message: 'Выполнено',
        description: res?.data?.message ?? 'Пустая строка успешно удалена',
      });

      setKeyValue('moduleData', { ...moduleData, data: newData });
      setDeletingKey('');
    }),
    edit = () => {
      form.setFieldsValue(record);
      setEditingKey(record.id);
    },
    handleDel = () => {
      form.setFieldsValue(record);
      handleDelete(record.id);
    };

  return (
    <Space>
      <Typography.Link disabled={isDisabled} onClick={edit}>
        <AiOutlineEdit className={styles.ico} />
      </Typography.Link>

      <Popconfirm
        cancelText="Нет"
        okText="Да"
        onCancel={() => setDeletingKey('')}
        onConfirm={handleDel}
        title="Удалить?"
      >
        <Typography.Link disabled={isDisabled}>
          <RiDeleteBinLine className={styles.ico} />
        </Typography.Link>
      </Popconfirm>
    </Space>
  );
}

export default observer(RowActionBtns);
