/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Switch } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import classNames from 'classnames';
import React, { MouseEventHandler, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';

import { TRANSITION_TIME } from '../../../constants/constants';
import ButtonIco from '../ButtonIco/ButtonIco';
import ConfirmSwitcher from '../ConfirmSwitcher/ConfirmSwitcher';

import styles from './AccordionSimple.module.scss';

export interface AccordionSimpleProps {
  title: string;
  children: any;
  isClosed?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isSwitch?: boolean;
  isEnabled?: boolean;
  handleActive?: SwitchChangeEventHandler;
  onAction?: any;
  onAction2?: any;
  isActionConfirm?: boolean;
  ActionIco?: any;
  ActionIco2?: any;
}

const AccordionSimple: React.FC<AccordionSimpleProps> = (
  props: AccordionSimpleProps
) => {
  const {
    title,
    children,
    isClosed = true,
    onClick,
    isSwitch,
    isEnabled,
    handleActive,
    onAction,
    onAction2,
    isActionConfirm,
    ActionIco = () => <span>+</span>,
    ActionIco2 = () => <span>+</span>,
  }: AccordionSimpleProps = props;
  const [isCollapsed, setIsCollapsed] = useState(isClosed);

  const handleCollapse = () => {
    onClick && setTimeout(onClick, TRANSITION_TIME);
    setIsCollapsed(!isCollapsed);
  };

  const isOpenBtnStyle = classNames({
    [styles.collapseBtn]: true,
    [styles.isAccordionOpen]: !isCollapsed,
  });

  const contentContainerStyle = classNames({
    [styles.contentContainer]: true,
    [styles.isOpen]: !isCollapsed,
    [styles.isHidden]: isCollapsed,
  });

  return (
    <section className={styles.container}>
      <div className={styles.header} onClick={handleCollapse}>
        <button type="button" className={isOpenBtnStyle}>
          <IoIosArrowForward />
        </button>
        <div className={styles.headerContent}>
          <div className={styles.info}>
            <h5 className={styles.title}>{title}</h5>
          </div>
          <div className={styles.btnsBlock}>
            {onAction2 && (
              <ButtonIco
                onClick={(e: any) => {
                  e.stopPropagation();
                  onAction2();
                }}
                style={{ width: 20, marginLeft: 5 }}
              >
                <ActionIco2 />
              </ButtonIco>
            )}

            {onAction && (
              <ConfirmSwitcher isConfirm={isActionConfirm} onClick={onAction}>
                <ButtonIco
                  onClick={(e: any) => {
                    e.stopPropagation();
                    !isActionConfirm && onAction();
                  }}
                  style={{ width: 35 }}
                >
                  <ActionIco />
                </ButtonIco>
              </ConfirmSwitcher>
            )}

            {isSwitch && handleActive && (
              <Switch
                size="small"
                checked={isEnabled}
                onChange={(value: boolean, e: any) => {
                  e.stopPropagation();
                  handleActive(value, e);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div role="presentation" className={contentContainerStyle}>
        <div className={styles.content}>{children}</div>
      </div>
    </section>
  );
};

export default AccordionSimple;
