import { message } from 'antd';
import { Key } from 'antd/lib/table/interface';

import adminGroupsApi from '../../../api/adminObjects/groups/groups';
import { MONTHS } from '../../../constants/constants';
import {
  INITIAL_MARKERS,
  INITIAL_SETTINGS,
} from '../../../constants/initialSettingsConstants';
import { MESSAGES } from '../../../constants/tableConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { DataType } from '../../../ts/models/table.model';
import { IBreadCrumbsItem } from '../BreadCrumbs/BreadCrumbs';

const { setAdminsData } = rootStore.adminPanelStore;

export const convertDate = (dateString: number) => {
  const date = new Date(dateString * 1000);
  const month = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  const nowDate = new Date(Date.now());
  const nowYear = nowDate.getFullYear();

  const string = `${day} ${MONTHS[month]} ${
    year === nowYear ? `в ${hours}:${minutes}` : year
  }`;

  return string;
};

export const createNewUserInfo = (values: any) => {
  const newUserInfo = {
    login: values.login,
    password: values.password,
    name: values.name,
    description: values.dsc,
    email: values.email,
    groups: [
      ...values.njs.map((item: string) => Number(item)),
      ...values.web.map((item: string) => Number(item)),
    ],
    isDelete: values.isDelete,
    isChange: values.isChange,
    active: values.active,
    // eslint-disable-next-line camelcase
    regionsId: values.regions_id,
  };

  if (!newUserInfo.password) delete newUserInfo.password;

  return newUserInfo;
};

export const onChangeGroupsRow = async (keys: Key[]) => {
  const groupId = keys[0];

  const list = await adminGroupsApi.fetchGroupSettings(
    groupId,
    'admin.group..settings'
  );

  message.success(MESSAGES.SUCCESS_CONFIGS_LOAD, 0.5);

  setAdminsData('groupId', groupId);
  setAdminsData('adminSettingConfigData', list.dataRows);
  setAdminsData('adminSettingConfigHeader', list.headerItem);
};

export const onSelectConfig = async (
  record: DataType,
  groupId: N<string | number>
) => {
  if (!groupId) return;
  await adminGroupsApi.updateGroupAccess(
    record,
    groupId,
    'admin.group.settings'
  );

  const list = await adminGroupsApi.fetchGroupSettings(
    groupId,
    'admin.group..settings'
  );

  message.success(MESSAGES.SUCCESS_CONFIG_UPDATE, 0.5);

  setAdminsData('adminSettingConfigData', list.dataRows);
  setAdminsData('adminSettingConfigHeader', list.headerItem);
};

export const parsingNavigationPath = (
  key: string,
  menu: any,
  newBreadCrumbs: IBreadCrumbsItem[]
) => {
  menu.forEach((item: any) => {
    if (item.key !== key) {
      if (item.children) {
        return parsingNavigationPath(key, item.children, newBreadCrumbs);
      }

      return;
    }

    newBreadCrumbs.push({
      label: item.label,
      key: item.key,
    });
  });
};

export const createInitialValue = (user: any) => {
  if (!user.jprofile) return { ...INITIAL_SETTINGS, ...INITIAL_MARKERS };

  const initialValue = {} as any;

  for (const setting in user.jprofile) {
    initialValue[setting as keyof typeof INITIAL_MARKERS] =
      user.jprofile[setting];
  }

  initialValue['mapIconsSize'] = INITIAL_SETTINGS.mapIconsSize;
  initialValue['isClusters'] = INITIAL_SETTINGS.isClusters;
  initialValue['isWidgets'] = INITIAL_SETTINGS.isWidgets;

  return initialValue;
};

export const parseItemTitle = (title: string) => {
  let newTitle = '';

  if (title === 'Редактируемый') {
    newTitle = 'Возможность редактировать';

    return newTitle;
  }

  if (title === 'Удаляемый') {
    newTitle = 'Возможность удалять';

    return newTitle;
  }

  if (title === 'Статус') {
    newTitle = 'Статус пользователя';

    return newTitle;
  }
};

export const updateParams = (param: any, data: any, key: any) => {
  const val = param.value;
  const jsonbParam = {
    ...val.value,
    [key]: {
      ...val.value[key],
      ...data,
    },
  };

  const getNewParam = (value: any) => ({
    ...param,
    value: {
      ...val,
      value: value,
    },
  });

  if (key === 'regions_id') {
    const newParam = getNewParam({ ...data });

    return newParam;
  }

  if (val.type === 'jsonb') {
    const newParam = getNewParam(jsonbParam);

    return newParam;
  }

  const newParam = getNewParam(data[key]);

  return newParam;
};
