import { Button } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { BsCheck2 } from 'react-icons/bs';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import Popover from '../../../../../ui-kit/Popover/Popover';

import styles from './SelectButton.module.scss';

interface SelectButtonProps {
  className?: string;
  phase: number;
  isDisabled: boolean;
  onClick: () => void;
}

const SelectButton: FC<SelectButtonProps> = ({
  phase,
  onClick,
  isDisabled,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { selectedPhase } = rootStore.detailedStore;

  const isSelectedPhase = selectedPhase === phase;
  const btnTitle = !isSelectedPhase ? 'Выбрать' : <BsCheck2 />;

  const btnStyle = classNames({
    [styles.btnSelect]: true,
    [styles.active]: isSelectedPhase,
  });

  const popOverProps = {
    content: `Выбрать фазу №${phase} для удержания?`,
    placement: 'bottom',
    tipsDelay: interfaceTipsDelay,
  };

  return (
    <Popover {...popOverProps}>
      <Button
        className={btnStyle}
        type="primary"
        id={`${phase}`}
        onClick={onClick}
        size="small"
        disabled={isDisabled}
      >
        {btnTitle}
      </Button>
    </Popover>
  );
};

export default observer(SelectButton);
