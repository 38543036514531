import api from '../api';
import { INITIAL_MARKERS } from '../constants/initialSettingsConstants';
import rootStore from '../stores/rootStore/rootStore';

const useRemoteSettings = () => {
  const { uiStore } = rootStore;
  const { setKeyValue, setTheme, setMarkerKey } = uiStore;

  const applySettings = (settings: any) => {
    for (const settingKey in settings) {
      const value = settings[settingKey];

      switch (settingKey) {
        case 'theme':
          setTheme(value);
          break;
        default:
          if (
            INITIAL_MARKERS[settingKey as keyof typeof INITIAL_MARKERS] !==
            undefined
          ) {
            setMarkerKey(settingKey, value);
          } else {
            setKeyValue(settingKey as keyof typeof uiStore, value);
          }
          break;
      }
    }
  };

  const saveSettingsItem = (name: string, value: any, appPath: string) => {
    api.user.settings.saveItem(name, value, appPath);
  };

  return { applySettings, saveSettingsItem };
};

export default useRemoteSettings;
