import { notification } from 'antd';
import { z } from 'zod';

import { VERSION } from '../../constants/versions';
import http from '../http';

const INCORRECT_DATA = 'Получены некорректные данные.';
const MEDIA = `${VERSION}/media/`;

const CrossroadSchema = z.string();

const mediaApi = {
  fetchCrossroad: async (id: number) => {
    try {
      const res = await http(`${MEDIA}crossroads/crossroad${id}.svg`);

      return CrossroadSchema.parse(res.data);
    } catch (e: any) {
      notification.error({
        message: `Ошибка запроса изображения перекрестка ЕПУТС id${id}`,
        description: e.response?.data?.message ?? INCORRECT_DATA,
      });

      return null;
    }
  },
};

export default mediaApi;
