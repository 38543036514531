import { Spin } from 'antd';
import { SpinProps } from 'antd/lib';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { TLStatus } from '../../../../../ts/models/tl.model';
import Img from '../../../../ui-kit/Img/Img';
import getImg from '../../helpers/getImg';
import { getTypeTactObj } from '../helpers/getTypeTactObj';

import PhaseContent from './PhaseContent/PhaseContent';

import styles from './MainPhaseCard.module.scss';

interface MainPhaseCardProps {
  tlStatus: TLStatus;
  extId: N<number>;
}

const MainPhaseCard: FC<MainPhaseCardProps> = ({ extId, tlStatus }) => {
  const { secTL, phasesArray } = rootStore.uiStore;
  const [imgUrl, setImgUrl] = useState('');

  const { mode } = tlStatus;

  const curPhase = phasesArray.find(
    ({ stop, start }) => secTL <= stop && secTL >= start
  );

  const timeToEnd = curPhase ? curPhase.stop - secTL : null;

  useEffect(() => {
    curPhase && setImgUrl(getImg(curPhase.phase, extId, mode));
  }, [curPhase, extId, mode]);

  const spinProps: SpinProps = {
    size: 'large',
    className: styles.spin,
  };

  if (!curPhase || timeToEnd === null)
    return (
      <div className={styles.container}>
        <Spin {...spinProps} />
      </div>
    );

  const tactTypeObj = getTypeTactObj(curPhase.tactType).dsc;

  const tactTypeText = tactTypeObj ? `(${tactTypeObj})` : '';

  const phaseTitle = `Фаза ${curPhase.phase} ${tactTypeText}`;

  return (
    <div className={styles.container}>
      <div className={styles.titleRow}>
        <span className={styles.phaseName}>{phaseTitle}</span>
        <div className={styles.secRow}>
          <p>Время до конца фазы: </p>
          <span className={styles.phaseSec}>{timeToEnd}</span>
        </div>
      </div>
      <Img src={imgUrl} alt="Картинка Фазы" className={styles.img} />
      <PhaseContent curPhase={curPhase} />
    </div>
  );
};

export default observer(MainPhaseCard);
