import { z } from 'zod';

import {
  MODES_ARR,
  SOURCE_ARR,
  STATES_ARR,
} from '../../../../components/TrafficLightDetailed/TlManagement/constants/constants';
import { CircleElEnum, TLPhaseCodes } from '../../../../ts/enums/tl';
import { getTlStatusZodValidation } from '../../../helpers/getTlStatusZodValidation';
import { getZodMessageCustom } from '../../../helpers/getZodMessageCustom';
import { NNInt, XY } from '../../../zod/utils.zod';
import {
  CoordinatesSchema,
  DeviceInfoSchema,
  LinkedDevicesSchema,
  OtherSchema,
} from '../../mapObjects.zod';

export const EputsAdapterSchema = z.object({
  /* eslint-disable camelcase */
  device_id: NNInt,
  scSystemId: NNInt,
  sourceType: NNInt,
  tlId: NNInt,
  ogcFid1: NNInt.nullable(),
});

export const HeaderSchema = z.object({
  code: z.string(),
  type: z.literal('trafficlight'),
  ver: z.string(),
});

export const GeometrySchema = z.object({
  type: z.literal('MultiPoint'),
  coordinates: CoordinatesSchema.array(),
});

export const PropertiesSchema = z.object({
  highway: z.string(),
});

export const FeatureOGSSchema = z.object({
  type: z.literal('Feature'),
  geometry: GeometrySchema,
  properties: PropertiesSchema,
});

export const LaneParamsSchema = z.object({
  id: z.number(),
  span: z.number(),
  width: z.number(),
  offset: z.number(),
});

export const TLFinalArrayFromProfilesSchema = z
  .object({
    centerPoint: XY,
    id: z.number(),
    isGradient: z.boolean(),
    offsetX: z.number(),
    offsetY: z.number(),
    mainOffsetX: z.number(),
    mainOffsetY: z.number(),
    mainWidth: z.number(),
    width: z.number(),
    angle: NNInt,
    length: z.number(),
    comment: z.string(),
    opacity: z.number(),
    laneParams: LaneParamsSchema.array(),
    color: z.string(),
  })
  .array()
  .nullable();

export const TLDetailsImageSchema = z.object({
  code: z.nativeEnum(TLPhaseCodes).nullable(),
  tact: NNInt.nullable(),
  directions: z.array(z.number()),
  image: z.string().endsWith('.png'),
  main: z.union([z.literal(0), z.literal(1)]),
  phase: z.number().positive().nullable(),
});

export const TLAnimationSchema = z.record(z.string(), z.unknown()).nullable();

export const TLTactSchema = z.object({
  main: z.union([z.literal(0), z.literal(1)]),
  tact: z.number().positive(),
  phase: z.number().positive(),
  time: NNInt,
  color: z.string().nullable(),
  tactType: z.number().positive().lte(3).nullable(),
  animation: TLAnimationSchema.optional(),
  addInfo: z.unknown().nullable().optional(),
  direction: z.array(z.number()).nullable(),
  durationMin: NNInt,
});

export const TLZoomsTactSchema = z.object({
  range: CoordinatesSchema,
  diameter: NNInt,
  borderWidth: NNInt,
  hue: NNInt,
  other: OtherSchema,
  tlTactsImagesIndex: NNInt.nullable(),
});

export const DashSchema = z.object({
  sizeRate: z.number(),
  dashArray: CoordinatesSchema,
});

export const TLTactsImageSchema = z.object({
  type: z.nativeEnum(CircleElEnum),
  angle: NNInt,
  positionXY: CoordinatesSchema,
  size: NNInt,
  color: z.string(),
  strokeWidth: NNInt,
  dash: DashSchema.nullable(),
  animation: TLAnimationSchema,
});

export const TLCrossroadSchema = z.object({
  angle: NNInt.lte(360),
  positionXY: CoordinatesSchema,
  size: NNInt,
  range: XY.nullable().optional(),
  svgURL: z.string().nullable().optional(),
  strokeWidth: z.number().positive().optional(),
  color: z.string().optional(),
  colorTransition: z.string().optional(),
  isBlinkingTransitionTact: z.boolean().optional(),
  crosswalkColor: z.string().optional(),
  crosswalkColorTransition: z.string().optional(),
});

export const TLDetailsSchema = z.object({
  images: TLDetailsImageSchema.array().nullable(),
  imagesURL: z.string().nullable(),
  other: OtherSchema,
  tlTacts: TLTactSchema.array().nullable(),
  tlZoomsTacts: TLZoomsTactSchema.array().nullable(),
  tlTactsImages: TLTactsImageSchema.array().array().array().nullable(),
  tlCrossroad: TLCrossroadSchema.nullable().optional(),
});

export const TdkSchema = z.object({
  codeNum: z.number().positive().lte(19),
  short: z.string(),
  full: z.string(),
});

export const TLGeneralSchema = z.object({
  currentMode: NNInt.optional().nullable(),
  progNum: NNInt.nullable(),
  progNumFrom: z.string().nullable(),
  progNumTo: z.string().nullable(),
  cycleTime: NNInt.nullable(),
  cycleTimeStart: z.string().datetime({ offset: true }).nullable(),
  tactsCount: NNInt.multipleOf(2),
  tactsView: NNInt,
  tactsTVP: NNInt.nullable(),
  tactsVPU: NNInt.nullable(),
  tdk: TdkSchema.nullable(),
  linksIdMgpApi: NNInt.array().nullable(),
  imagesPoint: z.object({ geometry: GeometrySchema }).nullable(),
  other: z.string().nullable().optional(),
  mag: z.number().nullable(),
});

export const TLSignalProgramItemSchema = z.object({
  color: z.string().nullable(),
  animation: TLAnimationSchema.nullable(),
  type: z.number().positive().lte(6),
  start: NNInt,
  stop: NNInt.nullable(),
  dbgdir: NNInt.nullable().optional(),
  dbgonlygreen: NNInt.nullable().optional(),
  dbgphase: NNInt,
  dbgstep: NNInt.nullable().optional(),
  onlygreen: NNInt.nullable().optional(),
  phase: NNInt,
  step: NNInt.nullable().optional(),
});

export const TLSignalProgramItemSchema2 = z.object({
  data: TLSignalProgramItemSchema.array(),
  dirNum: NNInt,
  viewName: z.string(),
  phaseGroupNum: NNInt,
});

export const CommandSchema = z.object({
  cmd: z.string(),
  full: z.string(),
  short: z.string(),
});

export const TLStatusSchema = z
  .object({
    mode: NNInt.refine(
      (val) => MODES_ARR.includes(val),
      getZodMessageCustom({ nameValue: 'mode', rangeArr: MODES_ARR })
    ),
    cycle: NNInt.nullable(),
    timeRcv: NNInt.nullable(),
    lsec: NNInt.nullable(),
    last: z.number(),
    offset: NNInt,
    state: NNInt.refine(
      (val) => STATES_ARR.includes(val),
      getZodMessageCustom({ nameValue: 'state', rangeArr: STATES_ARR })
    ),
    source: NNInt.nullable().refine(
      (val) => val === null || SOURCE_ARR.includes(val),
      getZodMessageCustom({ nameValue: 'source', rangeArr: SOURCE_ARR })
    ),
    extmode: NNInt.nullable(),
    extsource: NNInt.nullable(),
    command: CommandSchema.nullable(),
  })
  .refine(
    (tlStatus) => getTlStatusZodValidation(tlStatus, 'cycle'),
    getZodMessageCustom({ nameValue: 'cycle' })
  )
  .refine(
    (tlStatus) => getTlStatusZodValidation(tlStatus, 'lsec'),
    getZodMessageCustom({ nameValue: 'lsec' })
  )
  .refine(
    (tlStatus) => getTlStatusZodValidation(tlStatus, 'timeRcv'),
    getZodMessageCustom({ nameValue: 'timeRcv' })
  )
  .refine(
    (tlStatus) => getTlStatusZodValidation(tlStatus, 'last'),
    getZodMessageCustom({ nameValue: 'last' })
  );

export const TLSchema = z.object({
  addinfo: z.unknown().nullable().optional(),
  create: z.string().datetime({ offset: true }),
  id: NNInt,
  idMgpAPI: NNInt.nullable(),
  deviceInfo: DeviceInfoSchema,
  ogcFid1: NNInt,
  regionId: z.number(),
  linkedDevices: LinkedDevicesSchema.nullable().optional(),
  eputsAdapter: EputsAdapterSchema,
  header: HeaderSchema,
  featureOGS: FeatureOGSSchema,
  finalArrayFromProfiles: TLFinalArrayFromProfilesSchema,
  tlDetails: TLDetailsSchema,
  tlGeneral: TLGeneralSchema,
  tlSignalProgram: TLSignalProgramItemSchema.array().array().nullable(),
  tlSignalProgram2: TLSignalProgramItemSchema2.array().nullable().optional(),
  tlStatus: TLStatusSchema,
});
