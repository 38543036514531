import {
  TL_STATUS_COLORS_HEX,
  TL_STATUS_HEX,
} from '../constants/tlStatusConstants';
import { TlMode } from '../ts/models/tl.model';

const { RED, YELLOW, GREY } = TL_STATUS_COLORS_HEX;

const parseTLMode = (mode: N<TlMode>) => {
  const color = mode ? TL_STATUS_HEX[mode] : GREY;
  const isSpecialMode = [RED, YELLOW, GREY].some((el) => el === color);
  const isEmpty = mode === null || isSpecialMode;

  return { color, isEmpty, isSpecialMode };
};

export default parseTLMode;
