export const getCookiesFromObject = (object: object): string => {
  const likeCookieString: string = Object.entries(object).reduce(
    (acc, [key, value], i) => {
      if (i === 0) {
        return key + '=' + value;
      }

      return acc + '; ' + key + '=' + value;
    },
    ''
  );

  return likeCookieString;
};
