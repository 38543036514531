import { Spin } from 'antd';
import { FC } from 'react';

import JsonView from '../../../../../../ui-kit/JsonView/JsonView';
import { StatusTypes } from '../../../../ts/TestingPanel.model';

import styles from './ResponseInfo.module.scss';

export interface ResponseInfoProps {
  response: U<any>;
  statusMessage: StatusTypes;
  isLoading: boolean;
}

const ResponseInfo: FC<ResponseInfoProps> = (props) => {
  const { response, statusMessage, isLoading } = props;

  const getResponseJsonLogic = (): JSX.Element => {
    if (statusMessage === StatusTypes.init)
      return <div className={styles.tag}>Запрос ещё не был отправлен</div>;

    if (response === undefined)
      return <div className={styles.tag}>Запрос не вернул данные</div>;

    if (typeof response !== 'object')
      return (
        <div className={styles.tag}>
          Ответ запроса не является форматом JSON
        </div>
      );

    return <JsonView object={response} />;
  };

  return (
    <div className={styles.box}>
      <h3 className={styles.title}>JSON ответ от сервера</h3>
      <div className={styles.response}>
        {isLoading ? <Spin size="large" /> : getResponseJsonLogic()}
      </div>
    </div>
  );
};

export default ResponseInfo;
