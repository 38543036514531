import { RadioValue } from '../../../../TrafficLightDetailed/TlDetectors/model/graph.model';
import { CHART_NAMES } from '../Chart/constants/constants';
import { IDataGraph } from '../PopupDetector.model';

import { getArrSum } from './getArrSum';
import { IData, getAverage } from './getAverage';
import { getDatePeriod } from './getDatePeriod';
import { getDetectorsBarsStatus } from './getDetectorsBarsStatus';
import { getGeneral } from './getGeneral';

export interface ICalcData {
  comparableValue: number;
  date: string;
}

export const getFormatDataDt = (
  data: IData[],
  chartNames: string[],
  graphTitle: RadioValue
) => {
  const calcData: ICalcData[] = [];

  switch (graphTitle) {
    case CHART_NAMES.avSpeed:
      calcData.push(...getAverage(data, chartNames));
      break;
    case CHART_NAMES.vehicles:
      calcData.push(...getGeneral(data));
      break;
    default:
      calcData.push(...getAverage(data, chartNames));
  }

  const notNullablesData = calcData.filter(
    ({ comparableValue }) => comparableValue
  );

  const dataCompValues = notNullablesData.map(
    ({ comparableValue }) => comparableValue
  );

  const sumCalcData = getArrSum(dataCompValues);

  const averageCalcData = Math.round(sumCalcData / notNullablesData.length);

  const formattedData = calcData.reduce(
    (acc: IDataGraph[], value: ICalcData) => {
      const status = getDetectorsBarsStatus(
        value.comparableValue,
        averageCalcData
      );

      acc.push({ ...value, status });

      return acc;
    },
    []
  );

  const datePeriod = getDatePeriod(data);
  const isNullableData = formattedData.every((el) => !el.comparableValue);

  return {
    formattedData,
    datePeriod,
    isNullableData,
  };
};
