import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties, FC } from 'react';

import { DEFAULT_CIRCLE } from '../../../../constants/constructorConstants';
import useSwitcher from '../../../../hooks/useSwitcher';
import useSwitchers from '../../../../hooks/useSwitchers';
import { CircleElEnum } from '../../../../ts/enums/tl';
import { TactsImagesEl } from '../../../../ts/models/tl.model';
import getSvg from '../../helpers/getSvg';
import sortByCrosswalk from '../../helpers/sortByCrosswalk';
import PhaseCircleImgWrapper from '../../PhaseCircleImgWrapper/PhaseCircleImgWrapper';

const { STROKE_WIDTH, SIZE, HUE } = DEFAULT_CIRCLE;
const { Crosswalk } = CircleElEnum;

interface PhaseCircleImagesProps {
  data: N<TactsImagesEl[]>;
  rate: number;
  isBlinkingAnimation: boolean;
}

const PhaseCircleImages: FC<PhaseCircleImagesProps> = ({
  data,
  isBlinkingAnimation,
  rate,
}) => {
  const opacityAnimations = data && data.map((d) => d.animation?.opacity);
  const colorAnimations = data && data.map((d) => d.animation?.color);
  const [isOpacities] = useSwitchers(opacityAnimations);
  const [isColorChanging] = useSwitchers(colorAnimations);
  const [isBlinking] = useSwitcher(isBlinkingAnimation);
  const isBlinkingStatus = isBlinkingAnimation ? isBlinking : false;

  if (!data || isBlinkingStatus || !opacityAnimations) return null;

  const circleData = sortByCrosswalk(data);

  return (
    <>
      {circleData.map((item, i) => {
        const { type, color, angle, size, strokeWidth, dash, hue, positionXY } =
          item;

        const dashArr = dash?.dashArray;
        const imgId = uniqueId(`${type}${i}_`);
        const isCrosswalk = type === Crosswalk;
        const side = Math.round((size ?? SIZE) * rate);
        const [offsetX, offsetY] = positionXY.map((offset) =>
          Math.round((offset ?? 0) * rate)
        );

        const imgWrapperStyle = {
          top: offsetY,
          left: offsetX,
          width: side * (dash?.sizeRate ?? 1),
          height: side * (dash?.sizeRate ?? 1),
          transform: isCrosswalk
            ? `translate(-50%,-50%) rotate(${angle}deg)`
            : '',
        };

        const elementStyle: CSSProperties = {
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: side,
          height: side,
          filter: `hue-rotate(${hue ?? HUE}deg)`,
          strokeWidth: strokeWidth ?? STROKE_WIDTH,
          strokeDasharray: dashArr ? `${dashArr[0]} ${dashArr[1]}` : '',
          color:
            isColorChanging[i] === undefined || isColorChanging[i]
              ? color ?? 'green'
              : 'red',
          opacity: opacityAnimations[i] ? Number(isOpacities[i]) : 1,
          transform: `translate(-50%,-50%) ${
            !isCrosswalk ? `rotate(${angle}deg)` : ''
          }`,
        };

        return (
          <PhaseCircleImgWrapper style={imgWrapperStyle} key={imgId}>
            {getSvg(type, elementStyle)}
          </PhaseCircleImgWrapper>
        );
      })}
    </>
  );
};

export default observer(PhaseCircleImages);
