export const TYPE_OF_TACTS = {
  GREEN: 1,
  GREEN_FLASH: 2,
  YELLOW_FLASH: 3,
  YELLOW_AND_RED: 4,
  RED: 5,
  OTHER: 6,
} as const;

export type TactsVal = typeof TYPE_OF_TACTS[keyof typeof TYPE_OF_TACTS];
type TactsMap = Record<TactsVal, string>;

const { GREEN, GREEN_FLASH, YELLOW_AND_RED, YELLOW_FLASH, RED, OTHER } =
  TYPE_OF_TACTS;

export const DEFAULT_TACT_TYPE = 1;

export type TLTactTypesCode = IntRange<1, 4>;
export type TLTactTypesText = '' | 'ТВП' | 'ВПУ';

export interface ITypesInfo {
  code: TLTactTypesCode;
  dsc: TLTactTypesText;
  name: string;
}

export const TACT_TYPES_INFO: ITypesInfo[] = [
  {
    code: 1,
    dsc: '',
    name: 'Основная фаза',
  },
  {
    code: 2,
    dsc: 'ТВП',
    name: 'Вызывная фаза (ТВП)',
  },
  {
    code: 3,
    dsc: 'ВПУ',
    name: 'Вызывная фаза (ВПУ)',
  },
];

export const TYPE_COLORS: TactsMap = {
  [GREEN]: '#00ff40',
  [GREEN_FLASH]: '#22402a',
  [YELLOW_FLASH]: '#fde910',
  [YELLOW_AND_RED]: 'linear-gradient(90deg, #99244f,#f7ce46)',
  [RED]: '#ce6f4f',
  [OTHER]: 'inherit',
};

export const TYPES_CONTENT: { [key: number]: string } = {
  1: ' (Разрешено)',
  2: ' (Пром такт)',
  3: ' (Пром такт)',
  4: ' (Пром такт)',
  5: ' (Запрещено)',
};

export const TYPE_OF_SUB_TACTS = {
  GREEN_TO_RED: 'YN',
  RED_TO_GREEN: 'NY',
};

export const PHASE_DIR_TYPES = {
  Y: 'Y',
  N: 'N',
  YY: 'Y-Y',
  YN: 'Y-N',
  NY: 'N-Y',
  NN: 'N-N',
};

export const MAIN_PHASE = 1;
export const SUB_PHASE = 0;
