import { observer } from 'mobx-react-lite';
import { lazy, Suspense, useEffect } from 'react';

import api from '../../api';
import rootStore from '../../stores/rootStore/rootStore';
import Detailed from '../ui-kit/Detailed/Detailed';

import { USER_LAST_WATCHED_VIDEO_PROFILE_KEY } from './constants';

const Actions = lazy(() => import('./Actions/Actions'));
const InitialVideo = lazy(() => import('./InitialVideo/InitialVideo'));
const VideoList = lazy(() => import('./VideoList/VideoList'));

const HEADER = 'Модуль видеонаблюдения';

const VideoWall = () => {
  const { setIsNot, setKeyValue } = rootStore.uiStore;
  const { user } = rootStore.userDataStore;
  const {
    videoList,
    isVideoPanel,
    isFullScreen,
    selectedVideoId,
    setVideoKeysValues,
    savedVideoLists,
    changeVideoList,
  } = rootStore.videoWallPanelStore;

  const handleSelectVideoProfileId = async (selId: number) => {
    if (!user) return;

    user.jprofile[USER_LAST_WATCHED_VIDEO_PROFILE_KEY] = selId;

    await api.user.settings.saveItem(
      USER_LAST_WATCHED_VIDEO_PROFILE_KEY,
      selId,
      'wideoWall.saveViewedProfileSetting'
    );
  };

  useEffect(() => {
    if (!selectedVideoId) return;
    handleSelectVideoProfileId(selectedVideoId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setVideoKeysValues, selectedVideoId]);

  useEffect(() => {
    const user = rootStore.userDataStore.user;

    if (!user) return;

    const userSavedId = user.jprofile[USER_LAST_WATCHED_VIDEO_PROFILE_KEY];

    if (!userSavedId) return;

    const savedProfile = savedVideoLists.find((el) => el.id === userSavedId);

    if (!savedProfile) return;

    const { jprofile, name, isChange } = savedProfile;

    changeVideoList(jprofile.videoList, name, userSavedId, isChange);
  }, [changeVideoList, savedVideoLists]);

  const onVideoClose = () => {
    setIsNot('isPanel', false);
    setKeyValue('panelType', null);
    setVideoKeysValues({ isFullScreen: false });
  };

  const title =
    selectedVideoId &&
    `${
      savedVideoLists.find((item) => item.id === selectedVideoId)?.name ??
      'Профиль не найден!'
    }`;

  const detailedProps = {
    header: isFullScreen ? '' : HEADER,
    backBtnTitle: 'к карте',
    handleBackBtn: onVideoClose,
  };

  return (
    <Suspense fallback={isVideoPanel}>
      <Detailed {...detailedProps}>
        {title ? (
          <h3 style={{ opacity: 0.7 }}>Выбраный профиль: {title}</h3>
        ) : null}
        {videoList.length ? <Actions /> : null}
        {videoList.length ? <VideoList /> : <InitialVideo />}
      </Detailed>
    </Suspense>
  );
};

export default observer(VideoWall);
