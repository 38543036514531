import { FC } from 'react';

import { findById } from '../../../../../helpers/findBy';
import { ImagePostfix } from '../../../../../ts/enums/enums';
import { TlMode } from '../../../../../ts/models/tl.model';
import Img from '../../../../ui-kit/Img/Img';
import { COMMANDS_CONTENT } from '../../../TlManagement/constants/constants';
import getImg from '../../helpers/getImg';

import styles from './NotPhaseCard.module.scss';

const TITLE = 'Включен режим:';

interface INotPhaseCard {
  mode: TlMode;
  extId: N<number>;
}

const NotPhaseCard: FC<INotPhaseCard> = ({ mode, extId }) => {
  const name = findById(COMMANDS_CONTENT, mode)?.body ?? 'Режим неизвестен';

  return (
    <div className={styles.wrapper}>
      <h3>
        {TITLE}
        {name}
      </h3>

      <div className={styles.imgWrapper}>
        <Img
          src={getImg(ImagePostfix.Off, extId, mode)}
          alt="Схема"
          className={styles.img}
        />
      </div>
    </div>
  );
};

export default NotPhaseCard;
