import { useState } from 'react';

import { TL_NAME_STORE } from '../../constants/mapConstants';
import rootStore from '../../stores/rootStore/rootStore';

const useSocketUpdate = () => {
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const { mapDataStore } = rootStore;

  const addItemHandler = (message: any) => {
    let targetType = message.change.path.shift();
    const targetId = message.change.path.shift();
    const fieldValue = message.change.value;

    if (targetType === 'trafficLights') {
      targetType = TL_NAME_STORE;
    }

    mapDataStore.addItemWS(targetType, targetId, fieldValue);
    setLastUpdate(message.lastUpdate);
  };

  const deleteItemHandler = (message: any) => {
    let targetType = message.change.path.shift();
    const targetId = message.change.path.shift();

    if (targetType === 'trafficLights') {
      targetType = TL_NAME_STORE;
    }

    mapDataStore.deleteItemWS(targetType, targetId);
    setLastUpdate(message.lastUpdate);
  };

  const fieldChangedHandler = (message: any) => {
    let targetType = message.change.path.shift();
    const targetId = message.change.path.shift();
    const fieldPath = message.change.path;
    const fieldValue = message.change.value;

    if (targetType === 'trafficLights') {
      targetType = TL_NAME_STORE;
    }

    mapDataStore.updateItemWS(targetType, targetId, fieldPath, fieldValue);
    setLastUpdate(message.lastUpdate);
  };

  return { addItemHandler, deleteItemHandler, fieldChangedHandler, lastUpdate };
};

export default useSocketUpdate;
