import { Segmented } from 'antd';
import React, { FC } from 'react';

import styles from './ChangeExportType.module.scss';
interface IChangeExportType {
  setType: SetState<string>;
}

const ChangeExportType: FC<IChangeExportType> = ({ setType }) => (
  <Segmented
    options={['xlsx', 'csv']}
    defaultValue="xlsx"
    onChange={setType}
    size="small"
    className={styles.segmented}
  />
);

export default ChangeExportType;
