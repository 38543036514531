import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';

import styles from './SearchSettings.module.scss';

interface ISearchSettings {
  isSerachSettings: boolean;
}

const SearchSettings: FC<ISearchSettings> = ({ isSerachSettings }) => {
  const { systems, setMapData, searchedArea } = rootStore.mapDataStore;

  const handleChangeSearchedType = (title: string) => {
    setMapData('searchedArea', title);
  };

  const height = isSerachSettings ? systems.length * 45 + 'px' : '0';
  const padding = isSerachSettings ? '2rem 2rem 0 2rem' : '0';

  return (
    <div className={styles.settings} style={{ height, padding }}>
      <p>Область поиска</p>
      {systems.map((item, i) => (
        <Button
          type={item.title === searchedArea ? 'primary' : 'default'}
          key={i}
          size="small"
          onClick={() => handleChangeSearchedType(item.title)}
        >
          {item.title}
        </Button>
      ))}
    </div>
  );
};

export default observer(SearchSettings);
