import adminObjects from './adminObjects/groups/index';
import catalogs from './catalogs';
import filesApi from './files';
import mapObjects from './mapObjects';
import media from './media';
import serverObjects from './serverObjects';
import testingPanel from './testingPanel';
import user from './user';

export default {
  mapObjects,
  serverObjects,
  catalogs,
  media,
  adminObjects,
  testingPanel,
  filesApi,
  user
};
