import { BREAK_POINTS } from '../../constants/constants';

interface IResponsive {
  breakpoint: number;
  settings: {
    slidesToShow: number;
  };
}

const getSliderSettings = (correction = 0, breakPoints = BREAK_POINTS) => {
  return {
    infinite: true,
    draggable: true,
    responsive: Object.values(breakPoints).reduce(
      (acc: IResponsive[], value, i) => {
        if (!value) return acc;

        const slides = 4 - i;

        const isSlidesMin = slides + correction !== 0 && slides !== 1;

        const slidesToShow = isSlidesMin ? slides + correction : 1;

        acc.push({
          breakpoint: value,
          settings: {
            slidesToShow,
          },
        });

        return acc;
      },
      []
    ),
    speed: 500,
    slidesToShow: 5 + correction,
    slidesToScroll: 1,
  };
};

export default getSliderSettings;
