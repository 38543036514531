import { Feature, Overlay } from 'ol';
import type { Coordinate } from 'ol/coordinate';
import { RefObject, useCallback } from 'react';

import setDisplayStyle from '../../../helpers/setDisplayStyle';
import rootStore from '../../../stores/rootStore/rootStore';
import { System } from '../../../ts/enums/enums';

interface IUseTooltip {
  tooltipRef: RefObject<HTMLDivElement>;
  tooltip: U<Overlay>;
}

const useTooltip = (props: IUseTooltip) => {
  const { tooltipRef, tooltip } = props;
  const { getCoordinatesById } = rootStore.mapDataStore;
  const { setTooltipData } = rootStore.uiStore;

  const closeTooltip: () => void = useCallback(() => {
    setDisplayStyle([tooltipRef]);
    setTooltipData(null);
  }, [setTooltipData, tooltipRef]);

  const getTooltip = useCallback(
    async (
      id: number,
      system: System,
      coordinates: U<Coordinate>,
      clustersFeatures: Feature[] = []
    ) => {
      closeTooltip();
      const featureCenter = coordinates ?? getCoordinatesById(id);

      setTooltipData({ id, system, clustersFeatures });
      tooltip?.setPosition(featureCenter);

      setDisplayStyle([tooltipRef], 'block');
    },
    [closeTooltip, getCoordinatesById, setTooltipData, tooltip, tooltipRef]
  );

  return { closeTooltip, getTooltip };
};

export default useTooltip;
