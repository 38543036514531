import { useEffect, useState } from 'react';

import api from '../../../../api';
import { addIconInMenu } from '../helpers/addIconInMenu';
import { IRequestItem } from '../ts/TestingPanel.model';

const useMenuItems = () => {
  const [menuItems, setMenuItems] = useState<IRequestItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getSystemsTree = async () => {
    try {
      setIsLoading(true);
      const newTree = await api.testingPanel.fetchRequestsTree(
        'testingPanel.getTree'
      );

      setMenuItems(addIconInMenu(newTree.menu));
      setIsError(false);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSystemsTree();
  }, []);

  return { menuItems, isLoading, isError };
};

export default useMenuItems;
