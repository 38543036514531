import { FC } from 'react';

import { CameraWrapperProps } from '../../models/camera.model';

import styles from './CameraWrapper.module.scss';

const CameraWrapper: FC<CameraWrapperProps> = ({
  calcVal,
  camera,
  size,
  children,
}) => {
  const { offsetX, offsetY } = camera;

  const wrapperStyle = {
    top: calcVal(offsetY),
    left: calcVal(offsetX),
    width: size,
    height: size,
  };

  return (
    <div className={styles.container}>
      <div style={wrapperStyle} className={styles.container}>
        {children}
      </div>
    </div>
  );
};

export default CameraWrapper;
