import {
  NOT_WORK_STATES,
  SPECIAL_MODES,
} from '../../components/TrafficLightDetailed/TlManagement/constants/constants';
import { TLGeneral, TLStatus } from '../../ts/models/tl.model';
import { NNInt } from '../zod/utils.zod';

import { getZodMessageCustom } from './getZodMessageCustom';
import { IData, ObjType } from './validateData';

interface IShema {
  tlGeneral: TLGeneral;
  tlStatus: TLStatus;
}

export const getValidate = (Schema: any, objType: string, el: IData) =>
  objType !== ObjType.tl
    ? Schema.safeParse(el)
    : Schema.refine(
        ({ tlStatus: { mode, state }, tlGeneral: { cycleTime } }: IShema) =>
          SPECIAL_MODES.includes(mode) || NOT_WORK_STATES.includes(state)
            ? NNInt.nullable().safeParse(cycleTime).success
            : NNInt.safeParse(cycleTime).success,
        getZodMessageCustom({ nameValue: 'cycleTime', nameParent: 'TlGeneral' })
      ).safeParse(el);
