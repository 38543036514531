import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import { ITypesInfo } from '../../../../../constants/signalsConstants';
import { findById } from '../../../../../helpers/findBy';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { ImagePostfix } from '../../../../../ts/enums/enums';
import { ITimePoints } from '../../../../../ts/models/signalPrograms.model';
import { TLStatus } from '../../../../../ts/models/tl.model';
import Img from '../../../../ui-kit/Img/Img';
import Popover from '../../../../ui-kit/Popover/Popover';
import getImg from '../../helpers/getImg';

import { getCardData } from './helpers';
import SelectButton from './SelectButton/SelectButton';

import styles from './PhaseCard.module.scss';

export interface IPhaseCard {
  extId: N<number>;
  tlStatus: TLStatus;
  tactTypeObj: ITypesInfo;
  phasesSet: ITimePoints[];
  id: number;
  phase: ITimePoints;
  isSliderCard?: boolean;
  isDisabled?: boolean;
  onSelectPhase?: (value: number) => void;
}

const PhaseCard: FC<IPhaseCard> = ({
  extId,
  tlStatus,
  phasesSet,
  tactTypeObj,
  id,
  phase,
  isSliderCard = false,
  isDisabled = false,
  onSelectPhase,
}) => {
  const { secTls, interfaceTipsDelay } = rootStore.uiStore;
  const { mode } = tlStatus;
  const [imgUrl, setImgUrl] = useState('');

  const sec = findById(secTls, id)?.sec ?? 0;

  const curPhase = phasesSet.find(
    ({ stop, start }) => sec <= stop && sec >= start
  );

  useEffect(() => {
    const type = curPhase ? phase.phase : ImagePostfix.Off;

    setImgUrl(getImg(type, extId, mode));
  }, [curPhase, extId]); // eslint-disable-line

  const isCurrentPhase = sec <= phase.stop && sec >= phase.start;
  const remainder = phase.stop - sec;

  const PhaseCardStyles = classNames({
    [styles.card]: true,
    [styles.isCurrent]: isCurrentPhase,
    [styles.isPromTac]: remainder < phase.time,
    [styles.isSlider]: isSliderCard,
    [styles.isDisabled]: isDisabled,
  });

  const popOverProps = {
    placement: 'top',
    tipsDelay: interfaceTipsDelay,
  };

  return (
    <li className={PhaseCardStyles}>
      <Img src={imgUrl} alt="Картинка Фазы" className={styles.img} />

      <p className={styles.pahseInfo}>
        <span className={styles.phase}>Ф {phase.phase}</span>
        {tactTypeObj.dsc && (
          <Popover {...popOverProps} content={tactTypeObj.name}>
            <span className={styles.phaseType}>{tactTypeObj.dsc}</span>
          </Popover>
        )}
        {isCurrentPhase && remainder > 0 && (
          <span className={styles.time}>{remainder}</span>
        )}
      </p>
      {getCardData(phase).map(({ label, value }, i) => (
        <p className={styles.pahseInfo} key={i}>
          <span>{label}</span>
          <span>{value}</span>
        </p>
      ))}
      {onSelectPhase ? (
        <SelectButton
          onClick={() => onSelectPhase(phase.phase)}
          phase={phase.phase}
          isDisabled={isDisabled}
        />
      ) : null}
    </li>
  );
};

export default observer(PhaseCard);
