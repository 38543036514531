import { observer } from 'mobx-react-lite';
import { CSSProperties, FC } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Cell, Pie, PieChart } from 'recharts';

import { PHASE_CIRCLE_COLORS } from '../../../../constants/colorsConstants';
import { DEFAULT_CIRCLE } from '../../../../constants/constructorConstants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { useCircleDiameter } from '../../hooks';
import { PhaseCircleProps } from '../PhaseCircleOverlay.model';

import styles from './PhaseCircle.module.scss';

const { HUE, OPACITY } = DEFAULT_CIRCLE;
const { GREEN, ORANGE } = PHASE_CIRCLE_COLORS;

const PhaseCircle: FC<PhaseCircleProps> = ({
  timer,
  len,
  color,
  data,
  circleBorderWidth,
}) => {
  const { isPhaseCircleLabel } = rootStore.uiStore;

  const { diameter } = useCircleDiameter();

  const containerStyles: CSSProperties = {
    filter: `hue-rotate(${HUE}deg)`,
  };

  const circularWrapperStyles: CSSProperties = {
    ...containerStyles,
    width: diameter,
    height: diameter,
    opacity: `${OPACITY}%`,
  };

  return (
    <>
      <div className={styles.circularWrapper} style={circularWrapperStyles}>
        <CircularProgressbar
          value={timer}
          minValue={0}
          maxValue={len}
          strokeWidth={50}
          styles={buildStyles({
            strokeLinecap: 'butt',
            pathColor: color,
            trailColor: 'transparent',
            pathTransition:
              timer === 0 ? 'none' : 'stroke-dashoffset 0.5s ease 0s',
          })}
        />
      </div>

      <div className={styles.pie} style={containerStyles}>
        <PieChart width={diameter + 75} height={diameter + 75}>
          <Pie
            dataKey="time"
            data={data}
            innerRadius={diameter / 2 - circleBorderWidth}
            outerRadius={diameter / 2}
            startAngle={90}
            endAngle={450}
            label={isPhaseCircleLabel}
          >
            {data.map(({ color }, i) => {
              const col = color || (i % 2 ? GREEN : ORANGE);

              return <Cell key={`cell-${i}`} fill={col} />;
            })}
          </Pie>
        </PieChart>
      </div>
    </>
  );
};

export default observer(PhaseCircle);
