import Feature from 'ol/Feature';
import OLVectorImageLayer from 'ol/layer/VectorImage';
import { useContext, useEffect } from 'react';

import MapContext from '../../Map/MapContext';
import { vector } from '../MapSource';
interface VectorImageLayerProps {
  features: Feature[];
  style: any;
  zIndex?: number;
}

function VectorLayer(props: VectorImageLayerProps) {
  const { features, style, zIndex = 0 } = props;
  const { map }: any = useContext(MapContext);

  useEffect(() => {
    if (!map) {
      return;
    }

    const vectorLayer = new OLVectorImageLayer({
      source: vector({ features }),
      style,
    });

    map.addLayer(vectorLayer);
    vectorLayer.setZIndex(zIndex);

    return () => {
      if (map) {
        map.removeLayer(vectorLayer);
      }
    };
  }, [map, features, zIndex, style]);

  return null;
}

export default VectorLayer;
