import { Feature } from 'ol';
import { Point } from 'ol/geom';

import { IInfoPanelData } from '../../../stores/uiStore/uiStore';
import { IFeaturesArr } from '../../Map/helpers/getFeaturesCluster';

import { getColorStatusOverlay } from './getColorStatusOverlay';

export const getErrorsObj = (
  features: Feature<Point>[],
  infoData: N<IInfoPanelData>
) => {
  const errorsFeatures = features.reduce(
    (acc: IFeaturesArr[], value: Feature<Point>) => {
      const features: Feature<Point>[] = value.get('features');
      const colorAlarm = getColorStatusOverlay(features, infoData);

      if (!colorAlarm) return acc;

      const coord = value.getGeometry()?.getCoordinates();

      if (value.get('features').length > 1) {
        acc.push({ feature: value, coord: coord, colorAlarm });

        return acc;
      }

      acc.push({ feature: value.get('features')[0], coord: coord, colorAlarm });

      return acc;
    },
    []
  );

  return errorsFeatures;
};
