export const convetTimeToColor = (time: number) => {
  if (time <= 40) return 'green';
  if (time > 40 && time <= 100) return 'orange';

  return 'red';
};

export const convertDateToTime = (date: number) => {
  const hours = Math.floor(date / 60 / 60);
  const minutes = Math.floor(date / 60);
  const sec = Math.floor(date);

  const time = `${hours.toString().padStart(2, '0')}:${(minutes % 60)
    .toString()
    .padStart(2, '0')}:${(sec % 60).toString().padStart(2, '0')}`;

  return time;
};
