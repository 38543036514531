import { VERSION } from '../../../constants/versions';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';

const AUTH = VERSION + '/user/auth';

export const auth = {
  authorization: async (login: string, password: string, appPath: string) => {
    const request = await http.post(
      `${AUTH}/authorization`,
      {
        login,
        password,
      },
      {
        headers: getAppPathHeaders(appPath),
      }
    );

    return request;
  },
  refreshToken: async (refreshToken: string, appPath: string) => {
    const request = await http.get(`${AUTH}/refresh-token`, {
      headers: {
        'Refresh-Token': `Bearer ${refreshToken}`,
        ...getAppPathHeaders(appPath),
      },
    });

    return request;
  },
};
