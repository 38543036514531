import { FC } from 'react';

import { RadioValue } from '../../../../TrafficLightDetailed/TlDetectors/model/graph.model';

import TitleInfo from './TitleInfo/TitleInfo';

import styles from '../PopupDetector.module.scss';

interface IDefaultComponent {
  propsTitleInfo: {
    dateInterval: string[];
    timeUpdate: U<string>;
    radioValue: RadioValue;
  };
}

const DefaultComponent: FC<IDefaultComponent> = ({ propsTitleInfo }) => {
  return (
    <div className={styles.containerInfo}>
      <div className={styles.title}>
        <TitleInfo {...propsTitleInfo} />
      </div>
    </div>
  );
};

export default DefaultComponent;
