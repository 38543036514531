import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { ReactComponent as ItcSign } from '../../../assets/icons/ic_itc.svg';
import { ReactComponent as SberLogo } from '../../../assets/icons/ic_logo.svg';
import { ReactComponent as OsutLogoNonText } from '../../../assets/icons/ic_osut_none_text.svg';
import { ReactComponent as SberSign } from '../../../assets/icons/ic_sber.svg';
import { LOGO_URLS } from '../../../constants/constants';
import rootStore from '../../../stores/rootStore/rootStore';
import { SidebarCode } from '../../../ts/enums/userData';

import styles from './Logo.module.scss';
interface ILogoProps {
  isSmall?: boolean;
  className?: string;
}

const Logo: React.FC<ILogoProps> = (props: ILogoProps) => {
  const { isSmall } = props;

  const { hasAccess } = rootStore.userDataStore;

  const isAccess = hasAccess(SidebarCode.Logo);

  const changeFavicon = (access: boolean) => {
    const oldIcon = document.querySelectorAll('link[rel="shortcut icon"]');

    const faviconUrl = access ? LOGO_URLS.SBER : LOGO_URLS.OSUT;

    oldIcon.forEach((el) => el.setAttribute('href', el.baseURI + faviconUrl));
  };

  useEffect(() => {
    changeFavicon(isAccess);
  }, [isAccess]);

  return (
    <div className={styles.container}>
      {isAccess ? (
        <>
          <SberLogo
            className={classNames({
              [styles.logo]: true,
              [styles.logoSmall]: isSmall,
            })}
          />
          <ItcSign
            className={classNames({
              [styles.itc]: true,
              [styles.itcSmall]: isSmall,
            })}
          />
          <SberSign
            className={classNames({
              [styles.sber]: true,
              [styles.sberSmall]: isSmall,
            })}
          />
        </>
      ) : (
        <OsutLogoNonText
          className={classNames({
            [styles.logoOsut]: !isSmall,
            [styles.logoSmall]: isSmall,
          })}
        />
      )}
    </div>
  );
};

export default observer(Logo);
