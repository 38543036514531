import { observer } from 'mobx-react-lite';
import React from 'react';

import { moveFeatures } from '../../../helpers/changeFeatures';
import rootStore from '../../../stores/rootStore/rootStore';
import { VectorImageLayer } from '../../ui-kit/MapLayers';
import { getGeoStyle } from '../helpers';

function StreamsLayers() {
  const { markers } = rootStore.uiStore,
    { streams } = rootStore.mapDataStore;

  if (!markers.isStreams) {
    return;
  }

  return (
    <VectorImageLayer features={moveFeatures(streams, 0)} style={getGeoStyle} />
  );
}

export default observer(StreamsLayers as any);
