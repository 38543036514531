import { FC } from 'react';

import { RadioValue } from '../../../../../TrafficLightDetailed/TlDetectors/model/graph.model';
import { CHART_NAMES } from '../../Chart/constants/constants';
import styles from '../../PopupDetector.module.scss';

interface ITitleInfo {
  dateInterval: string[];
  timeUpdate: U<string>;
  radioValue: RadioValue;
}

const { vehicles } = CHART_NAMES;

const TitleInfo: FC<ITitleInfo> = ({
  dateInterval,
  timeUpdate,
  radioValue,
}) => {
  const title =
    radioValue === vehicles ? 'Интенсивность ТС' : 'Средняя скорость ТС';

  return (
    <>
      <h2>{title}</h2>
      <p className={styles.intervalTitle}>
        * за период: {dateInterval[0]} по {dateInterval[1]}
      </p>
      {timeUpdate ? (
        <p className={styles.intervalTitle}>* обновлено: {timeUpdate}</p>
      ) : null}
    </>
  );
};

export default TitleInfo;
