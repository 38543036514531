import { VERSION } from '../../../constants/versions';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';

const ABOUT = VERSION + '/admin/about/';

const about = {
  getAboutSystem: (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(ABOUT + 'system', {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
  getAboutVersions: (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(ABOUT + 'versions', {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
  getAboutRegions: (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(ABOUT + 'regions', {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
  getAboutSubSystems: (regionId: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(ABOUT + 'subsystems', {
        params: {
          regionId,
        },
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
  getAboutSystemType: (subsystemId: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(ABOUT + 'systemtypes', {
        params: {
          subsystemId,
        },
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
};

export default about;
