import { EVENTS, SYSTEM } from '../constants/constants';
import { System } from '../ts/enums/enums';

const {
    LIGHTS,
    DETECTORS,
    CAMERAS,
    PUBLIC_TRANSPORT,
    METEO,
    SPECIAL_TRANSPORT,
  } = SYSTEM,
  { RTA, ROAD_WORKS } = EVENTS,
  getSystemTitle = (system: any, id: N<number>) => {
    switch (system) {
      case LIGHTS:
        return `Светофорный объект ${id}`;
      case DETECTORS:
        return `Детектор транспорта ${id}`;
      case CAMERAS:
        return `Камера видеонаблюдения ${id}`;
      case System.Streams:
        return `Участок дороги ${id}`;
      case PUBLIC_TRANSPORT:
        return `Общественный транспорт ${id}`;
      case SPECIAL_TRANSPORT:
        return `Специальная техника ${id}`;
      case METEO:
        return `Метеостанция ${id}`;
      case ROAD_WORKS:
        return `Дорожные работы ${id}`;
      case RTA:
        return `ДТП ${id}`;
      default:
        return '';
    }
  };

export default getSystemTitle;
