import { SearchOutlined } from '@ant-design/icons';
import { Table as AntdTable } from 'antd';
import { TableProps, FormInstance } from 'antd/lib';
import {
  FilterDropdownProps,
  TableRowSelection,
} from 'antd/lib/table/interface';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';

import {
  ColumnTypes,
  DataType,
  IFilteredInfo,
  IIteractionButton,
  IQueryParamItem,
  ITableColumn,
  ITableConfigType,
} from '../../../ts/models/table.model';
import { convertDate } from '../../Admin/helpers/helpers';
import { IGroups } from '../../Admin/models/adminTypes.model';

import Export from './Export/Export';
import FilterInfo from './FilterInfo/FilterInfo';
import {
  addFilters,
  addSystemFiltered,
  setFilteredValue,
} from './helpers/addFilters';
import { addSortedFunc } from './helpers/addSortedFunc';
import { checkFilters } from './helpers/checkFilters';
import { getTableConfig } from './helpers/getTableConfig';
import IteractionButtons from './IteractionButtons/IteractionButtons';
import TableSearch from './TableSearch/TableSearch';
import TableServerSearch from './TableServerSearch/TableServerSearch';
import TableWrapper from './TableWrapper/TableWrapper';

import styles from './Table.module.scss';

interface ITable {
  data: DataType[];
  headerItems: ITableColumn[];
  tableConfigType: ITableConfigType;
  groups?: IGroups;
  iteractionButtons?: IIteractionButton[];
  rowSelection?: TableRowSelection<DataType>;
  queryParams?: IQueryParamItem[];
  handleSumbitServerSearch?: (form: FormInstance) => Promise<void>;
  exportBtnTitle?: string;
  limitIsExceeded?: boolean;
}

const Table: FC<ITable> = ({
  data,
  headerItems,
  tableConfigType,
  groups,
  iteractionButtons,
  rowSelection,
  queryParams,
  handleSumbitServerSearch,
  exportBtnTitle = 'Таблица',
  limitIsExceeded,
}) => {
  const [filteredInfo, setFilteredInfo] = useState<IFilteredInfo>(null);
  const [columnsData, setColumnsData] = useState<ITableColumn[]>([]);

  useEffect(() => {
    const visibleColumns = headerItems.filter(
      (item: ITableColumn) => !!item.visible
    );

    const newColumns = visibleColumns.map((item) => {
      const column = {
        ...item,
      };

      if (column.width && column.dataIndex !== 'colloboration') {
        column.width = undefined;
      }

      column.ellipsis = {
        showTitle: true,
      };

      if (!column.filterSettings) return column;

      const { searched, filters, sorted } = column.filterSettings;

      if (filters) {
        addFilters(column);
      }

      if (sorted) {
        addSortedFunc(column);
      }

      if (searched) {
        addSystemFiltered(column);

        column.filterDropdown = ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: FilterDropdownProps) => (
          <TableSearch
            column={column}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            clearFilters={clearFilters}
            confirm={confirm}
            title={column.title}
          />
        );

        column.filterIcon = (filtered: boolean) => {
          const iconStyles = classNames({
            [styles.activeSearch]: filtered,
          });

          return <SearchOutlined className={iconStyles} />;
        };
      }

      if (column.type === ColumnTypes.date) {
        column.render = (date: number) => (
          <p style={{ margin: 0 }}>{convertDate(date)}</p>
        );
      }

      return column;
    });

    setColumnsData(newColumns);
  }, [headerItems, data]);

  const handleChange: TableProps<DataType>['onChange'] = (
    pagination,
    filters
  ) => {
    if (Object.keys(filters).length) {
      checkFilters(filters) ? setFilteredInfo(null) : setFilteredInfo(filters);

      return;
    }
    setFilteredInfo(null);
  };

  useEffect(() => {
    if (columnsData.length) {
      const newColumns = columnsData.map((column) => {
        const newColumn = {
          ...column,
          filteredValue: setFilteredValue(filteredInfo, column.dataIndex),
        };

        return newColumn;
      });

      setColumnsData(newColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredInfo, headerItems]);

  return (
    <TableWrapper>
      <div className={styles.filterAndSearch}>
        {filteredInfo ? (
          <FilterInfo
            filteredInfo={filteredInfo}
            setFilteredInfo={setFilteredInfo}
            groups={groups}
            headers={headerItems}
          />
        ) : null}
        {queryParams?.length && handleSumbitServerSearch ? (
          <TableServerSearch
            queryParams={queryParams}
            handleSumbitServerSearch={handleSumbitServerSearch}
            limitIsExceeded={limitIsExceeded}
          />
        ) : null}
      </div>
      <AntdTable
        rowSelection={rowSelection}
        showSorterTooltip={false}
        columns={columnsData}
        dataSource={data}
        onChange={handleChange}
        {...getTableConfig(tableConfigType, data.length)}
        className={styles.table}
      />
      <div className={styles.btnsRow}>
        {iteractionButtons ? (
          <IteractionButtons iteractionButtons={iteractionButtons} />
        ) : null}
        {exportBtnTitle && (
          <Export data={data} headers={columnsData} title={exportBtnTitle} />
        )}
      </div>
    </TableWrapper>
  );
};

export default Table;
