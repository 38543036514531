import { Switch } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';

import ExternalResourseButtons from './ui/ExternalResourseButtons/ExternalResourseButtons';

import styles from './StatisticsPanel.module.scss';

const StatisticsPanel: FC = () => {
  const { setIsNot, isWidgets } = rootStore.uiStore;
  const handleIsWidgets = () => setIsNot('isWidgets');

  return (
    <>
      <div className={styles.widgets}>
        <span>виджеты</span>

        <Switch checked={isWidgets} onClick={handleIsWidgets} size="small" />
      </div>
      <ExternalResourseButtons />
    </>
  );
};

export default observer(StatisticsPanel);
