import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { PanelType } from '../../../ts/enums/enums';
import CollapseBtn from '../CollapseBtn/CollapseBtn';

import styles from './PanelLayout.module.scss';

interface LayoutProps {
  children: N<JSX.Element>;
}

const PanelLayout: FC<LayoutProps> = ({ children }) => {
  const { isPanel, panelType } = rootStore.uiStore;
  const { setIsNotConstructor } = rootStore.constructorStore;

  const panelStyle = classNames({
    [styles.panelDefault]: true,
    [styles.isPanel]: isPanel,
  });

  const onAction =
    panelType === PanelType.Constructor
      ? () => setIsNotConstructor('isConstructorDetailed')
      : undefined;

  return (
    <section className={panelStyle}>
      <aside className={styles.container}>
        <CollapseBtn onAction={onAction} />
        {children}
      </aside>
    </section>
  );
};

export default observer(PanelLayout);
