import { Switch } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';

import useRemoteSettings from '../../../hooks/useRemoteSettings';
import rootStore from '../../../stores/rootStore/rootStore';

import styles from './ColorTheme.module.scss';

function ColorTheme() {
  const { isDark, setTheme } = rootStore.uiStore;
  const themeTitle = isDark ? 'Ночной' : 'Дневной';
  const { saveSettingsItem } = useRemoteSettings();

  const changeThemeHandler = async () => {
    const theme = isDark ? 'light' : 'dark';

    setTheme(theme);
    saveSettingsItem('theme', theme, 'setting.theme');
  };

  return (
    <div className={styles.theme}>
      <span className={styles.themeLabel}>{themeTitle} режим</span>

      <Switch checked={isDark} onChange={changeThemeHandler} />
    </div>
  );
}

export default observer(ColorTheme);
