/* eslint-disable no-unsafe-optional-chaining */
import { SYSTEM } from '../../../../constants/constants';
import { findById } from '../../../../helpers/findBy';
import { System } from '../../../../ts/enums/enums';
import { TL } from '../../../../ts/models/tl.model';
import { getFilteredLinkedDevices } from '../../helpers/getFilteredLinkedDevices';

export const getTlObjects = (tl: TL) => {
  const filteredDevices = getFilteredLinkedDevices(tl);

  if (!filteredDevices) return null;

  const tlObjects = [
    {
      system: SYSTEM.CAMERAS,
      list: [...filteredDevices.cameras],
    },
    {
      system: SYSTEM.DETECTORS,
      list: [...filteredDevices.detectors],
    },
  ];

  return tlObjects;
};

export const getSortedTlsArray = (trafficLights: number[], tls: TL[]) => {
  return trafficLights
    .map((id) => {
      const tl = findById(tls, id);

      return tl;
    })
    .sort()
    .reverse();
};
interface ITlObjects {
  system: System;
  list: any[];
}

export const getIsEmpty = (tlObjects: ITlObjects[]) => {
  return Boolean(tlObjects[0].list.length + tlObjects[1].list.length);
};
