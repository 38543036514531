import { isEqual } from 'lodash';
import type { Map } from 'ol';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import Cluster from 'ol/source/Cluster';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { IClickedCartographyObj } from '../../../../../stores/uiStore/uiStore';

import { getClusterWithInfoDataFeature } from './getClusterWithInfoDataFeature';
import { getSelectInteraction } from './getSelectInteraction';
import { setCenteredCluster } from './setCenteredCluster';

interface setCenterObjCartographyProps {
  map: Map;
  clickedCartographyObj: IClickedCartographyObj;
  clusterSource: Cluster;
  clusterFeatures: Feature[];
  setIsCrosshairPopup: SetState<boolean>;
}

export const setCenterObjCartography = ({
  map,
  clickedCartographyObj,
  clusterSource,
  clusterFeatures,
  setIsCrosshairPopup,
}: setCenterObjCartographyProps) => {
  const { setMapData } = rootStore.mapDataStore;
  const { id, system } = clickedCartographyObj;

  const featuresCl = clusterSource.getFeatures();

  if (!featuresCl.length) return;

  const cluster = getClusterWithInfoDataFeature(featuresCl, id);

  const select = getSelectInteraction(map);

  if (!select) return;

  if (!cluster) {
    select?.clear();

    return setMapData('isClusterOpen', false);
  }

  const featuresOnCluster: Feature<Point>[] = cluster.get('features');

  const isClickedOnSameCluster = isEqual(clusterFeatures, featuresOnCluster);

  if (!isClickedOnSameCluster) {
    select?.clear();
    select.selectCluster(cluster);
    setMapData('isClusterOpen', true);
    setMapData('clusterFeatures', featuresOnCluster);
  }

  setIsCrosshairPopup(false);

  setCenteredCluster(
    map,
    id,
    system,
    featuresOnCluster,
    isClickedOnSameCluster
  );
};
