import { FC } from 'react';

import { IServerData } from '../../NJInfo';
import InfoBox from '../InfoBox/InfoBox';

interface DBInfoProps {
  data: IServerData;
}

const DBInfo: FC<DBInfoProps> = ({ data }) => {
  return (
    <InfoBox title="Базы данных">
      <ul>
        {data.databases.map((el) => (
          <li key={el}>{el}</li>
        ))}
      </ul>
    </InfoBox>
  );
};

export default DBInfo;
