import { VERSION, VERSIONS } from '../../../constants/versions';
import { JProfile } from '../../../ts/models/constructor.model';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';

const CONSTRUCTOR = (VERSIONS.CONSTRUCTOR ?? VERSION) + '/objects/tl/';
const PROFILE = CONSTRUCTOR + 'profile/';

const JVER = '4.101.3.10.1.5';

const profilesApi = {
  fetchList: (id = 1) =>
    tryCatchWrapper(async () => {
      const listData = await http.get(`${CONSTRUCTOR + id}/profile/`);

      return listData.data.profiles;
    }),

  fetchItem: (id: number) =>
    tryCatchWrapper(async () => {
      const res = await http.get(PROFILE + id);
      const profile = res.data?.profile;

      return { jprofile: profile?.jprofile, tlEputsId: profile?.tl_number };
    }),

  updateItem: (id: number, dsc: string, jprofile: JProfile) =>
    tryCatchWrapper(
      async () =>
        await http.put(
          PROFILE + id,
          {
            jver: JVER,
            setProfile: {
              id,
              dsc,
              jprofile,
            },
          },
          {
            headers: {
              'web-app-path': 'Web.constructor.tl.profiles',
            },
          }
        )
    ),

  createItem: (jprofile: JProfile) =>
    tryCatchWrapper(
      async () =>
        await http.post(
          PROFILE,
          {
            jver: JVER,
            addProfile: {
              jprofile,
            },
          },
          {
            headers: {
              'web-app-path': 'Web.constructor.tl.profiles',
            },
          }
        )
    ),

  deleteItem: (id: number) =>
    tryCatchWrapper(
      async () =>
        await http.delete(PROFILE + id, {
          headers: {
            'web-app-path': 'Web.constructor.tl.profiles',
          },
          data: {
            jver: JVER,
          },
        })
    ),
};

export default profilesApi;
