import { FC } from 'react';
import { BarChart, Bar, Cell, ResponsiveContainer } from 'recharts';

import { IDataGraph } from '../PopupDetector.model';

import styles from './ChartBar.module.scss';

interface IChartBar {
  dataGr: N<IDataGraph[]>;
  activeIndex: number;
  setActiveIndex: SetState<number>;
}

const ChartBar: FC<IChartBar> = ({ dataGr, activeIndex, setActiveIndex }) => {
  return (
    <div className={styles.container}>
      {dataGr && (
        <ResponsiveContainer className={styles.container} height={100}>
          <BarChart
            className={styles.barChart}
            data={dataGr}
            onMouseMove={(data) =>
              data?.activeLabel !== undefined &&
              setActiveIndex(data.activeLabel)
            }
          >
            <Bar
              dataKey="comparableValue"
              background={{ fill: 'var(--bg-tint)' }}
            >
              {dataGr.map((item: IDataGraph, index: number) => (
                <Cell
                  cursor="pointer"
                  stroke={index === activeIndex ? 'red' : 'none'}
                  strokeWidth={2}
                  fill={item.status}
                  key={`cell-${index}`}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default ChartBar;
