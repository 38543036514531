import axios from 'axios';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import rootStore from '../../stores/rootStore/rootStore';
import Detailed from '../ui-kit/Detailed/Detailed';

import styles from './ExternalResource.module.scss';

interface IAuth {
  username: string;
  password: string;
}

export interface IExternalResourse {
  name: string;
  url: string;
  auth: IAuth | null;
}

const ExternalResource: FC = () => {
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [isPreRequestEnd, setIsPreRequestEnd] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { setKeyValue, externalResource, panelType } = rootStore.uiStore;

  const onClose = () => {
    setKeyValue('externalResource', null);
  };

  useEffect(() => {
    setKeyValue(
      'isModalLoading',
      !(isPreRequestEnd && isLoad && externalResource)
    );
  }, [isLoad, isPreRequestEnd, setKeyValue, externalResource]);

  useEffect(() => {
    setIsPreRequestEnd(false);
    setIsLoad(false);
    authRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalResource]);

  if (!externalResource || panelType !== 'statistics') return null;

  const authRequest = async () => {
    try {
      setIsPreRequestEnd(false);

      if (!externalResource.auth) return;

      await axios.get(externalResource.url, {
        auth: {
          username: externalResource.auth?.username,
          password: externalResource.auth?.password,
        },
      });
    } catch (e: any) {
      console.error('authRequest', e.message);
    } finally {
      setIsPreRequestEnd(true);
    }
  };

  const iframeStyle = classNames({
    [styles.frame]: true,
    [styles.frameLoad]: isLoad,
  });

  const handleLoad = () => {
    setIsLoad(true);
  };

  const handleError = () => {
    setIsLoad(true);
    setError('Ошибка внешнего ресурса');
  };

  return (
    <Detailed header={externalResource.name} onClose={onClose}>
      {error ? <div className={styles.error}>{error}</div> : null}
      {isPreRequestEnd && !error ? (
        <iframe
          className={iframeStyle}
          allowFullScreen
          frameBorder="0"
          title={externalResource.name}
          src={externalResource.url}
          onLoad={handleLoad}
          onError={handleError}
        />
      ) : null}
    </Detailed>
  );
};

export default observer(ExternalResource);
