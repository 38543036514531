import { useEffect, useState } from 'react';

import mediaApi from '../../../api/media/mediaApi';
import cacheStore from '../../../stores/cacheStore/cacheStore';
import rootStore from '../../../stores/rootStore/rootStore';

import { useCrossroadEls } from '.';

interface CrossroadImg {
  id: N<number>;
  color: U<string>;
  crosswalkColor: U<string>;
  strokeWidth: U<number>;
  isCrossroad?: boolean;
}

const useCrossroadImg = ({
  id,
  color,
  crosswalkColor,
  strokeWidth,
  isCrossroad,
}: CrossroadImg) => {
  const [data, setData] = useState<N<string>>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { isConstructor, setConstructorData } = rootStore.constructorStore;
  const { setCachedImages, cachedImages } = cacheStore;

  useEffect(() => {
    (async () => {
      if (!id) return;
      const cache = cachedImages[`crossroad${id}`];

      if (cache) {
        isConstructor && setConstructorData('isCrossroadLoaded', true);

        return setData(cache);
      }
      setIsLoading(true);

      const res = await mediaApi.fetchCrossroad(id);

      setData(res);
      res && setCachedImages(`crossroad${id}`, res);
      setIsLoading(false);
      isConstructor && setConstructorData('isCrossroadLoaded', Boolean(res));
    })();
  }, [id, setConstructorData]); // eslint-disable-line

  useCrossroadEls({
    data,
    id,
    color,
    crosswalkColor,
    strokeWidth,
    isCrossroad,
  });

  return { data, isLoading };
};

export default useCrossroadImg;
