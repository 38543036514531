import { VERSION } from '../../../constants/versions';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';

import { AdminGroupsSchema, WebGroupSettingsSchema } from './groups.zod';

const ADMIN = VERSION + '/admin';
const GROUPS = ADMIN + '/groups';
const ACCESS = ADMIN + '/access';

const adminGroupsApi = {
  fetchGroups: async (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(GROUPS, {
        headers: getAppPathHeaders(appPath),
      });

      return AdminGroupsSchema.array().safeParse(res.data);
    }),
  fetchGroupSettings: async (id: number | string, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(ACCESS + `/${id}`, {
        headers: getAppPathHeaders(appPath),
      });

      const validation = WebGroupSettingsSchema.safeParse(res.data);

      if (!validation.success) console.log(validation.error);

      return res.data;
    }),
  updateGroupAccess: async (item: any, groupId: any, appPath: string) =>
    tryCatchWrapper(async () => {
      await http.post(
        ACCESS,
        {
          fieldId: item.id,
          groupId: Number(groupId),
          access: !item.access,
        },
        {
          headers: getAppPathHeaders(appPath),
        }
      );
    }),
  addNewGroup: async (body: any, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.post(GROUPS, body, {
        headers: getAppPathHeaders(appPath),
      });

      return res.status;
    }),
  deleteGroup: async (id: string | number, body: any, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.delete(GROUPS + `/${id}`, {
        headers: getAppPathHeaders(appPath),
        data: body,
      });

      return res.status;
    }),
  changeGroupItem: async (id: number | string, body: any, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.put(GROUPS + `/${id}`, body, {
        headers: getAppPathHeaders(appPath),
      });

      return res.status;
    }),
};

export default adminGroupsApi;
