import http from './api/http';
import { VERSION, VERSIONS } from './constants/versions';
import { formatProfileItem, formatProfileList } from './helpers/formatProfile';
import tryCatchWrapper from './helpers/tryCatchWrapper';
import rootStore from './stores/rootStore/rootStore';
import { JProfile } from './ts/models/constructor.model';

interface Detector {
  id: number;
  caption: string;
  longitude: number;
  latitude: number;
  directions?: [];
}

const TABLES = VERSION + '/tables';
const ROW = TABLES + '/row/';
const CONSTRUCTOR = (VERSIONS.CONSTRUCTOR ?? VERSION) + '/objects/tl/';
const PROFILE = CONSTRUCTOR + 'profile/';

const JVER = '4.101.3.10.1.5';

export const getDetectorJournal = tryCatchWrapper(
  async (id: number, period: number) => {
    const { detectors } = rootStore.mapDataStore;

    const { data } = await http.get(
      `${VERSION}detect?id=${id}&journal=${period}`
    );
    const detector: U<Detector> = detectors.find((el) => el.id === id);

    const journal =
      data?.journal &&
      data.journal.map((el: any, i: number) => {
        el.description =
          detector &&
          detector.directions &&
          detector.directions[i]['description'];

        return el;
      });

    return journal;
  }
);

export const getModulesList = tryCatchWrapper(async () => {
  const groups = await http.get(`${TABLES}/groups`);

  return groups?.data?.groups;
});

export const getModuleItem = async (key: string) => {
  const itemData = await http.get(`${TABLES}/item/${key}`);

  return itemData?.data?.data;
};

export const deleteTableRow = async (code: number, id: number) =>
  await http.delete(`${ROW + id}?code=${code}`);

export const createTableRow = async (code: number, row: any) =>
  await http.post(ROW, { code, row });

export const editTableRow = async (code: number, row: any) =>
  await http.put(ROW + row.id, { code, row });

export const getConstructorList = tryCatchWrapper(async (id = 1) => {
  const res = await http.get(`${CONSTRUCTOR + id}/profile/`);
  const profiles = res.data?.profiles;

  return profiles && formatProfileList(profiles);
});

export const getConstructorProfile = tryCatchWrapper(async (id: number) => {
  const res = await http.get(PROFILE + id);
  const profile = res.data?.profile;

  return { jprofile: profile?.jprofile, tlEputsId: profile?.tl_number };
});

export const updateConstructorProfile = tryCatchWrapper(
  async (id: number, dsc: string, jprofile: JProfile) =>
    await http.put(
      PROFILE + id,
      {
        jver: JVER,
        setProfile: {
          id,
          sname: dsc,
          jprofile,
        },
      },
      {
        headers: {
          'web-app-path': 'Web.constructor.tl.profiles',
        },
      }
    )
);

export const createConstructorProfile = tryCatchWrapper(
  async (jprofile: JProfile) => {
    const res = await http.post(
      PROFILE,
      {
        jver: JVER,
        addProfile: {
          jprofile,
        },
      },
      {
        headers: {
          'web-app-path': 'Web.constructor.tl.profiles',
        },
      }
    );

    const profile = res?.data?.profiles;
    const newSelected = profile ? formatProfileItem(profile) : null;

    return { newSelected, res };
  }
);

export const deleteConstructorProfile = tryCatchWrapper(
  async (id: number) =>
    await http.delete(PROFILE + id, {
      headers: {
        'web-app-path': 'Web.constructor.tl.profiles',
      },
      data: {
        jver: JVER,
      },
    })
);
