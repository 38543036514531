import Overlay from 'ol/Overlay';
import { useEffect, useState } from 'react';

import useRefs from '../../../hooks/useRefs';
import { createOverlays } from '../helpers';
import createOverlay from '../helpers/createOverlay';

const useMapOverlays = (arrLength: number) => {
  const [crosshairPopupRef, phaseCircleRef, tooltipRef, constructorCircleRef] =
    useRefs(4);
  const adjacentPhaseCircleRefs = useRefs(arrLength);
  const [crosshairPopup, setCrosshairPopup] = useState<Overlay>();
  const [tooltip, setTooltip] = useState<Overlay>();
  const [phaseCirclePopup, setPhaseCirclePopup] = useState<Overlay>();
  const [adjacentPhaseCirclePopups, setAdjacentPhaseCirclePopups] = useState<
    Overlay[]
  >([]);
  const [constructorCirclePopup, setConstructorCirclePopup] =
    useState<Overlay>();

  useEffect(() => {
    setCrosshairPopup(
      createOverlay({ element: crosshairPopupRef, insertFirst: true })
    );
    setPhaseCirclePopup(
      createOverlay({ element: phaseCircleRef, autoPan: true })
    );
    setAdjacentPhaseCirclePopups(
      createOverlays({ elements: adjacentPhaseCircleRefs })
    );
    setTooltip(createOverlay({ element: tooltipRef }));
    setConstructorCirclePopup(
      createOverlay({ element: constructorCircleRef, autoPan: true })
    );
  }, [
    adjacentPhaseCircleRefs,
    phaseCircleRef,
    crosshairPopupRef,
    constructorCircleRef,
    tooltipRef,
  ]);

  return {
    refs: {
      tooltipRef,
      crosshairPopupRef,
      phaseCircleRef,
      adjacentPhaseCircleRefs,
      constructorCircleRef,
    },
    popups: {
      tooltip,
      crosshairPopup,
      phaseCirclePopup,
      adjacentPhaseCirclePopups,
      constructorCirclePopup,
    },
  };
};

export default useMapOverlays;
