import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import CircleCoordinatesModal from '../CircleCenterConstructor/CircleCoordinatesModal/CircleCoordinatesModal';

import styles from './StartExplanationConstructor.module.scss';

const StartExplanationConstructor = () => {
  const { tlEputsId, circleCenter } = rootStore.constructorStore;

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!tlEputsId) {
    return (
      <p className={styles.startNotice}>
        Выберите светофорный объект из списка выше.
      </p>
    );
  }

  const circleModalProps = { isModalOpen, setIsModalOpen };

  if (!circleCenter[0]) {
    return (
      <>
        <p className={styles.startNotice}>
          Для начала работы необходимо загрузить данные или ввести центр круга.
        </p>

        <p className={styles.startNotice}>
          {`Для указания центра кликните по карте или введите координаты вручную, 
          нажав на кнопку нижу.`}
        </p>

        <Button
          type="primary"
          className={styles.putCenter}
          onClick={() => setIsModalOpen(true)}
        >
          ВВЕСТИ ЦЕНТР
        </Button>
        <CircleCoordinatesModal {...circleModalProps} />
      </>
    );
  }

  return null;
};

export default observer(StartExplanationConstructor);
