import { Button, InputNumber, notification, Switch } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { FiInfo } from 'react-icons/fi';

import { TL_CIRCLE_ZOOM } from '../../constants/constants';
import NOTICE from '../../constants/notificationConstants';
import rootStore from '../../stores/rootStore/rootStore';
import ButtonIco from '../ui-kit/ButtonIco/ButtonIco';
import Popover from '../ui-kit/Popover/Popover';

import styles from './MapInfo.module.scss';

const formatZoom = (val: number) => Math.round(val * 100) / 100;

const MapInfo = () => {
  const { isInfoPanel, isCollapseInfoPanel, interfaceTipsDelay } =
    rootStore.uiStore;
  const { currentZoom, setMapData, coordinates } = rootStore.mapDataStore;
  const {
    setConstructorData,
    setIsNotConstructor,
    isWheelZoom,
    importedZoom,
    zoomRangeEnd,
    circleCenter,
    isConstructor,
  } = rootStore.constructorStore;

  const [isInfo, setIsInfo] = useState(true);
  const [zoomStart, setZoomStart] = useState(16);

  useEffect(() => {
    importedZoom && setZoomStart(importedZoom);
  }, [importedZoom]);

  const handleIsInfo = () => setIsInfo(!isInfo);

  const handleZoomStart = (val: N<number>) =>
    val !== null && setZoomStart(formatZoom(val));

  const handleZoomEnd = (val: N<number>) =>
    val !== null && setConstructorData('zoomRangeEnd', val);

  const handleZoom = (zoom: number) => {
    if (!circleCenter[0] || !zoom) {
      return notification.error(NOTICE.PUT_CIRCLE_CENTER);
    }

    setConstructorData('isApplyCenter', true);
    setMapData('coordinates', circleCenter);
    setMapData('currentZoom', zoom);
  };

  const handleZoomFrom = () => handleZoom(zoomStart);
  const handleZoomTo = () => handleZoom(zoomRangeEnd);
  const handleWheelZoom = () => setIsNotConstructor('isWheelZoom');

  const coord = isConstructor ? circleCenter : coordinates;
  const longitude = coord[0] ? coord[0].toFixed(10) : 'не установлено';
  const latitude = coord[1] ? coord[1].toFixed(10) : 'не установлено';

  const getOffsetStyle = (mainStyle: 'mapInfoBtn' | 'mapInfo') =>
    classNames({
      [styles[mainStyle]]: true,
      [styles.offset]: !isCollapseInfoPanel && isInfoPanel,
    });

  const tooltipText = `${
    !isInfo ? 'Показать' : 'Скрыть'
  } отладочную информацию карты?`;

  const popOverProps = {
    tipsDelay: interfaceTipsDelay,
    content: tooltipText,
    placement: 'left',
  };

  return (
    <>
      <Popover {...popOverProps}>
        <div className={getOffsetStyle('mapInfoBtn')}>
          <ButtonIco isRound onClick={handleIsInfo}>
            <FiInfo />
          </ButtonIco>
        </div>
      </Popover>

      {isInfo && (
        <>
          {isConstructor && (
            <div className={styles.zoomContainer}>
              <div>Сравнение масштаба</div>

              <div className={styles.zoomWrapper}>
                <div>
                  <span className={styles.inputLabelFrom}> От:</span>
                  <InputNumber
                    min={TL_CIRCLE_ZOOM.START}
                    max={TL_CIRCLE_ZOOM.END}
                    step={TL_CIRCLE_ZOOM.STEP}
                    className={styles.zoomInput}
                    onChange={handleZoomStart}
                    onStep={handleZoomStart}
                    value={zoomStart}
                  />

                  <span className={styles.inputLabelTo}> До:</span>
                  <InputNumber
                    min={zoomStart}
                    max={TL_CIRCLE_ZOOM.END}
                    step={TL_CIRCLE_ZOOM.STEP}
                    className={styles.zoomInput}
                    onChange={handleZoomEnd}
                    onStep={handleZoomEnd}
                    value={zoomRangeEnd}
                  />
                </div>

                <div>
                  <Button
                    type="primary"
                    onClick={handleZoomFrom}
                    className={styles.compareBtn}
                  >
                    От
                  </Button>

                  <Button
                    danger
                    type="primary"
                    onClick={handleZoomTo}
                    className={styles.compareBtn}
                  >
                    До
                  </Button>
                </div>
              </div>

              <div className={styles.zoomWrapper}>
                <div className={styles.wheelZoom}>
                  Масштабирование колесом мыши:
                </div>
                <div className={styles.wheelZoomSwitch}>
                  <Switch
                    size="small"
                    checked={isWheelZoom}
                    onClick={handleWheelZoom}
                  />
                </div>
              </div>
            </div>
          )}

          <div className={getOffsetStyle('mapInfo')}>
            <div>{`Масштаб: ${currentZoom.toFixed(2)}`}</div>

            <div>{`Долгота: ${longitude}`}</div>

            <div>{`Широта: ${latitude}`}</div>
          </div>
        </>
      )}
    </>
  );
};

export default observer(MapInfo);
