import { z } from 'zod';

import {
  BasicRequestsTreeSchema,
  menuTreeSchema,
} from '../../../../api/testingPanel/testingPanelApi.zod';

export type TMethods = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH';

export enum StatusTypes {
  init = 'Ожидает отправки',
  successful = 'Успешно',
  loading = 'Отправляется',
  faliled = 'Ошибка',
}

export type IRequestItem = z.infer<typeof BasicRequestsTreeSchema> & {
  children: N<IRequestItem[]>;
  icon?: JSX.Element;
};

export type TRequestMenuTree = z.infer<typeof menuTreeSchema>;
