/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { TRAFFIC_LIGHTS_STATUS_SETS } from '../../../../../constants/constants';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { MapActions } from '../../../../../stores/uiStore/uiStore';
import { System } from '../../../../../ts/enums/enums';
import { TlMode } from '../../../../../ts/models/tl.model';
import { switchIco } from '../../../../DetailedMain/helpers/switchHeaderIco';
import Popover from '../../../../ui-kit/Popover/Popover';

import { getCurrentObject } from './getCurrentObject';
import { getDeviceTitle } from './helpers';
import PopoverContent from './PopoverContent/PopoverContent';
import StatusColor from './StatusColor/StatusColor';

import styles from './LinkedDeviceItem.module.scss';

interface LinkedDeviceItemProps {
  id: number;
  system: System;
}

const ERROR_TEXT = 'Ошибка';

const LinkedDeviceItem: FC<LinkedDeviceItemProps> = ({ id, system }) => {
  const { tls, cameras, detectors } = rootStore.mapDataStore;
  const { tipsValues, setInfoData } = rootStore.uiStore;

  const currentObject = getCurrentObject(system, id, {
    tls,
    cameras,
    detectors,
  });

  const handleOpenLinkedDevice = () => {
    currentObject &&
      setInfoData(
        {
          id: currentObject.id,
          coordinate: [currentObject.longitude, currentObject.latitude],
          system,
        },
        MapActions.Center
      );
  };

  if (!currentObject) return null;

  const mode: TlMode = currentObject.tlStatus?.mode ?? null;

  const popoverProps = {
    tipsDelay: tipsValues.interfaceTipsDelay,
    content: (
      <PopoverContent
        mode={mode}
        system={system}
        currentObject={currentObject}
        id={id}
      />
    ),
    placement: 'left',
  };

  return (
    <Popover {...popoverProps}>
      <div className={styles.device} onClick={handleOpenLinkedDevice}>
        {switchIco(system, mode)}
        <p>{getDeviceTitle(currentObject, system, id)}</p>
        {TRAFFIC_LIGHTS_STATUS_SETS.ERRORS.mode.includes(mode) ? (
          <Tag color="red" className={styles.error}>
            {ERROR_TEXT}
          </Tag>
        ) : null}
        <StatusColor mode={mode} system={system} />
      </div>
    </Popover>
  );
};

export default observer(LinkedDeviceItem);
