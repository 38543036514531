import { uniqueId } from 'lodash';

import { TL_DETAILED } from '../../../../constants/tlDetailedConstants';
import { TL_STATUS_ICO } from '../../../../constants/tlStatusConstants';
import { System } from '../../../../ts/enums/enums';
import { switchIco } from '../../../DetailedMain/helpers/switchHeaderIco';
import { COMMANDS_CONTENT } from '../../../TrafficLightDetailed/TlManagement/constants/constants';

import styles from './ContentInfo.module.scss';

const ContentInfo = () => (
  <div className={styles.container}>
    <div className={styles.typeDesignation}>
      {Object.values(System).map((item) => {
        if (item === System.Streams || item === System.Clusters) return null;

        return (
          <div key={uniqueId()} className={styles.titleIco}>
            <div className={styles.ico}>
              {switchIco(item, TL_DETAILED.MODE.Work.com)}
            </div>
            <p>- {item}</p>
          </div>
        );
      })}
    </div>
    <div className={styles.statusInfo}>
      {COMMANDS_CONTENT.map((item) => {
        return (
          <div key={uniqueId()} className={styles.titleIco}>
            <div className={styles.ico}>{TL_STATUS_ICO[item.id]}</div>
            <p>- {item.body}</p>
          </div>
        );
      })}
    </div>
  </div>
);

export default ContentInfo;
