import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties, FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { TrafficLane } from '../../../ts/models/constructor.model';
import { LANE_LENGTH_MAX } from '../constants/constants';

import LaneNumber from './LaneNumber/LaneNumber';

import styles from './TrafficLanesBlock.module.scss';

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

interface TrafficLaneProps {
  trafficLane: PartialBy<TrafficLane, 'final'>;
  rate: number;
}

const INFO_BORDER = 18.5;

const TrafficLanesBlock: FC<TrafficLaneProps> = ({ rate, trafficLane }) => {
  const { currentZoom } = rootStore.mapDataStore;

  const {
    id,
    offsetX,
    offsetY,
    isEnabled,
    width,
    mainWidth,
    length,
    angle,
    color,
    opacity,
    isGradient,
    isMain,
    mainOffsetX,
    mainOffsetY,
    laneParams,
  } = trafficLane;

  if (isEnabled === false) return null;

  const isInfo = currentZoom > INFO_BORDER;
  const calcVal = (val: number) => Math.round(val * rate);

  const laneStyle: CSSProperties = {
    height: calcVal(length),
    background: isGradient
      ? `linear-gradient(0deg, rgba(0,0,0,0) 0%, ${color} 80%)`
      : color,
    opacity,
    borderRadius: `${calcVal(1)}px`,
  };

  const mainLaneStyle: CSSProperties = {
    height: calcVal(10),
    top: calcVal(10),
  };

  const blockWrapperStyle: CSSProperties = {
    height: `${calcVal(LANE_LENGTH_MAX)}px`,
    width: `${calcVal(isMain ? mainWidth : width)}px`,
    top: calcVal(offsetY),
    left: calcVal(offsetX),
    transform: `translate(-50%, 0%) rotate(${angle}deg)`,
  };

  const generalBlockProps = { id, calcVal, angle };
  const laneNumberProps = { calcVal, angle };

  const oneLaneWrapperStyle = {
    ...blockWrapperStyle,
    top: calcVal(mainOffsetY),
    left: calcVal(mainOffsetX),
  };

  const oneLaneStyle = [
    {
      id,
      top: 0,
      left: 0,
      height: calcVal(LANE_LENGTH_MAX),
      width: calcVal(mainWidth),
    },
  ];

  const barStyles =
    laneParams?.map(({ width, offset, id }) => ({
      id: id,
      height: calcVal(LANE_LENGTH_MAX),
      top: 0,
      width: calcVal(width),
      left: calcVal(offset),
    })) ?? [];

  const wrapperStyle = isMain ? oneLaneWrapperStyle : blockWrapperStyle;
  const laneStyles = isMain ? oneLaneStyle : barStyles;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper} style={wrapperStyle}>
        {laneStyles.map((barStyle) => (
          <div className={styles.bar} style={barStyle} key={uniqueId('bar_')}>
            <div className={styles.lane} style={laneStyle} />
            {isInfo && <LaneNumber {...laneNumberProps} id={id} />}
          </div>
        ))}

        {barStyles.length > 1 && isInfo && !isMain && (
          <div className={styles.generalBlock} style={mainLaneStyle}>
            <LaneNumber {...generalBlockProps} />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(TrafficLanesBlock);
