import { CELLS_WIDTH } from '../../../../constants/tableConstants';
import { ITableColumn } from '../../../../ts/models/table.model';

export const addSortedFunc = (item: ITableColumn) => {
  const type = typeof item.dataIndex;

  item.ellipsis = {
    showTitle: false,
  };

  if (item.dataIndex === 'insertDate') {
    item.width = CELLS_WIDTH.xs;
    item.sorter = (a, b) =>
      a.insert_date && b.insert_date ? a.insert_date - b.insert_date : 0;

    return;
  }

  if (item.dataIndex === 'login') {
    item.width = CELLS_WIDTH.xs;
  }

  if (type === 'string') {
    item.sorter = (a, b) => {
      const itemA = a[item.dataIndex];
      const itemB = b[item.dataIndex];

      if (itemA < itemB) return -1;
      if (itemA > itemB) return 1;

      return 0;
    };

    return;
  }

  item.sorter = (a, b) => a[item.dataIndex] - b[item.dataIndex];
};
