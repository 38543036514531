export enum CircleElEnum {
  Gathered = 'gathered',
  Top = 'top',
  Bottom = 'bottom',
  Direct = 'direct',
  Right = 'right',
  Left = 'left',
  DirectRight = 'direct-right',
  DirectLeft = 'direct-left',
  Crosswalk = 'crosswalk',
}

export enum TLPhaseCodes {
  Undefined,
  Common,
  Phase,
  AllRed = 31,
  Edit,
  Off,
  AllYellow,
}
