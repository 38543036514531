import { Button } from 'antd';
import { FC } from 'react';

import styles from './DropDownContent.module.scss';

interface IDropDownContent {
  handleDeleteSystem: () => void;
  handleAddObjectClick: () => void;
}

const DropDownContent: FC<IDropDownContent> = ({
  handleAddObjectClick,
  handleDeleteSystem,
}) => {
  const items = [
    {
      key: '1',
      label: (
        <Button onClick={handleAddObjectClick} className={styles.btn}>
          Добавить Систему
        </Button>
      ),
    },
    // === Will need a little later ===
    // {
    //   key: '2',
    //   label: (
    //     <Button
    //       onClick={handleDeleteSystem}
    //       className={styles.btn}
    //       type="primary"
    //       danger
    //     >
    //       Удалить Систему
    //     </Button>
    //   ),
    // },
  ];

  return (
    <ul className={styles.dropdown}>
      {items.map(({ key, label }) => (
        <li key={key}>{label}</li>
      ))}
    </ul>
  );
};

export default DropDownContent;
