import { Button, Space, Tag } from 'antd';
import React, { FC, useEffect, useState } from 'react';

import { IFilteredInfo, ITableColumn } from '../../../../ts/models/table.model';
import { IGroups } from '../../../Admin/models/adminTypes.model';
import { showFilterTagText } from '../helpers/showFilterTagText';

import styles from './FilterInfo.module.scss';

interface IFilterInfo {
  filteredInfo: IFilteredInfo;
  setFilteredInfo: (param: IFilteredInfo) => void;
  groups?: IGroups;
  headers: ITableColumn[];
}

const CLEAR_BTN_TEXT = 'Сбросить Фильтры';

const FilterInfo: FC<IFilterInfo> = ({
  filteredInfo,
  setFilteredInfo,
  groups,
  headers,
}) => {
  const [filters, setFilters] = useState<string[]>();

  const handleClear = () => {
    setFilteredInfo(null);
  };

  const handleClose = (filter: string) => {
    const values = filteredInfo ? Object.values(filteredInfo) : [];
    let count = 0;

    values.forEach((item) => {
      if (item) count++;
    });

    if (count === 1) {
      setFilteredInfo(null);

      return;
    }

    setFilteredInfo({
      ...filteredInfo,
      [filter]: null,
    });
  };

  useEffect(() => {
    const keysArr = [];

    for (const filter in filteredInfo) {
      keysArr.push(filter);
    }

    setFilters(keysArr);
  }, [filteredInfo]);

  if (!filteredInfo) return null;

  return (
    <Space className={styles.btsWrapper}>
      <Button onClick={handleClear} type="primary">
        {CLEAR_BTN_TEXT}
      </Button>
      <ul className={styles.list}>
        {filters?.map((filter: string, i: number) => {
          if (filteredInfo[filter]) {
            return (
              <li key={i}>
                <Tag
                  className={styles.item}
                  closable
                  onClose={() => handleClose(filter)}
                >
                  {showFilterTagText(filter, filteredInfo, headers, groups)}
                </Tag>
              </li>
            );
          }

          return null;
        })}
      </ul>
    </Space>
  );
};

export default FilterInfo;
