import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { MdDeleteForever } from 'react-icons/md';

import rootStore from '../../../../../stores/rootStore/rootStore';
import Confirm from '../../../../ui-kit/Confirm/Confirm';

import styles from './InputDeleteBtn.module.scss';

interface IInputDeleteBtn {
  idx: number;
}

const InputDeleteBtn: FC<IInputDeleteBtn> = ({ idx }) => {
  const { deleteFromArray } = rootStore.constructorStore;

  return (
    <Confirm onConfirm={() => deleteFromArray('phases', idx)}>
      <MdDeleteForever className={styles.deleteBtn} />
    </Confirm>
  );
};

export default observer(InputDeleteBtn);
