import Spin, { SpinProps } from 'antd/lib/spin';
import { observer } from 'mobx-react-lite';
import { useState, useEffect, useCallback, lazy, Suspense } from 'react';

import { getConstructorList } from '../../../api_';
import rootStore from '../../../stores/rootStore/rootStore';
import StartExplanationConstructor from '../../Constructor/StartExplanationConstructor/StartExplanationConstructor';

import { Selected } from './ConstructorPanel.model';

import styles from './ConstructorPanel.module.scss';

const Info = lazy(() => import('./Info/Info'));
const DataSelector = lazy(() => import('./DataSelector/DataSelector'));
const Constructor = lazy(() => import('../../Constructor/Constructor'));
const ActionBtns = lazy(() => import('./ActionBtns/ActionBtns'));
const OutputConstructor = lazy(
  () => import('../../Constructor/OutputConstructor/OutputConstructor')
);

const spinProps: SpinProps = {
  size: 'large',
  className: styles.spinner,
};

const ConstructorPanel = () => {
  const {
    view,
    setConstructorData,
    isConstructorLoaded,
    circleCenter,
    tlEputsId,
    clearStore,
  } = rootStore.constructorStore;
  const { isPanel } = rootStore.uiStore;

  const { isDataSelectors, isActionBtns, isDirections } = view;

  const [isOutput, setIsOutput] = useState(false);
  const [list, setList] = useState<Selected[]>([]);
  const [selected, setSelected] = useState<N<Selected>>(null);

  const onCloseOutput = () => setIsOutput(false);
  const onOpenOutput = () => setIsOutput(true);

  const getList = useCallback(async () => {
    if (tlEputsId) {
      const data: U<Selected[]> = await getConstructorList(tlEputsId);

      data && setList(data);
    }
  }, [tlEputsId]);

  useEffect(() => {
    getList();
  }, [getList]);

  useEffect(() => {
    return () => {
      clearStore();
    };
  }, [clearStore]);

  useEffect(() => {
    setConstructorData('isCircleConstructor', isPanel);
    !isPanel && clearStore();
  }, [isPanel, clearStore, setConstructorData]);

  const dataSelectorProps = { list, selected, setSelected };

  const actionBtnsProps = {
    ...dataSelectorProps,
    onOpenOutput,
    getList,
  };

  const outputProps = { isOutput, onCloseOutput };
  const isConstructor = tlEputsId && circleCenter[0];

  if (!isConstructorLoaded) return null;

  return (
    <Suspense fallback={<Spin {...spinProps} />}>
      {isConstructor && isDirections ? <Info /> : null}
      {isDataSelectors && <DataSelector {...dataSelectorProps} />}
      <StartExplanationConstructor />
      {isConstructor ? (
        <>
          <Constructor />
          {isActionBtns && <ActionBtns {...actionBtnsProps} />}
          {isOutput && <OutputConstructor {...outputProps} />}
        </>
      ) : null}
    </Suspense>
  );
};

export default observer(ConstructorPanel);
