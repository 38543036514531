import { uniqueId } from 'lodash';
import { FC } from 'react';

import { SYSTEM } from '../../../../constants/constants';
import { LinkedDivices } from '../../../../stores/mapDataStore/mapDataStore.model';

import LinkedDeviceItem from './LinkedDeviceItem/LinkedDeviceItem';

import styles from './LinkedsInfo.module.scss';

const { LIGHTS, CAMERAS, DETECTORS } = SYSTEM;

interface LinkedsInfoProps {
  linkedDevices: LinkedDivices;
}

const LinkedsInfo: FC<LinkedsInfoProps> = ({ linkedDevices }) => {
  const { trafficLights, cameras, detectors } = linkedDevices;

  const isEmpty = !trafficLights.length && !cameras.length && !detectors.length;

  if (isEmpty)
    return <p className={styles.noLinkedText}>Нет связанных устройств</p>;

  return (
    <div className={styles.box}>
      {trafficLights.length
        ? trafficLights.map((deviceId) => (
            <LinkedDeviceItem
              key={uniqueId(`${deviceId}`)}
              id={deviceId}
              system={LIGHTS}
            />
          ))
        : null}
      {cameras.length
        ? cameras.map((deviceId) => (
            <LinkedDeviceItem
              key={uniqueId(`${deviceId}`)}
              id={deviceId}
              system={CAMERAS}
            />
          ))
        : null}
      {detectors.length
        ? detectors.map((deviceId) => (
            <LinkedDeviceItem
              key={uniqueId(`${deviceId}`)}
              id={deviceId}
              system={DETECTORS}
            />
          ))
        : null}
    </div>
  );
};

export default LinkedsInfo;
