import { DatePicker, Form, Input, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { FormInstance } from 'antd/lib';
import dayjs from 'dayjs';
import { uniqueId } from 'lodash';
import { FC, useEffect } from 'react';

import { IQueryParamItem } from '../../../../../ts/models/table.model';
import { DATE_FORMAT } from '../../../../TrafficLightDetailed/TlDetectors/constants/constants';

import BtnsBlock from './BtnsBlock/BtnsBlock';
import { getFormFieldsetValues, getInputNumberRules } from './helpers';

interface ISearchForm {
  queryParams: IQueryParamItem[];
  handleSumbitServerSearch: (form: FormInstance) => Promise<void>;
  onClose: () => void;
}

const { RangePicker } = DatePicker;

const SearchForm: FC<ISearchForm> = ({
  queryParams,
  handleSumbitServerSearch,
  onClose,
}) => {
  const [form] = Form.useForm();

  const btnsprops = {
    form,
    handleSumbitServerSearch,
    onClose,
  };

  useEffect(() => {
    form.setFieldsValue(getFormFieldsetValues(queryParams));
  }, [form, queryParams]);

  return (
    <Form form={form} layout="vertical">
      {queryParams.map((param) => {
        if (param.webElement === 'input') {
          return (
            <Form.Item
              key={uniqueId('#')}
              label={param.webElementCapture}
              name={param.jkey}
              rules={getInputNumberRules()}
            >
              <Input type={param.jvalueType} min={0} />
            </Form.Item>
          );
        }
        if (param.webElement === 'select' && param.webElementOptions) {
          return (
            <Form.Item
              key={uniqueId('#')}
              label={param.webElementCapture}
              name={param.jkey}
            >
              <Select
                options={param.webElementOptions}
                allowClear
                placeholder={param.webElementCapture}
                mode={param.jvalueType.split('.')[1] ? 'multiple' : undefined}
              />
            </Form.Item>
          );
        }
        if (param.webElement === 'datePicker') {
          return (
            <Form.Item
              key={uniqueId('#')}
              label={param.webElementCapture}
              name={param.jkey}
            >
              <RangePicker
                locale={locale}
                ranges={{
                  'Сегодняшний день': [
                    dayjs().startOf('day'),
                    dayjs().endOf('day'),
                  ],
                  'Текущая неделя': [
                    dayjs().startOf('week'),
                    dayjs().endOf('week'),
                  ],
                  'Текущий Месяц': [
                    dayjs().startOf('month'),
                    dayjs().endOf('month'),
                  ],
                  'Текущий год': [
                    dayjs().startOf('year'),
                    dayjs().endOf('year'),
                  ],
                }}
                showTime
                format={DATE_FORMAT}
                placeholder={['Начальная дата', 'Конечная дата']}
              />
            </Form.Item>
          );
        }

        return null;
      })}
      <BtnsBlock {...btnsprops} />
    </Form>
  );
};

export default SearchForm;
