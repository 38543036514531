import { observer } from 'mobx-react-lite';

import rootStore from '../../../../stores/rootStore/rootStore';

import CamerasItem from './CamerasItem/CamerasItem';

import styles from './CamerasList.module.scss';

const TEXT = 'Загрузите или создайте новый плейлист';

const CamerasList = () => {
  const { videoPanelList, videoList, isVideoListEdit } =
    rootStore.videoWallPanelStore;

  if (!videoList) return <p className={styles.text}>{TEXT}</p>;

  const filteredList = videoList.filter((item) => !!item?.caption);

  const camerasList = !isVideoListEdit ? filteredList : videoPanelList;

  return (
    <ul className={styles.wrapper}>
      {camerasList.map((item) => (
        <CamerasItem camera={item} key={item.id} />
      ))}
    </ul>
  );
};

export default observer(CamerasList);
