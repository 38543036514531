export const checkMatches = (elements: any[], value: string) => {
  return elements.filter((item: any) => {
    if (!value) return true;

    let isIncludes = false;
    const curValue = value.trim();

    if (item.id?.toString().toLowerCase().includes(curValue.toLowerCase()))
      isIncludes = true;

    if (item.highway?.toString().toLowerCase().includes(curValue.toLowerCase()))
      isIncludes = true;

    if (item.caption?.toString().toLowerCase().includes(curValue.toLowerCase()))
      isIncludes = true;

    if (item.extId?.toString().toLowerCase().includes(curValue.toLowerCase()))
      isIncludes = true;

    return isIncludes;
  });
};
