import { Feature } from 'ol';
import { Stroke } from 'ol/style';
import Style from 'ol/style/Style';
import Chart from 'ol-ext/style/Chart';
// eslint-disable-next-line no-duplicate-imports
import type { chartType } from 'ol-ext/style/Chart';

import { CIRCLE_RADIUS } from '../constants/constants';

import { listOfColors } from './colors';

interface IOptionsProps {
  styleChart?: chartType;
  scale?: number;
}

export const getClusterStyle = (
  features: Feature[],
  { styleChart = 'pie', scale = 1 }: IOptionsProps
) => {
  const { colors, data } = listOfColors(features);

  return new Style({
    image: new Chart({
      type: styleChart,
      radius: CIRCLE_RADIUS,
      data,
      scale: scale * 1.5,
      // @ts-ignore
      colors,
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
    }),
  });
};
