import { Button, Form, Table, notification } from 'antd';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import rootStore from '../../stores/rootStore/rootStore';
import Detailed from '../ui-kit/Detailed/Detailed';

import EditableCell from './EditableCell/EditableCell';
import useImport from './hooks/useImport';
import useTable from './hooks/useTable';
import ImportExportDropdown from './ImportExportDropdown/ImportExportDropdown';
import ModalImport from './ModalImport/ModalImport';

import styles from './ModuleTable.module.scss';

const components = {
  body: {
    cell: EditableCell,
  },
};

function ModuleTable() {
  const { moduleData, importedData, setKeyValue } = rootStore.uiStore,
    { modalOpen, scvDataToJson, abortImport, importRequest, importLoading } =
      useImport(),
    [form] = Form.useForm(),
    [columns, rows] = useTable({ moduleData, form }),
    refImportBtn = useRef<any>(null),
    [fileName, setFileName] = useState('');

  useEffect(() => {
    refImportBtn.current.value = '';
  }, [importedData]);

  const handleAddRow = async () => {
      if (!moduleData) return;

      const newData = [...moduleData.data];

      newData.push({ id: uniqueId('newRow_') });
      setKeyValue('moduleData', { ...moduleData, data: newData });
    },
    handleFileUpload = (e: any) => {
      const file = e.target.files[0];

      if (file.type !== 'text/csv') {
        notification.error({
          message: 'Ошибка импорта',
          description: 'Неверный формат файла',
        });

        refImportBtn.current.value = '';

        return;
      }

      const reader = new FileReader();

      reader.readAsText(file);
      reader.onload = function () {
        const data = reader.result;

        setFileName(file.name);
        scvDataToJson(data);
      };
    },
    onClose = () => {
      setKeyValue('moduleData', null);
      setKeyValue('selectedModulItems', []);
    };

  return (
    <Detailed header={moduleData?.title || ''} onClose={onClose}>
      <div className={styles.tableWrapper}>
        <Form component={false} form={form}>
          <Table
            bordered
            columns={columns}
            components={components}
            dataSource={rows}
            pagination={{
              showSizeChanger: true,
              position: ['bottomRight'],
            }}
            rowClassName={() => 'editable-row'}
            scroll={{ y: '75vh', x: 900 }}
          />
        </Form>

        <div className={styles.buttonsBox}>
          <Button
            className={styles.tableActionBtn}
            onClick={handleAddRow}
            type="primary"
          >
            Добавить строку
          </Button>

          <ImportExportDropdown
            className={styles.tableActionBtn}
            refImportBtn={refImportBtn}
          />

          <ModalImport
            abortImport={abortImport}
            fileName={fileName}
            importLoading={importLoading}
            importRequest={importRequest}
            importedData={importedData}
            modalOpen={modalOpen}
          />

          <input
            accept="text/csv"
            className={styles.uploadBtn}
            onChange={(e) => handleFileUpload(e)}
            ref={refImportBtn}
            type="file"
          />
        </div>
      </div>
    </Detailed>
  );
}

export default observer(ModuleTable);
