import { FC } from 'react';

import useMenuItems from './hooks/useMenuItems';
import MenuSelect, { IMenuProps } from './ui/MenuSelect/MenuSelect';

import styles from './TestingPanel.module.scss';

const TestPanel: FC = () => {
  const { menuItems, isError, isLoading } = useMenuItems();

  const menuProps: IMenuProps = {
    menuItems,
    isError,
    isLoading,
  };

  return (
    <div>
      <h2 className={styles.title}>Выбор запроса</h2>
      <MenuSelect {...menuProps} />
    </div>
  );
};

export default TestPanel;
