import { observer } from 'mobx-react-lite';

import rootStore from '../../../stores/rootStore/rootStore';
import ProfileSettings from '../../ProfileSettings/ProfileSettings';
import UserCard from '../../UserCard/UserCard';

import Buttons from './Buttons/Buttons';
import Note from './Note/Note';

import styles from './ProfilePanel.module.scss';

const NOTE_TEXT = '* - настройки при загрузке приложения';

function ProfilePanel() {
  const { user, role } = rootStore.userDataStore;

  if (!user || !role) return null;

  return (
    <section className={styles.wrapper}>
      <section className={styles.userBox}>
        <UserCard userId={user.id} hiddenContent />
      </section>
      <ProfileSettings />
      <Note text={NOTE_TEXT} />
      <Buttons user={user} />
    </section>
  );
}

export default observer(ProfilePanel);
