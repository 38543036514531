import { getArrSum } from './getArrSum';
import { IData } from './getAverage';
import { getDateRangeStr } from './getDateRangeStr';
import { ICalcData } from './getFormatDataDt';

export const getGeneral = (data: IData[]): ICalcData[] => {
  const calcData = data.map((item: IData) => {
    const sumDirections = getArrSum(Object.values(item));

    const { dateFrom, dateTo } = item;

    const { normalDateTime } = getDateRangeStr(dateFrom, dateTo);

    return { date: normalDateTime, comparableValue: sumDirections };
  });

  return calcData;
};
