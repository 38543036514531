import classNames from 'classnames';
import { FC } from 'react';

import { IVideoLIstItem } from '../../../../../../../../stores/videoWallPandelStore/videoWallPanelStore.model';

import styles from './CaptionList.module.scss';

interface ICaptionList {
  cameras: IVideoLIstItem[];
  isShowList: boolean;
}

const CaptionList: FC<ICaptionList> = ({ cameras, isShowList }) => {
  if (!cameras) return null;

  const listStyles = classNames({
    [styles.camerasList]: true,
    [styles.isOpen]: isShowList,
  });

  const actualLenght = cameras.filter((camera) => camera.caption).length;
  const height = isShowList ? actualLenght * 18 + 20 + 'px' : '0';

  return (
    <ul className={listStyles} style={{ height }}>
      {cameras.map((item: IVideoLIstItem) => {
        if (item.caption) {
          return (
            <li key={item.id}>
              {item.id} - {item.caption}
            </li>
          );
        }

        return null;
      })}
    </ul>
  );
};

export default CaptionList;
