import { Form, Slider } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { ISettingsProps } from '../../../ts/models/profileSettings.model';
import Popover from '../../ui-kit/Popover/Popover';

import styles from './MapIconSetting.module.scss';

const MARKS = {
  '0.65': 'S',
  '0.85': 'M',
  '1': 'L',
  '1.15': 'XL',
};
const MAX = 1.15;
const MIN = 0.65;
const STEP = 0.05;

const MapIconSetting: FC<ISettingsProps> = ({
  popOverProps,
  popoverContent,
  isReload,
  name,
  handleProfileHybrid,
  label,
}) => {
  const { mapIconsSize } = rootStore.uiStore;

  return (
    <Popover {...popOverProps} content={popoverContent}>
      <Form.Item
        className={styles.settingsItem}
        label={`${label}${isReload ? '*' : ''}`}
        name={name}
      >
        <Slider
          className={styles.slider}
          marks={MARKS}
          max={MAX}
          min={MIN}
          onAfterChange={(val) =>
            handleProfileHybrid && handleProfileHybrid(name, val)
          }
          step={STEP}
          value={mapIconsSize}
        />
      </Form.Item>
    </Popover>
  );
};

export default observer(MapIconSetting);
