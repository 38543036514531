import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { ITimePoints } from '../../../../../ts/models/signalPrograms.model';
import { TLStatus } from '../../../../../ts/models/tl.model';
import ImageGallery from '../../../../ui-kit/ImageGallery/ImageGallery';
import { getTypeTactObj } from '../helpers/getTypeTactObj';
import PhaseCard from '../PhaseCard/PhaseCard';

import styles from './PhasesCards.module.scss';

type PhasesInfo = {
  tlStatus: TLStatus;
  extId: N<number>;
  phasesSet: ITimePoints[];
  id: number;
  isError: boolean;
};

interface IPhaseCardList {
  phases: PhasesInfo;
  isTitle?: boolean;
  className?: string;
}

const PhasesCards: FC<IPhaseCardList> = ({
  phases,
  className = '',
  isTitle = true,
}) => {
  const { phasesArray } = rootStore.uiStore;

  const listContainerStyle = classNames({
    [styles.list]: true,
    [className]: className,
  });

  return (
    <ul className={listContainerStyle}>
      <ImageGallery>
        {phasesArray.map((phase) => (
          <PhaseCard
            key={uniqueId('#')}
            phase={phase}
            tactTypeObj={getTypeTactObj(phase.tactType)}
            {...phases}
          />
        ))}
      </ImageGallery>
    </ul>
  );
};

export default observer(PhasesCards);
