import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import React from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import Accordion from '../../../ui-kit/Accordion/Accordion';
import AccordionSimple from '../../../ui-kit/AccordionSimple/AccordionSimple';
import SummaryInfoItem from '../../../ui-kit/SummaryInfoItem/SummaryInfoItem';

import { PARAMS_DATA } from './constants/constants';

function ParamsTab() {
  const { setIsMarkers, infoData, handlePopupFromTab, isSummaryInfo } =
    rootStore.uiStore;
  const { streamsData } = rootStore.mapDataStore;
  const { title, data, isMarkers } = streamsData;

  return (
    <>
      <Accordion
        activeId={infoData?.id as number}
        data={data}
        isDisabled={isMarkers}
        onClick={(e: any) => handlePopupFromTab(e, title)}
        setIsMarkers={setIsMarkers}
        title={title}
      />

      <AccordionSimple isClosed={!isSummaryInfo} title="Сводная информация">
        {PARAMS_DATA.map((data) => (
          <SummaryInfoItem key={uniqueId(`${data.title}_`)} data={data} />
        ))}
      </AccordionSimple>
    </>
  );
}

export default observer(ParamsTab);
