import { observer } from 'mobx-react-lite';

import rootStore from '../../../stores/rootStore/rootStore';
import Switcher from '../../ui-kit/Switcher/Switcher';

import SearchedInput from './SearchedInput/SearchedInput';
import { TABS } from './VideoPanelTabs/constats';
import VideoWallPanelTabs from './VideoPanelTabs/VideoWallPanelTabs';

import styles from './VideoWallPanel.module.scss';

const VideoWallPanel = () => {
  const { segment } = rootStore.videoWallPanelStore;

  return (
    <div className={styles.wrapper}>
      <SearchedInput />
      <VideoWallPanelTabs />
      <Switcher obj={TABS} segment={segment} />
    </div>
  );
};

export default observer(VideoWallPanel);
