import {
  CarOutlined,
  CloudOutlined,
  InfoOutlined,
  SettingOutlined,
  TeamOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { AiFillHdd } from 'react-icons/ai';
import { GiModernCity } from 'react-icons/gi';
import { ImBooks } from 'react-icons/im';
import { IoLogoNodejs } from 'react-icons/io';
import { MdTraffic } from 'react-icons/md';
import { RiListCheck, RiTrademarkFill } from 'react-icons/ri';

export const CURRENT_ICONS = {
  accessNJSGroups: <TeamOutlined />,
  accessWebGroups: <TeamOutlined />,
  accessWebUsers: <TeamOutlined />,
  access: <SettingOutlined />,
  njs: <IoLogoNodejs />,
  info: <InfoOutlined />,
  systems: <AiFillHdd />,
  city: <GiModernCity />,
  video: <VideoCameraOutlined />,
  meteo: <CloudOutlined />,
  detector: <CarOutlined />,
  trafficLight: <MdTraffic />,
  catalog: <ImBooks />,
  equipment: <RiTrademarkFill />,
  manufacturers: <RiListCheck />,
};
