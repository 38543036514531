import { Menu } from 'antd';
import { MenuProps } from 'antd/lib';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { adminPanelSettigsMenu } from '../../../../data/adminPanelMenu';
import rootStore from '../../../../stores/rootStore/rootStore';
import { CURRENT_ICONS } from '../constants';
import { addIconInAdminPanel } from '../helpers/helpers';

import styles from './SettingMenu.module.scss';

interface ISelect {
  key: string;
  keyPath: string[];
}

const SettingMenu = () => {
  const { setAdminsData, selectedAdminItems } = rootStore.adminPanelStore;
  const [settigsMenu, setSettigsMenu] = useState<ItemType[] | undefined>();

  useEffect(() => {
    setSettigsMenu(addIconInAdminPanel(adminPanelSettigsMenu, CURRENT_ICONS));
  }, []);

  const onSelect = ({ key, keyPath }: ISelect) => {
    setAdminsData('adminTableType', key);
    setAdminsData('adminPanelKeys', keyPath);
    setAdminsData('adminSettingConfigData', null);
    setAdminsData('systemData', null);
  };

  const menuSettigsProps: MenuProps = {
    items: settigsMenu,
    mode: 'inline',
    className: styles.menu,
    onSelect,
    selectedKeys: selectedAdminItems,
  };

  return <Menu {...menuSettigsProps} />;
};

export default observer(SettingMenu);
