import { Spin } from 'antd';
import React from 'react';

import styles from './ModalLoading.module.scss';

const ModalLoading = () => (
  <div className={styles.container}>
    <div className={styles.wrapper}>
      <Spin size="large" />
    </div>
  </div>
);

export default ModalLoading;
