import { notification } from 'antd';
import { SafeParseReturnType, ZodObject } from 'zod';

import { IS_DEV } from '../../constants/constants';
import NOTICE from '../../constants/notificationConstants';
import { sendError } from '../../helpers/sendError';
import { MapObject } from '../../ts/enums/enums';
import { MapObjectType } from '../../ts/models/mapObject.model';
import { CameraSchema } from '../mapObjects/cameras/cameras.zod';
import { DetectorSchema } from '../mapObjects/detectors/detectors.zod';
import { TLSchema } from '../mapObjects/trafficLights/zod/tls.zod';

import { getValidate } from './getValidate';

export interface IData {
  id: number;
  idMgpAPI?: number;
}

export enum ObjType {
  tl = 'светофорах',
  cm = 'камерах',
  dt = 'детекторах',
}

export const validateData = (
  data: U<IData[]>,
  Schema: ZodObject<any>,
  objType = 'объектах'
) => {
  if (!Array.isArray(data)) {
    return notification.error(NOTICE.INCORRECT_DATA_TYPES);
  }

  if (!data.length) return data;

  const errorsArray: any = [];
  const warningsArray: any = [];

  const errorIds = data.reduce((errorsIds: string, el: IData) => {
    const validation: SafeParseReturnType<any, any> = getValidate(
      Schema,
      objType,
      el
    );
    const validationNewKeys = Schema.strict().safeParse(el);

    if (validation.success && validationNewKeys.success) return errorsIds;

    if (!validation.success) {
      const id = objType === ObjType.tl ? el?.idMgpAPI : el?.id;

      // eslint-disable-next-line no-param-reassign
      errorsIds += id ? `${id}, ` : `без номера, `;
      console.error(el?.id, validation.error.issues);
      errorsArray.push({ id: el?.id, errors: validation.error.issues });
    }

    if (!validationNewKeys.success) {
      console.warn(`(FRONT): ${el?.id}`, validationNewKeys.error.issues);
      warningsArray.push({
        id: el?.id,
        errors: validationNewKeys.error.issues,
      });
    }

    return errorsIds;
  }, '');

  errorIds &&
    notification.error({
      message: `Ошибка в ${objType}: ${errorIds.slice(0, -2)}.`,
      description: NOTICE.INCORRECT_TYPES_FLEX.description,
    });

  errorIds &&
    !IS_DEV &&
    sendError(NOTICE.INCORRECT_TYPES_FLEX.description, {
      dsc: `Ошибка в ${objType}: ${errorIds.slice(0, -2)}.`,
      issues: errorsArray,
    });

  warningsArray &&
    !IS_DEV &&
    sendError(NOTICE.NEW_KEY.description, {
      dsc: `Предупреждение в ${objType}: ${warningsArray.slice(0, -2)}.`,
      issues: warningsArray,
    });

  return data;
};

export const validateMapObj = (type: MapObjectType, data: U<IData[]>) => {
  switch (MapObject[type]) {
    case MapObject.cm:
      return validateData(data, CameraSchema, ObjType.cm);
    case MapObject.tl:
      return validateData(data, TLSchema, ObjType.tl);
    case MapObject.dt:
      return validateData(data, DetectorSchema, ObjType.dt);
    default:
      return console.error(`Валидация данных типа '${type}' не произведена.`);
  }
};
