import { uniqueId } from 'lodash';
import { Overlay } from 'ol';

import setDisplayStyle from '../../../helpers/setDisplayStyle';

import { IFeaturesArr } from './getFeaturesCluster';

export const activateMapOverlays = (
  featuresArray: IFeaturesArr[],
  overlays: Overlay[],
  elRefs: any[]
) => {
  let isActivate = false;

  if (!featuresArray.length || !elRefs.length) return isActivate;

  featuresArray.forEach((item, i) => {
    const { feature, coord } = item;

    const isCluster = feature.get('features');

    const id = !isCluster ? feature.get('id') : uniqueId('cluster');

    if (elRefs[i]?.current && overlays[i] && coord) {
      elRefs[i].current.id = id;
      overlays[i].set('position', coord, true);
      setDisplayStyle([elRefs[i]], 'block');
      isActivate = true;
    }
  });

  return isActivate;
};
