import { cloneDeep } from 'lodash';
import { Feature } from 'ol';
import type { Point } from 'ol/geom';

import { IFeaturesArr } from '../../Map/helpers/getFeaturesCluster';

export const deleteSelectedClusterAlarm = (errorsFeatures: IFeaturesArr[]) => {
  const errorsAlarms = cloneDeep(errorsFeatures);
  const selectedFeatures = errorsFeatures.find(
    ({ isSelectFeature }) => isSelectFeature
  );

  if (!selectedFeatures) return errorsAlarms;

  const idSelectedFeature = selectedFeatures.feature.get('id');

  const newErrorsFeatures = errorsAlarms.reduce((acc: IFeaturesArr[], item) => {
    const features: U<Feature<Point>[]> = item.feature.get('features');

    const isSelectedCluster =
      Boolean(features) &&
      features?.some((el) => el.get('id') === idSelectedFeature);

    !isSelectedCluster && acc.push(item);

    return acc;
  }, []);

  return newErrorsFeatures;
};
