import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import PhaseCircleOverlay from '../../Overlays/PhaseCircleOverlay/PhaseCircleOverlay';
import { PhaseCircleOverlaysProps } from '../Mapper.model';

import AdjacentPhaseCircleOverlays from './AdjacentPhaseCircleOverlays';

import styles from './PhaseCircleOverlays.module.scss';

const PhaseCircleOverlays: FC<PhaseCircleOverlaysProps> = ({
  phaseCircleRef,
  selectedTL,
  adjacentPhaseCircleRefs,
  adjacentTLs,
}) => {
  const { isPhaseCircle } = rootStore.uiStore;

  const adjacentTlsProps = {
    adjacentPhaseCircleRefs,
    adjacentTLs,
  };

  return (
    <>
      <div className={styles.popupContainer} ref={phaseCircleRef}>
        {isPhaseCircle && selectedTL && (
          <PhaseCircleOverlay selectedTL={selectedTL} />
        )}
      </div>

      <AdjacentPhaseCircleOverlays {...adjacentTlsProps} />
    </>
  );
};

export default observer(PhaseCircleOverlays);
