import { toJS } from 'mobx';
import { Overlay } from 'ol';
import { RefObject } from 'react';

import { findById } from '../../../helpers/findBy';
import setDisplayStyle from '../../../helpers/setDisplayStyle';
import { TL } from '../../../ts/models/tl.model';

import activateAdjacentTLs from './activateAdjacentTrafficLights';
import getAdjacentTLs from './getAdjacentLights';

interface IHandleActiveLights {
  tls: TL[];
  id: number;
  adjacentPhaseCircleRefs: RefObject<HTMLDivElement>[];
  phaseCircleRef: RefObject<HTMLDivElement>;
  setAdjacentTLs: SetState<N<TL[]>>;
  setSelectedTL: SetState<N<TL>>;
  isAdjacentPhaseCircles: boolean;
  adjacentPhaseCirclePopups: Overlay[];
}

const handleActiveLights = ({
  tls,
  id,
  adjacentPhaseCircleRefs,
  phaseCircleRef,
  setAdjacentTLs,
  setSelectedTL,
  isAdjacentPhaseCircles,
  adjacentPhaseCirclePopups,
}: IHandleActiveLights) => {
  const activeTL: U<TL> = toJS(findById(tls, id));

  if (activeTL) {
    setSelectedTL(activeTL);
    setDisplayStyle([phaseCircleRef], 'block');

    if (isAdjacentPhaseCircles) {
      const adjacent = getAdjacentTLs(activeTL, tls);

      setAdjacentTLs(adjacent);

      activateAdjacentTLs(
        activeTL.linkedDevices?.trafficLights,
        adjacentPhaseCirclePopups,
        adjacentPhaseCircleRefs
      );
    }
  }
};

export default handleActiveLights;
