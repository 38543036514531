import { ImagePostfix } from '../ts/enums/enums';
import { TlMode } from '../ts/models/tl.model';

const { Off, AllRed, AllYellow } = ImagePostfix;

const getImagePostfix = (mode: TlMode) => {
  switch (mode) {
    case undefined:
      return Off;
    case 0:
      return Off;
    case 2:
      return Off;
    case 5:
      return AllYellow;
    case 6:
      return AllRed;
    default:
      return null;
  }
};

export default getImagePostfix;
