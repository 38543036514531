import { useContext } from 'react';

import { InfoDataContext } from '../InfoPanelWithContext';

const useInfoData = () => {
  const { infoDataPanel, setInfoDataPanel } = useContext(InfoDataContext);

  return { infoData: infoDataPanel, setInfoData: setInfoDataPanel };
};

export default useInfoData;
