import { isEqual, uniqueId } from 'lodash';
import { Map, MapBrowserEvent, Overlay } from 'ol';
import type { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
// eslint-disable-next-line no-duplicate-imports
import type { FeatureLike } from 'ol/Feature';
import { Point } from 'ol/geom';
import RenderFeature from 'ol/render/Feature';

import { CLUSTERS } from '../../../constants/mapClusterConstants';
import { CENTERING, CURSOR_MAP, ZOOM } from '../../../constants/mapConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { IInfoPanelData } from '../../../stores/uiStore/uiStore';
import { System } from '../../../ts/enums/enums';
import { IPopups } from '../../Mapper/Mapper.model';

import { getFeatureOnClusterCoord } from './getFeatureOnClusterCoord';
import { setClustersZoomChange } from './setClustersZoomChange';
import { setTooltipOnCluster } from './setTooltipOnCluster';

const { GRAB, POINTER } = CURSOR_MAP;

export type TGetTooltip = (
  id: number,
  system: System,
  coordinates: U<Coordinate>,
  clustersFeatures?: Feature[]
) => void;
export type TCloseTooltip = () => void;
export type THandleInfoPanel = (
  id: number | string,
  coordinates: Coordinate,
  isFromMap: boolean,
  system: System,
  coordFeatureOnCluster?: U<Coordinate>
) => U<void>;

export const getHit = (e: MapBrowserEvent<PointerEvent>, map: Map) => {
  const pixel = map.getEventPixel(e.originalEvent);

  return map.hasFeatureAtPixel(pixel);
};

interface IHandlePointerProps {
  event?: MapBrowserEvent<PointerEvent>;
  isPoint?: boolean;
}

export const handlePointer = (
  map: Map,
  { event, isPoint = true }: IHandlePointerProps
) => {
  const isPointer = isPoint && event && getHit(event, map);

  map.getTargetElement().style.cursor = isPointer ? POINTER : GRAB;
};

export const handleTooltip = (
  closeTooltip: TCloseTooltip,
  getTooltip: TGetTooltip
) => {
  let isStateChanged = true;
  let featurePrev: FeatureLike[] = [];

  return (
    event: MapBrowserEvent<PointerEvent>,
    map: Map,
    clusterFeatures: Feature<Point>[],
    setIsTooltip: SetState<boolean>,
    infoData: N<IInfoPanelData>
  ) => {
    const hit = getHit(event, map);

    map.forEachFeatureAtPixel(event.pixel, (feature: FeatureLike) => {
      isStateChanged = !(
        hit &&
        feature.get('features') &&
        isEqual(featurePrev, feature.get('features'))
      );

      if (hit) {
        const features = feature.get('features') ?? [feature];

        featurePrev = [...features];
        const isCluster = features.length > 1;

        if (!features[0].get('id')) return setIsTooltip(false);

        const isInfoDataFeature = infoData?.id === features[0].get('id');

        !isCluster && setIsTooltip(!isInfoDataFeature);

        if (!isStateChanged) return;

        handlePointer(map, { event });

        if (isCluster) {
          const isSameCluster = isEqual(
            clusterFeatures,
            feature.get('features')
          );

          isStateChanged = setTooltipOnCluster({
            map,
            feature,
            isSameCluster,
            getTooltip,
            setIsTooltip,
          });

          return;
        }

        const system = features[0].get('system');

        const featureInCluster = clusterFeatures.find(
          (el) => el && features[0].get('id') === el.get('id')
        );

        let coord: U<Coordinate>;

        if (featureInCluster) {
          coord = getFeatureOnClusterCoord(map, featureInCluster);

          if (!coord) return;
        }

        const coordinates =
          system === System.Streams ? event.coordinate : undefined;

        getTooltip(
          features[0].get('id'),
          features[0].get('system'),
          coord ?? coordinates
        );

        isStateChanged = false;
      }
    });

    if (!hit && !isStateChanged) {
      closeTooltip();
      handlePointer(map, { isPoint: false });
      isStateChanged = true;
    }
  };
};

export const handleInfoDataByClick = (
  e: MapBrowserEvent<any>,
  map: Map,
  handleInfoPanel: THandleInfoPanel
) => {
  const { clusterFeatures, setMapData } = rootStore.mapDataStore;
  const { setKeyValue } = rootStore.uiStore;
  let newIsInfoPanel = false;

  setKeyValue('clickedCartographyObj', null);

  const features: FeatureLike[] = [];

  const handleFeature = (feature: FeatureLike) => {
    const features = feature.get('features') ?? [feature];

    setMapData('selectedFeature', feature as Feature<Point>);

    if (features.length > 1) {
      newIsInfoPanel = true;
      const geom = feature.getGeometry() as RenderFeature;
      const coord = geom?.getFlatCoordinates();

      const isSameCluster = isEqual(clusterFeatures, features);

      !isSameCluster &&
        handleInfoPanel(uniqueId('cluster_'), coord, true, System.Clusters);

      return newIsInfoPanel;
    }

    newIsInfoPanel = true;
    const feat = features[0];
    const system: System = feat.get('system');

    const coordFeature = feat.getGeometry()?.getFlatCoordinates();
    const coordinates = system === System.Streams ? e.coordinate : coordFeature;
    const coordFeatureOnCluster = getFeatureOnClusterCoord(map, feat);

    if (feat.get('selectclusterlink')) return (newIsInfoPanel = true);

    if (!feat.get('id')) {
      return (newIsInfoPanel = false);
    }

    handleInfoPanel(
      feat.get('id'),
      coordinates,
      true,
      system,
      coordFeatureOnCluster
    );
  };

  map.forEachFeatureAtPixel(e.pixel, (feature: FeatureLike) =>
    features.push(feature)
  );

  if (features.length === 1) {
    handleFeature(features[0]);
  }

  return newIsInfoPanel;
};

export const handleCenterZoom = (map: U<Map>, center: U<Coordinate>) => {
  if (!map) return;

  const { isConstructor } = rootStore.constructorStore;
  const view = map.getView();
  const zoomLevel = view.getZoom();

  if (
    (zoomLevel !== undefined && zoomLevel > ZOOM.CLUSTERS_BORDER) ||
    isConstructor
  ) {
    return handleCenter(map, center);
  }

  view.animate({
    center,
    zoom: ZOOM.CLUSTERS_BORDER,
    duration: CLUSTERS.ANIMATION_DURATION,
  });

  setClustersZoomChange(map, CLUSTERS.ANIMATION_DURATION);
};

export const handleCenter = (
  map: U<Map>,
  center: U<Coordinate>,
  duration = CENTERING.ANIMATION_DURATION
) => {
  if (!map) return;

  const view = map.getView();

  view.animate({ center, duration });

  setClustersZoomChange(map, duration);
};

export const handleZoom = (map: U<Map>, zoom: number) => {
  if (!map) return;

  map.getView().animate({
    zoom,
    duration: ZOOM.DURATION,
  });

  setClustersZoomChange(map, ZOOM.DURATION);
};

export const addOverlays = (map: Map, popups: IPopups) => {
  Object.values(popups)
    .flat()
    .forEach((el: U<Overlay>) => el?.getElement() && map.addOverlay(el));
};
