import { notification } from 'antd';

export interface Err {
  name: string;
  response: string;
  message: MessageChannel;
}
interface IParams {
  ignoreCodes?: number[];
}

const tryCatchWrapper = function (f: any, codes?: IParams) {
  return async function (...args: any[]) {
    try {
      return await f.apply(f, args);
    } catch (e: any) {
      if (e?.name === 'AxiosError') {
        const res = e?.response;
        const message = res?.data?.message || e.message;

        if (res.data?.constructor?.name === 'Blob') {
          const data = await res.data.text();

          console.error(`Data: ${data.split('/H1>')[1].split('<P>')[0]}`);
        }

        if (codes?.ignoreCodes?.includes(res.status)) {
          return;
        }

        notification.error({ message: 'Ошибка сервера', description: message });

        return;
      }

      console.error(e);
    }
  };
};

export default tryCatchWrapper;
