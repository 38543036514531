import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import parseTLMode from '../../../helpers/parseTLMode';
import PhaseCircleNoData from '../PhaseCircleNoData/PhaseCircleNoData';

import PhaseCircleEmpty from './PhaseCircleEmpty/PhaseCircleEmpty';
import PhaseCircleMain from './PhaseCircleMain/PhaseCircleMain';
import { PhaseCircleOverlayProps } from './PhaseCircleOverlay.model';

const PhaseCircleOverlay: FC<PhaseCircleOverlayProps> = ({ selectedTL }) => {
  if (!selectedTL?.idMgpAPI) {
    return <PhaseCircleNoData />;
  }

  const { color, isEmpty } = parseTLMode(selectedTL?.mode);
  const cmd = selectedTL.tlStatus?.command?.full;

  if (isEmpty) {
    return (
      <PhaseCircleEmpty id={selectedTL.idMgpAPI} color={color} cmd={cmd} />
    );
  }

  return (
    <ErrorBoundary fallback={<PhaseCircleNoData />} resetKeys={[selectedTL]}>
      <PhaseCircleMain selectedTL={selectedTL} color={color} />
    </ErrorBoundary>
  );
};

export default PhaseCircleOverlay;
