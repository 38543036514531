import { FC } from 'react';

import styles from './LaneNumber.module.scss';

interface LaneNumberProps {
  id: number;
  angle: number;
  calcVal: (val: number) => number;
}

const LaneNumber: FC<LaneNumberProps> = ({ id, calcVal, angle }) => {
  const laneNumberStyle = {
    fontSize: `${calcVal(2)}px`,
    top: calcVal(3),
    transform: `translate(-50%, -50%) rotate(${-angle}deg)`,
  };

  return (
    <div className={styles.laneNumber} style={laneNumberStyle}>
      {id}
    </div>
  );
};

export default LaneNumber;
