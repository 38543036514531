import { isEqual } from 'lodash';
import Feature from 'ol/Feature';
// eslint-disable-next-line no-duplicate-imports
import type { FeatureLike } from 'ol/Feature';
import { Point } from 'ol/geom';
import { toLonLat } from 'ol/proj';
import { Style, Stroke } from 'ol/style';

import { SYSTEM } from '../../../../../constants/constants';
import { CLUSTER_SCALE_ZOOM_RATE } from '../../../../../constants/mapClusterConstants';
import { TL_STATUS } from '../../../../../constants/tlStatusConstants';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { IInfoPanelData } from '../../../../../stores/uiStore/uiStore';
import { System } from '../../../../../ts/enums/enums';
import { TlMode } from '../../../../../ts/models/tl.model';
import { getStyle } from '../../../../Mapper/helpers';

import { getClusterCacheStyle } from './getClusterCacheStyle';
import { getClusterStyle } from './getClusterStyle';
import { getClusterSystems } from './getClusterSystems';
import { getEmptyCluster } from './getEmptyCluster';
import { getEmptyFeature } from './getEmptyFeature';
import { getFeatureCacheStyle } from './getFeatureCacheStyle';

export interface IClusterCache {
  style: Style;
  systems: System[];
}

export interface IFeatureCache {
  style: Style;
  mode: TlMode;
  system: System;
}

export interface IStyleCache {
  cluster: IClusterCache[];
  feature: IFeatureCache[];
}

export function getClusterIconZoomRatio(currentZoom: number) {
  const ratio = 1 + currentZoom / CLUSTER_SCALE_ZOOM_RATE;

  return ratio;
}

export function getFeatureStyle(
  features: FeatureLike,
  infoData: IInfoPanelData | null = null
) {
  const sel = features.get('features');
  const { mapIconsSize } = rootStore.uiStore;
  const { currentZoom } = rootStore.mapDataStore;

  if (sel) {
    const feature = sel[0];
    const system = feature.get('system');
    const isSelectFeature = infoData?.id === feature.get('id');

    if (isSelectFeature) return [getEmptyCluster()];

    const mode: IntRange<0, 8> | IntRange<9, 12> = sel[0].get('mode');
    const systemWithMode =
      system === SYSTEM.LIGHTS ? `${system}_${TL_STATUS[mode]}` : system;

    const iconSize = getClusterIconZoomRatio(currentZoom);

    const style = getStyle(
      systemWithMode,
      mapIconsSize > 1 ? mapIconsSize * iconSize * 0.8 : mapIconsSize * iconSize
    );

    return [style];
  }

  return [
    new Style({
      stroke: new Stroke({
        color: '#ffffff',
        width: 1,
      }),
    }),
  ];
}

export const getStyleForCluster = (
  feature: FeatureLike,
  infoData: IInfoPanelData | null,
  mapIconsSize: number,
  isBigZoom: boolean,
  isLargeZoom: boolean,
  styleCache: IStyleCache
) => {
  const { isNeedOpenedCluster } = rootStore.uiStore;
  const { isClusterOpen } = rootStore.mapDataStore;

  const features: Feature<Point>[] = feature.get('features');

  const systemFeature = features[0].get('system');
  const modeFeature: TlMode = features[0].get('mode');
  const isCluster = features.length > 1;
  const isStyleCache = Boolean(
    styleCache.cluster.length || styleCache.feature.length
  );

  let scale = 0.8 * mapIconsSize;

  if (infoData && isCluster && isClusterOpen) {
    const geomFeature = feature.getGeometry();

    if (!geomFeature) return getEmptyFeature();

    //@ts-ignore
    const coord = toLonLat(geomFeature.getCoordinates());

    const isSelectCluster = isEqual(coord, infoData?.coordinate);
    const isFeatureOnCluster = features.some(
      (el) => infoData.id === el.get('id')
    );

    if (!isNeedOpenedCluster && (isSelectCluster || isFeatureOnCluster))
      return getClusterStyle(features, { scale, styleChart: 'donut' });

    if (isSelectCluster || isFeatureOnCluster) {
      return getEmptyCluster();
    }
  }

  if (isCluster && isStyleCache) {
    const styleCluster = getClusterCacheStyle(features, styleCache.cluster);

    if (styleCluster) return styleCluster;
  }

  if (!isCluster && isStyleCache) {
    const isChosenFeature = features.some(
      (item) => item.get('id') === infoData?.id
    );

    if (isChosenFeature) return getEmptyFeature();

    const styleFeature = getFeatureCacheStyle(
      styleCache.feature,
      systemFeature,
      modeFeature
    );

    if (styleFeature) return styleFeature;
  }

  if (isBigZoom && !isCluster) {
    scale = isLargeZoom ? mapIconsSize * 2 : mapIconsSize;
  }

  if (!isCluster) {
    const systemWithMode =
      systemFeature === SYSTEM.LIGHTS
        ? `${systemFeature}_${TL_STATUS[modeFeature]}`
        : systemFeature;
    const styleFeature = getStyle(systemWithMode, scale);

    styleCache.feature.push({
      mode: modeFeature,
      system: systemFeature,
      style: styleFeature,
    });

    return styleFeature;
  }

  const systems = getClusterSystems(features);
  const styleCluster = getClusterStyle(features, { scale });

  styleCache.cluster.push({ systems, style: styleCluster });

  return styleCluster;
};
