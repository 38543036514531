/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Card } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useRef } from 'react';

import rootStore from '../../stores/rootStore/rootStore';

import ClusterInfo from './ClusterInfo/ClusterInfo';
import useInfoDataPanelLogic from './hooks/useInfoDataPanelLogic';
import SystemsInfo from './SystemsInfo/SystemsInfo';

import styles from './InfoPanel.module.scss';

const RIGHT_PANEL: any = {
  systemsContent: <SystemsInfo />,
  clustersContent: <ClusterInfo />,
};

const InfoPanel: FC = () => {
  const {
    infoData,
    isInfoPanel,
    infoPanelType,
    isCollapseInfoPanel,
    setKeyValue,
    isDetailedForm,
    setIsNot,
  } = rootStore.uiStore;

  const containerRef = useRef<HTMLDivElement>(null);

  useInfoDataPanelLogic(infoData);

  useEffect(() => {
    containerRef.current?.scrollTo(0, 0);
  }, [infoData]);

  useEffect(() => {
    !isInfoPanel && !isDetailedForm && setKeyValue('infoData', null);
  }, [isInfoPanel, setKeyValue, isDetailedForm]);

  const handleCollapseButton = () => {
    isInfoPanel && setIsNot('isCollapseInfoPanel');
  };

  const panelStyle = classNames({
    [styles.panelDefault]: true,
    [styles.isRightPanel]: isInfoPanel && !isCollapseInfoPanel,
  });

  const collapseButtonStyle = classNames({
    [styles.collapseButton]: true,
    [styles.collapseButtonHidden]: !isInfoPanel,
  });

  return (
    <section className={panelStyle}>
      <div ref={containerRef} className={styles.container}>
        {infoPanelType ? RIGHT_PANEL[infoPanelType] : null}
      </div>
      <Card className={collapseButtonStyle} onClick={handleCollapseButton}>
        {isCollapseInfoPanel ? 'Развернуть панель' : 'Свернуть панель'}
      </Card>
    </section>
  );
};

export default observer(InfoPanel);
