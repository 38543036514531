import { Popconfirm } from 'antd';
import { FC } from 'react';

interface ConfirmProps {
  onConfirm?: (e?: React.MouseEvent<HTMLElement>) => unknown;
  children: JSX.Element;
  disabled?: boolean;
}

const Confirm: FC<ConfirmProps> = ({
  onConfirm,
  children,
  disabled = false,
}) => (
  <Popconfirm
    title="Вы уверены?"
    cancelText="Нет"
    okText="Да"
    onConfirm={onConfirm}
    disabled={disabled}
  >
    {children}
  </Popconfirm>
);

export default Confirm;
