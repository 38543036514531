import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import LoginChoice from './LoginChoice/LoginChoice';
import LoginForm from './LoginForm/LoginForm';
import LoginLogo from './LoginLogo/LoginLogo';

import styles from './Login.module.scss';

const Login = () => {
  const [authMethod, setAuthMethod] = useState({
    isFace: false,
    isFingerprint: false,
    isKey: false,
    isPassword: false,
  });

  return (
    <main className={styles.container}>
      <LoginLogo />
      {authMethod.isPassword && <LoginForm />}
      {!authMethod.isPassword && (
        <LoginChoice setAuthMethod={setAuthMethod} authMethod={authMethod} />
      )}
    </main>
  );
};

export default observer(Login);
