import { makeAutoObservable } from 'mobx';

import RootStore from '../rootStore/rootStore';

class DetailedStore {
  rootStore;
  selectedPhase: N<number> = null;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }

  setSelectedPhase = (phase: N<number>) => {
    const isSelectedPhase = this.selectedPhase === phase;

    if (isSelectedPhase || phase === null) {
      this.selectedPhase = null;

      return;
    }
    this.selectedPhase = phase;
  };
}

export default DetailedStore;
