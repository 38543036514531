import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { ReactComponent as ProfileIco } from '../../../assets/icons/ic_profile_sidebar.svg';
import rootStore from '../../../stores/rootStore/rootStore';
import Popover from '../../ui-kit/Popover/Popover';

import styles from './ProfileBtn.module.scss';

function ProfileBtn() {
  const [isProfile, setIsProfile] = useState(false),
    { isPanel, panelType, switchPanel, interfaceTipsDelay } = rootStore.uiStore,
    hanldeProfile = (e: any) => {
      switchPanel(e);
      setIsProfile(!isProfile);
    },
    profileIcoStyle = classNames({
      [styles.profileIco]: true,
      [styles.active]: isPanel && panelType === 'profile',
    });

  return (
    <Popover
      content="Профиль пользователя"
      placement="left"
      tipsDelay={interfaceTipsDelay}
    >
      <div
        className={styles.profileWrapper}
        id="profile"
        onClick={hanldeProfile}
        role="presentation"
      >
        <ProfileIco className={profileIcoStyle} />
      </div>
    </Popover>
  );
}

export default observer(ProfileBtn);
