import { notification } from 'antd';

const onRejectedError = (error: any) => {
  switch (error.response?.status) {
    case 0:
    case 502:
      notification.error({
        message: 'Ошибка сервера: 502',
        description: 'Сервер временно недоступен',
        duration: 0,
        key: 'error-notification',
      });
      break;
  }

  return Promise.reject(error);
};

export default onRejectedError;
