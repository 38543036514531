import { Dropdown, Menu, Spin } from 'antd';
import { MenuProps } from 'antd/lib';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import adminApi from '../../../../api/adminObjects/admin';
import rootStore from '../../../../stores/rootStore/rootStore';
import { CURRENT_ICONS } from '../constants';
import { getMenuLabelTooltip } from '../helpers/getMenuLabelTooltips';
import {
  addIconInAdminPanel,
  createSecondRootKeys,
  IAdminsPanelTree,
} from '../helpers/helpers';
import { onOpenMenuHandler } from '../helpers/onOpenMenuHandler';
import MenuEditForm from '../MenuEditForm/MenuEditForm';

import DropDownContent from './DropDownContent/DropDownContent';

import styles from './SystemMenu.module.scss';

const FORM_TITLES: Record<string, string> = {
  add: 'добавления',
  delete: 'удаления',
};

interface ISelect {
  key: string;
  keyPath: string[];
}

const appPath = '/Panel/AdminPanel/SystemMenu/SystemMenu';

const SystemMenu = () => {
  const {
    setAdminsData,
    selectedAdminItems,
    adminPanelKeys,
    isAddSystem,
    isDeleteSystem,
  } = rootStore.adminPanelStore;
  const [systemsMenu, setSystemsMenu] = useState<IAdminsPanelTree[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [rootKeys, setRootKeys] = useState<string[]>([]);
  const [secondRootKeys, setSecondRootKeys] = useState<string[]>([]);

  useEffect(() => {
    if (adminPanelKeys) {
      setOpenKeys(adminPanelKeys);
    }
  }, [adminPanelKeys]);

  const getSystemsTree = useCallback(async () => {
    const res = await adminApi.systems.systemsCrud.getSystemsTree(appPath);

    if (!res) {
      setIsError(true);
      setIsLoading(false);
      setAdminsData('isAddSystem', false);
      setAdminsData('isDeleteSystem', false);
    }

    const newTree = res.data;

    setSystemsMenu(
      getMenuLabelTooltip(addIconInAdminPanel(newTree.menu, CURRENT_ICONS))
    );
    setAdminsData('menu', newTree.menu);

    const newRootKeys: string[] = newTree.menu[0].children.map(
      (item: IAdminsPanelTree) => {
        return item.key;
      }
    );
    const newSecondRootKeys = createSecondRootKeys(newTree.menu[0].children);

    setSecondRootKeys(newSecondRootKeys);
    setRootKeys(newRootKeys);
    setIsError(false);

    setIsLoading(false);
    setAdminsData('isAddSystem', false);
    setAdminsData('isDeleteSystem', false);
  }, [setAdminsData]);

  useEffect(() => {
    getSystemsTree();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAddSystem || isDeleteSystem) getSystemsTree();
  }, [getSystemsTree, isAddSystem, isDeleteSystem]);

  const openForm = (type: string) => {
    setAdminsData('isModalOpen', true);
    setAdminsData('modalContent', {
      title: `Форма ${FORM_TITLES[type]} элементов меню`,
      content: <MenuEditForm type={type} />,
    });
    setAdminsData('adminModalType', 'menuEdit');
  };

  const handleAddObjectClick = () => {
    openForm('add');
  };

  const handleDeleteSystem = () => {
    openForm('delete');
  };

  const onOpenChange = (keys: string[]) => {
    onOpenMenuHandler(
      keys,
      openKeys,
      rootKeys,
      secondRootKeys,
      setAdminsData,
      setOpenKeys
    );
  };

  const onSelect = ({ key, keyPath }: ISelect) => {
    setAdminsData('adminTableType', key);
    setAdminsData('adminPanelKeys', keyPath);
    setAdminsData('adminSettingConfigData', null);
    setAdminsData('systemData', null);
  };

  const menuSystemsProps: MenuProps = {
    items: systemsMenu,
    mode: 'inline',
    className: styles.systemsMenu,
    onSelect,
    onOpenChange,
    openKeys: openKeys,
    selectedKeys: selectedAdminItems,
  };

  return (
    <div className={styles.systemsWrapper}>
      <Spin spinning={isLoading} />
      {isError && <p>Ошибка загрузки меню</p>}
      <Dropdown
        placement="bottomLeft"
        trigger={['contextMenu']}
        dropdownRender={() => {
          return (
            <DropDownContent
              handleAddObjectClick={handleAddObjectClick}
              handleDeleteSystem={handleDeleteSystem}
            />
          );
        }}
      >
        <Menu {...menuSystemsProps} />
      </Dropdown>
    </div>
  );
};

export default observer(SystemMenu);
