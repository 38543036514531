import { VERSION } from '../../../constants/versions';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';

const SYSTEMS = VERSION + '/admin/systems';

interface IAddSystemBody {
  regionId: number;
  subststemId: number;
  systemTypeId: number;
  name: string;
  dsc: string;
}

const systemsCrud = {
  add: (body: IAddSystemBody, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.post(SYSTEMS, {
        ...body,
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
  getSystemsTree: (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(SYSTEMS, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
  deleteSystem: (id: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.delete(SYSTEMS + `/${id}`, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
};

export default systemsCrud;
