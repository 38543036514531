import { useEffect, useState } from 'react';

import { SECOND, UPDATE_WEATHER_TIME } from '../constants/constants';
import { getDayInfo } from '../helpers/formatDate';
import { getWeather } from '../helpers/getWeather';

const useDayInfo = (mapCenter: any) => {
  const [{ time, timezone, day, dayName }, setDayInfo] = useState(getDayInfo()),
    [{ temp, weather }, setWeather] = useState({ temp: '', weather: '' });

  useEffect(() => {
    const fetchWeather = async () => setWeather(await getWeather(mapCenter));

    fetchWeather();
    const timerIdDayInfo = setInterval(() => setDayInfo(getDayInfo()), SECOND);
    const timerIdWeather = setInterval(
      () => fetchWeather(),
      UPDATE_WEATHER_TIME
    );

    return () => {
      clearInterval(timerIdDayInfo);
      clearInterval(timerIdWeather);
    };
  }, []); // eslint-disable-line

  return [
    { firstRaw: time, secondRaw: `GMT ${timezone}`, style: 'infoTop' },
    { firstRaw: day, secondRaw: dayName, style: 'infoCenter' },
    { firstRaw: temp, secondRaw: weather, style: 'infoBottom' },
  ];
};

export default useDayInfo;
