import type { Map } from 'ol';
import Feature from 'ol/Feature';
import type { Point } from 'ol/geom';
import type { Interaction } from 'ol/interaction';
import SelectCluster from 'ol-ext/interaction/SelectCluster';

export const getFeatureOnClusterCoord = (
  map: Map,
  featureInfoData: Feature<Point>
) => {
  const interactions = map.getInteractions().getArray();
  const select = interactions.find((interaction: Interaction) =>
    interaction instanceof SelectCluster ? interaction : null
  ) as N<SelectCluster>;

  if (!select) return;

  const featuresSelect = select.getLayer().getSource()?.getFeatures() as U<
    Feature<Point>[]
  >;

  if (!featuresSelect?.length) return;

  const featureSelect = featuresSelect.find((feature) => {
    const feat = feature.get('features');

    if (!feat) return false;

    return feat[0].get('id') === featureInfoData.get('id');
  });

  const coord = featureSelect?.getGeometry()?.getFlatCoordinates();

  if (!coord) return;

  return coord;
};
