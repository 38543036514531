import { Spin, Tabs } from 'antd';
import { SpinProps } from 'antd/lib';
import { FC } from 'react';

import parseTLMode from '../../../../helpers/parseTLMode';
import { ITimePoints } from '../../../../ts/models/signalPrograms.model';
import { TLStatus } from '../../../../ts/models/tl.model';

import MainPhaseCard from './MainPhaseCard/MainPhaseCard';
import NotPhaseCard from './NotPhaseCard/NotPhaseCard';
import PhasesCards from './PhasesCards/PhasesCards';

import styles from './PhaseCardsList.module.scss';

type PhasesInfo = {
  tlStatus: TLStatus;
  extId: N<number>;
  phasesSet: ITimePoints[];
  id: number;
  isError: boolean;
};

interface IPhaseCardList {
  phases: PhasesInfo;
  isTitle?: boolean;
  className?: string;
}

const spinProps: SpinProps = {
  size: 'large',
};

const TITLE = 'Информация о фазах';

const PhaseCardsLIst: FC<IPhaseCardList> = ({
  phases,
  className = '',
  isTitle = true,
}) => {
  const {
    tlStatus: { mode },
    isError,
    extId,
    phasesSet,
  } = phases;

  const { isEmpty, isSpecialMode } = parseTLMode(mode);

  if (isError || isEmpty || isSpecialMode)
    return <NotPhaseCard mode={mode} extId={extId} />;

  if (!phasesSet.length) {
    return (
      <div className={styles.wrapper}>
        <h3>{TITLE}</h3>
        <Spin {...spinProps} />
      </div>
    );
  }

  const TABPANES = [
    {
      label: 'Все фазы',
      key: '1',
      children: <PhasesCards phases={phases} />,
    },
    {
      label: 'Текущая фаза',
      key: '2',
      children: <MainPhaseCard {...phases} />,
    },
  ];

  return (
    <div className={styles.wrapper}>
      {isTitle ? <h3>{TITLE}</h3> : null}
      <Tabs items={TABPANES} />
    </div>
  );
};

export default PhaseCardsLIst;
