import { isEqual } from 'lodash';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';

import { IFeaturesArr } from './getFeaturesCluster';

export const getFeaturesOverlaysInExtent = (
  featuresWithOverlays: IFeaturesArr[],
  features: Feature<Point>[][],
  isOverlaysForAllFeatures: boolean
) => {
  const featuresData = featuresWithOverlays.reduce(
    (acc: IFeaturesArr[], item) => {
      const { feature, isSelectFeature } = item;

      const isOnExtent = features.some((el) => {
        if (el.length !== 1 && feature.get('features')?.length) {
          return isEqual(el, feature.get('features'));
        }

        return el[0]?.get('id') === feature.get('id');
      });

      if (isSelectFeature || isOnExtent) {
        acc.push(item);
      }

      return acc;
    },
    []
  );

  const singleFeatures = features.reduce((acc: IFeaturesArr[], value) => {
    if (value.length === 1) {
      const feature = value[0];

      if (!feature) return acc;

      const coord = feature.getGeometry()?.getCoordinates();

      acc.push({
        feature,
        coord,
      });
    }

    return acc;
  }, []);

  return isOverlaysForAllFeatures ? singleFeatures : featuresData;
};
