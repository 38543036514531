import { observer } from 'mobx-react-lite';
import { FC, useEffect, useRef, useState } from 'react';

import { CONFIG } from '../../../../constants/configConstants';
import { calcHeightRelativeWidth } from '../../../../helpers/calcHeightRelativeWidth';
import rootStore from '../../../../stores/rootStore/rootStore';

import styles from './CameraInfo.module.scss';

interface CameraInfoProps {
  id: number;
}

const CameraInfo: FC<CameraInfoProps> = ({ id }) => {
  const frameRef = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = useState('');
  const { getById } = rootStore.mapDataStore;
  const camera = getById(id, 'cameras');

  useEffect(() => {
    if (frameRef.current) {
      const width = frameRef.current.getBoundingClientRect().width;

      const newHeight = calcHeightRelativeWidth(width, {
        rWidth: 16,
        rHeigth: 9,
      });

      setHeight(newHeight + 'px');
    }
  }, [frameRef]);

  return (
    <>
      <iframe
        ref={frameRef}
        allowFullScreen
        className={styles.camera}
        height={height}
        src={`${CONFIG.ANT_MEDIA}/LiveApp/play.html?name=${camera?.srcId}&autoplay=true`}
        title={`camera_${camera?.srcId}`}
        width="100%"
      />

      {/* <div className={styles.row}>
        <div className={styles.caption}>
          <p>система:</p>

          <p>серийный номер:</p>
        </div>

        <div className={styles.rowContent}>
          <p>авангард</p>

          <p>sdn7332HD8-45f</p>
        </div>
      </div> */}
    </>
  );
};

export default observer(CameraInfo);
