import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';

import { DATE_FORMAT } from '../../../../constants/constants';

export const MINUTES_IN_HOUR = 60;

interface IGetTimeWithZoneProps {
  timeZone: number;
  dateJS?: Dayjs;
  dateFormat?: string;
}

export const getTimeWithTimeZone = ({
  timeZone,
  dateJS = dayjs(),
  dateFormat = DATE_FORMAT,
}: IGetTimeWithZoneProps) => {
  const utc = dateJS.utcOffset() / MINUTES_IN_HOUR;

  const difTimeZone = timeZone - utc;

  const dateFormattedJS = dateJS.add(difTimeZone, 'hour');

  const dateFormatted = dateFormattedJS.format(dateFormat);

  return { dateFormatted, dateFormattedJS };
};
