import { IQueryParamItem } from './../../../../ts/models/table.model';

export const getSearchCount = (queryParams: IQueryParamItem[]) => {
  const count = queryParams.reduce((sum, item) => {
    if (item.currentValue) return sum + 1;

    return sum;
  }, 0);

  return count;
};
