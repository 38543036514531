import { Form, Table } from 'antd';

import EditableCell from '../EditableCell/EditableCell';
import useTable from '../hooks/useTable';

const components = {
  body: {
    cell: EditableCell,
  },
};

interface ImportFormProps {
  importedData: any;
}

const ImportForm = (props: ImportFormProps) => {
  const { importedData } = props;
  const [form] = Form.useForm();
  const [columns, rows] = useTable({ moduleData: importedData, form });

  return (
    <Form form={form} component={false}>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        dataSource={rows}
        columns={columns}
        scroll={{ y: '75vh', x: 900 }}
        bordered
        pagination={{
          showSizeChanger: true,
          position: ['bottomRight'],
        }}
      />
    </Form>
  );
};

export default ImportForm;
