import { SegmentedValue } from 'antd/lib/segmented';
import { makeAutoObservable } from 'mobx';

import { createVideoList } from '../../components/VideoWall/helpers/helpers';
import { PanelType } from '../../ts/enums/enums';
import { ICamera } from '../../ts/models/camera.model';
import { ISavedVideoLIst } from '../../ts/models/videoWall.model';
import RootStore from '../rootStore/rootStore';

import { IVideoLIstItem } from './videoWallPanelStore.model';

class VideoWallPanelStore {
  rootStore;
  segment: SegmentedValue = 'list';
  videoPanelList: ICamera[] = [];
  videoList: IVideoLIstItem[] = [];
  videoItem: N<ICamera> = null;
  selectedVideoKey: N<string> = null;
  layoutValue = 4;
  isVideoListEdit = false;
  isChanged = false;
  isFullScreen = false;
  selectedVideoId: N<number> = null;
  savedVideoLists: ISavedVideoLIst[] = [];
  isAbilityToChange = false;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }

  setVideoKeysValues = <K extends keyof this>(params: {
    [key in K]: this[key];
  }) => {
    for (const key in params) {
      this[key] = params[key];
    }
  };

  get isVideoPanel() {
    const { panelType } = this.rootStore.uiStore;

    return panelType === PanelType.VideoWall;
  }

  clearAllData = () => {
    const { setVideoKeysValues } = this;

    setVideoKeysValues({
      videoList: [],
      selectedVideoKey: null,
      selectedVideoId: null,
    });
  };

  createNewVideoLIst = () => {
    const { layoutValue, setVideoKeysValues } = this;

    const newVideoLIst = createVideoList(layoutValue ** 2);

    setVideoKeysValues({
      selectedVideoId: null,
      selectedVideoKey: null,
      isVideoListEdit: true,
      videoList: newVideoLIst,
    });
  };

  changeVideoList = (
    cameras: IVideoLIstItem[],
    listKey: string,
    id: number,
    isChange: boolean
  ) => {
    const { setVideoKeysValues } = this;

    setVideoKeysValues({
      videoList: cameras,
      selectedVideoId: id,
      selectedVideoKey: listKey,
      isChanged: false,
      isVideoListEdit: false,
      isAbilityToChange: isChange,
    });
  };
}

export default VideoWallPanelStore;
