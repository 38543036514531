import { toJS } from 'mobx';

import { TL } from '../../../ts/models/tl.model';

const getAdjacentTLs = (activeTrafficLight: TL, tls: TL[]) => {
  const adjacentIds = activeTrafficLight?.linkedDevices?.trafficLights;

  if (!adjacentIds?.length) {
    return;
  }

  const adjacentEls = tls.filter((el) =>
    adjacentIds.some((id) => id === el.id)
  );

  return adjacentEls.map((el) => JSON.parse(JSON.stringify(toJS(el))));
};

export default getAdjacentTLs;
