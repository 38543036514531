import { Spin } from 'antd';
import { SpinProps } from 'antd/lib';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import infoApi from '../../../api/serverObjects/info/info';
import rootStore from '../../../stores/rootStore/rootStore';
import useChannel from '../../Socket/useChannel';

import { convetTimeToColor } from './helpers';
import DBInfo from './ui/DBInfo/DBInfo';
import MainNJSInfo from './ui/MainNJSInfo/MainNJSInfo';
import NotifyChannelsInfo from './ui/NotifyChannelsInfo/NotifyChannelsInfo';
import PingContainer from './ui/PingContainer/PingContainer';
import TimeRefresh from './ui/TimeRefresh/TimeRefresh';

import styles from './NJSInfo.module.scss';

interface IDataTimeRefresh {
  [key: string]: number;
}

interface INotifyChannel {
  database: string;
  channel: string;
}

export interface IServerData {
  version: string;
  uptime: number;
  serverSystemId: number;
  webSystemId: number;
  dataTimeRefresh: IDataTimeRefresh;
  partialDataTimeUpdate: IDataTimeRefresh;
  databases: string[];
  notifyChannels: INotifyChannel[];
  appName: string;
}

const DEFAULT_COLOR = 'green';
const DEFAULT_PING = 40;

const NJInfo = () => {
  const { categories } = rootStore.channelsDataStore;
  const serverData = useChannel(categories, 'server', 'info', true);
  const [data, setData] = useState<N<IServerData>>(null);
  const [isLoading, setIsLOading] = useState({
    dataTime: false,
    ping: false,
  });
  const [pingInfo, setPingInfo] = useState({
    color: DEFAULT_COLOR,
    time: DEFAULT_PING,
  });
  const [updateTime, setUpdateTime] = useState<N<number>>();

  const handleResetDataTime = () => {
    setIsLOading({
      ...isLoading,
      dataTime: true,
    });

    infoApi.fetchRefreshServerData().finally(() => {
      setIsLOading({
        ...isLoading,
        dataTime: false,
      });
    });
  };

  const handleResetPing = useCallback(() => {
    const startTime = Date.now();

    setIsLOading({
      ...isLoading,
      ping: true,
    });

    infoApi
      .fetchPing()
      .then((_res) => {
        const endTime = Date.now();
        const time = endTime - startTime;

        setPingInfo({
          color: convetTimeToColor(time),
          time,
        });
      })
      .catch((e) => {
        const endTime = Date.now();
        const time = endTime - startTime;

        setPingInfo({
          color: convetTimeToColor(time),
          time,
        });
        console.log(e);
      })
      .finally(() => {
        setIsLOading({
          ...isLoading,
          ping: false,
        });
      });
  }, [isLoading]);

  useEffect(() => {
    handleResetPing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (serverData) {
      setData({ ...serverData });
      setUpdateTime(Date.now() - serverData.uptime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverData]);

  const spinProps: SpinProps = {
    size: 'small',
    className: styles.spin,
  };

  if (!data)
    return (
      <section className={styles.wrapper}>
        <div>
          <h2>Дождитесь загрузки данных</h2>
          <Spin {...spinProps} />
        </div>
      </section>
    );

  return (
    <section className={styles.wrapper}>
      <div className={styles.headInfo}>
        {updateTime ? (
          <MainNJSInfo
            data={data}
            timer={updateTime}
            setTimer={setUpdateTime}
          />
        ) : null}
        <PingContainer
          isLoading={isLoading.ping}
          pingInfo={pingInfo}
          handleReset={handleResetPing}
        />
      </div>
      <div className={styles.infoBoxes}>
        <TimeRefresh
          data={data}
          type="fullData"
          handleRefresh={handleResetDataTime}
          isLoading={isLoading.dataTime}
        />
        <TimeRefresh
          data={data}
          type="partialData"
          handleRefresh={handleResetDataTime}
          isLoading={isLoading.dataTime}
        />
        <DBInfo data={data} />
        <NotifyChannelsInfo data={data} />
      </div>
    </section>
  );
};

export default observer(NJInfo);
