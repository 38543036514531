/* eslint-disable no-param-reassign */
import { ITimePoints } from '../../../../../ts/models/signalPrograms.model';

import { getTypeTactObj } from './getTypeTactObj';

export const getPhaseInfo = (curPhase: ITimePoints) => {
  const { length, time, tactType, durationMin, direction } = curPhase;
  const durMainTact = length - time;
  const tactTypeText = getTypeTactObj(tactType).name;
  const direct = direction ? [...direction] : null;
  const accessDirections =
    direct?.sort().reduce((acc, dir, i, arr) => {
      acc += `${dir}н`;
      if (i !== arr.length - 1) {
        acc += ', ';
      }

      return acc;
    }, '') ?? 'Нет разрешенных направлений';

  return [
    { label: 'Длительность фазы', value: `${length}c.` },
    { label: 'Время основного такта', value: `${durMainTact}c.` },
    { label: 'Время пром такта', value: `${time}c.` },
    { label: 'Мин. время фазы', value: `${durationMin}c.` },
    { label: 'Тип фазы', value: tactTypeText },
    { label: 'Разр. направления', value: accessDirections },
  ];
};
