import AdminPanelStore from '../../../../stores/adminPanelStore/adminPanelStore';

export const onOpenMenuHandler = (
  keys: string[],
  openKeys: string[],
  rootKeys: string[],
  secondRootKeys: string[],
  setAdminsData: <
    K extends keyof AdminPanelStore,
    T extends AdminPanelStore[K]
  >(
    key: K,
    value: T
  ) => void,
  setOpenKeys: (param: any) => void
) => {
  if (!keys.length) {
    setOpenKeys([]);
    setAdminsData('adminTableType', null);

    return;
  }

  if (keys[0] !== 'eputsSystem') {
    setOpenKeys([]);
    setAdminsData('adminPanelKeys', []);
    setAdminsData('adminTableType', null);

    return;
  }

  setOpenKeys(keys);
  setAdminsData('adminTableType', keys[keys.length - 1]);
  setAdminsData('subId', keys[keys.length - 1].split('#')[1]);

  const newKeys = [...keys];

  setAdminsData('adminPanelKeys', newKeys.reverse());

  const latestOpenKey: any = keys.find((key) => openKeys.indexOf(key) === -1);

  if (rootKeys.indexOf(latestOpenKey) === -1) {
    if (secondRootKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
      const newKeys = [...keys];

      setAdminsData('adminPanelKeys', newKeys.reverse());
    } else {
      let regionKey;

      keys.forEach((key) => {
        if (key.includes('region')) {
          regionKey = key;
        }
      });

      setOpenKeys(
        latestOpenKey ? ['eputsSystem', regionKey, latestOpenKey] : []
      );

      setAdminsData(
        'adminPanelKeys',
        latestOpenKey ? ['eputsSystem', regionKey, latestOpenKey].reverse() : []
      );
    }
  } else {
    setOpenKeys(latestOpenKey ? ['eputsSystem', latestOpenKey] : []);

    setAdminsData(
      'adminPanelKeys',
      latestOpenKey ? ['eputsSystem', latestOpenKey].reverse() : []
    );
  }
};
