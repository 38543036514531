import { CircleElEnum } from '../ts/enums/tl';
import { CircleElsDynamic } from '../ts/models/constructor.model';

import { DEFAULT_CROSSROAD_COLORS } from './colorsConstants';
import { TL_CIRCLE_ZOOM } from './constants';

export const DEFAULT_CIRCLE = {
  SIZE: 100,
  SIZE_RATE: 1,
  DIAMETER: 300,
  DIAMETER_RATE: 20,
  BORDER_WIDTH: 8,
  HUE: 0,
  OPACITY: 20,
  DASH: [4, 4] as XY,
  STROKE_WIDTH: 2,
  CROSSWALK_WIDTH: 20,
  CROSSWALK_LENGTH: 1,
  CROSSWALK_SIZE: 50,
  CROSSWALK_SIZE_RATE: 0.5,
};

export const CIRCLE_ELEMENT_OBJ = {
  type: CircleElEnum.Gathered,
  isCircleElement: false,
  isDashed: false,
  isDirectPlus: false,
  isAdditionalTop: false,
  isDashAnimation: false,
  isOpacityAnimation: false,
  isColorAnimation: false,
  color: 'green',
  dash: DEFAULT_CIRCLE.DASH,
  animation: null,
  hue: DEFAULT_CIRCLE.HUE,
};

export const CIRCLE_ELEMENT_DYNAMIC = {
  angle: 0,
  bottomAngle: 0,
  additionalTopAngle: 0,
  offsetX: 0,
  offsetY: 0,
  size: 150,
  strokeWidth: DEFAULT_CIRCLE.STROKE_WIDTH,
  sizeRate: DEFAULT_CIRCLE.CROSSWALK_LENGTH,
};

export const DEFAULT_CIRCLE_ELS_DYNAMIC: CircleElsDynamic = {
  [TL_CIRCLE_ZOOM.START]: [],
};

export const PHASES_DEFAULT = [
  {
    phaseNumber: null,
    directions: [],
    code: 1,
  },
  {
    phaseNumber: null,
    directions: [],
    code: 31,
  },
  {
    phaseNumber: null,
    directions: [],
    code: 32,
  },
  {
    phaseNumber: null,
    directions: [],
    code: 33,
  },
  {
    phaseNumber: null,
    directions: [],
    code: 34,
  },
];

export const FINAL_PREFIX = 'Фин.: ';

const { MAIN, MAIN_TRANSITION, CROSSWALK, CROSSWALK_TRANSITION } =
  DEFAULT_CROSSROAD_COLORS;

export const CROSSROAD_DEFAULT = {
  isLoaded: false,
  isDirections: false,
  isMainTact: true,
  isBlinkingTransitionTact: true,
  isTrafficLanesOffset: true,
  isCamerasOffset: true,
  phases: [],
  angle: 0,
  offsetX: 0,
  offsetY: 0,
  size: 200,
  color: MAIN,
  colorTransition: MAIN_TRANSITION,
  crosswalkColor: CROSSWALK,
  crosswalkColorTransition: CROSSWALK_TRANSITION,
};

export const CAMERA_DEFAULT = {
  isEnabled: false,
  isExtended: false,
  offsetX: 0,
  offsetY: 0,
  size: 10,
  distance: 50,
  widthRate: 1,
  angle: 0,
  arcHeight: 10,
  preDistanceRate: 0.4,
  color: 'purple',
  opacity: 0.4,
  final: null,
  linkedDetectorId: null,
};

export const VIEW_DEFAULT = {
  isDataSelectors: true,
  isShowCenterSettings: true,
  isCrossroad: false,
  isTrafficLanes: false,
  isDirections: false,
  isCameras: false,
  isActionBtns: true,
  isCircleCenter: true,
  isCircle: true,
  isCircleFill: false,
};

export const LANE_SPAN = 0.5;

export const TRAFFIC_LANE_DEFAULT = {
  laneParams: [
    {
      id: 1,
      span: LANE_SPAN,
      width: 4,
      offset: 0,
    },
  ],
  isEnabled: false,
  isMain: true,
  isGradient: true,
  angle: 0,
  offsetX: 0,
  offsetY: 0,
  mainOffsetX: 0,
  mainOffsetY: 0,
  mainWidth: 4,
  width: 4,
  length: 100,
  color: 'orange',
  opacity: 1,
  final: null,
  comment: '',
};
