import { getFormattedTimeToDayJS } from '../../../../helpers/formatDate';
import { CHART_NAMES } from '../../../InfoPanel/SystemsInfo/PopupDetector/Chart/constants/constants';
import { getSliceDates } from '../IntancyGraph/helpers/getSliceDates';
import { IDateInfo, IPickerValue, TSelectDates } from '../model/graph.model';

export const DATES = {
  DAY: 'Сутки',
  THREE_DAYS: 'Три дня',
  WEEK: 'Неделя',
  MONTH: 'Месяц',
  SELECT_TIME: 'Выбрать период',
} as const;

export const GRAPHS_TITLE: Record<keyof typeof CHART_NAMES, string> = {
  vehicles: 'Интенсивность',
  avSpeed: 'Ср. скорость',
};

export const GRAPHS_TITLE_ARRAY = Object.values(GRAPHS_TITLE);

export enum INTERVAL_DATES {
  HOUR = 3600,
  DAY = 86400,
  HALF_HOUR = 1800,
  FIFTEEN_MIN = 900,
  TEN_MIN = 600,
}

export type TIntervalsLabels =
  | 'СУТКИ'
  | '1 ЧАС'
  | '30 МИН'
  | '15 МИН'
  | '10 МИН';

interface IIntervals {
  label: TIntervalsLabels;
  duration: INTERVAL_DATES;
}

export const INTERVALS: IIntervals[] = [
  { label: 'СУТКИ', duration: INTERVAL_DATES.DAY },
  { label: '1 ЧАС', duration: INTERVAL_DATES.HOUR },
  { label: '30 МИН', duration: INTERVAL_DATES.HALF_HOUR },
  { label: '15 МИН', duration: INTERVAL_DATES.FIFTEEN_MIN },
  { label: '10 МИН', duration: INTERVAL_DATES.TEN_MIN },
];

export const DATE_FORMAT = 'DD.MM.YYYY, HH:mm:ss';
export const DATE_FORMAT_TO_SHOW = 'DD.MM.YYYY HH:mm:ss';

const selectDate: TSelectDates = [...getSliceDates(1)];
const initInterval = INTERVAL_DATES.HOUR;

export const INITIAL_DATE_INFO: IDateInfo = {
  selectedDate: selectDate,
  interval: initInterval,
  radioValueDate: DATES.DAY,
  isNeedUtcFormatted: true,
};

export const INITIAL_RANGE_VALUE: IPickerValue = {
  rangePicker: selectDate,
  timePicker: getFormattedTimeToDayJS({ s: initInterval }),
  status: undefined,
};
