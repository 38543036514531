import { notification, ConfigProvider, theme, App as AntdApp } from 'antd';
import ruRu from 'antd/lib/locale/ru_RU';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import NOTICE from '../../constants/notificationConstants';
import {
  darkComponentsToken,
  darkToken,
  lightToken,
  lightComponentsToken,
} from '../../constants/themeTokens';
import useAuth from '../../hooks/useAuth';
import rootStore from '../../stores/rootStore/rootStore';
import Admin from '../Admin/Admin';
import DetailedConstructor from '../Constructor/DetailedConstructor/DetailedConstructor';
import DetailedMain from '../DetailedMain/DetailedMain';
import ExternalResource from '../ExternalResource/ExternalResource';
import InfoPanelWithContext from '../InfoPanel/InfoPanelWithContext';
import Login from '../Login/Login';
import InfoBtn from '../Map/InfoBtn/InfoBtn';
import MapButtons from '../MapBtns/MapButtons';
import MapInfo from '../MapInfo/MapInfo';
import MapObjectsLoader from '../MapObjectsLoader/MapObjectsLoader';
import Mapper from '../Mapper/Mapper';
import ModuleTable from '../ModuleTable/ModuleTable';
import Panel from '../Panel/Panel';
import TestingDetailed from '../Panel/TestPanel/ui/TestingDetailed/TestingDetailed';
import RegionSwitcher from '../RegionSwitcher/RegionSwitcher';
import Sidebar from '../Sidebar/Sidebar';
import useSocket from '../Socket/useSocket';
import useLinkedTlsIds from '../TLTimeSec/hooks/useLinkedTlsIds';
import TLTimeSec from '../TLTimeSec/TLTimeSec';
import ModalLoading from '../ui-kit/ModalLoading/ModalLoading';
import VideoWall from '../VideoWall/VideoWall';
import Widgets from '../Widgets/Widgets';

const { defaultAlgorithm, darkAlgorithm } = theme;

function App() {
  const {
    isDetailedForm,
    isTlsDataFetched,
    moduleData,
    isModalLoading,
    regionData,
    externalResource,
    isDebugMode,
    isDark,
    infoData,
    isAdjacentPhaseCircles,
    isPhaseCircle,
  } = rootStore.uiStore;
  const { isAdminLoad } = rootStore.adminPanelStore;
  const { isVideoPanel } = rootStore.videoWallPanelStore;
  const { isAuth, isAppReady } = rootStore.userDataStore;
  const { tls, fetchDataByRegion } = rootStore.mapDataStore;
  const { isTestingPanel } = rootStore.testingPanelStore;
  const { isConstructor, isConstructorDetailed } = rootStore.constructorStore;
  const { checkAuthHandler } = useAuth();

  useSocket();

  useEffect(() => {
    checkAuthHandler();
  }, []); // eslint-disable-line

  useEffect(() => {
    isAuth && regionData && fetchDataByRegion(regionData);
  }, [fetchDataByRegion, regionData, isAuth]);

  useEffect(() => {
    !isTlsDataFetched && notification.error(NOTICE.ERROR_TLS_FETCHING);
  }, [isTlsDataFetched]);

  const { linkedDevicesTlsIds } = useLinkedTlsIds({
    infoData,
    isAdjacentPhaseCircles,
    isPhaseCircle,
    tls,
  });

  if (!isAppReady) {
    return <ModalLoading />;
  }
  if (!isAuth) {
    return <Login />;
  }
  if (!regionData) {
    return <RegionSwitcher />;
  }

  return (
    <ConfigProvider
      locale={ruRu}
      theme={{
        algorithm: isDark ? darkAlgorithm : defaultAlgorithm,
        token: isDark ? darkToken : lightToken,
        components: isDark ? darkComponentsToken : lightComponentsToken,
      }}
    >
      <AntdApp>
        <DndProvider backend={HTML5Backend}>
          <Sidebar />
          <Panel />
          <MapButtons />
          <MapObjectsLoader />
          <InfoPanelWithContext />
          {linkedDevicesTlsIds.map((id) => (
            <TLTimeSec key={id} id={id} />
          ))}
          {/* <NotificationPanel /> */}
          {!isConstructor && (
            <>
              <Widgets />
              {isDetailedForm && <DetailedMain />}
              {moduleData && <ModuleTable />}
              {isAdminLoad && <Admin />}
              {isVideoPanel && <VideoWall />}
              {isTestingPanel && <TestingDetailed />}
            </>
          )}
          {isModalLoading && <ModalLoading />}
          {isConstructorDetailed && <DetailedConstructor />}
          {isConstructor || isDebugMode ? <MapInfo /> : <InfoBtn />}
          {externalResource && <ExternalResource />}
          <Mapper />
        </DndProvider>
      </AntdApp>
    </ConfigProvider>
  );
}

export default observer(App);
