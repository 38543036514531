import { SettingOutlined } from '@ant-design/icons';
import { Input, Segmented } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState, ChangeEvent, useEffect } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import Popover from '../../ui-kit/Popover/Popover';
import Switcher from '../../ui-kit/Switcher/Switcher';

import { PANEL_TABS_MAIN, TABS, СartographyTabName } from './constants';
import SearchSettings from './SearchSettings/SearchSettings';

import styles from './MainPanel.module.scss';

const MainPanel = () => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { setMapData } = rootStore.mapDataStore;
  const { hasAccess } = rootStore.userDataStore;
  const [segment, setSegment] = useState<СartographyTabName>(
    СartographyTabName.Subsystems
  );
  const [isSerachSettings, setIsSearchSetting] = useState(false);
  const isDisable = segment !== СartographyTabName.Subsystems;

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setMapData('searchedValue', e.target.value);
  };

  const handleSettings = () => {
    if (isSerachSettings) setMapData('searchedArea', '');
    setIsSearchSetting(!isSerachSettings);
  };

  useEffect(() => {
    return () => {
      setMapData('searchedArea', '');
      setMapData('searchedValue', '');
    };
  }, [setMapData]);

  const panelTabs = PANEL_TABS_MAIN.map((el, i) => {
    const newEl = { ...el, disabled: !hasAccess(el.code) };

    newEl.label = (
      <Popover content={el.value} tipsDelay={interfaceTipsDelay}>
        {el.label}
      </Popover>
    );

    return newEl;
  });

  return (
    <>
      <Input
        onChange={onSearch}
        className={styles.search}
        placeholder="Поиск объекта"
        addonAfter={
          <Popover content="Настройка" tipsDelay={interfaceTipsDelay}>
            <SettingOutlined onClick={handleSettings} disabled={isDisable} />
          </Popover>
        }
        allowClear
        disabled={isDisable}
      />
      {segment === СartographyTabName.Subsystems && (
        <SearchSettings isSerachSettings={isSerachSettings} />
      )}
      <div className={styles.tabsWrapper}>
        <Segmented
          value={segment}
          onChange={setSegment as any}
          options={panelTabs}
          className={styles.segmented}
        />
      </div>

      <Switcher segment={segment} obj={TABS} />
    </>
  );
};

export default observer(MainPanel);
