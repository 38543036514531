import { Progress } from 'antd';
import { observer } from 'mobx-react-lite';

import rootStore from '../../stores/rootStore/rootStore';

import styles from './MapObjectsLoader.module.scss';

const MapObjectsLoader = () => {
  const { percentLoad, setPercent } = rootStore.mapDataStore;

  if (percentLoad >= 103) return null;

  if (percentLoad >= 102) {
    setPercent(200);
  }

  return (
    <div className={styles.container}>
      <Progress percent={percentLoad} />
    </div>
  );
};

export default observer(MapObjectsLoader);
