import { Overlay } from 'ol';
import { fromLonLat } from 'ol/proj';
import { RefObject } from 'react';

import { findById } from '../../../helpers/findBy';
import setDisplayStyle from '../../../helpers/setDisplayStyle';
import rootStore from '../../../stores/rootStore/rootStore';
import { TL } from '../../../ts/models/tl.model';

const activateAdjacentTLs = (
  linkedTls: Nullish<number[]>,
  adjacentPhaseCirclePopups: Overlay[],
  adjacentPhaseCircleRefs: RefObject<HTMLDivElement>[]
) => {
  const { tls } = rootStore.mapDataStore;

  if (linkedTls?.length) {
    linkedTls.forEach((id, i) => {
      const adjacent: TL = findById(tls, id);

      const coordinates =
        adjacent?.tlGeneral?.imagesPoint?.geometry.coordinates[0];

      const ref = adjacentPhaseCircleRefs[i];

      if (ref.current !== null) {
        ref.current.id = `${id}`;
        coordinates &&
          adjacentPhaseCirclePopups[i].setPosition(fromLonLat(coordinates));
        setDisplayStyle([ref], 'block');
      }
    });
  }
};

export default activateAdjacentTLs;
