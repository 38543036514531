import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { ReactElement, useEffect, useState } from 'react';

import { SYSTEM } from '../../constants/constants';
import { findById } from '../../helpers/findBy';
import getSystemTitle from '../../helpers/getSystemTitle';
import rootStore from '../../stores/rootStore/rootStore';
import { IInfoPanelData } from '../../stores/uiStore/uiStore';
import { TL } from '../../ts/models/tl.model';
import { getCurrentObject } from '../InfoPanel/SystemsInfo/LinkedsInfo/LinkedDeviceItem/getCurrentObject';
import Detailed from '../ui-kit/Detailed/Detailed';

import DetailedSwitcher from './DetailedSwitcher';
import { switchIco } from './helpers/switchHeaderIco';

interface IDetailed {
  backBtnTitle: string;
  headerIco: ReactElement;
  header: string;
  handleBackBtn: () => void;
}

const { LIGHTS, DETECTORS, CAMERAS } = SYSTEM;

function DetailedMain() {
  const { setIsNot, infoData, isOpenInfoPanel, setKeyValue } =
    rootStore.uiStore;

  const { tls, cameras, detectors } = rootStore.mapDataStore;
  const [detailedDataObject, setDetailedDataObject] =
    useState<IInfoPanelData>();
  const [detailed, setDetailed] = useState<IDetailed>();

  const currentObject = (
    infoData?.system && isNumber(infoData?.id)
      ? getCurrentObject(infoData.system, infoData.id, {
          tls,
          cameras,
          detectors,
        })
      : null
  ) as N<TL>;

  const mode = currentObject?.tlStatus?.mode;

  useEffect(() => {
    if (!infoData) return;

    const { id, system } = infoData;
    let caption = '';
    let activeItem = null;

    setDetailedDataObject(infoData);

    switch (system) {
      case LIGHTS:
        activeItem = findById(tls, id);
        caption = activeItem?.featureOGS?.properties?.highway;
        break;
      case DETECTORS:
        activeItem = findById(detectors, id);
        caption = activeItem?.caption;
        break;
      case CAMERAS:
        activeItem = findById(cameras, id);
        caption = activeItem?.caption;
        break;
      default:
        console.log('Нет таких значений');
    }

    const detailedProps = {
      backBtnTitle: 'к карте',
      headerIco: switchIco(system, mode),
      header: `${getSystemTitle(
        system,
        system === LIGHTS ? activeItem.extId : id
      )} ${caption}`,
      handleBackBtn: () => {
        setIsNot('isDetailedForm', false);
        isOpenInfoPanel && setIsNot('isInfoPanel', true);
        setKeyValue('isShowPhaseCircle', true);
      },
    };

    setDetailed(detailedProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoData, isOpenInfoPanel, mode]);

  if (!infoData || !detailed) {
    return null;
  }

  return (
    <Detailed {...detailed}>
      {detailedDataObject && (
        <DetailedSwitcher detailedData={detailedDataObject} />
      )}
    </Detailed>
  );
}

export default observer(DetailedMain);
