import { observer } from 'mobx-react-lite';
import { RiRoadsterFill } from 'react-icons/ri';

import rootStore from '../../../stores/rootStore/rootStore';
import ButtonIco from '../../ui-kit/ButtonIco/ButtonIco';
import ButtonsBlock from '../../ui-kit/ButtonsBlock/ButtonsBlock';
import Popover from '../../ui-kit/Popover/Popover';

import styles from '../styles/BtnsBlock.module.scss';

const CircleBtns = () => {
  const { setIsNot, isTrafficLanes, mapButtonsTipsDelay, infoData } =
    rootStore.uiStore;
  const { isCrossroadBorder, tls } = rootStore.mapDataStore;

  const tl = tls.find(({ id }) => id === infoData?.id);

  if (!tl) return null;

  const handleIsTLanes = () => setIsNot('isTrafficLanes');

  const popOverProps = {
    placement: 'left',
    tipsDelay: mapButtonsTipsDelay,
  };

  return (
    <ButtonsBlock borderRadius={6} className={styles.btnsBlock}>
      <ButtonIco
        onClick={handleIsTLanes}
        isActive={isTrafficLanes}
        disabled={!isCrossroadBorder || !tl.finalArrayFromProfiles}
      >
        <Popover content="Вкл./Откл. полос загруженности" {...popOverProps}>
          <RiRoadsterFill />
        </Popover>
      </ButtonIco>
    </ButtonsBlock>
  );
};

export default observer(CircleBtns);
