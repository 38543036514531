interface CrBasic {
  color: string;
  strokeWidth: number;
}

interface CrDir extends CrBasic {
  phase: Element;
}

interface CrEls extends CrBasic {
  dirs: NodeListOf<Element>;
}

export const changeCrosswalkEls = (
  elements: NodeListOf<Element>,
  color: string
) =>
  elements.forEach((el) => {
    // @ts-ignore
    el.style.stroke = color;
    // @ts-ignore
    el.style.fill = color;
  });

const changeCrossroadDir = ({ phase, color, strokeWidth }: CrDir) => {
  for (let i = 0; i < phase?.children?.length ?? 0; i++) {
    const el = phase.children[i].children[0]?.children;

    if (!el?.length) continue;

    for (let j = 0; j < el.length; j++) {
      // @ts-ignore
      el[j].style.stroke = color;
      // @ts-ignore
      el[j].style.strokeWidth = strokeWidth;

      const { children } = el[j];

      if (children.length) {
        // @ts-ignore
        children[0].style.fill = color;
      }
    }
  }
};

export const changeCrossroadEls = ({ dirs, color, strokeWidth }: CrEls) =>
  // @ts-ignore
  dirs.forEach((phase) => changeCrossroadDir({ phase, color, strokeWidth }));
