import { makeAutoObservable } from 'mobx';

import RootStore from '../rootStore/rootStore';

import { FileAttributes } from './documentsStore.model';

interface IViewFile {
  type: string;
  id: number;
  attributes: FileAttributes;
  file: Blob;
}

class DocumentsStore {
  rootStore;
  viewFile: N<IViewFile> = null;
  isUpdate = false;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }

  setDocumentsKeysValues = <K extends keyof this>(params: {
    [key in K]: this[key];
  }) => {
    for (const key in params) {
      this[key] = params[key];
    }
  };
}

export default DocumentsStore;
