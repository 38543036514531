import { useCallback, useEffect, useState } from 'react';

import { WSData } from '../components/TLTimeSec/TLTimeSec';
import getCircleSec from '../helpers/getCircleSec';
import parseTLMode from '../helpers/parseTLMode';
import { ITimePoints } from '../ts/models/signalPrograms.model';
import { TLStatus } from '../ts/models/tl.model';

const useCircleTimer = (
  tlStatus: U<TLStatus>,
  formattedData: ITimePoints[],
  wsData: N<WSData>
) => {
  const len = tlStatus?.cycle ?? null;
  const { isEmpty } = parseTLMode(tlStatus?.mode ?? null);
  const [timer, setTimer] = useState(0);

  const calcSec = useCallback(() => {
    if (!formattedData || !wsData) return null;

    const { tactCurrentStartFrom, currIter } = wsData;

    const diff = Math.round(Date.now() / 1000) - tactCurrentStartFrom;
    const tactStart = currIter > 1 ? formattedData[currIter - 2]?.stop ?? 0 : 0;

    return tactStart + diff;
  }, [formattedData, wsData]);

  const getSec = useCallback(() => {
    if (len === null || !tlStatus) return;
    const { lsec, timeRcv } = tlStatus;

    const curSec = calcSec() ?? getCircleSec(lsec, len, timeRcv);

    setTimer(curSec);
  }, [tlStatus, calcSec, len]);

  useEffect(() => tlStatus && getSec(), [tlStatus, getSec]);

  useEffect(() => {
    let timerId: U<NodeJS.Timeout> = undefined;

    if (!isEmpty) {
      timerId = setInterval(() => {
        setTimer((prev) => {
          let next = 0;
          let timeEdge = 0;

          if (formattedData && wsData) {
            const { currIter, tactNext } = wsData;

            timeEdge = formattedData[currIter - 1]?.stop;

            if (tactNext > 1 && tactNext <= formattedData.length) {
              next = formattedData[tactNext - 2]?.stop;
            }
          }
          const t = prev !== timeEdge ? prev : prev + next - timeEdge;

          return t + 1;
        });
      }, 1000);
    } else {
      timerId && clearInterval(timerId);
    }

    return () => timerId && clearInterval(timerId);
  });

  useEffect(() => {
    if (len !== null && timer >= len && !isEmpty) {
      setTimeout(() => setTimer(0), 500);
      setTimeout(() => setTimer(1), 1000);
    }
  }, [timer, getSec, len, isEmpty]);

  return isEmpty ? 0 : timer;
};

export default useCircleTimer;
