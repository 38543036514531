import { Popconfirm, Space, Typography, notification } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FaRegSave } from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';

// @ts-ignore
import { createTableRow, editTableRow } from '../../../api_';
import tryCatchWrapper from '../../../helpers/tryCatchWrapper';
import rootStore from '../../../stores/rootStore/rootStore';

import styles from './RowEditBtns.module.scss';

interface RowEditBtnsProps {
  setEditingKey: (key: string) => void;
  form: any;
  id: number;
}

function RowEditBtns(props: RowEditBtnsProps) {
  const { setEditingKey, form, id } = props;
  const { moduleData, setKeyValue } = rootStore.uiStore;

  if (!moduleData) return null;

  const handleAccept = () => setEditingKey('');
  const save = tryCatchWrapper(async () => {
    const row = await form.validateFields(),
      data = [...moduleData.data],
      index = data.findIndex((item) => id === item.id);

    if (index > -1) {
      const res = `${id}`.includes('newRow_')
        ? await createTableRow(moduleData.code, row)
        : await editTableRow(moduleData.code, { ...data[index], ...row });

      notification.success({
        message: 'Сохранено',
        description: res?.data?.message,
      });

      data.splice(index, 1, { ...data[index], ...res.data.row });
      setKeyValue('moduleData', { ...moduleData, data });
    }
    setEditingKey('');
  });

  return (
    <Space>
      <Typography.Link onClick={save}>
        <FaRegSave className={styles.ico} />
      </Typography.Link>

      <Popconfirm
        cancelText="Нет"
        okText="Да"
        onConfirm={handleAccept}
        title="Не изменять?"
      >
        <Typography.Link>
          <ImCancelCircle className={styles.ico} />
        </Typography.Link>
      </Popconfirm>
    </Space>
  );
}

export default observer(RowEditBtns);
