import { Button } from 'antd';
import React, { FC } from 'react';
import { RiRestartLine } from 'react-icons/ri';

import { convertDate } from '../../../helpers/helpers';
import { IServerData } from '../../NJInfo';
import InfoBox from '../InfoBox/InfoBox';

interface ITimeRefresh {
  data: IServerData;
  type: string;
  isLoading: boolean;
  handleRefresh: () => void;
}

interface IValues {
  [key: string]: Record<string, number>;
}

type Titles = Record<string, string>;

const TimeRefresh: FC<ITimeRefresh> = ({
  data,
  type,
  isLoading,
  handleRefresh,
}) => {
  const VALUES: IValues = {
    fullData: data.dataTimeRefresh,
    partialData: data.partialDataTimeUpdate,
  };
  const TITLES: Titles = {
    fullData: 'Полное обновление данных:',
    partialData: 'Частичное обновление данных:',
  };

  return (
    <InfoBox title={TITLES[type]}>
      <>
        <ul>
          {Object.keys(VALUES[type]).map((item, i) => (
            <li key={i}>
              <span>{item}</span>:{' '}
              {VALUES[type][item]
                ? convertDate(VALUES[type][item] / 1000)
                : '---'}
            </li>
          ))}
        </ul>
        {type === 'fullData' ? (
          <Button
            type="primary"
            size="middle"
            icon={<RiRestartLine />}
            onClick={handleRefresh}
            loading={isLoading}
          >
            Обновить данные
          </Button>
        ) : null}
      </>
    </InfoBox>
  );
};

export default TimeRefresh;
