import { notification } from 'antd';

import { getSendErrorBody } from '../api/helpers/getSendErrorBody';
import infoApi from '../api/serverObjects/info/info';

export const sendError = async (title: string, e: unknown) => {
  try {
    await infoApi.sendError(getSendErrorBody(title, e));
  } catch (error) {
    notification.error({
      message: 'Отчет об ошибке не отправлен',
      description: `${error}`,
    });
  }
};
