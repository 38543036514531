import { VERSION } from '../../constants/versions';
import { MapObjectType } from '../../ts/models/mapObject.model';
import { validateMapObj } from '../helpers/validateData';
import http from '../http';
import tryCatchWrapper from '../tryCatchWrapper';

const OBJECTS = `${VERSION}/objects/`;

const mapObjectsApi = {
  fetchFullObject: (type: MapObjectType, regionId: number) =>
    tryCatchWrapper(async () => {
      const res = await http(OBJECTS + type, {
        params: {
          regionId,
        },
      });
      const data = res?.data?.data;

      return validateMapObj(type, data);
    }),

  fetchObjectItem: (type: MapObjectType, id: number) =>
    tryCatchWrapper(async () => {
      const res = await http.get(`${OBJECTS + type}/${id}`);
      const data = res?.data?.data;

      return validateMapObj(type, [data]);
    }),
};

export default mapObjectsApi;
