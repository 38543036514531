import { fromLonLat } from 'ol/proj';

const formatGeoLonLat = (data: any, phaseIndex: number | undefined) => {
  const geoModified = JSON.parse(JSON.stringify(data));

  data.features.forEach((feature: any, i: number) => {
    feature.properties.phase = phaseIndex;

    const newCoord: any[] = [];

    feature.geometry.coordinates.forEach((items: any) => {
      const itemsModified = items.map((lonLat: any) => fromLonLat(lonLat));

      newCoord.push(itemsModified);
    });

    geoModified.features[i].geometry.coordinates = newCoord;
  });

  return geoModified;
};

export default formatGeoLonLat;
