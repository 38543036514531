import dayjs from 'dayjs';

import 'dayjs/locale/ru';
import { DATE_FORMAT } from '../../../../TrafficLightDetailed/TlDetectors/constants/constants';

import { IData } from './getAverage';
import { getDateRangeStr } from './getDateRangeStr';

export const getDatePeriod = (data: IData[]) => {
  const dateNow = dayjs();

  const defaultDateFrom = dateNow.subtract(-1, 'day').format(DATE_FORMAT);
  const defaultDateTo = dateNow.format(DATE_FORMAT);

  const datePeriod = getDateRangeStr(
    data.at(0)?.dateFrom ?? defaultDateFrom,
    data.at(-1)?.dateTo ?? defaultDateTo
  ).normalDate;

  return datePeriod;
};
