import { uniqueId } from 'lodash';

import { IVideoLIstItem } from '../../../../../../stores/videoWallPandelStore/videoWallPanelStore.model';

export const checkRemovedList = (removedList: IVideoLIstItem[]) => {
  const list = removedList.filter((item) => item.caption);

  return Boolean(list.length);
};

export const createNewList = (
  list: { id: string }[],
  currentLength: number,
  maxLenght: number
) => {
  for (let i = currentLength; i < maxLenght; i++) {
    const newItem = {
      id: uniqueId(),
    };

    list.push(newItem);
  }
};
