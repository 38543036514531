import { TABLE_CONFIG_SETTINGS } from '../../../../constants/tableConstants';
import {
  IAdminTableConfig,
  ITableConfigType,
} from '../../../../ts/models/table.model';

const getTableSize = (displayHeight: number) => {
  const { large, middle } = TABLE_CONFIG_SETTINGS.height;

  if (displayHeight > large) return 'large';

  return displayHeight < large && displayHeight > middle ? 'middle' : 'small';
};

const getPaginationCount = (displayHeight: number, type: ITableConfigType) => {
  const { large, middle } = TABLE_CONFIG_SETTINGS.height;
  const { large: typeL, middle: typeM } = TABLE_CONFIG_SETTINGS[type];

  return displayHeight < large && displayHeight > middle ? typeL : typeM;
};

const getPaginationOptions = (
  displayHeight: number,
  type: ITableConfigType,
  max: number
) => {
  const pageSize = getPaginationCount(displayHeight, type);
  const pageSizeOptions = [];

  for (let i = 1; i <= 3; i++) {
    const size = pageSize * i;

    if (size >= max) {
      pageSizeOptions.push(max);
      break;
    }

    pageSizeOptions.push(size);
  }
  const lastIndex = pageSizeOptions.length - 1;

  if (pageSizeOptions[lastIndex] !== max) pageSizeOptions.push(max);

  return pageSizeOptions;
};

export const getTableConfig = (type: ITableConfigType, max: number) => {
  const displayHeight = window.innerHeight;
  const config: IAdminTableConfig = {
    bordered: false,
    pagination: {
      showSizeChanger: true,
      position: ['bottomRight'],
      defaultPageSize: max ? max : 10,
      pageSizeOptions: getPaginationOptions(displayHeight, type, max),
    },
    scroll: {
      x: 1350,
    },
    size: getTableSize(displayHeight),
  };

  return config;
};
