import React from 'react';
import { BiBell } from 'react-icons/bi';
import { FaRoad } from 'react-icons/fa';

import { ReactComponent as Districts } from '../../../assets/icons/cartography_tabs/districts.svg';
import { ReactComponent as Parameters } from '../../../assets/icons/cartography_tabs/parameters.svg';
import { ReactComponent as Subsystems } from '../../../assets/icons/cartography_tabs/subsystems.svg';
import { AccessCode, СartographyTabsCode } from '../../../ts/enums/userData';
import AllSystemsTab from '../MainPanelTabs/AllSystemsTab/AllSystemsTab';
import IncidentsTab from '../MainPanelTabs/EventsTab/EventsTab';
import MapDistrictsTab from '../MainPanelTabs/MapDistrictsTab/MapDistrictsTab';
import ParamsTab from '../MainPanelTabs/ParamsTab/ParamsTab';
import RoadsTab from '../MainPanelTabs/RoadsTab/RoadsTab';

export enum СartographyTabName {
  Map = 'Карта',
  RoadNetwork = 'Дорожная сеть',
  Subsystems = 'Подсистемы',
  Params = 'Параметры',
  Events = 'События',
}

interface IPanelTab {
  label: JSX.Element;
  value: СartographyTabName;
  code: AccessCode;
}

export const PANEL_TABS_MAIN: IPanelTab[] = [
  {
    label: <Districts />,
    value: СartographyTabName.Map,
    code: СartographyTabsCode.MapTab,
  },
  {
    label: <FaRoad />,
    value: СartographyTabName.RoadNetwork,
    code: СartographyTabsCode.RoadTab,
  },
  {
    label: <Subsystems />,
    value: СartographyTabName.Subsystems,
    code: СartographyTabsCode.SubsystemsTab,
  },
  {
    label: <Parameters />,
    value: СartographyTabName.Params,
    code: СartographyTabsCode.ParamsTab,
  },
  {
    label: <BiBell />,
    value: СartographyTabName.Events,
    code: СartographyTabsCode.EventsTab,
  },
];

export const TABS = {
  [СartographyTabName.Map]: MapDistrictsTab,
  [СartographyTabName.RoadNetwork]: RoadsTab,
  [СartographyTabName.Subsystems]: AllSystemsTab,
  [СartographyTabName.Params]: ParamsTab,
  [СartographyTabName.Events]: IncidentsTab,
};
