import axios from 'axios';
import { toLonLat } from 'ol/proj';

import { CONFIG } from '../constants/configConstants';

const { REACT_APP_WEATHER_API_ID } = process.env,
  formatTemp = (temp: any) => (temp >= 0 ? `+${temp}°` : `${temp}°`);

const translateWeatherStatus = (key: any) => {
  switch (key) {
    case 'Clear':
      return 'ясно';
    case 'Clouds':
      return 'облачно';
    case 'Rain':
      return 'дождь';
    case 'Drizzle':
      return 'изморозь';
    case 'Snow':
      return 'снег';
    case 'Thunderstorm':
      return 'гроза';
    case 'Smoke':
      return 'смог';
    case 'Haze':
      return 'дымка';
    case 'Mist':
      return 'туман';
    case 'Fog':
      return 'туман';
    case 'Squall':
      return 'шторм';
    case 'Tornado':
      return 'ураган';
    default:
      return key;
  }
};

export const getWeather = async (mapCenter: any) => {
  const url = `${CONFIG.WTHR}/data/2.5/weather`,
    center = toLonLat(mapCenter),
    params = {
      lon: center[0],
      lat: center[1],
      units: 'metric',
      appid: REACT_APP_WEATHER_API_ID,
    },
    { data } = await axios.get(url, { params });

  return {
    temp: formatTemp(Math.round(data.main?.temp)),
    weather: translateWeatherStatus(data.weather[0]?.main),
  };
};
