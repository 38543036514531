export enum PanelType {
  Cartography = 'cartography',
  Statistics = 'statistics',
  Reports = 'reports',
  Constructor = 'constructor',
  Modules = 'modules',
  Admins = 'admins',
  Profile = 'profile',
  VideoWall = 'videoWall',
  Testing = 'testing',
}

export enum System {
  Lights = 'светофорные объекты',
  Detectors = 'детекторы транспорта',
  Cameras = 'камеры',
  SpecialTransport = 'специальная техника',
  PublicTransport = 'общественный транспорт',
  Meteo = 'метеостанции',
  Clusters = 'кластеры',
  Streams = 'загруженность участков',
}

export enum Events {
  RTA = 'ДТП',
  ROAD_WORKS = 'дорожные работы',
  ACTIVITIES = 'мероприятия',
  TRAFFIC_RESTRICTIONS = 'ограничения движения',
}

export enum MapObject {
  tl = 'tl',
  cm = 'cm',
  dt = 'dt',
}

export enum MapObjectFull {
  trafficLights = 'trafficLights',
  cameras = 'cameras',
  detectors = 'detectors',
}

export enum ImagePostfix {
  Off = 'off',
  AllRed = 'ar',
  AllYellow = 'yf',
}

export enum ErrorText {
  SomethingWentWrong = 'Что-то пошло не так...',
  NoCrossroadsImg = 'Изображение перекрестка временно отсутствует',
}

export enum ChanelTypes {
  CommandsLogsNew = 'commands_logs_new',
  TlCurrentTacts = 'tlCurrentTacts',
}
