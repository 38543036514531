/* eslint-disable @typescript-eslint/no-unused-vars */
const defaultInstance = 'njs';
// line need for bind different api instances
const envInstanse = process.env.REACT_APP_API_INSTANCE_NAME;

const API = `/${envInstanse ?? defaultInstance}/api`;

const location = window.location;
const hostUrl = location.toString().slice(0, -1);
const isSecure = location.protocol === 'https:';
const wsProtocol = isSecure ? 'wss' : 'ws';

interface IConfig {
  OSM_SOURCE: U<object>;
  getCameraName: (...args: any) => string;
  VERSION: number;
  EPUTS_URL: string;
  WEB_SOCKET_URL: string;
  ANT_MEDIA: string;
  WTHR: string;
}

const BASIC: IConfig = {
  OSM_SOURCE: undefined,
  getCameraName: (camera: any, id: any) => camera?.srcId,
  VERSION: 1,
  EPUTS_URL: hostUrl + API,
  WEB_SOCKET_URL: `${wsProtocol}://${location.host}/${
    envInstanse ?? defaultInstance
  }/ws`,
  ANT_MEDIA: '',
  WTHR: 'https://api.openweathermap.org',
};

const DEV: IConfig = {
  ...BASIC,
  EPUTS_URL: `https://dev.eputs.org${API}`,
  WEB_SOCKET_URL: 'wss://dev.eputs.org/njs/ws',
  ANT_MEDIA: 'https://ant.eputs.org/',
};

const LOCAL: IConfig = {
  ...BASIC,
  EPUTS_URL: '/api',
  WEB_SOCKET_URL: 'ws://localhost:5000/ws',
};

const BUILD: IConfig = {
  ...BASIC,
  ANT_MEDIA: 'https://rpo.ant.eputs.org/',
};

const PROD: IConfig = {
  ...BASIC,
  OSM_SOURCE: {
    url: `${hostUrl + API}/v1/tiles/{z}/{x}/{y}`,
  },
};

const KHABAROVSK: IConfig = {
  ...PROD,
  ANT_MEDIA: 'http://192.168.20.32/',
};

const CONFIG_NAMES = {
  DEV,
  LOCAL,
  BUILD,
  KHABAROVSK,
} as const;

type TConfigNames = keyof typeof CONFIG_NAMES;

const configName = process.env.REACT_APP_CONFIG_NAME as TConfigNames;

if (!CONFIG_NAMES[configName]) {
  const cfgError = {
    message: `Ошибка обработки конфига, название конфига из .env '${configName}' не соответствует допустимым значениям`,
    REACT_APP_CONFIG_NAME: configName,
    existConfigsNames: Object.keys(CONFIG_NAMES),
  };

  console.error('Ошибка обработки конфига', JSON.stringify(cfgError, null, 2));
}

export const CONFIG: IConfig = CONFIG_NAMES[configName] ?? CONFIG_NAMES.DEV;
