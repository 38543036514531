import { Map } from 'ol';
import { getCenter } from 'ol/extent';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import BaseLayer from 'ol/layer/Base';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';

export const getFeaturesExtent = (map: Map) => {
  const currentExtent = map.getView().calculateExtent();

  const clusterLayer = map
    .getLayers()
    .getArray()
    .find((layer: BaseLayer) =>
      layer instanceof AnimatedCluster ? layer : null
    ) as U<AnimatedCluster>;

  if (!clusterLayer) return;

  const extentFeatures = clusterLayer
    ?.getSource()
    ?.getFeaturesInExtent(currentExtent);

  if (!extentFeatures) return;

  const featuresCl: Feature<Point>[][] = extentFeatures.map((item: Feature) =>
    item.get('features')
  );

  const cordCenter = getCenter(currentExtent);

  return { featuresCl, cordCenter };
};
