import {
  IQueryParamItem,
  ValueTypes,
} from './../../../../../../ts/models/table.model';

const ERROR_TEXT = 'Неверный формат полученных данных';

export const getServerSearchValues = (queryParams: IQueryParamItem[]) => {
  const values: { [key: string]: IQueryParamItem } = {};

  queryParams.forEach((param) => {
    if (param.currentValue) values[param.webElementCapture] = param;
  });

  return values;
};

const isArray = (value: unknown): value is [] => {
  return Array.isArray(value);
};

export const isDateValues = (
  jvalue: string,
  value: any
): value is { from: string; to: string } => {
  const isDate =
    jvalue === ValueTypes.dateTime && value.from !== null && value.to !== null;

  return isDate;
};

export const parseFilterValue = (param: IQueryParamItem) => {
  const value = param.currentValue;

  switch (param.jvalueType) {
    case ValueTypes.string:
      return value as string;
    case ValueTypes.number:
      return value as number;
    case ValueTypes.dateTime:
      return (
        isDateValues(param.jvalueType, value) &&
        `C ${value.from} по ${value.to}`
      );

    default:
      return (isArray(value) && value.join(' ,')) || ERROR_TEXT;
  }
};
