import { Button, InputNumber, Space, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import { IVideoLIstItem } from '../../../../../../stores/videoWallPandelStore/videoWallPanelStore.model';
import { LAYOUT_VARIABLES } from '../../constats';

import { createNewList } from './helpers';

import styles from './LayoutVariables.module.scss';

const TOOLTIP_TITLE = 'Раскладка создается квадратом размером X на X камер';
const BUTTON_TEXT = 'Добавить раскладку';
const ADD_BTN_TEXT = ['Добавить', 'X'];
const INPUT_PLCH = 'Введите число';
const BTNS_TYPE = 'primary';
const DEF_TYPE = 'default';

const LayoutVariables = () => {
  const { setVideoKeysValues, layoutValue, videoList, isVideoListEdit } =
    rootStore.videoWallPanelStore;
  const [variables, setVariables] = useState(LAYOUT_VARIABLES);
  const [isAdd, setIsAdd] = useState(false);
  const [value, setValue] = useState<string | number | null>();

  const handleVariablesClick = (value: number) => {
    setVideoKeysValues({ layoutValue: value });

    const newList: IVideoLIstItem[] = [...videoList];
    const maxLenght = value ** 2;
    const currentLength = newList.length;

    if (currentLength < maxLenght && isVideoListEdit) {
      const newEmptyArray: { id: string }[] = [];

      createNewList(newEmptyArray, currentLength, maxLenght);

      setVideoKeysValues({ videoList: [...newList, ...newEmptyArray] });

      return;
    }
  };

  const handleIsAdd = () => {
    setIsAdd(!isAdd);
  };

  const handleAdd = () => {
    const newVariable = {
      label: `${value} x ${value}`,
      value: value,
    };

    let isIncludes = false;

    variables.forEach((variable) => {
      if (variable.value === newVariable.value) isIncludes = true;
    });

    const newVariables = [...variables, newVariable].sort((a, b) => {
      if (a.value && b.value) {
        return Number(a.value) - Number(b.value);
      }

      return -1;
    });

    if (!isIncludes) {
      setVariables(newVariables);
    }

    setIsAdd(false);
    setValue(null);
  };

  return (
    <div className={styles.wrapper}>
      {isAdd ? (
        <Space align="center">
          <InputNumber
            min={1}
            max={10}
            value={value}
            onChange={setValue}
            placeholder={INPUT_PLCH}
          />
          <Button
            type={BTNS_TYPE}
            onClick={handleAdd}
            disabled={Boolean(!value)}
          >
            {ADD_BTN_TEXT[0]}
          </Button>
          <Button type={BTNS_TYPE} danger onClick={handleIsAdd}>
            {ADD_BTN_TEXT[1]}
          </Button>
        </Space>
      ) : (
        <Tooltip placement="right" title={TOOLTIP_TITLE}>
          <Button onClick={handleIsAdd}>{BUTTON_TEXT}</Button>
        </Tooltip>
      )}
      <ul>
        {variables.map((variable, i) => (
          <li key={i}>
            <Button
              type={layoutValue === variable.value ? BTNS_TYPE : DEF_TYPE}
              onClick={() => handleVariablesClick(Number(variable.value))}
            >
              {variable.label}
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default observer(LayoutVariables);
