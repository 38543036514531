import classNames from 'classnames';
import { FC, useEffect, useState, useRef } from 'react';

import { IUserData } from '../../../../stores/userDataStore/userDataStore';
import { getInfoData } from '../../helpers/getInfoData';
import GroupsBox from '../GroupsBox/GroupsBox';

import styles from './InfoBlock.module.scss';

interface IInfoBlock {
  user: IUserData;
  isContent: boolean;
  hiddenContent: U<boolean>;
}

const InfoBlock: FC<IInfoBlock> = ({ user, isContent, hiddenContent }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(isContent ? '100%' : '0');

  useEffect(() => {
    if (contentRef.current && hiddenContent) {
      const newHieght = contentRef.current?.scrollHeight;
      const curentHieght = isContent ? newHieght + 40 + 'px' : '0';

      setHeight(curentHieght);
    }
  }, [isContent, hiddenContent]);

  const contentStyles = classNames({
    [styles.content]: true,
    [styles.isOpen]: isContent,
  });

  const infoData = getInfoData(user);

  return (
    <div className={contentStyles} ref={contentRef} style={{ height }}>
      {infoData.map(({ key, value }, i) => (
        <p className={styles.row} key={i}>
          <span>{key}</span>
          {value}
        </p>
      ))}
      <GroupsBox userGroups={user.groups} />
    </div>
  );
};

export default InfoBlock;
