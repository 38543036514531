import { notification } from 'antd';
import { FormInstance } from 'antd/lib';
import { useMemo, useState } from 'react';

import adminApi from '../../../../../api/adminObjects/admin';
import rootStore from '../../../../../stores/rootStore/rootStore';

import { ILoadState } from './useAbout';

interface IUseMenuHandlersProps {
  form: FormInstance;
  getSubSystems: (id: number) => Promise<void>;
  setLoadState: SetState<ILoadState>;
  loadState: ILoadState;
  getSystemTypes: (id: number) => Promise<void>;
  appPath: string;
}

export const useMenuHandlers = ({
  form,
  getSubSystems,
  setLoadState,
  loadState,
  getSystemTypes,
  appPath,
}: IUseMenuHandlersProps) => {
  const { setAdminsData } = rootStore.adminPanelStore;
  const [isTextArea, setIsTextArea] = useState(false);
  const [isInput, setIsInput] = useState(false);

  const handleChangeRegion = (value: number) => {
    if (!loadState.regions) {
      getSubSystems(value);
    } else {
      setLoadState({
        ...loadState,
        subsystems: null,
        systemtypes: null,
      });

      form.setFieldValue('subsystems', []);
      form.setFieldValue('systemtypes', []);
      form.setFieldValue('name', '');
      form.setFieldValue('dsc', '');

      setIsInput(false);
      setIsTextArea(false);

      getSubSystems(value);
    }
  };

  const handleChangeSubSystem = (value: number) => {
    if (!loadState.subsystems) {
      getSystemTypes(value);
    } else {
      form.setFieldValue('systemtypes', []);
      form.setFieldValue('name', '');
      form.setFieldValue('dsc', '');

      setIsInput(false);
      setIsTextArea(false);

      getSystemTypes(value);
    }
  };

  const handleChangeSystemsTypes = () => {
    setIsInput(true);
  };

  const handleInputChange = useMemo(() => {
    return (e: any) => {
      if (e.target.value.length >= 1) {
        setIsTextArea(true);
      } else {
        setIsTextArea(false);
      }
    };
  }, []);

  const handleResetForm = () => {
    form.resetFields();

    setLoadState({
      ...loadState,
      subsystems: null,
      systemtypes: null,
    });

    setIsInput(false);
    setIsTextArea(false);
  };

  const handleSubmitForm = async () => {
    const values = form.getFieldsValue();

    const newValues = {
      regionId: Number(values.region),
      subststemId: Number(values.subsystems),
      systemTypeId: Number(values.systemtypes),
      name: values.name,
      dsc: values.dsc,
    };

    const res = await adminApi.systems.systemsCrud.add(newValues, appPath);

    if (!res) return;

    notification.success({
      message: 'Успешно',
      description: res.data.message,
    });

    setAdminsData('isModalOpen', false);
    setAdminsData('isAddSystem', true);
  };

  return {
    handleChangeRegion,
    handleChangeSubSystem,
    handleChangeSystemsTypes,
    handleInputChange,
    handleResetForm,
    handleSubmitForm,
    isTextArea,
    isInput,
  };
};
