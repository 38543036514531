import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FC, useState, useEffect } from 'react';

import { findById } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import CameraIcon from '../Camera/CameraIcon/CameraIcon';
import CameraProjection from '../Camera/CameraProjection/CameraProjection';
import { CamerasProps, LinkedCamera } from '../models/camera.model';

const Cameras: FC<CamerasProps> = ({ rate, linkedCams }) => {
  const { cameras, setMapData } = rootStore.mapDataStore;

  const [cams, setCams] = useState<LinkedCamera[]>([]);

  useEffect(() => {
    const newCams: LinkedCamera[] = linkedCams.map((id: number) => {
      const camera: LinkedCamera = toJS(findById(cameras, id));

      camera.isExtended = false;

      return camera;
    });

    setCams(newCams);

    return () => {
      setMapData('selectedCamera', null);
    };
  }, [linkedCams, cameras, setMapData]);

  const handleCameraClick = (id: number) => {
    const camera = findById(cams, id);

    const newCams = cams.map((cam) => {
      if (cam.id !== id) {
        cam.isExtended = false;
      } else {
        camera.isExtended = !camera.isExtended;
        setMapData('selectedCamera', camera.isExtended ? camera : null);
      }

      return cam;
    });

    const sortedCams = newCams.sort((a) => (a.isExtended ? -1 : 1));

    setCams(sortedCams);
  };

  return (
    <>
      {cams.map((camera) => {
        const { finalFromProfiles } = camera;

        if (!finalFromProfiles) return null;

        return (
          <CameraProjection
            key={camera.id}
            camera={finalFromProfiles}
            rate={rate}
            onClick={() => handleCameraClick(camera.id)}
            isExtended={camera.isExtended}
          />
        );
      })}

      {cams.map((camera) => {
        const { finalFromProfiles } = camera;

        if (!finalFromProfiles) return null;

        return (
          <CameraIcon
            key={`camIco_${camera.id}`}
            camera={finalFromProfiles}
            rate={rate}
            onClick={() => handleCameraClick(camera.id)}
          />
        );
      })}
    </>
  );
};

export default observer(Cameras);
