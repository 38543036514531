import { System } from '../../../ts/enums/enums';

export const generateWgSet = (data: any) => [
  {
    title: System.Lights,
    total: data?.widgetTLs?.total,
    ratio: data?.widgetTLs?.ratio,
    sign: 'светофоров',
    amounts: [
      data?.widgetTLs?.working,
      data?.widgetTLs?.errors,
      data?.widgetTLs?.disactive,
    ],
  },
  {
    title: System.Cameras,
    total: data?.widgetCMRs?.total,
    ratio: data?.widgetCMRs?.ratio,
    sign: 'камер',
    amounts: [
      data?.widgetCMRs?.working,
      data?.widgetCMRs?.errors,
      data?.widgetCMRs?.disactive,
    ],
  },
  {
    title: System.Detectors,
    total: data?.widgetDTs?.total,
    ratio: data?.widgetDTs?.ratio,
    sign: 'детекторов',
    amounts: [
      data?.widgetDTs?.working,
      data?.widgetDTs?.errors,
      data?.widgetDTs?.disactive,
    ],
  },
  {
    title: 'ДИТ',
    total: 0,
    sign: 'табло',
    amounts: [0, 0, 0],
  },
  {
    title: 'ЗПИ',
    total: 0,
    sign: 'знаков',
    amounts: [0, 0, 0],
  },
  {
    title: 'камеры (КФВФ)',
    total: 0,
    sign: 'камер',
    amounts: [0, 0, 0],
  },
  {
    title: 'АДМС',
    total: 0,
    sign: 'станций',
    amounts: [0, 0, 0],
  },
];
