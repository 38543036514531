import useSwitcher from './useSwitcher';

const useSwitchers = (data: any) => {
  const [isEnabled0] = useSwitcher(
      data && Boolean(data[0]),
      data && data[0]?.interval
    ),
    [isEnabled1] = useSwitcher(
      data && Boolean(data[1]),
      data && data[1]?.interval
    ),
    [isEnabled2] = useSwitcher(
      data && Boolean(data[2]),
      data && data[2]?.interval
    ),
    [isEnabled3] = useSwitcher(
      data && Boolean(data[3]),
      data && data[3]?.interval
    ),
    [isEnabled4] = useSwitcher(
      data && Boolean(data[4]),
      data && data[4]?.interval
    ),
    [isEnabled5] = useSwitcher(
      data && Boolean(data[5]),
      data && data[5]?.interval
    ),
    [isEnabled6] = useSwitcher(
      data && Boolean(data[6]),
      data && data[6]?.interval
    ),
    [isEnabled7] = useSwitcher(
      data && Boolean(data[7]),
      data && data[7]?.interval
    ),
    [isEnabled8] = useSwitcher(
      data && Boolean(data[8]),
      data && data[7]?.interval
    ),
    [isEnabled9] = useSwitcher(
      data && Boolean(data[9]),
      data && data[7]?.interval
    ),
    [isEnabled10] = useSwitcher(
      data && Boolean(data[10]),
      data && data[7]?.interval
    ),
    [isEnabled11] = useSwitcher(
      data && Boolean(data[11]),
      data && data[7]?.interval
    );

  return [
    [
      isEnabled0,
      isEnabled1,
      isEnabled2,
      isEnabled3,
      isEnabled4,
      isEnabled5,
      isEnabled6,
      isEnabled7,
      isEnabled8,
      isEnabled9,
      isEnabled10,
      isEnabled11,
    ],
  ];
};

export default useSwitchers;
