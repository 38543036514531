import { SYSTEM } from '../../../../../constants/constants';
import { System } from '../../../../../ts/enums/enums';

const { LIGHTS } = SYSTEM;

export const getDeviceTitle = (
  currentObject: any,
  system: System,
  id: number,
  notSlice?: boolean
) => {
  const maxLength = 35;

  const idText =
    currentObject.caption ?? currentObject.featureOGS?.properties?.highway;

  const eputsIdText =
    system === LIGHTS ? currentObject.extId + ' (eputsId: ' + id + ')' : id;

  const title = idText + ' - id:' + eputsIdText;

  return title.length > maxLength && !notSlice
    ? title.slice(0, maxLength) + '...'
    : title;
};
