import { notification } from 'antd';

import { TABLE_CSV_SEPARATOR } from '../../../constants/constants';
import downloadFile from '../../../helpers/downloadFile';
import getNormalizeDateTime from '../../../helpers/getNormalizeDateTime';
import rootStore from '../../../stores/rootStore/rootStore';

const useExport = () => {
  const { moduleData } = rootStore.uiStore;

  const exportData = () => {
    if (!moduleData) return;

    const { data, headerItem } = moduleData;

    let headerRow = '';
    let result = '';

    if (data.lenght === 0) {
      notification.error({
        message: 'Ошибка экспорта',
        description: 'Нельзя экспортировать пустую таблицу',
      });

      return;
    }

    //build header row
    for (const el of headerItem) {
      if (el.referens === 'id') {
        headerRow += el.referens;
        continue;
      }

      if (el.importFieldName) {
        headerRow += TABLE_CSV_SEPARATOR + el.importFieldName;
      }
    }

    result += headerRow;

    // Build rows to strings
    data.forEach((dataItem: any) => {
      let dataItemStr = '';

      // Build row to string
      for (const fieldName in dataItem) {
        const culomnItem = headerItem.find(
          (item: any) => fieldName === item.referens
        );

        if (!culomnItem && culomnItem.importFieldName === '') {
          continue;
        }

        dataItemStr += TABLE_CSV_SEPARATOR + dataItem[fieldName].toString();
      }

      result += `\n${dataItemStr}`;
    });

    notification.success({
      message: 'Экспорт выполнен',
      description: 'Данные из таблицы успешно экспортированны в файл',
    });

    downloadFile(result, `${getNormalizeDateTime()} ${moduleData.title}.csv`);
  };

  return { exportData };
};

export default useExport;
