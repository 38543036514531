import { ReactComponent as ArrowBottom } from '../../../assets/icons/arrows/arr_bottom.svg';
import { ReactComponent as ArrowDirectLeft } from '../../../assets/icons/arrows/arr_direct-left.svg';
import { ReactComponent as ArrowDirectRight } from '../../../assets/icons/arrows/arr_direct-right.svg';
import { ReactComponent as ArrowDirect } from '../../../assets/icons/arrows/arr_direct.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrows/arr_left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrows/arr_right.svg';
import { ReactComponent as ArrowTop } from '../../../assets/icons/arrows/arr_top.svg';
import { ReactComponent as Crosswalk } from '../../../assets/icons/arrows/crosswalk_custom.svg';
import { CircleElEnum } from '../../../ts/enums/tl';

const { Gathered, Top, Bottom, Direct, Right, Left, DirectRight, DirectLeft } =
  CircleElEnum;

const getSvg = (type: string, style: React.CSSProperties) => {
  switch (type) {
    case Gathered:
      return <ArrowTop style={style} />;
    case Top:
      return <ArrowTop style={style} />;
    case Bottom:
      return <ArrowBottom style={style} />;
    case Direct:
      return <ArrowDirect style={style} />;
    case Right:
      return <ArrowRight style={style} />;
    case Left:
      return <ArrowLeft style={style} />;
    case DirectLeft:
      return <ArrowDirectLeft style={style} />;
    case DirectRight:
      return <ArrowDirectRight style={style} />;
    case CircleElEnum.Crosswalk:
      return <Crosswalk style={style} />;
    default:
      return;
  }
};

export default getSvg;
