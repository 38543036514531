import { TL_DETAILED } from '../../../../constants/tlDetailedConstants';
import { TlMode } from '../../../../ts/models/tl.model';

export interface ITip {
  btn: string;
  info: IInfo;
}

export interface IInfo {
  workSgnProgram: string;
  accessCmd: ValueOf<typeof TL_MANAGEMENT_TABS>[];
  durationOn: N<number>;
}

export interface ICommandsContent {
  id: TlMode;
  title: string;
  body: string;
  code: number;
  tip: ITip;
  disabled: boolean;
  canSelected: boolean;
}

export const ERROR_TEXT_SGN_PROGRAMS =
  'Недостаточно данных для отображения сигнальной программы';

export const STATES_ARR = Object.values(TL_DETAILED.STATE).map(
  ({ com }) => com
);
export const SOURCE_ARR = Object.values(TL_DETAILED.SOURCE).map(
  ({ com }) => com
);

export const MODES_INFO = TL_DETAILED.MODE;

export const MODES_ARR = Object.values(MODES_INFO).map(({ com }) => com);

const { Off, YellowFlash, AllRed } = MODES_INFO;

export const SPECIAL_MODES = [Off.com, YellowFlash.com, AllRed.com];

export const NOT_WORK_STATES = Object.values(TL_DETAILED.STATE).reduce(
  (acc: number[], { com }) => {
    com !== TL_DETAILED.STATE.Work.com &&
      com !== TL_DETAILED.STATE.Undefined.com &&
      acc.push(com);

    return acc;
  },
  []
);

export const TL_MANAGEMENT_TABS = {
  MANAGEMENT: 'КОМАНДЫ УПРАВЛЕНИЯ',
  PHASE_HOLD: 'УДЕРЖАНИЕ ФАЗ',
  PLAN_SELECTION: 'ВЫБОР ПЛАНА',
  COORDINATED_MANAGEMENT: 'КООРДИНИРОВАННОЕ УПРАВЛЕНИЕ',
  PHASE_TIME_CHANGE_CURRENT_PLAN_MODE: 'ИЗМЕНЕНИЕ ВРЕМЕНИ ФАЗ ТЕКУЩЕГО ПЛАНА',
} as const;

const {
  PHASE_HOLD,
  PLAN_SELECTION,
  COORDINATED_MANAGEMENT,
  PHASE_TIME_CHANGE_CURRENT_PLAN_MODE,
} = TL_MANAGEMENT_TABS;

const IMPOSSIBLE_SELECTED = 'Нельзя установить удаленно из АСУДД';
const UNWORK_SGN_PROGRAM =
  'СО перестает работать по текущей сигнальной программе';
const WORK_SGN_PROGRAM = 'СО работает по текущей сигнальной программе';

export const COMMANDS_CONTENT: ICommandsContent[] = [
  {
    id: MODES_INFO.Off.com,
    title: 'Выключить ДК',
    body: MODES_INFO.Off.dsc,
    code: 123163781263,
    tip: {
      btn: 'Выключить ДК?',
      info: {
        workSgnProgram: 'СО перестает работать',
        accessCmd: [],
        durationOn: null,
      },
    },
    disabled: false,
    canSelected: true,
  },
  {
    id: MODES_INFO.YellowFlash.com,
    title: 'Включить ЖМ',
    body: MODES_INFO.YellowFlash.dsc,
    code: 23648273648,
    tip: {
      btn: 'Включить ЖМ?',
      info: {
        workSgnProgram: UNWORK_SGN_PROGRAM,
        accessCmd: [],
        durationOn: null,
      },
    },
    disabled: false,
    canSelected: true,
  },
  {
    id: MODES_INFO.AllRed.com,
    title: 'Включить всем красный',
    body: MODES_INFO.AllRed.dsc,
    code: 2937642379864,
    tip: {
      btn: 'Включить всем красный?',
      info: {
        workSgnProgram: UNWORK_SGN_PROGRAM,
        accessCmd: [],
        durationOn: null,
      },
    },
    disabled: false,
    canSelected: true,
  },
  {
    id: MODES_INFO.Work.com,
    title: 'Включить рабочий штатный режим',
    body: MODES_INFO.Work.dsc,
    code: 123163781263,
    tip: {
      btn: 'Включить рабочий штатный режим?',
      info: {
        workSgnProgram: WORK_SGN_PROGRAM,
        accessCmd: [
          PLAN_SELECTION,
          COORDINATED_MANAGEMENT,
          PHASE_TIME_CHANGE_CURRENT_PLAN_MODE,
        ],
        durationOn: null,
      },
    },
    disabled: false,
    canSelected: true,
  },
  {
    id: MODES_INFO.RemoteControl.com,
    title: 'Включить удаленное управление из АСУДД',
    body: MODES_INFO.RemoteControl.dsc,
    code: 2937642379864,
    tip: {
      btn: 'Включить удаленное управление из АСУДД?',
      info: {
        workSgnProgram: WORK_SGN_PROGRAM,
        accessCmd: [PHASE_HOLD],
        durationOn: 15,
      },
    },
    disabled: false,
    canSelected: true,
  },
  {
    id: MODES_INFO.Critical.com,
    title: 'Включить критическую неисправность',
    body: MODES_INFO.Critical.dsc,
    code: 123123,
    tip: {
      btn: IMPOSSIBLE_SELECTED,
      info: {
        workSgnProgram: UNWORK_SGN_PROGRAM,
        accessCmd: [],
        durationOn: null,
      },
    },
    disabled: true,
    canSelected: true,
  },
  {
    id: MODES_INFO.ManualControlVPU.com,
    title: 'Включить ручное управление с ВПУ',
    body: MODES_INFO.ManualControlVPU.dsc,
    code: 123123,
    tip: {
      btn: IMPOSSIBLE_SELECTED,
      info: {
        workSgnProgram: UNWORK_SGN_PROGRAM,
        accessCmd: [],
        durationOn: null,
      },
    },
    disabled: true,
    canSelected: true,
  },
  {
    id: MODES_INFO.TechnologicalMode.com,
    title: 'Включить технологический режим',
    body: MODES_INFO.TechnologicalMode.dsc,
    code: 123123,
    tip: {
      btn: IMPOSSIBLE_SELECTED,
      info: {
        workSgnProgram: UNWORK_SGN_PROGRAM,
        accessCmd: [],
        durationOn: null,
      },
    },
    disabled: true,
    canSelected: true,
  },
  {
    id: MODES_INFO.AdaptiveControl.com,
    title:
      'Работа по заданной программе пользователем из АРМ «Мониторинг и управление дорожным хозяйством» (не по расписанию)',
    body: MODES_INFO.AdaptiveControl.dsc,
    code: 123123,
    tip: {
      btn: IMPOSSIBLE_SELECTED,
      info: {
        workSgnProgram: UNWORK_SGN_PROGRAM,
        accessCmd: [],
        durationOn: null,
      },
    },
    disabled: true,
    canSelected: false,
  },
  {
    id: MODES_INFO.CoordinatedManagement.com,
    title: 'Включить координированное управление',
    body: MODES_INFO.CoordinatedManagement.dsc,
    code: 123123,
    tip: {
      btn: IMPOSSIBLE_SELECTED,
      info: {
        workSgnProgram: UNWORK_SGN_PROGRAM,
        accessCmd: [],
        durationOn: null,
      },
    },
    disabled: true,
    canSelected: true,
  },
  {
    id: MODES_INFO.LocalAdaptiveControl.com,
    title: 'Включить локальное адаптивное управление',
    body: MODES_INFO.LocalAdaptiveControl.dsc,
    code: 123123,
    tip: {
      btn: IMPOSSIBLE_SELECTED,
      info: {
        workSgnProgram: UNWORK_SGN_PROGRAM,
        accessCmd: [],
        durationOn: null,
      },
    },
    disabled: true,
    canSelected: true,
  },
];

export const REMOTE_CONTROL_NAME = {
  PHASE_HOLD_REMOTE: [MODES_INFO.RemoteControl.dsc],
  PLAN_SELECT_REMOTE: [MODES_INFO.Work.dsc, MODES_INFO.AdaptiveControl.dsc],
};

export enum COMMANDS {
  SET_OBJECT_MODE = 'SET_OBJECT_MODE',
  SET_OBJECT_PHASE_NOW = 'SET_OBJECT_PHASE_NOW',
  SET_OBJECT_PHASE = 'SET_OBJECT_PHASE',
  SET_OBJECT_PLAN = 'SET_OBJECT_PLAN',
  SET_OBJECT_PLAN_TIME_ON_SCHEDULER = 'SET_OBJECT_PLAN_TIME_ON_SCHEDULER',
  SET_OBJECT_SYNC_PLAN = 'SET_OBJECT_SYNC_PLAN',
  SET_OBJECT_PHASE_FLOW = 'SET_OBJECT_PHASE_FLOW',
}

export const COMMANDS_VALUES = {
  SET_OBJECT_MODE: 'управление СО',
  SET_OBJECT_PHASE_NOW: 'удержание фазы',
  SET_OBJECT_PHASE: 'удержание фазы по времени',
  SET_OBJECT_PLAN: 'выбор плана',
  SET_OBJECT_PLAN_TIME_ON_SCHEDULER: 'выбор плана по времени',
  SET_OBJECT_SYNC_PLAN: 'координированное управление',
  SET_OBJECT_PHASE_FLOW: 'изменение времени фаз текущего плана',
};

export enum TlManagmentKeys {
  Managment = '1',
  PhaseHold = '2',
  PlanSelection = '3',
  CoordinatedManagment = '4',
  PhaseTimeChangeCurrentPlanMode = '5',
}

export const DEFAULT_DISABLED_TIME_DURATION = 5;
export const MINUTE_SECONDS = 60;
