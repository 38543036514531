import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { TL } from '../../../ts/models/tl.model';
import PhaseCircleOverlay from '../../Overlays/PhaseCircleOverlay/PhaseCircleOverlay';

import styles from './PhaseCircleOverlays.module.scss';

interface AdjacentPhaseCircleOverlaysProps {
  adjacentPhaseCircleRefs: any;
  adjacentTLs: N<TL[]>;
}

const AdjacentPhaseCircleOverlays: FC<AdjacentPhaseCircleOverlaysProps> = ({
  adjacentPhaseCircleRefs,
  adjacentTLs,
}) => {
  const { isAdjacentPhaseCircles, isPhaseCircle } = rootStore.uiStore;
  const isAdjacent =
    isAdjacentPhaseCircles && isPhaseCircle && Boolean(adjacentTLs);

  return (
    <>
      {adjacentPhaseCircleRefs.map((el: any, i: number) => {
        const adjacentTrafficLight = adjacentTLs?.find(
          (tl) => tl.id === Number(el.current?.id ?? -1)
        );

        return (
          <div className={styles.popupContainer} key={`adjacent${i}`} ref={el}>
            {isAdjacent && adjacentTrafficLight ? (
              <PhaseCircleOverlay selectedTL={adjacentTrafficLight} />
            ) : null}
          </div>
        );
      })}
    </>
  );
};

export default observer(AdjacentPhaseCircleOverlays);
