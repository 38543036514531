import { z } from 'zod';

import { NNInt } from '../../zod/utils.zod';
import { BasicMapDataSchema, LinkedDevicesSchema } from '../mapObjects.zod';

export const FinalFromProfilesSchema = z.object({
  angle: NNInt,
  offsetX: z.number(),
  offsetY: z.number(),
  size: NNInt,
  color: z.string(),
  distance: NNInt,
  widthRate: z.number(),
  arcHeight: NNInt,
  preDistanceRate: z.number(),
  opacity: z.number(),
});

export const CameraSchema = BasicMapDataSchema.extend({
  // eslint-disable-next-line camelcase
  ant_url: z.string().url(),
  regionId: z.number(),
  linkedDevices: LinkedDevicesSchema,
  srcId: z.string(),
  finalFromProfiles: FinalFromProfilesSchema.nullable().optional(),
});
