import { fromLonLat } from 'ol/proj';

type TExtentTuple = [number, number, number, number];

export const normalizeExtent = (extent: TExtentTuple) => {
  return [
    ...fromLonLat([extent[0], extent[1]]),
    ...fromLonLat([extent[2], extent[3]]),
  ];
};
