export const getSendErrorBody = (title: string, error: unknown) => {
  const body = {
    error: {
      title,
      error,
    },
  };

  return body;
};
