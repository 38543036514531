import { useEffect, useRef, useState } from 'react';

const useCounter = (threshold: any, time: any) => {
  const [сounter, setСounter] = useState(0),
    intervalRef = useRef<any>(null);

  useEffect(() => {
    setСounter(0);
  }, [threshold]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setСounter((prevCounter) => prevCounter + 1);
    }, time / threshold);

    return () => clearInterval(intervalRef.current);
  }, [сounter, threshold, time]);

  useEffect(() => {
    if (сounter >= threshold) {
      clearInterval(intervalRef.current);
    }
  }, [сounter, intervalRef, threshold]);

  return [сounter];
};

export default useCounter;
