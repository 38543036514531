import { Spin } from 'antd';
import { FC, useState } from 'react';

import useUserInfo from './hooks/useUserInfo';
import Header from './ui/Header/Header';
import InfoBlock from './ui/InfoBlock/InfoBlock';

import styles from './UserCard.module.scss';

interface UserCardProps {
  userId: number;
  hiddenContent?: boolean;
}

const UserCard: FC<UserCardProps> = ({ userId, hiddenContent }) => {
  const { user, isLoading, error } = useUserInfo(userId);
  const [isContent, setIsContent] = useState(!hiddenContent);

  if (error) {
    return <div className={styles.wrapper}>{error}</div>;
  }

  if (isLoading || !user)
    return (
      <div className={styles.wrapper}>
        <Spin size="large" />
      </div>
    );

  const handleAvatarClick = () => {
    setIsContent(!isContent);
  };

  const infoText = `Нажмите на аватар для ${
    !isContent ? 'просмотра' : 'скрытия'
  } полной
  информации`;

  return (
    <div className={styles.main}>
      <Header
        user={user}
        handleAvatarClick={() => hiddenContent && handleAvatarClick()}
      />
      {hiddenContent && <p className={styles.info}>{infoText}</p>}
      <InfoBlock
        user={user}
        isContent={isContent}
        hiddenContent={hiddenContent}
      />
    </div>
  );
};

export default UserCard;
