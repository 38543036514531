import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import rootStore from '../../stores/rootStore/rootStore';
import ButtonCloseOverviewMap from '../Controls/OverviewMapControl/ButtonCloseOverviewMap/ButtonCloseOverviewMap';

import CircleBtns from './CircleBtns/CircleBtns';
import SystemsBtns from './SystemsBtns/SystemsBtns';
import ZoomBtns from './ZoomBtns/ZoomBtns';

import styles from './MapButtons.module.scss';

const MapButtons = () => {
  const { isInfoPanel, isCollapseInfoPanel, isPhaseCircle } = rootStore.uiStore;

  const containerStyles = classNames({
    [styles.container]: true,
    [styles.containerOffset]: !isCollapseInfoPanel && isInfoPanel,
  });

  return (
    <>
      <ButtonCloseOverviewMap />
      <div className={containerStyles}>
        {/* <NotificationWidgetButton /> */}
        {isPhaseCircle ? <CircleBtns /> : <SystemsBtns />}
        <ZoomBtns />
      </div>
    </>
  );
};

export default observer(MapButtons);
