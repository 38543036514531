import { useEffect, useState } from 'react';

const useSwitcher = (isOn = false, interval = 500, base = true) => {
  const [isEnabled, setIsEnabled] = useState(base);

  useEffect(() => {
    if (!isOn) {
      return;
    }

    setTimeout(() => setIsEnabled(!isEnabled), interval);
  }, [isEnabled, isOn, interval]);

  return [isEnabled];
};

export default useSwitcher;
