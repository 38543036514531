export const adminPanelSettigsMenu = [
  {
    label: 'Информация',
    key: 'info',
    children: [
      {
        label: 'О системе',
        key: 'infoSystem',
      },
      {
        label: 'Версии',
        key: 'infoVersions',
      },
    ],
  },
  {
    label: 'Web сервер NJS',
    key: 'njs',
    children: [
      {
        label: 'Настройки сервера',
        key: 'njsSettings#58',
      },
      {
        label: 'Информация',
        key: 'njsInfo',
      },
    ],
  },
  {
    label: 'Управление доступом',
    key: 'access',
    children: [
      {
        label: 'Группы Web сервера (NJS)',
        key: 'accessNJSGroups',
      },
      {
        label: 'Web группы',
        key: 'accessWebGroups',
      },
      {
        label: 'Пользователи',
        key: 'accessWebUsers',
      },
    ],
  },
];

export const adminCatalogsMenu = [
  {
    label: 'Справочник',
    key: 'catalog',
    children: [
      {
        label: 'Оборудование',
        key: 'equipment',
      },
      {
        label: 'Производители',
        key: 'manufacturers',
      },
    ],
  },
];
