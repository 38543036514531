import { VERSION } from '../../constants/versions';
import { IProfileBody } from '../../ts/models/videoWall.model';
import { getAppPathHeaders } from '../helpers/getAppPathHeaders';
import http from '../http';
import tryCatchWrapper from '../tryCatchWrapper';

import { ProfilesSchema, ProfileSchema } from './videoWall.zod';

const VIDEOWALL = VERSION + '/videoWall';
const PROFILES = VIDEOWALL + '/profiles';

const videoWallApi = {
  getVideoList: () =>
    tryCatchWrapper(async (appPath: string) => {
      const res = await http.get(PROFILES, {
        headers: getAppPathHeaders(appPath),
      });

      const validate = ProfilesSchema.safeParse(res?.data);

      if (!validate.success) console.log(validate.error);

      return res.data;
    }),
  createVideoWallProfile: (body: IProfileBody, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.post(PROFILES, body, {
        headers: getAppPathHeaders(appPath),
      });

      const validate = ProfileSchema.safeParse(res?.data?.profile);

      if (!validate.success) console.log(validate.error);

      return res.data;
    }),
  updateVideoWallProfile: (
    body: IProfileBody,
    id: number | string,
    appPath: string
  ) =>
    tryCatchWrapper(async () => {
      const res = await http.put(`${PROFILES}/${id}`, body, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
  deleteVideoWallProfile: (id: number, appPath: string) =>
    tryCatchWrapper(async () => {
      await http.delete(`${PROFILES}/${id}`, {
        headers: getAppPathHeaders(appPath),
      });
    }),
};

export default videoWallApi;
