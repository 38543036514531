import api from '../../../api';
import rootStore from '../../../stores/rootStore/rootStore';

export const getAllGroupsObj = async () => {
  const { webSystemId, serverSystemId } = rootStore.userDataStore;
  const { data } = await api.adminObjects.adminGroupsApi.fetchGroups(
    'admin.groups'
  );

  const groups: any = {};

  data.forEach((item: any) => {
    if (item.systemId === webSystemId) {
      groups.web = item.groups;
    } else if (item.systemId === serverSystemId) {
      groups.njs = item.groups;
    }
  });

  return groups;
};
