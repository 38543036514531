import { observer } from 'mobx-react-lite';
import { Map as OLMap, View } from 'ol';
import { FC, useEffect, useRef, useState } from 'react';

import { MAP_INITIAL_OPTIONS, ZOOM } from '../../constants/mapConstants';
import rootStore from '../../stores/rootStore/rootStore';
import { MapProps } from '../Mapper/Mapper.model';

import { normalizeExtent } from './helpers/normalizeExtent';
import useInfoPanel from './hooks/useInfoPanel';
import useMapEventHandlers from './hooks/useMapEventHandlers';
import useMapSettings from './hooks/useMapSettings';
import useTooltip from './hooks/useTooltip';
import useZoomAndCenter from './hooks/useZoomAndCenter';
import MapContext from './MapContext';

import styles from './Map.module.scss';

const Map: FC<MapProps> = ({
  children,
  refs,
  popups,
  setIsTooltip,
  setIsCrosshairPopup,
}) => {
  const { currentZoom, wheelZoom, clusterFeatures } = rootStore.mapDataStore;
  const { isApplyCenter, circleCenter, isWheelZoom } =
    rootStore.constructorStore;
  const { isCollapseInfoPanel } = rootStore.uiStore;

  const { constructorCircleRef, tooltipRef } = refs;

  const {
    infoData,
    initialMapCenter,
    isInfoPanel,
    isPanel,
    isCenterDependsOnPanels,
    regionData,
  } = rootStore.uiStore;

  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<OLMap>();
  const [centerZoom, setMapCenterZoom] = useState(initialMapCenter);
  const [center, setMapCenter] = useState(initialMapCenter);

  // On component mount
  useEffect(() => {
    if (!regionData) return;

    const extent = normalizeExtent(regionData.extent);
    const zoom = regionData.initialZoom;

    const mapObject = new OLMap({
      ...MAP_INITIAL_OPTIONS,
      view: new View({
        zoom,
        minZoom: zoom,
        maxZoom: ZOOM.MAX,
        extent,
      }),
    });

    mapObject.getView().setCenter(initialMapCenter);
    mapObject.setTarget(mapRef.current as HTMLDivElement);
    setMap(mapObject);

    return () => mapObject.setTarget(undefined);
  }, []); // eslint-disable-line

  useMapSettings({
    map,
    isApplyCenter,
    constructorCircleRef,
    circleCenter,
    isWheelZoom,
    setMapCenterZoom,
  });

  const { closeTooltip, getTooltip } = useTooltip({
    tooltipRef,
    tooltip: popups.tooltip,
  });

  const { handleInfoPanel } = useInfoPanel({
    map,
    refs,
    popups,
    setMapCenterZoom,
    setMapCenter,
    infoData,
    setIsCrosshairPopup,
  });

  useZoomAndCenter({
    map,
    isInfoPanel,
    isPanel,
    centerZoom,
    center,
    wheelZoom,
    currentZoom,
    infoData,
    isCenterDependsOnPanels,
    isCollapseInfoPanel,
  });

  useMapEventHandlers({
    map,
    initialMapCenter,
    closeTooltip,
    getTooltip,
    clusterFeatures,
    setIsTooltip,
    popups,
    infoData,
    handleInfoPanel,
    refs,
    isInfoPanel,
  });

  return (
    <MapContext.Provider value={{ map }}>
      <div className={styles.olMap} ref={mapRef} role="presentation">
        {children}
      </div>
    </MapContext.Provider>
  );
};

export default observer(Map);
