import { FC } from 'react';

import { DEFAULT_CIRCLE } from '../../../../constants/constructorConstants';
import PhaseCircle from '../PhaseCircle/PhaseCircle';
import PhaseCircleHeader from '../PhaseCircleHeader/PhaseCircleHeader';
import PhaseCircleLayout from '../PhaseCircleLayout/PhaseCircleLayout';
import { IPhaseCircleEmpty } from '../PhaseCircleOverlay.model';

const PhaseCircleEmpty: FC<IPhaseCircleEmpty> = ({ id, color, cmd }) => {
  const data = [{ name: 'onePhase', time: 100, color }];

  return (
    <PhaseCircleLayout id={id}>
      <PhaseCircleHeader color={color} cmd={cmd} />
      <PhaseCircle
        timer={100}
        len={100}
        circleBorderWidth={DEFAULT_CIRCLE.BORDER_WIDTH}
        color={color}
        data={data}
      />
    </PhaseCircleLayout>
  );
};

export default PhaseCircleEmpty;
