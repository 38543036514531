import React, { useCallback, useMemo, useState } from 'react';

import RowActionBtns from '../RowActionBtns/RowActionBtns';
import RowEditBtns from '../RowEditBtns/RowEditBtns';

const useTable = ({ moduleData, form }: any) => {
  const [editingKey, setEditingKey] = useState(''),
    [deletingKey, setDeletingKey] = useState(''),
    isEditing = useCallback(
      (record: any) => record.key === editingKey,
      [editingKey]
    ),
    columnsStructured = useMemo(
      () =>
        moduleData?.headerItem.map(
          ({ title, readonly, visible, referens, type }: any) => ({
            title,
            dataIndex: referens,
            key: referens,
            editable: !readonly,
            visible,
            width: null,
            sorter:
              type === 'number'
                ? (a: any, b: any) => a[referens] - b[referens]
                : null,
          })
        ),
      [moduleData]
    ),
    columnsVisible = useMemo(() => {
      const cols = columnsStructured?.filter((item: any) => item.visible);

      cols?.push({
        title: 'Действия',
        dataIndex: 'actions',
        fixed: 'right',
        width: 100,
        render: (_: any, record: any) => {
          const editable = isEditing(record),
            isDisabled = editingKey !== '' || deletingKey !== '',
            actionProps = {
              setDeletingKey,
              setEditingKey,
              form,
              record,
              isDisabled,
              importedData: moduleData,
            },
            editProps = { setEditingKey, form, id: record.id };

          if (editable) {
            return <RowEditBtns {...editProps} />;
          }

          return <RowActionBtns {...actionProps} />;
        },
      });

      return cols;
    }, [
      columnsStructured,
      deletingKey,
      editingKey,
      form,
      isEditing,
      moduleData,
    ]),
    columns = useMemo(
      () =>
        columnsVisible?.map((col: any) => {
          if (!col.editable) {
            return col;
          }

          return {
            ...col,
            onCell: (record: any) => ({
              record,
              inputType: 'text',
              dataIndex: col.dataIndex,
              title: col.title,
              editing: isEditing(record),
            }),
          };
        }),
      [columnsVisible, isEditing]
    ),
    rows = useMemo(
      () =>
        [...moduleData.data].map((item) =>
          columns.reduce((res: any, { dataIndex }: any) => {
            if (!res.id) {
              res.id = item.id;
              res.key = item.id;
            }
            res[dataIndex] = item[dataIndex];

            return res;
          }, {})
        ),
      [moduleData, columns]
    );

  return [columns, rows];
};

export default useTable;
