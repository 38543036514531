import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { toLonLat } from 'ol/proj';

import rootStore from '../../../stores/rootStore/rootStore';
import { MapActions } from '../../../stores/uiStore/uiStore';
import { System } from '../../../ts/enums/enums';

export const openDetailedForm = (feature: Feature<Point>, e: any) => {
  const { setInfoData, setIsNot } = rootStore.uiStore;
  const { getCoordinatesById } = rootStore.mapDataStore;

  const system = feature.get('system');
  const id = feature.get('id');
  const coordinates = system === System.Streams ? e.coordinate : undefined;
  const featureCenter = coordinates ?? getCoordinatesById(id);

  setInfoData(
    {
      id,
      coordinate: featureCenter ? toLonLat(featureCenter) : [],
      system,
    },
    MapActions.NoActions
  );

  setIsNot('isDetailedForm', true);
};
