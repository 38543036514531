export const FOLDER_PATHS = {
  URL: 'https://dev.eputs.org/static',
  DEFAULT: '/media',
  SIGN1: '/sign1/',
};

export const FOLDER_LIST = [
  {
    value: FOLDER_PATHS.SIGN1,
    label: FOLDER_PATHS.SIGN1,
  },
];

export const FILE_TYPE = [
  {
    value: '.png',
    label: 'png',
  },
];

export const SYSTEM_TYPE = [
  {
    value: 'Sg',
    label: 'Сигнал',
  },
];
