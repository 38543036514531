import { Button } from 'antd';
import { observer } from 'mobx-react-lite';

import rootStore from '../../../stores/rootStore/rootStore';

import styles from './PhaseCircleNoData.module.scss';

const PhaseCircleNoData = () => {
  const { setKeyValue } = rootStore.uiStore;
  const handleClose = () => setKeyValue('isShowPhaseCircle', false);

  return (
    <div className={styles.notData}>
      <p> Недостаточно данных для отображения круга</p>
      <Button onClick={handleClose} type="primary">
        закрыть
      </Button>
    </div>
  );
};

export default observer(PhaseCircleNoData);
