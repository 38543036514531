export const CLUSTERS = {
  ANIMATION_DURATION: 700,
  CLUSTERS_BORDER_RESOLUTION: 2.560099327224622,
  DISTANCE: 20,
  ZOOM: 150,
  LOCATION_DISTANCE: 40,
  POINT_RADIUS: 28,
};

export const ANIMATION_DURATION_CLUSTER = CLUSTERS.ANIMATION_DURATION + 300;

export const CLUSTER_SCALE_ZOOM_RATE = 380;
export const DOUBLE_CLICK_ZOOM_MOVE = 20;
export const DOUBLE_CLICK_LATENCY = 500;

export const EMPTY_DURATION_CLUSTER = 400;
