import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import { findById } from '../../../../helpers/findBy';
import rootStore from '../../../../stores/rootStore/rootStore';
import { ITimePoints } from '../../../../ts/models/signalPrograms.model';
import { getPhases } from '../../../SignalProgramsBand/helpers/getPhases';
import getCsdData from '../../../SignalProgramsGraph/helper/getCsdData';
import PhaseCardsList from '../../../TrafficLightDetailed/TlMainInfo/PhaseCardsList/PhaseCardsList';

import styles from './TrafficLightInfo.module.scss';

interface SystemTLProps {
  id: number;
}

const TrafficLightInfo: FC<SystemTLProps> = ({ id }) => {
  const { setKeyValue } = rootStore.uiStore;
  const { tls } = rootStore.mapDataStore;
  const { tlStatus, extId } = findById(tls, id);

  const [phasesSet, setPhasesSet] = useState<ITimePoints[]>([]);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    setIsError(false);
    const res = getCsdData({
      id,
      isStatic: false,
      tlsData: tls,
    });

    if (!res) return;

    const { timePoints } = res;

    if (!timePoints.length) return setIsError((prev) => true);

    setPhasesSet(timePoints);

    const phasesArray = getPhases(timePoints);

    setKeyValue('phasesArray', phasesArray);
  }, [id, setKeyValue, tls]);

  const cardsProps = { extId, phasesSet, tlStatus, id, isError };

  return (
    <div className={styles.container}>
      <PhaseCardsList phases={cardsProps} />
    </div>
  );
};

export default observer(TrafficLightInfo);
