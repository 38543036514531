import { notification } from 'antd';

import { VERSION } from '../../constants/versions';
import { getAppPathHeaders } from '../helpers/getAppPathHeaders';
import http from '../http';

import { menuTreeSchema } from './testingPanelApi.zod';

const INCORRECT_DATA = 'Получены некорректные данные.';
const TESTING_PANEL = `${VERSION}/testing/`;
const TREE = TESTING_PANEL + 'tree';

const testingApi = {
  fetchRequestsTree: async (appPath: string) => {
    try {
      const res = await http.get(TREE, {
        headers: getAppPathHeaders(appPath),
      });

      return menuTreeSchema.parse(res.data);
    } catch (e: any) {
      notification.error({
        message: `Ошибка запроса дерева запросов интерфейса тестирования`,
        description: e.response?.data?.message ?? INCORRECT_DATA,
      });

      throw e;
    }
  },
};

export default testingApi;
