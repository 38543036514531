import { observer } from 'mobx-react-lite';
import { FC, memo, useEffect, useState } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import { System } from '../../../../ts/enums/enums';
import Accordion from '../../../ui-kit/Accordion/Accordion';

import { checkMatches } from './helpers';

interface ISystems {
  title: System;
  data: any[];
  isMarkers: boolean;
}

const AllSystemsTab: FC = () => {
  const { setIsMarkers, handlePopupFromTab, infoData } = rootStore.uiStore;
  const { filteredSystems, searchedValue } = rootStore.mapDataStore;
  const [searchedAndFilteredSystems, setSearchedAndFilteredSystems] = useState<
    ISystems[]
  >([]);

  useEffect(() => {
    const newArray: any = filteredSystems.map((item) => {
      const newItem = {
        ...item,
        data: checkMatches(item.data, searchedValue),
      };

      newItem.data.sort((a, b) => {
        if (a.extId && b.extId) {
          return a.extId - b.extId;
        }

        return a.id - b.id;
      });

      return newItem;
    });

    setSearchedAndFilteredSystems(newArray);
  }, [filteredSystems, searchedValue]);

  return (
    <div>
      {searchedAndFilteredSystems.map(({ title, data, isMarkers }) => {
        const isItemOnAccordion = data.some(
          ({ id }) => infoData?.id !== undefined && id === infoData?.id
        );
        const isOpen =
          (Boolean(!searchedValue) || !isMarkers || Boolean(!data.length)) &&
          !isItemOnAccordion;

        return (
          <Accordion
            activeId={infoData?.id as number}
            data={data}
            isDisabled={isMarkers}
            key={title}
            onClick={(e: any) => {
              handlePopupFromTab(e, title);
            }}
            setIsMarkers={setIsMarkers}
            title={title}
            isOpen={isOpen}
            isNeedScroll={true}
          />
        );
      })}
    </div>
  );
};

export default memo(observer(AllSystemsTab));
