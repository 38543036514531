import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Space } from 'antd';
import React from 'react';

import useExport from '../hooks/useExport';

import styles from './ImportExportDropdown.module.scss';

interface ImportExportDropdownProps {
  className: string;
  refImportBtn: any;
}

const ImportExportDropdown = (props: ImportExportDropdownProps) => {
  const { className, refImportBtn } = props;
  const { exportData } = useExport();

  const actionsList = (
    <Menu
      items={[
        {
          label: 'Импорт данных в таблицу',
          key: '0',
          onClick: () => {
            refImportBtn.current.click();
          },
        },
        {
          label: 'Экспорт данных из таблицы',
          key: '1',
          onClick: exportData,
        },
      ]}
    />
  );

  return (
    <Dropdown
      className={styles.dropdown}
      overlay={actionsList}
      placement="bottomLeft"
      trigger={['click']}
    >
      <Button className={className} type="primary">
        <Space>
          Импорт \ Экспорт
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default ImportExportDropdown;
