/* eslint-disable camelcase */
import { TDataDetectors } from '../../../InfoPanel/SystemsInfo/PopupDetector/PopupDetector.model';
import { RadioValue } from '../model/graph.model';

export const normilizeData = (
  mainDataKey: RadioValue,
  dataRes: TDataDetectors,
  isMinificGraph = false
) => {
  if (!dataRes.data?.length) return { data: [], loading: false };

  const directionCount = dataRes.data[0].directions.length;

  const directionsNames = dataRes.directionsNames;

  const data = dataRes.data.map((item) => {
    const { date, date_from, date_to } = item;
    const graphPoint: any = {
      time: date,
    };

    for (let i = 0; i < directionCount; i++) {
      const dirName = directionsNames[i]
        ? directionsNames[i]
        : `Направление ${i + 1}`;

      if (item.directions[i]) {
        graphPoint[dirName] = Math.trunc(item.directions[i][mainDataKey]);
      }
    }

    const res = isMinificGraph
      ? { ...graphPoint, dateTo: date_to, dateFrom: date_from }
      : { ...graphPoint };

    return res;
  });

  return { data, loading: false };
};
