import { Button, Spin, Tooltip } from 'antd';
import { FC, useState, useEffect } from 'react';

import api from '../../../../../api';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { IExternalResourse } from '../../../../ExternalResource/ExternalResource';

import styles from './ExternalResourseButtons.module.scss';

const ExternalResourseButtons: FC = () => {
  const { setKeyValue } = rootStore.uiStore;
  const { hasAccess } = rootStore.userDataStore;
  const [isLoading, setIsLoading] = useState(false);
  const [buttonsList, setButtonsList] = useState([]);

  const fetchDynamicButtons = async () => {
    try {
      setIsLoading(true);

      const res = await api.serverObjects.infoApi.fetchDynamicButtons();

      setButtonsList(res.buttons);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDynamicButtons();
  }, []);

  const openExternalResourse = (resourse: IExternalResourse) => {
    setKeyValue('externalResource', resourse);
  };

  if (isLoading || !buttonsList) return <Spin />;

  return (
    <div className={styles.container}>
      {buttonsList.map((item: any) => {
        if (!hasAccess(item.code)) return null;

        const resourse: IExternalResourse = {
          name: item.dsc,
          url: item.link.url,
          auth:
            item.link.AuthorizationType === 'Basic'
              ? {
                  username: item.link.user,
                  password: item.link.password,
                }
              : null,
        };

        return (
          <Tooltip key={item.code} placement={'right'} title={item.hint}>
            <Button
              type="primary"
              className={styles.openBtn}
              onClick={() => openExternalResourse(resourse)}
            >
              {item.dsc ?? 'Название не найденно'}
            </Button>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default ExternalResourseButtons;
