import { FC } from 'react';
import { AiOutlineRotateLeft, AiOutlineRotateRight } from 'react-icons/ai';
import { BiZoomIn, BiReset } from 'react-icons/bi';

import styles from './MaskPreview.module.scss';

interface IMaskPreview {
  setRotate: (value: number) => void;
  isShowIcons?: boolean;
}

const MaskPreview: FC<IMaskPreview> = ({ setRotate, isShowIcons }) => {
  const handleSetRotate = (e: any, degs: number) => {
    e.preventDefault();
    e.stopPropagation();
    setRotate(degs);
  };

  return (
    <div className={styles.conatinerMask}>
      <div className={styles.rowTitle}>
        <BiZoomIn size={40} className={styles.icon} />
      </div>
      {isShowIcons && (
        <div className={styles.rowPanel}>
          <AiOutlineRotateLeft
            size={30}
            className={styles.rotateLeft}
            onClick={(e) => handleSetRotate(e, -90)}
          />
          <AiOutlineRotateRight
            size={30}
            className={styles.rotateRight}
            onClick={(e) => handleSetRotate(e, 90)}
          />
          <BiReset
            size={30}
            className={styles.reset}
            onClick={(e) => handleSetRotate(e, 0)}
          />
        </div>
      )}
    </div>
  );
};

export default MaskPreview;
