import { Input } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';

import styles from './SearchedInput.module.scss';

const INPUT_PLCH = 'Поиск камеры';

const SearchedInput = () => {
  const { cameras } = rootStore.mapDataStore;
  const { setVideoKeysValues } = rootStore.videoWallPanelStore;
  const [searchedValue, setSearchedValue] = useState('');

  const handleChange = (e: any) => {
    setSearchedValue(e.target.value);
  };

  useEffect(() => {
    const filteredList = cameras.filter((camera) =>
      camera.caption.toLowerCase().includes(searchedValue.toLowerCase())
    );

    setVideoKeysValues({ videoPanelList: filteredList });
  }, [cameras, searchedValue, setVideoKeysValues]);

  return (
    <Input
      placeholder={INPUT_PLCH}
      className={styles.input}
      value={searchedValue}
      onChange={handleChange}
    />
  );
};

export default observer(SearchedInput);
