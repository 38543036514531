import { notification } from 'antd';
import { makeAutoObservable, toJS } from 'mobx';

import { FOLDER_PATHS } from '../../components/Constructor/DetailedConstructor/ConstructorPhaseImages/constants/constants';
import { TL_CIRCLE_ZOOM } from '../../constants/constants';
import {
  CAMERA_DEFAULT,
  CIRCLE_ELEMENT_DYNAMIC,
  CIRCLE_ELEMENT_OBJ,
  CROSSROAD_DEFAULT,
  DEFAULT_CIRCLE,
  DEFAULT_CIRCLE_ELS_DYNAMIC,
  LANE_SPAN,
  PHASES_DEFAULT,
  TRAFFIC_LANE_DEFAULT,
  VIEW_DEFAULT,
} from '../../constants/constructorConstants';
import NOTICE from '../../constants/notificationConstants';
import { findById } from '../../helpers/findBy';
import { zooms } from '../../helpers/findZoomRangeStart';
import { TLPhaseCodes } from '../../ts/enums/tl';
import {
  CircleElDynamic,
  ICameraConstructor,
  ICircleElConstructor,
  ICrossroad,
  Id,
  TrafficLane,
  ViewKeys,
} from '../../ts/models/constructor.model';
import getLaneWidth from '../helpers/getLaneWidth';
import RootStore from '../rootStore/rootStore';

import {
  ConstructorArrays,
  ICircleElsGeneral,
  IPhase,
  TConstructorBools,
} from './constructorStore.model';

const { DIAMETER, BORDER_WIDTH, OPACITY, HUE, DIAMETER_RATE } = DEFAULT_CIRCLE;

class ConstructorStore {
  rootStore;
  view = VIEW_DEFAULT;
  isConstructorLoaded = false;
  isCircleConstructor = false;
  isBlinkingAnimation = false;
  isApplyCenter = false;
  isCircleCenter = true;
  isFinalVersion = false;
  isAppliedAsFinal = false;
  isCenteredByClick = true;
  isAutoRange = false;
  circleCenter: XY = [0, 0];
  circleDiameter = DIAMETER;
  circleDiameterRate = DIAMETER_RATE;
  circleBorderWidth = BORDER_WIDTH;
  circleOpacity = OPACITY;
  circleHue = HUE;
  zoomRangeEnd = TL_CIRCLE_ZOOM.END;
  importedZoom = null;
  isConstructorDetailed = false;
  isWheelZoom = true;
  circleElsGeneral: ICircleElsGeneral[] = [];
  circleElsDynamic = DEFAULT_CIRCLE_ELS_DYNAMIC;
  phases: IPhase[] = PHASES_DEFAULT;
  isCrossroadLoaded = false;
  crossroad: ICrossroad = CROSSROAD_DEFAULT;
  cameras: ICameraConstructor[] = [];
  trafficLanes: TrafficLane[] = [];
  zoomRangeStart = TL_CIRCLE_ZOOM.START;
  zoomRangePoint = TL_CIRCLE_ZOOM.START;
  currentPhaseIdx = 1;
  tlEputsId: N<number> = null;
  create: Nullish<Date> = null;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }

  setIsNotConstructor = (key: TConstructorBools, bool?: boolean) => {
    this[key] = bool ?? !this[key];
  };

  setConstructorData = <K extends keyof this, T extends this[K]>(
    key: K,
    value: T
  ) => (this[key] = value);

  setConstructorKeysValues = <K extends keyof this>(params: {
    [key in K]: this[key];
  }) => {
    for (const key in params) {
      this[key] = params[key];
    }
  };

  setView = (key: ViewKeys, bool?: boolean) => {
    this.view[key] = bool ?? !this.view[key];
  };

  setCrossroad = <K extends keyof ICrossroad, T extends ICrossroad[K]>(
    key: K,
    value: T
  ) => {
    const { setTrafficLane, trafficLanes, crossroad, cameras, setCamera } =
      this;
    const { isTrafficLanesOffset, isCamerasOffset, offsetX, offsetY } =
      crossroad;

    if (typeof value === 'number') {
      if (isTrafficLanesOffset) {
        trafficLanes.forEach((trafficLane) => {
          const { id } = trafficLane;
          const newOffsetX = trafficLane.offsetX + value - offsetX;
          const newOffsetY = trafficLane.offsetY + value - offsetY;

          key === 'offsetX' && setTrafficLane('offsetX', newOffsetX, id);
          key === 'offsetY' && setTrafficLane('offsetY', newOffsetY, id);
        });
      }

      if (isCamerasOffset) {
        cameras.forEach((camera) => {
          const { id } = camera;

          if (id === null) return;

          const newOffsetX = camera.offsetX + value - offsetX;
          const newOffsetY = camera.offsetY + value - offsetY;

          key === 'offsetX' && setCamera('offsetX', newOffsetX, id);
          key === 'offsetY' && setCamera('offsetY', newOffsetY, id);
        });
      }
    }

    crossroad[key] = value;
  };

  setCrossroadPhase = (idx: number, value: boolean) => {
    const phases = toJS(this.crossroad.phases);

    this.crossroad.phases = [
      ...phases.slice(0, idx),
      value,
      ...phases.slice(idx + 1),
    ];
  };

  setTrafficLane = <K extends keyof TrafficLane, T extends TrafficLane[K]>(
    key: K,
    value: T,
    id: Id
  ) => {
    const trafficLane = findById(this.trafficLanes, id);

    if (trafficLane) trafficLane[key] = value;
  };

  addTrafficLane = (id: Id) => {
    this.trafficLanes.push({ ...TRAFFIC_LANE_DEFAULT, id });
  };

  setLaneParams = (amount: number, id: Id) => {
    const trafficLane = findById(this.trafficLanes, id);

    if (!trafficLane) return;

    const { laneParams } = trafficLane;

    if (laneParams.length >= amount) {
      const newLaneParams = [...laneParams].slice(0, amount);

      trafficLane.width = getLaneWidth(newLaneParams);
      trafficLane.laneParams = newLaneParams;

      return;
    }

    trafficLane.laneParams.push({
      id: (laneParams.at(-1)?.id ?? 0) + 1,
      span: LANE_SPAN,
      width: laneParams.at(-1)?.width ?? 0,
      offset: getLaneWidth(laneParams) + LANE_SPAN,
    });

    trafficLane.width = getLaneWidth(laneParams);
  };

  setLaneWidth = (value: number, id: Id, laneId: Id) => {
    const trafficLane = findById(this.trafficLanes, id);

    if (!trafficLane) return;
    const { laneParams } = trafficLane;

    const laneParamsItem = findById(laneParams, laneId);

    if (laneParamsItem) laneParamsItem.width = value;

    let offset = trafficLane.laneParams[0].offset;

    trafficLane.laneParams = [...trafficLane.laneParams].map((params) => {
      const newParams = {
        ...params,
        offset,
      };

      offset += params.width + LANE_SPAN;

      return newParams;
    });

    trafficLane.width = getLaneWidth(trafficLane.laneParams);
  };

  setCamera = <
    K extends keyof ICameraConstructor,
    T extends ICameraConstructor[K]
  >(
    key: K,
    value: T,
    id: Id
  ) => {
    const camera = this.cameras.find((el) => el.id === id);

    if (camera) camera[key] = value;
  };

  addCamera = (id: number, caption: string) => {
    this.cameras.push({ ...CAMERA_DEFAULT, id, caption });
  };

  deleteFromArray = (key: ConstructorArrays, idx: number) =>
    this[key].splice(idx, 1);

  deleteById = (key: 'cameras' | 'trafficLanes', id: Id) => {
    const idx = this[key].findIndex((el) => el.id === id);

    this.deleteFromArray(key, idx);
  };

  setCircleEl = <
    K extends keyof ICircleElsGeneral,
    T extends ICircleElsGeneral[K]
  >(
    idx: number,
    key: K,
    value: T
  ) => {
    const newEl = { ...this.circleElsGeneral[idx - 1], [key]: value };

    this.circleElsGeneral[idx - 1] = newEl;
  };

  setCircleElDynamic = <
    K extends keyof CircleElDynamic,
    T extends CircleElDynamic[K]
  >(
    idx: number,
    key: K,
    value: T,
    byHand?: boolean
  ) => {
    const { circleElsDynamic, zoomRangePoint, isAutoRange } = this;

    if (!circleElsDynamic[zoomRangePoint]) {
      const closestZoomRange = zooms.find((zoom) => {
        return zoom <= zoomRangePoint && circleElsDynamic[zoom];
      });

      if (closestZoomRange) {
        circleElsDynamic[zoomRangePoint] = JSON.parse(
          JSON.stringify(toJS(circleElsDynamic[closestZoomRange]))
        );
      }
    }

    if (!byHand) {
      circleElsDynamic[zoomRangePoint][idx - 1][key] = value;
    }

    if (isAutoRange || byHand) {
      this.zoomRangeStart = zoomRangePoint;
    }
  };

  addCircleEl = () => {
    const { circleElsDynamic, circleElsGeneral, directions } = this;

    const directionNumber = directions.length
      ? Math.max(...this.directions) + 1
      : 1;

    circleElsGeneral.push({
      ...CIRCLE_ELEMENT_OBJ,
      direction: directionNumber,
    });

    for (const key in circleElsDynamic) {
      circleElsDynamic[key].push(CIRCLE_ELEMENT_DYNAMIC);
    }
  };

  deleteCircleEl = (idx: number) => {
    const {
      phases,
      deleteFromArray,
      zoomRangePoint,
      circleElsDynamic,
      circleElsGeneral,
    } = this;
    const { direction } = circleElsGeneral[idx];

    phases.forEach((ph) => {
      const directionIdx = ph.directions.findIndex(
        (item) => item === direction
      );

      directionIdx >= 0 && ph.directions.splice(directionIdx, 1);
    });

    deleteFromArray('circleElsGeneral', idx);
    circleElsDynamic[zoomRangePoint] &&
      circleElsDynamic[zoomRangePoint].splice(idx, 1);
  };

  deleteZoomRange = (range: string) => {
    const { circleElsDynamicKeys, rootStore, circleElsDynamic } = this;
    const rangeOffset = Number(range) - TL_CIRCLE_ZOOM.STEP;

    const newZoomRangeStart = circleElsDynamicKeys
      .reverse()
      .find((key) => Number(key) <= rangeOffset);

    rootStore.mapDataStore.currentZoom = Number(newZoomRangeStart);
    this.zoomRangeStart = Number(newZoomRangeStart);
    delete circleElsDynamic[Number(range)];
  };

  onPhase = (idx: number) => {
    const { phases, circleElsGeneral } = this;

    this.currentPhaseIdx = idx;
    circleElsGeneral.forEach((el) => (el.isCircleElement = false));

    phases[idx].directions.forEach((direction) => {
      const elIdx = circleElsGeneral.findIndex(
        (el) => el.direction === direction
      );

      if (elIdx >= 0) {
        circleElsGeneral[elIdx].isCircleElement = true;
      }
    });
  };

  setPhase = (
    folder: string,
    name: string,
    i: number,
    isChangeImage = true
  ) => {
    if (isChangeImage) {
      this.phases[i].image = FOLDER_PATHS.DEFAULT + folder + name;
    }
    this.phases[i].name = name;
  };

  addPhase = (phaseNumber: N<number>) => {
    const { phases, onPhase } = this;

    const newPhase = {
      phaseNumber: phaseNumber,
      directions: [],
      code: phaseNumber ? TLPhaseCodes.Phase : TLPhaseCodes.Undefined,
    };

    phases.push(newPhase);
    onPhase(phases.length - 1);
  };

  handlePhase = (circleElIdx: number) => {
    const { circleElsGeneral, phases, currentPhaseIdx } = this;
    const ph = phases[currentPhaseIdx];

    !ph && notification.error(NOTICE.CONSTRUCTOR_PHASE_ERROR);
    if (!phases.length || !ph) return;

    const { isCircleElement, direction } = circleElsGeneral[circleElIdx - 1];
    const { directions } = ph;

    if (!isCircleElement) {
      const idx = directions.findIndex((el) => el === direction);

      return idx >= 0 && directions.splice(idx, 1);
    }

    !directions.includes(direction) && directions.push(direction);
  };

  get isConstructor() {
    const { isPanel, panelType } = this.rootStore.uiStore;

    return isPanel && panelType === 'constructor' && this.isCircleConstructor;
  }

  get circleParams() {
    return {
      diameter: this.circleDiameter,
      opacity: this.circleOpacity,
      hue: this.circleHue,
      borderWidth: this.circleBorderWidth,
      animation: this.isBlinkingAnimation ? [{ type: 'switch' }] : null,
    };
  }

  get circleEls(): ICircleElConstructor[] {
    const { circleElsGeneral, circleElsDynamic, zoomRangeStart } = this;

    if (!circleElsGeneral.length || !circleElsDynamic[TL_CIRCLE_ZOOM.START])
      return [];

    return circleElsGeneral.map((circleEl, i) => {
      return {
        ...circleEl,
        ...(circleElsDynamic[zoomRangeStart][i] ?? {}),
        idx: i,
      };
    });
  }

  get circleElsDynamicKeys() {
    return Object.keys(this.circleElsDynamic).sort();
  }

  get directions() {
    return this.circleElsGeneral.map((el) => el.direction);
  }

  get phaseNumbers() {
    return this.phases.reduce<number[]>((res, el) => {
      if (el.phaseNumber) res.push(el.phaseNumber);

      return res;
    }, []);
  }

  clearStore = (isExit = true) => {
    if (isExit) {
      this.isCircleConstructor = false;
    }
    this.circleCenter = [0, 0];
    this.circleElsGeneral = [];
    this.circleElsDynamic = DEFAULT_CIRCLE_ELS_DYNAMIC;
    this.phases = PHASES_DEFAULT;
    this.isConstructorDetailed = false;
    this.tlEputsId = null;
    this.isCenteredByClick = true;
    this.isWheelZoom = true;
    this.crossroad = CROSSROAD_DEFAULT;
    this.zoomRangeStart = TL_CIRCLE_ZOOM.START;
    this.cameras = [];
    this.trafficLanes = [];
    this.create = null;
  };
}

export default ConstructorStore;
