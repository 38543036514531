import addMarkers, { MarkersItem } from './addMarkers';

interface IMapIconsData {
  title: string;
  data: MarkersItem[];
  isMarkers: boolean;
}

export const getFeatures = (mapIconsData: IMapIconsData[]) => {
  const newFeatures = mapIconsData.map(({ title, data, isMarkers }) => ({
    features: addMarkers(data, title),
    isFeatures: isMarkers,
  }));

  return newFeatures;
};
