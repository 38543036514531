import { Tooltip } from 'antd';

import { sliceTitleBy } from '../../../../helpers/sliceTitle';

import { IAdminsPanelTree } from './helpers';

export const getMenuLabelTooltip = (menu: IAdminsPanelTree[]) => {
  menu.forEach((item) => {
    if (typeof item.label === 'string' && item.label.length > 25) {
      item.label = (
        <Tooltip title={item.label} placement="right">
          {sliceTitleBy(item.label, 25)}
        </Tooltip>
      );
    }

    if (item.children) {
      return getMenuLabelTooltip(item.children);
    }
  });

  return menu;
};
