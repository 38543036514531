import { System } from '../../../../../ts/enums/enums';
import { TlMode } from '../../../../../ts/models/tl.model';

import { IFeatureCache } from './clusterStyles';

export const getFeatureCacheStyle = (
  featureStyle: IFeatureCache[],
  systemFeature: System,
  modeFeature: TlMode
) => {
  const styleFeature =
    featureStyle.find(
      ({ mode, system }) => systemFeature === system && modeFeature === mode
    )?.style ?? null;

  return styleFeature;
};
