import { FC } from 'react';

import {
  ISavedVideoLIst,
  ListType,
} from '../../../../../../../ts/models/videoWall.model';

import FormCamerasLIstItem from './ListItem/FormCamerasLIstItem';

import styles from './List.module.scss';

// type ListType = 'personal' | 'public';

interface IList {
  profiles: ISavedVideoLIst[];
  type: ListType;
}

const TITLES: Record<string, string> = {
  personal: 'Личные профили',
  public: 'Общедоступные профили',
};

const List: FC<IList> = ({ profiles, type }) => {
  if (!profiles.length)
    return <p className={styles.noList}>{TITLES[type] + ' отсутствуют'}</p>;

  return (
    <>
      <p className={styles.title}>{TITLES[type]}</p>
      <ul className={styles.videoLists}>
        {profiles.map((item) => (
          <FormCamerasLIstItem
            listKey={item.name}
            key={item.id}
            cameras={item.jprofile.videoList}
            id={item.id}
          />
        ))}
      </ul>
    </>
  );
};

export default List;
