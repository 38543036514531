import { makeAutoObservable } from 'mobx';

import { Socket } from '../../components/Socket/useSocket';
import RootStore from '../rootStore/rootStore';

export interface ICategory {
  id: string | number;
  data: any;
  channel: string;
}

class ChannelsDataStore {
  rootStore;
  categories: ICategory[] = [];

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }

  getCategory = (
    channelName: string,
    categoryId: string | number
  ): ICategory => {
    const currentCategory = this.categories.find(
      (item: ICategory) =>
        item.channel === channelName && item.id === categoryId
    );

    if (!currentCategory) {
      this.updateCategory(channelName, categoryId, null);

      return this.getCategory(channelName, categoryId);
    }

    return currentCategory;
  };

  updateCategory = (
    channelName: string,
    categoryId: string | number,
    newMessage: any
  ) => {
    const currentCategory = this.categories.find(
      (item: ICategory) =>
        item.channel === channelName && item.id === categoryId
    );

    if (!currentCategory) {
      const newCategory: ICategory = {
        id: categoryId,
        channel: channelName,
        data: newMessage,
      };

      this.categories = [...this.categories, newCategory];

      return;
    }

    currentCategory.data = newMessage;

    this.categories = [...this.categories];
  };

  clearCategory = (channelName: string, categoryId: string): void => {
    this.categories = [
      ...this.categories.filter(
        (item) => item.channel !== channelName || item.id !== categoryId
      ),
    ];
  };

  reSubscribeAll = () => {
    if (this.categories.length > 0) {
      this.categories.forEach((caregory: ICategory) => {
        Socket.subscribe(caregory.channel, caregory.id, false);
      });
    }
  };
}

export default ChannelsDataStore;
