import { SizeType } from 'antd/lib/config-provider/SizeContext';
import {
  FilterDropdownProps,
  FilterValue,
  Key,
} from 'antd/lib/table/interface';
import { ReactNode } from 'react';

export interface IScroll {
  x?: number | string;
  y?: number | string;
}

type TablePaginationPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight';

export interface IAdminTablePagination {
  showSizeChanger: boolean;
  position: TablePaginationPosition[];
  defaultPageSize: number;
  pageSizeOptions: number[];
}

export interface IAdminTableConfig {
  pagination: IAdminTablePagination;
  scroll?: IScroll;
  bordered?: boolean;
  size?: SizeType;
}

export interface ITableConfigSettings {
  [key: string]: {
    [key: string]: number;
  };
}

export type ITableConfigType =
  | 'height'
  | 'users'
  | 'groups'
  | 'settings'
  | 'fullScreen';

export interface IFilterValue {
  [key: string]: FilterValue;
}

export type IFilteredInfo = N<Record<string, FilterValue | null>>;

export interface IFilter {
  text: string;
  value: boolean | string | number;
}

export interface IFiltersSettings {
  sorted: boolean;
  searched: boolean;
  filters: N<IFilter[]>;
}

export interface DataType {
  key: string;
}

export interface ICollaboration {
  deviceName: string;
  eputsDeviceId: N<number>;
  latitude: number;
  longitude: number;
  scSystemId: number;
  scSystemName: string;
  statusId: number;
  tlId: number;
  srcId: string;
  name: string;
  streamUrl: string;
  id: string | number;
  isChange: boolean;
  isDelete: boolean;
  play_ant_url: string;
  region_id: number;
  device_type_id: number;
}
export interface ITableColumn {
  fixed?: any;
  access?: boolean;
  id?: number;
  type: 'string' | 'number' | 'boolean' | 'date';
  title: string;
  visible: boolean;
  readonly: boolean;
  referens: string;
  dataIndex: string;
  render?:
    | ((collaboration: ICollaboration) => JSX.Element)
    | ((statusId: number) => JSX.Element)
    | ((acsess: boolean) => string | JSX.Element)
    | ((text: string) => string | JSX.Element)
    | ((tag: number[]) => JSX.Element)
    | ((collaboration: number | ICollaboration) => JSX.Element)
    | ((cmd_status: string) => JSX.Element);
  ellipsis?: {
    showTitle: boolean;
  };
  key?: string | number;
  filters?: IFilter[];
  onFilter?: (value: string | number | boolean, record: any) => boolean;
  width?: number;
  filterMode?: 'menu' | 'tree' | undefined;
  filterSearch?: boolean;
  sorter?: (a: any, b: any) => number;
  filterDropdown?: (props: FilterDropdownProps) => ReactNode;
  filterIcon?: (param: boolean) => ReactNode;
  filteredValue?: N<(boolean | Key)[]>;
  onFilterDropdownOpenChange?: (param: boolean) => void;
  accessId?: number;
  filterSettings: IFiltersSettings;
  link?: string;
}

export interface IDateSearch {
  onChange: (value: any, dateString: string[]) => void;
  showTime: any;
  format: string;
  disabledDate: (current: any) => any;
  disabledTime?: (date: any) => any;
  placeholder: [string, string];
}

export interface IServerSearch {
  onSearch: (value: string) => void;
  placeholder: string;
  loading: boolean;
}

export interface IIteractionButton {
  text: string;
  handler: () => void;
}

interface IOption {
  label: string;
  value: string | number | boolean;
}

export enum ValueTypes {
  string = 'string',
  number = 'number',
  numberArray = 'number.Array',
  stringArray = 'string.Array',
  dateTime = 'dateTime',
}

export interface IQueryParamItem {
  jkey: string;
  webElement: 'select' | 'input' | 'datePicker';
  webElementHint: string;
  webElementOptions: N<IOption[]>;
  webElementCapture: string;
  jvalueType: ValueTypes;
  defaultValue: N<unknown>;
  currentValue: N<unknown>;
  minValue: N<unknown>;
  maxValue: N<unknown>;
}
export enum ColumnTypes {
  date = 'date',
  string = 'string',
  boolean = 'boolean',
  number = 'number',
}
