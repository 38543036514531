export const CHART_NAMES = {
  vehicles: 'vehicles',
  avSpeed: 'avSpeed',
} as const;

export enum RADIO_GRAPH {
  Vehicles = 0,
  AvSpeed = 1,
}

export const DAY_INTERVAL = 3600;
