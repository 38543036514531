import { Empty, Image as ImageANT, Spin } from 'antd';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';

import { CONFIG } from '../../../constants/configConstants';
import { VERSION } from '../../../constants/versions';

import MaskPreview from './MaskPreview/MaskPreview';

import styles from './Img.module.scss';

interface IImg {
  src: string | undefined;
  alt: string;
  className?: string;
  classNameImg?: string;
  spinClassName?: string;
  isViewGallery?: boolean;
  isShowIcons?: boolean;
}

const Img: FC<IImg> = ({
  src,
  alt,
  className = '',
  classNameImg,
  isViewGallery = true,
  isShowIcons,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorImg, setErrorImg] = useState<boolean>(false);
  const [rotate, setRotate] = useState<number>(0);

  const customSrc = CONFIG.EPUTS_URL + VERSION + src;

  const containerStyle = classNames({
    [styles.container]: true,
    [className]: className,
  });

  useEffect(() => {
    setErrorImg(false);
  }, [src]);

  const preview = {
    mask: (
      <MaskPreview
        setRotate={(value: number) =>
          setRotate((prev: number) =>
            value !== 0 ? prev + value : Math.trunc(rotate / 360) * 360
          )
        }
        isShowIcons={isShowIcons}
      />
    ),
  };

  if (errorImg || typeof src !== 'string') {
    return (
      <div className={containerStyle}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );
  }

  if (isLoading || !src) {
    return (
      <div className={containerStyle}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={containerStyle}>
      <ImageANT
        preview={isViewGallery && preview}
        src={customSrc}
        onError={() => {
          setErrorImg(true);
          setIsLoading(false);
        }}
        onLoad={() => setIsLoading(false)}
        onLoadStart={() => setIsLoading(true)}
        rootClassName={classNameImg || styles.img}
        style={{
          transition: 'transform 1s',
          transform: `rotate(${rotate}deg)`,
          fontSize: '10px',
        }}
        alt={alt}
      />
    </div>
  );
};

export default Img;
