import { Tabs } from 'antd';
import { observer } from 'mobx-react-lite';

import rootStore from '../../../stores/rootStore/rootStore';
import Detailed from '../../ui-kit/Detailed/Detailed';
import CircleParamsConstructor from '../CircleParamsConstructor/CircleParamsConstructor';

import ConstructorPhaseImages from './ConstructorPhaseImages/ConstructorPhaseImages';
import TrafficLanesDetailed from './TrafficLanesDetailed/TrafficLanesDetailed';

import styles from './DetailedConstructor.module.scss';

const DETAILED_ITEMS = [
  {
    label: 'Настройки',
    key: 'item-1',
    children: <CircleParamsConstructor />,
  },
  {
    label: 'Изображения',
    key: 'item-2',
    children: <ConstructorPhaseImages />,
  },
  {
    label: 'Загруженность',
    key: 'item-3',
    children: <TrafficLanesDetailed />,
  },
];

function DetailedConstructor() {
  const { setIsNotConstructor } = rootStore.constructorStore;

  const detailedProps = {
    header: 'Конструктор',
    onClose: () => setIsNotConstructor('isConstructorDetailed'),
    isRightPanel: true,
  };

  return (
    <Detailed {...detailedProps}>
      <Tabs
        className={styles.tabsCustom}
        defaultActiveKey="1"
        items={DETAILED_ITEMS}
      />
    </Detailed>
  );
}

export default observer(DetailedConstructor);
