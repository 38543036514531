import { Map, Feature } from 'ol';
import type { Coordinate } from 'ol/coordinate';
import { Point } from 'ol/geom';
import { Interaction } from 'ol/interaction';
import SelectCluster from 'ol-ext/interaction/SelectCluster';

import { IInfoPanelData } from '../../../stores/uiStore/uiStore';
import {
  AlarmOverlaysColors,
  getColorStatusOverlay,
} from '../../Mapper/helpers/getColorStatusOverlay';

export interface IFeaturesArr {
  feature: Feature<Point>;
  coord?: Coordinate;
  isSelectFeature?: boolean;
  colorAlarm?: ValueOf<typeof AlarmOverlaysColors>;
}

export const getFeaturesCluster = (map: Map, infoData: N<IInfoPanelData>) => {
  const interactions = map.getInteractions().getArray();
  const select = interactions.find((interaction: Interaction) =>
    interaction instanceof SelectCluster ? interaction : null
  ) as N<SelectCluster>;

  if (!select) return null;

  const featuresSelect = select.getLayer().getSource()?.getFeatures() as U<
    Feature<Point>[]
  >;

  if (!featuresSelect) return null;

  const featuresArray: IFeaturesArr[] = featuresSelect.reduce(
    (acc: IFeaturesArr[], value) => {
      const features: Feature<Point>[] = value.get('features');

      if (!features) return acc;

      const colorAlarm = getColorStatusOverlay(features, infoData);

      if (colorAlarm) {
        const coord = value.getGeometry()?.getCoordinates();

        acc.push({
          feature: features[0],
          coord,
          isSelectFeature: true,
          colorAlarm,
        });
      }

      return acc;
    },
    []
  );

  return featuresArray;
};
