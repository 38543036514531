import { Button } from 'antd';
import { FC } from 'react';

import { ReactComponent as ArrowBack } from '../../../../assets/icons/arrow_back_extended.svg';
import rootStore from '../../../../stores/rootStore/rootStore';

import styles from './DetailedHeader.module.scss';

export interface DetailedHeaderProps {
  backBtnTitle: string;
  header: string;
  headerIco: string;
  handleBackBtn: () => void;
  onClose?: (value: any) => void;
}

const DetailedHeader: FC<DetailedHeaderProps> = (props) => {
  const { backBtnTitle, header, headerIco, handleBackBtn, onClose } = props;
  const { setKeyValue } = rootStore.uiStore;

  const onCloseEvent = (e: any) => {
    if (!onClose) return;
    setKeyValue('isModalLoading', false);
    onClose(e);
  };

  return (
    <header className={styles.header}>
      {backBtnTitle && (
        <Button className={styles.backBtn} type="text" onClick={handleBackBtn}>
          <span className={styles.backBtnTitle}>
            <ArrowBack className={styles.arrowBack} />
            {backBtnTitle}
          </span>
        </Button>
      )}

      <div className={styles.headerTitle}>
        {headerIco}
        <h1>{header}</h1>
      </div>
      {onClose ? (
        <Button
          className={styles.closeBtn}
          onClick={onCloseEvent}
          type="primary"
          shape="circle"
        >
          &times;
        </Button>
      ) : null}
    </header>
  );
};

export default DetailedHeader;
