import { isEqual } from 'lodash';
import Feature from 'ol/Feature';
import type { Point } from 'ol/geom';

import { IClusterCache } from './clusterStyles';
import { getClusterSystems } from './getClusterSystems';

export const getClusterCacheStyle = (
  features: Feature<Point>[],
  clusterCache: IClusterCache[]
) => {
  const newSystems = getClusterSystems(features);

  const styleCluster =
    clusterCache.find(({ systems }) => isEqual(systems, newSystems))?.style ??
    null;

  return styleCluster;
};
