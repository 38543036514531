import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { FiMapPin } from 'react-icons/fi';

import getSystemTitle from '../../../../helpers/getSystemTitle';
import rootStore from '../../../../stores/rootStore/rootStore';
import { IInfoPanelData } from '../../../../stores/uiStore/uiStore';
import { System } from '../../../../ts/enums/enums';
import Popover from '../../../ui-kit/Popover/Popover';

import Title from './Title/Title';

import styles from './InfoHeader.module.scss';

interface IInfoHeader {
  caption: U<string>;
  system: System;
  activeId: N<number>;
  infoData: IInfoPanelData;
}

const InfoHeader: FC<IInfoHeader> = ({
  caption,
  system,
  activeId,
  infoData,
}) => {
  const { tipsValues, setInfoData } = rootStore.uiStore;
  const popoverProps = {
    tipsDelay: tipsValues.interfaceTipsDelay,
    content: 'Поиск выбранного объекта на карте',
    placement: 'left',
  };

  return (
    <div className={styles.infoHead}>
      <Popover {...popoverProps}>
        <FiMapPin
          onClick={() =>
            setInfoData({ ...infoData, coordFeatureOnCluster: null })
          }
        />
      </Popover>
      <div>
        <Title caption={getSystemTitle(system, activeId)} />
        <Title caption={caption} isSub />
      </div>
    </div>
  );
};

export default observer(InfoHeader);
