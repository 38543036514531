import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import Slider from 'react-slick';

import { COLORS } from '../../constants/colorsConstants';
import rootStore from '../../stores/rootStore/rootStore';
import Widget from '../ui-kit/Widget/Widget';
import WidgetPercents from '../ui-kit/WidgetPercents/WidgetPercents';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { generateWgSet } from '../Widgets/Helpers/generateWgSet';
import { getPercents } from '../Widgets/Helpers/getPercents';

import getSliderSettings from './sliderSettings';

import styles from './WidgetSlider.module.scss';

const { SUCCESS, DISABLED } = COLORS;

function WidgetSlider() {
  const { widgetsFromMap } = rootStore.mapDataStore;
  const { isInfoPanel } = rootStore.uiStore;
  const sliderSettings = isInfoPanel
    ? getSliderSettings(-1)
    : getSliderSettings();

  return (
    <Slider {...sliderSettings} className={styles.slider}>
      {generateWgSet(widgetsFromMap).map(
        ({ title, total, ratio, sign, amounts }) => (
          <Fade key={uniqueId('widget_')} direction="up" triggerOnce>
            <Widget title={title} total={total}>
              <WidgetPercents
                percents={getPercents(ratio, total, amounts[0])}
                percentsSign={sign}
                color={total ? SUCCESS : DISABLED}
                amounts={amounts}
                total={total}
              />
            </Widget>
          </Fade>
        )
      )}
    </Slider>
  );
}

export default observer(WidgetSlider);
