import { sendError } from '../../helpers/sendError';
import { TLData } from '../../ts/models/tl.model';

const checkCycleTime = (data: TLData[]) =>
  data.map((tl) => {
    const { tlTacts } = tl.tlDetails;

    if (tlTacts) {
      const { cycle } = tl.tlStatus;

      const cycleFromTacts = tlTacts.reduce((res, tact) => {
        // eslint-disable-next-line no-param-reassign
        res += tact.time;

        return res;
      }, 0);

      if (cycle !== cycleFromTacts) {
        tl.tlStatus.cycle = cycleFromTacts;
        const title = 'Не соответствие данных';
        const error = `Продолжительность цикла в СО${tl.id} 
            не соответствует продолжительности тактов`;

        sendError(title, error);
      }
    }

    return tl;
  });

export default checkCycleTime;
