/* eslint-disable jsx-a11y/no-static-element-interactions */
import { message } from 'antd/lib';
import classNames from 'classnames';
import { FC, useState, MouseEvent } from 'react';
import { BsFileEarmark, BsFileEarmarkCheck } from 'react-icons/bs';

import styles from './Copy.module.scss';

interface CopyProps {
  copyString: string;
  className?: string;
}

const Copy: FC<CopyProps> = ({ className = '', copyString }) => {
  const [isCopy, setIsCopy] = useState<boolean>(false);

  const handleCopy = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    navigator.clipboard.writeText(copyString);
    message.success('Текст скопирован');
    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 3000);
  };

  const copyBtnStyle = classNames({
    [styles.copyButton]: true,
    [styles.copied]: isCopy,
    [className]: !!className,
  });

  return (
    <div className={copyBtnStyle} onClick={handleCopy} title="Скопировать">
      {isCopy ? <BsFileEarmarkCheck size={20} /> : <BsFileEarmark size={20} />}
    </div>
  );
};

export default Copy;
