import React, { Dispatch, FC } from 'react';
import { FaRegSmile } from 'react-icons/fa';
import { GiUsbKey } from 'react-icons/gi';
import { HiOutlineKey } from 'react-icons/hi';
import { SiMonkeytie } from 'react-icons/si';

import { LOGIN_METHODS } from '../../../constants/constants';

import styles from './LoginChoice.module.scss';

interface IAuthMethod {
  isFace: boolean;
  isFingerprint: boolean;
  isKey: boolean;
  isPassword: boolean;
}

interface ILoginChoce {
  authMethod: IAuthMethod;
  setAuthMethod: Dispatch<IAuthMethod>;
}

const LOGIN_METHOD_ICONS = {
  isFace: <FaRegSmile />,
  isFingerprint: <SiMonkeytie />,
  isKey: <GiUsbKey />,
  isPassword: <HiOutlineKey />,
};

const LoginChoice: FC<ILoginChoce> = ({ setAuthMethod, authMethod }) => {
  const handleAuthMethod = (key: string, bool: boolean) => {
    setAuthMethod({ ...authMethod, [key]: bool });
  };

  return (
    <section className={styles.choice}>
      <h1> Выберите вариант для входа в систему: </h1>
      <ul>
        {LOGIN_METHODS.map((item) => (
          <li className={styles.item} key={item.type}>
            <button onClick={() => handleAuthMethod(item.type, item.isActive)}>
              {LOGIN_METHOD_ICONS[item.type as keyof typeof LOGIN_METHOD_ICONS]}
              <div className={styles.row}>
                <h4 className={styles.rowTitle}>{item.title}</h4>
                <p className={styles.rowInfo}>{item.info}</p>
              </div>
            </button>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default LoginChoice;
