function getNormalizeDateTime() {
  const currentDateObj = new Date(),
    NormalizeDateTime = `${currentDateObj.toLocaleDateString(
      'ru'
    )} ${currentDateObj.toLocaleTimeString('ru')}`;

  return NormalizeDateTime;
}

export default getNormalizeDateTime;
