import { Form } from 'antd';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { PROFILE_SETTINGS_MAIN } from '../../constants/initialSettingsConstants';
import createArray from '../../helpers/createArray';
import useSettings from '../../hooks/useRemoteSettings';
import rootStore from '../../stores/rootStore/rootStore';
import {
  isMarkerKey,
  isMarkerValue,
  isUIStoreValue,
  UIAndMarkersKeys,
  UIAndMarkersValues,
} from '../../ts/models/profileSettings.model';
import AccordionSimple from '../ui-kit/AccordionSimple/AccordionSimple';

import DefaultSetting from './DefaultSetting/DefaultSetting';
import HintsSetting from './HintsSetting/HintsSetting';
import MapIconSetting from './MapIconSetting/MapIconSetting';

import styles from './ProfileSettings.module.scss';
const SETTINGS_TITLE = 'Настройки';
const HINTS_TITLE = 'всплывающие подсказки';

const APP_PATH = 'profile.settings';

const ProfileSettings = () => {
  const { uiStore } = rootStore;
  const { setInfoData, profileValues, tipsValues, setKeyValue } = uiStore;

  const { saveSettingsItem } = useSettings();
  const [accordionsState, setAccordionsState] = useState(
    createArray(PROFILE_SETTINGS_MAIN.length, true)
  );

  const handleProfileHybrid = (
    name: UIAndMarkersKeys,
    value: UIAndMarkersValues
  ) => {
    if (isMarkerKey(name))
      return (
        isMarkerValue(name, value) && saveSettingsItem(name, value, APP_PATH)
      );

    if (name === 'isAdjacentPhaseCircles' || name === 'isPhaseCircleLabel') {
      setInfoData(null);
    }

    if (isUIStoreValue(name, value)) {
      setKeyValue(name, value);
      saveSettingsItem(name, value, APP_PATH);
    }
  };

  const handleAccordionState = (i: number) => {
    const newState = [...accordionsState];

    newState[i] = !newState[i];
    setAccordionsState(newState);
  };

  const popOverProps = {
    content: 'Здесь будет какая-то подсказка',
    placement: 'left',
    tipsDelay: tipsValues.profileTipsDelay,
  };

  return (
    <section className={styles.settings}>
      <h3 className={styles.settingsTitle}>{SETTINGS_TITLE}</h3>
      <hr />
      <Form initialValues={profileValues} layout="horizontal">
        {PROFILE_SETTINGS_MAIN.map(({ TITLE, DATA }, i) => (
          <AccordionSimple
            isClosed={accordionsState[i]}
            key={uniqueId(`${TITLE}_`)}
            onClick={() => handleAccordionState(i)}
            title={TITLE}
          >
            <div className={styles.settingsContent}>
              {DATA.map(
                ({ LABEL, NAME, IS_RELOAD_REQUIRED, POPOVER_CONTENT }) => {
                  const props = {
                    key: uniqueId('#'),
                    popOverProps,
                    name: NAME,
                    popoverContent: POPOVER_CONTENT,
                    isReload: IS_RELOAD_REQUIRED,
                    label: LABEL,
                    handleProfileHybrid,
                  };

                  return NAME === 'mapIconsSize' ? (
                    <MapIconSetting {...props} />
                  ) : (
                    <DefaultSetting {...props} />
                  );
                }
              )}
            </div>
          </AccordionSimple>
        ))}
        <AccordionSimple isClosed title={HINTS_TITLE}>
          <HintsSetting
            popOverProps={popOverProps}
            handleProfileHybrid={handleProfileHybrid}
            tipsValues={tipsValues}
          />
        </AccordionSimple>
        <hr />
      </Form>
    </section>
  );
};

export default observer(ProfileSettings);
