import { Tag } from 'antd';
import { FC, useEffect, useState } from 'react';

import { getAllGroupsObj } from '../../helpers/getAllGroupsObj';

import styles from './GroupsBox.module.scss';

interface GroupsBoxProps {
  userGroups: number[];
}

interface IGroup {
  code: string;
  dsc: string;
  id: string;
  isChange: boolean;
  isDelete: boolean;
  name: string;
}

interface IGroupList {
  web: IGroup[];
  njs: IGroup[];
}

const ENTER_TEXT = 'Входит в группы ';
const NOT_ENTER_TEXT = 'Не входит в группы ';
const NJS = 'NJS';
const WEB = 'WEB';

const GroupsBox: FC<GroupsBoxProps> = ({ userGroups }) => {
  const [groupsList, setGroupsList] = useState<IGroupList>({
    web: [],
    njs: [],
  });

  const setupGroupsList = async () => {
    const groups = await getAllGroupsObj();

    setGroupsList(groups);
  };

  useEffect(() => {
    setupGroupsList();
  }, []);

  return (
    <div className={styles.groupsBox}>
      <div className={styles.groups}>
        {ENTER_TEXT + WEB}
        {userGroups && groupsList ? (
          userGroups.map((groupId: number) => {
            const group = groupsList.web.find(
              (el) => Number(el.id) === Number(groupId)
            );

            if (!group) return null;

            return <Tag key={groupId}>{group.name}</Tag>;
          })
        ) : (
          <div className="ant-tag">{NOT_ENTER_TEXT + WEB}</div>
        )}
      </div>
      <div className={styles.groups}>
        {ENTER_TEXT + NJS}
        {userGroups && groupsList ? (
          userGroups.map((groupId: number) => {
            const group: any = groupsList.njs.find(
              (el: any) => Number(el.id) === Number(groupId)
            );

            if (!group) return null;

            return (
              <Tag key={groupId} color="default">
                {group.name}
              </Tag>
            );
          })
        ) : (
          <div className="ant-tag">{NOT_ENTER_TEXT + NJS}</div>
        )}
      </div>
    </div>
  );
};

export default GroupsBox;
