import { Selected } from '../components/Panel/ConstructorPanel/ConstructorPanel.model';
import { FINAL_PREFIX } from '../constants/constructorConstants';

export const formatProfileItem = (item: Selected) => {
  if (item.is_final_version) {
    item.sname = FINAL_PREFIX + item.sname;
  }

  return item;
};

export const formatProfileList = (data: Selected[]) =>
  data.map((item) => formatProfileItem(item));
