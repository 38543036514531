import { z } from 'zod';

import {
  CONTROL_ACCESS_DEVICES,
  DEVICES_TYPES,
} from '../../constants/constants';
import { MapObjectFull } from '../../ts/enums/enums';
import { getZodMessageCustom } from '../helpers/getZodMessageCustom';
import { NNInt } from '../zod/utils.zod';

export const controlAccess = z.nativeEnum(CONTROL_ACCESS_DEVICES);

export const DeviceInfoSchema = z.object({
  caption: z.string(),
  controlAccess: controlAccess.nullable(),
  deviceId: NNInt,
  deviceTypeId: NNInt.refine(
    (val) => DEVICES_TYPES.includes(val),
    getZodMessageCustom({
      nameParent: 'deviceInfo',
      nameValue: 'deviceTypeId',
      rangeArr: DEVICES_TYPES,
    })
  ),
  dsc: z.string(),
  externalDeviceId: NNInt.nullable(),
  latitude: z.number(),
  longitude: z.number(),
  ogcFid1: z.number().nonnegative().nullable(),
  regionId: NNInt.nullable(),
  uid: z.string().nullable(),
});

export const LinkedDevicesSchema = z.record(
  z.nativeEnum(MapObjectFull),
  z.number().array().nullable()
);

export const BasicMapDataSchema = z.object({
  deviceInfo: DeviceInfoSchema,
  caption: z.string(),
  id: z.number().int(),
  latitude: z.number(),
  longitude: z.number(),
});

export const CoordinatesSchema = z.tuple([z.number(), z.number()]);

export const OtherSchema = z.string().nullable().optional();
