export const getPercents = (ratio: any, total: any, working: any) => {
  if (ratio) {
    return ratio;
  }
  if (total) {
    return Math.round((working * 100) / total);
  }

  return 0;
};
