import { FC } from 'react';

import styles from './JsonView.module.scss';

type TQuote = 'curly' | 'square';

interface QuoteProps {
  position: 'start' | 'end';
  parantObj: any;
}

const QuoteSympols = {
  start: {
    curly: '{',
    square: '[',
  },
  end: {
    curly: '}',
    square: ']',
  },
};

const Quote: FC<QuoteProps> = ({ position, parantObj }) => {
  if (!parantObj || typeof parantObj !== 'object') return null;

  const type: TQuote = Array.isArray(parantObj) ? 'square' : 'curly';

  return <span className={styles.quote}>{QuoteSympols[position][type]}</span>;
};

export default Quote;
