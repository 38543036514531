import dayjs from 'dayjs';

import { IQueryParamItem } from './../../../../../ts/models/table.model';
import { DATE_FORMAT } from './../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import { isDateValues } from './../OpenBtn/PopoverContent/helpers';

export const getFormFieldsetValues = (queryParams: IQueryParamItem[]) => {
  const values: { [key: string]: unknown } = {};

  queryParams.forEach((param) => {
    if (isDateValues(param.jvalueType, param.currentValue)) {
      const from = dayjs(param.currentValue.from, DATE_FORMAT);
      const to = dayjs(param.currentValue.to, DATE_FORMAT);

      values[param.jkey] = [dayjs(from), dayjs(to)];

      return;
    }
    values[param.jkey] = param.currentValue;
  });

  return values;
};

export const getInputNumberRules = () => {
  return [
    () => ({
      validator(_: any, value: string) {
        const newReg = new RegExp(/(?<!-)\d+/, 'g');

        if (!value || newReg.test(value)) {
          return Promise.resolve();
        }

        return Promise.reject(
          'Можно использовать только положительные значения'
        );
      },
    }),
  ];
};
