import dayjs from 'dayjs';

import rootStore from '../../../../../../../stores/rootStore/rootStore';

import 'dayjs/locale/ru';
import { MINUTES_IN_HOUR } from './getTimeWithTimeZone';

export const getTimeZones = () => {
  const { regionData } = rootStore.uiStore;

  if (!regionData) return null;

  const { timeZone } = regionData;

  if (!timeZone) return null;

  const timeZoneClient = dayjs().utcOffset() / MINUTES_IN_HOUR;

  const isOurTimeZone = timeZoneClient === timeZone;

  return { timeZoneRegion: timeZone, isOurTimeZone, timeZoneClient };
};
