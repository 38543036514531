/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button } from 'antd';
import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import { SYSTEM } from '../../../constants/constants';
import { findById } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import { TL } from '../../../ts/models/tl.model';
import { COMMANDS_CONTENT } from '../../TrafficLightDetailed/TlManagement/constants/constants';
import useInfoData from '../hooks/useInfoData';

import { getCurrentObject } from './helpers';
import InfoBtns from './InfoBtns/InfoBtns';
import InfoHeader from './InfoHeader/InfoHeader';
import InfoMain from './InfoMain/InfoMain';
import InfoRow from './InfoRow/InfoRow';
import NoCurrentObj from './NoCurrentObj/NoCurrentObj';

import styles from './SystemsInfo.module.scss';

const { LIGHTS } = SYSTEM;

interface IStatusMode {
  modeName: string;
  modeNumber: N<number>;
}

const SystemsInfo: FC = () => {
  const { isDetailedForm, setInfoData } = rootStore.uiStore;

  const { systemsObj, cameras, detectors, tls } = rootStore.mapDataStore;

  const { infoData } = useInfoData();
  const [statusMode, setStatusMode] = useState<IStatusMode>({
    modeName: '',
    modeNumber: null,
  });
  const [currentId, setCurrentId] = useState<number>(0);

  useEffect(() => {
    if (!infoData) return;

    const { id } = infoData;
    const currentObject = findById(tls, id);
    const mode = currentObject?.mode;

    if (mode !== undefined) {
      const name =
        COMMANDS_CONTENT.find((el) => el?.id === mode)?.body ||
        'Режим неизвестен';

      setStatusMode({ modeName: name, modeNumber: mode });
    }

    isNumber(id) && setCurrentId(id);
  }, [tls, infoData]);

  if (!infoData || isDetailedForm) return null;

  const { id, system } = infoData;

  if (!systemsObj[system]) return null;

  const systemItem = findById(systemsObj[system], id);

  const isLights = system === LIGHTS;

  const caption = systemItem?.caption ?? systemItem?.highway;

  const currentObject = isNumber(id)
    ? getCurrentObject(system, id, {
        tls,
        cameras,
        detectors,
      })
    : null;

  if (!currentObject || !isNumber(id)) return <NoCurrentObj />;

  const {
    deviceInfo: { controlAccess },
  } = currentObject;

  const isTlObject = (currentObject: unknown): currentObject is TL =>
    system === LIGHTS;

  const activeId = isTlObject(currentObject) ? currentObject?.extId : id;

  const handleClosePanel = () => setInfoData(null);

  return (
    <div className={styles.info}>
      <Button
        type="primary"
        shape="circle"
        className={styles.btnClose}
        onClick={handleClosePanel}
      >
        &times;
      </Button>
      <InfoHeader
        caption={caption}
        system={system}
        activeId={activeId}
        infoData={infoData}
      />
      <hr className={styles.line} />
      <InfoRow
        statusMode={statusMode}
        currentId={currentId}
        isLights={isLights}
      />
      {isLights && <hr className={styles.line} />}
      <InfoMain
        currentObject={currentObject}
        system={system}
        systemItem={systemItem}
        id={id}
      />
      <InfoBtns
        systemItem={systemItem}
        isLights={isLights}
        infoData={infoData}
        controlAccess={controlAccess}
      />
    </div>
  );
};

export default observer(SystemsInfo);
