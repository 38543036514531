import { isNumber } from 'lodash';
import { makeAutoObservable, observable } from 'mobx';
import { Feature } from 'ol';
import type { Coordinate } from 'ol/coordinate';
import { Geometry } from 'ol/geom';
import { fromLonLat, toLonLat } from 'ol/proj';
import { MouseEvent } from 'react';

import { IRegionData } from '../../api/serverObjects/info/info.zod';
import { IExternalResourse } from '../../components/ExternalResource/ExternalResource';
import {
  SYSTEM,
  EVENTS,
  THEME_PROPERTIES,
  THEME,
} from '../../constants/constants';
import {
  INITIAL_MARKERS,
  INITIAL_SETTINGS,
} from '../../constants/initialSettingsConstants';
import { ZOOM } from '../../constants/mapConstants';
import { MapObjectFull, PanelType, System } from '../../ts/enums/enums';
import { ITimePoints } from '../../ts/models/signalPrograms.model';
import ConstructorStore from '../constructorStore/constructorStore';
import RootStore from '../rootStore/rootStore';

import { TL } from './../../ts/models/tl.model';
import { IModuleData, ISecTls } from './uiStore.model';

const {
  LIGHTS,
  DETECTORS,
  CAMERAS,
  SPECIAL_TRANSPORT,
  PUBLIC_TRANSPORT,
  METEO,
} = SYSTEM;
const { RTA, ROAD_WORKS, ACTIVITIES, TRAFFIC_RESTRICTIONS } = EVENTS;

export type TipType = 'Отк' | number;

export interface TipsValues {
  interfaceTipsDelay: TipType;
  mapTipsDelay: TipType;
  mapButtonsTipsDelay: TipType;
  profileTipsDelay: TipType;
}

export interface IInfoPanelData {
  system: System;
  coordinate?: Coordinate;
  id: number | string;
  mapAction?: MapActions;
  coordFeatureOnCluster?: N<Coordinate>;
}

interface IUpdateEntityData {
  key: string;
  objectId: number;
  objectType: string;
}

enum UpdateEntityEvents {
  ScheduledTlsCommands = 'scheduledTlsCommands',
}

export enum infoPanelTypeContent {
  ClusterContent = 'clustersContent',
  SystemsContent = 'systemsContent',
}

export enum MapActions {
  Zoom = 'zoom',
  NoActions = 'noActions',
  Center = 'center',
  CenterZoom = 'centerZoom',
}

interface ITooltipData {
  id: number;
  system: System;
  clustersFeatures: Feature<Geometry>[];
}

export interface IClickedCartographyObj {
  id: number;
  system: System;
}

class UIStore {
  rootStore;
  key = '';
  theme = localStorage.theme ?? THEME.LIGHT;
  isSidebar = false;
  isPanel = false;
  isInfoPanel = false;
  isCollapseInfoPanel = false;
  isNotificationPanel = false;
  isWidgets = INITIAL_SETTINGS.isWidgets;
  isClusters = INITIAL_SETTINGS.isClusters;
  isAdjacentPhaseCircles = INITIAL_SETTINGS.isAdjacentPhaseCircles;
  isTlsDataFetched = true;
  isShowPhaseCircle = INITIAL_SETTINGS.isShowPhaseCircle;
  isPhaseCircleLabel = INITIAL_SETTINGS.isPhaseCircleLabel;
  isUnderCircleFill = INITIAL_SETTINGS.isUnderCircleFill;
  isPhaseCircleEnable = false;
  isModalLoading = false;
  isCenterDependsOnPanels = INITIAL_SETTINGS.isCenterDependsOnPanels;
  selectedModulItems: string[] = [];
  interfaceTipsDelay: TipType = INITIAL_SETTINGS.interfaceTipsDelay;
  mapTipsDelay: TipType = INITIAL_SETTINGS.mapTipsDelay;
  mapButtonsTipsDelay: TipType = INITIAL_SETTINGS.mapButtonsTipsDelay;
  profileTipsDelay: TipType = INITIAL_SETTINGS.profileTipsDelay;

  isSummaryInfo = INITIAL_SETTINGS.isSummaryInfo;
  isDetailedForm = false;
  mapIconsSize = INITIAL_SETTINGS.mapIconsSize;
  panelType: N<PanelType> = null;
  infoPanelType: N<infoPanelTypeContent> = null;
  infoData: IInfoPanelData | null = null;
  tooltipData: N<ITooltipData> = null;
  moduleData: N<IModuleData> = null;
  importedData: N<IModuleData> = null;
  markers = INITIAL_MARKERS;
  regionData: N<IRegionData> = null;
  logoName = INITIAL_SETTINGS.logo;
  externalResource: IExternalResourse | null = null;
  isOpenInfoPanel = INITIAL_SETTINGS.isOpenInfoPanel;
  isNeedOpenedCluster = INITIAL_SETTINGS.isNeedOpenedCluster;
  isInfoBtn = INITIAL_SETTINGS.isInfoBtn;
  selectedTL: N<TL> = null;
  isAlarmAlert = INITIAL_SETTINGS.isAlarmAlert;
  isDebugMode = INITIAL_SETTINGS.isDebugMode;
  updatePhaseHoldArray = false;
  phasesArray: ITimePoints[] = [];
  activeKey = '1';
  isMinificMapOpen = true;
  isMinificMap = INITIAL_SETTINGS.isMinificMap;
  isScaleLine = INITIAL_SETTINGS.isScaleLine;
  isWidthMode = false;
  isQueryParamsError = false;
  clickedCartographyObj: N<IClickedCartographyObj> = null;
  secTls: ISecTls[] = [];
  isTrafficLanes = false;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      markers: observable,
    });
    this.rootStore = rootStore;
    this.setTheme(this.theme);
  }

  setIsNot = (key: string, bool?: boolean | undefined) => {
    // @ts-ignore
    this[key] = bool !== undefined ? bool : !this[key];
  };

  setKeyValue = <K extends keyof this, T extends this[K]>(key: K, value: T) => {
    this[key] = value;
  };

  setIsMarkers = (key: string) => {
    const {
      isLights,
      isDetectors,
      isCameras,
      isStreams,
      isPublicTransport,
      isSpecialTransport,
      isMeteo,
      isRta,
      isRoadWorks,
      isActivities,
      isTrafficRestrictions,
    } = this.markers;

    if (
      key === this.infoData?.system ||
      this.infoData?.system === System.Clusters
    ) {
      this.setInfoData(null);
    }

    switch (key) {
      case LIGHTS:
        this.markers.isLights = !isLights;
        break;
      case DETECTORS:
        this.markers.isDetectors = !isDetectors;
        break;
      case CAMERAS:
        this.markers.isCameras = !isCameras;
        break;
      case SPECIAL_TRANSPORT:
        this.markers.isSpecialTransport = !isSpecialTransport;
        break;
      case PUBLIC_TRANSPORT:
        this.markers.isPublicTransport = !isPublicTransport;
        break;
      case METEO:
        this.markers.isMeteo = !isMeteo;
        break;
      case System.Streams:
        this.markers.isStreams = !isStreams;
        break;
      case RTA:
        this.markers.isRta = !isRta;
        break;
      case ROAD_WORKS:
        this.markers.isRoadWorks = !isRoadWorks;
        break;
      case ACTIVITIES:
        this.markers.isActivities = !isActivities;
        break;
      case TRAFFIC_RESTRICTIONS:
        this.markers.isTrafficRestrictions = !isTrafficRestrictions;
        break;
    }

    this.markers = { ...this.markers };
  };

  clearDataAsRegion = () => {
    const { setKeyValue, setIsNot, setInfoData } = this;

    localStorage.removeItem('regionId');
    setKeyValue('regionData', null);
    setKeyValue('panelType', null);
    setIsNot('isSidebar');
    setIsNot('isInfoPanel', false);
    setIsNot('isPanel', false);
    setIsNot('isDetailedForm', false);
    setInfoData(null);
  };

  updatePhaseHoldTrigger = (data: IUpdateEntityData) => {
    const { key, objectId, objectType } = data;

    switch (key) {
      case UpdateEntityEvents.ScheduledTlsCommands:
        objectType === MapObjectFull.trafficLights &&
          this.infoData?.id === objectId &&
          (this.updatePhaseHoldArray = !this.updatePhaseHoldArray);
        break;
      default:
        console.log('WS WARN: Незарегистрированная сущность для обновления');
        break;
    }
  };

  setMarkerKey = (key: string, value: boolean) => {
    // @ts-ignore
    this.markers[key] = value;
  };

  setSecTls = (idTl: number, secTl: N<number>) => {
    if (secTl === null) {
      this.secTls = this.secTls.filter(({ id }) => id !== idTl);

      return;
    }

    const secTLItem = this.secTls.find(({ id }) => id === idTl);

    if (secTLItem) {
      secTLItem.sec = secTl;

      return;
    }

    this.secTls.push({ id: idTl, sec: secTl });
  };

  setInfoData = (
    data: IInfoPanelData | null,
    mapAction = MapActions.CenterZoom
  ): void => {
    if (data === null) {
      this.isInfoPanel = false;
      this.infoData = null;
      this.rootStore.mapDataStore.setMapData('selectedFeature', null);

      return;
    }

    if (this.isDetailedForm) {
      this.isInfoPanel = false;
      this.infoData = { ...data, mapAction };

      return;
    }

    this.isInfoPanel = true;
    this.infoData = { ...data, mapAction };

    if (data.system === System.Clusters) {
      this.infoPanelType = infoPanelTypeContent.ClusterContent;

      return;
    }

    this.infoPanelType = infoPanelTypeContent.SystemsContent;
  };

  setTooltipData = (data: N<ITooltipData>) => (this.tooltipData = data);

  get tipsValues(): TipsValues {
    return {
      interfaceTipsDelay: this.interfaceTipsDelay,
      mapTipsDelay: this.mapTipsDelay,
      mapButtonsTipsDelay: this.mapButtonsTipsDelay,
      profileTipsDelay: this.profileTipsDelay,
    };
  }

  get profileValues() {
    return {
      ...this.markers,
      isWidgets: this.isWidgets,
      isClusters: this.isClusters,
      isAdjacentPhaseCircles: this.isAdjacentPhaseCircles,
      isPhaseCircleLabel: this.isPhaseCircleLabel,
      isUnderCircleFill: this.isUnderCircleFill,
      isSummaryInfo: this.isSummaryInfo,
      mapIconsSize: this.mapIconsSize,
      isShowPhaseCircle: this.isShowPhaseCircle,
      isCenterDependsOnPanels: this.isCenterDependsOnPanels,
      isDebugMode: this.isDebugMode,
      isMinificMap: this.isMinificMap,
      isScaleLine: this.isScaleLine,
      isAlarmAlert: this.isAlarmAlert,
      isOpenInfoPanel: this.isOpenInfoPanel,
      isInfoBtn: this.isInfoBtn,
      isNeedOpenedCluster: this.isNeedOpenedCluster,
    };
  }

  get secTL() {
    return this.secTls.length ? this.secTls[0].sec : 0;
  }

  // color theme
  get isDark() {
    return this.theme === 'dark';
  }

  setTheme = (theme: string) => {
    const { style } = document.body;

    THEME_PROPERTIES.forEach((property) => {
      style.setProperty(property, `var(${property}-${theme})`);
    });
    localStorage.setItem('theme', theme);
    this.theme = theme;
  };

  // panel state
  switchPanel = (e: MouseEvent<HTMLElement>) => {
    const id = e.currentTarget?.id as PanelType;

    const { isConstructorLoaded, setConstructorData }: ConstructorStore =
      this.rootStore.constructorStore;

    this.isModalLoading = false;
    this.externalResource = null;

    if (id === PanelType.Constructor) {
      this.setInfoData(null);

      if (!isConstructorLoaded) {
        setTimeout(() => setConstructorData('isConstructorLoaded', true), 200);
      }
    }

    if (id === this.panelType) {
      this.isPanel = !this.isPanel;
    } else {
      this.isPanel = true;
      this.panelType = id;
      this.moduleData = null;
    }
  };

  get isPhaseCircle() {
    const { currentZoom } = this.rootStore.mapDataStore;

    return (
      this.isTlsDataFetched &&
      this.isShowPhaseCircle &&
      this.infoData?.system === LIGHTS &&
      !this.isDetailedForm &&
      currentZoom >= ZOOM.BIG
    );
  }

  handlePopupFromTab = (e: Event, title: System) => {
    const id = Number((e.target as HTMLElement).id);
    const coordinate = this.rootStore.mapDataStore.getCoordinatesById(id);

    if (!isNumber(id) || !coordinate) return;

    this.setInfoData(
      {
        id,
        system: title,
        coordinate: toLonLat(coordinate),
      },
      MapActions.Center
    );
  };

  get initialMapCenter() {
    if (!this.regionData) return;

    return fromLonLat(this.regionData.mapCenterCoords);
  }
}

export default UIStore;
