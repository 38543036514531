import { FC } from 'react';

import { System } from '../../../../ts/enums/enums';
import { switchIco } from '../../../DetailedMain/helpers/switchHeaderIco';

import styles from './CircleMarkerPopup.module.scss';

export interface CircleMarkerPopupProps {
  system: U<System>;
  colorWithMode: string;
}

const CircleMarkerPopup: FC<CircleMarkerPopupProps> = ({
  system,
  colorWithMode,
}) => {
  return (
    <>
      <div
        className={styles.crosshairPopupMain}
        style={{
          backgroundColor: colorWithMode,
        }}
      >
        {switchIco(system)}
      </div>
    </>
  );
};

export default CircleMarkerPopup;
