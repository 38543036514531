import { Tooltip } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';

import { sliceTitleBy } from '../../../../../helpers/sliceTitle';

import styles from './Title.module.scss';

interface ITitle {
  caption: Nullish<string>;
  isSub?: boolean;
}
const MAX_TITLE_LENGTH = 50;

const Title: FC<ITitle> = ({ caption, isSub = false }) => {
  if (!caption) return null;

  const isTooltip = caption.length > MAX_TITLE_LENGTH;
  const titleStyles = classNames({
    [styles.infoTitle]: true,
    [styles.subTitle]: isSub,
  });

  return (
    <h3 className={titleStyles}>
      {isTooltip ? (
        <Tooltip title={caption} placement="bottom">
          {sliceTitleBy(caption, MAX_TITLE_LENGTH)}
        </Tooltip>
      ) : (
        caption
      )}
    </h3>
  );
};

export default Title;
