import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';

import styles from './ButtonIco.module.scss';

interface ButtonIcoProps {
  children: any;
  onClick: MouseEventHandler<HTMLButtonElement>;
  isActive?: boolean;
  isRoundTop?: boolean;
  isRoundBottom?: boolean;
  isRound?: boolean;
  isSmall?: boolean;
  isPrimary?: boolean;
  isHover?: boolean;
  style?: any;
  disabled?: boolean;
}

const ButtonIco: React.FC<ButtonIcoProps> = (props: ButtonIcoProps) => {
  const {
    children,
    onClick,
    isActive,
    isRoundTop,
    isRoundBottom,
    isRound,
    isSmall,
    isPrimary,
    isHover,
    style,
    disabled = false,
  } = props;

  const btnStyles = classNames({
    [styles.mainBtn]: true,
    [styles.active]: isActive,
    [styles.round]: isRound,
    [styles.roundTop]: isRoundTop,
    [styles.roundBottom]: isRoundBottom,
    [styles.small]: isSmall,
    [styles.primary]: isPrimary,
    [styles.hover]: isHover,
  });

  return (
    <button
      className={btnStyles}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default ButtonIco;
