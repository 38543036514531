import { useState } from 'react';

import ImageFormItems from './ImageItems/ImageFormItems';
import ConstructorPhaseInitForm from './InitForm/InitForm';

const ConstructorPhaseImages = () => {
  const [folder, setFolder] = useState('/sign1/');

  return (
    <>
      <ConstructorPhaseInitForm setFolder={setFolder} />
      <hr />
      <ImageFormItems folder={folder} />
    </>
  );
};

export default ConstructorPhaseImages;
