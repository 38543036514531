import { SYSTEM } from '../../../../../constants/constants';
import { findById } from '../../../../../helpers/findBy';
import { ICamera } from '../../../../../ts/models/camera.model';
import { TL } from '../../../../../ts/models/tl.model';

const { LIGHTS, CAMERAS, DETECTORS } = SYSTEM;

interface IProps {
  tls: TL[];
  cameras: ICamera[];
  detectors: any[];
}

export const getCurrentObject = (
  system: string,
  id: number,
  { tls, cameras, detectors }: IProps
) => {
  switch (system) {
    case LIGHTS:
      return findById(tls, id);
    case CAMERAS:
      return findById(cameras, id);
    case DETECTORS:
      return findById(detectors, id);
    default:
      return undefined;
  }
};
