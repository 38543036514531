import { IVideoLIstItem } from '../../stores/videoWallPandelStore/videoWallPanelStore.model';

export enum ListType {
  personal = 'personal',
  public = 'public',
}

export interface ISavedVideoLIst {
  id: number;
  dsc: string;
  name: string;
  isChange: boolean;
  isShared: boolean;
  jprofile: {
    videoList: IVideoLIstItem[];
  };
  region_id: number;
  sc_user_id: number;
}

export interface IProfileBody {
  name: string;
  dsc: string;
  data: {
    videoList: IVideoLIstItem[];
  };
}
