import { Input } from 'antd';
import { observer } from 'mobx-react-lite';

import rootStore from '../../../../stores/rootStore/rootStore';

import styles from './TrafficLanesDetailed.module.scss';

const TrafficLanesDetailed = () => {
  const { trafficLanes, setTrafficLane } = rootStore.constructorStore;

  if (!trafficLanes.length) {
    return <h3>Полосы загруженности не созданы</h3>;
  }

  return (
    <>
      <h2 className={styles.header}>Комментарии</h2>
      {trafficLanes.map(({ id, comment }) => {
        return (
          <div key={`lanes${id}`} className={styles.container}>
            <label>Блок полос загруженности №{id}</label>
            <Input
              placeholder="Введите комментарий"
              value={comment}
              onChange={(e) => setTrafficLane('comment', e.target.value, id)}
              size="large"
            />
          </div>
        );
      })}
    </>
  );
};

export default observer(TrafficLanesDetailed);
