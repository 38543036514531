/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import { Feature } from 'ol';
import { FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { MapActions } from '../../../stores/uiStore/uiStore';
import { System } from '../../../ts/enums/enums';
import useInfoData from '../hooks/useInfoData';
import LinkedsInfo from '../SystemsInfo/LinkedsInfo/LinkedsInfo';
import Crossroads from '../SystemsInfo/Сrossroads/Crossroads';

import styles from './ClusterInfo.module.scss';
interface ILinkedDevices {
  cameras: number[];
  detectors: number[];
  trafficLights: number[];
}

const ClusterInfo: FC = () => {
  const { setInfoData, isDetailedForm } = rootStore.uiStore;

  const { infoData } = useInfoData();

  const { clusterFeatures } = rootStore.mapDataStore;

  const initialLink = {
    cameras: [],
    detectors: [],
    trafficLights: [],
  };

  if (!infoData || isDetailedForm) return null;

  let link: ILinkedDevices = initialLink;

  if (clusterFeatures.length) {
    link = clusterFeatures.reduce((acc: ILinkedDevices, value: Feature) => {
      const system = value.get('system');
      const id = value.get('id');

      switch (system) {
        case System.Cameras:
          acc.cameras.push(id);

          return acc;
        case System.Detectors:
          acc.detectors.push(id);

          return acc;
        case System.Lights:
          acc.trafficLights.push(id);

          return acc;
        default:
          return acc;
      }
    }, initialLink);
  }

  const tabsItems = [
    {
      label: 'Все объекты',
      key: 'item-1',
      children: (
        <div className={styles.wrapper}>
          <LinkedsInfo linkedDevices={link} />
        </div>
      ),
    },
    {
      label: 'Перекрёстки',
      key: 'item-2',
      children: <Crossroads linkedDevices={link} />,
    },
  ];

  const handleClosePanel = () => setInfoData(null);

  return (
    <div className={styles.info}>
      <Button
        type="primary"
        shape="circle"
        className={styles.btnClose}
        onClick={handleClosePanel}
      >
        &times;
      </Button>
      <div
        className={styles.infoHead}
        onClick={() => setInfoData(infoData, MapActions.Center)}
      >
        <h3 className={styles.infoTitle}>Группа обьектов</h3>
      </div>
      <Tabs items={tabsItems} />
    </div>
  );
};

export default observer(ClusterInfo);
