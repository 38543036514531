type Type = string | number;

const getType = (type: Type) => {
  switch (type) {
    case 'ar':
      return 'AR_31';
    case 'edt':
      return 'ED_32';
    case 'off':
      return 'OFF_33';
    case 'yf':
      return 'YF_34';
    default:
      return type;
  }
};

const getImageUrl = (id: N<number>, type: Type = 'off') => {
  const signalType = getType(type);

  return `/media/sign1/Sg${id}_${signalType}.png`;
};

export default getImageUrl;
