import { uniqueId } from 'lodash';
import React from 'react';

import { COLORS } from '../../../constants/colorsConstants';
import useCounter from '../../../hooks/useCount';
import ProgressBar from '../ProgressBar/ProgressBar';

import styles from './WidgetPercents.module.scss';

const { SUCCESS, ERROR, DISABLED } = COLORS,
  STATUSES = [
    { color: SUCCESS, sign: 'работают', amount: '' },
    { color: ERROR, sign: 'ошибка', amount: '' },
    { color: DISABLED, sign: 'неактивны', amount: '' },
  ];

interface WidgetPercentsProps {
  percents: any;
  percentsSign: any;
  color: any;
  amounts: any;
  total: number;
}

function WidgetPercents(props: WidgetPercentsProps) {
  const { percents, percentsSign, color, amounts, total } = props;

  const [percentsCounter] = useCounter(percents, 1000);

  amounts.forEach((amount: any, i: any) => (STATUSES[i].amount = amount));

  return (
    <article className={styles.content}>
      <span className={styles.percents}>{`${percentsCounter}%`}</span>

      <span className={styles.percentsSign}>
        {`работающих ${percentsSign}`}
      </span>

      <ProgressBar color={color} completed={percents} />

      <div className={styles.statusContainer}>
        {STATUSES.map(({ color, sign, amount }) => {
          const col = total ? color : DISABLED;

          return (
            <div className={styles.status} key={uniqueId('widgetPercents_')}>
              <span className={styles.statusAmount} style={{ color: col }}>
                {amount}
              </span>

              <span className={styles.statusSign}>{sign}</span>
            </div>
          );
        })}
      </div>
    </article>
  );
}

export default WidgetPercents;
