import React, { Suspense, lazy } from 'react';

import { SYSTEM } from '../../constants/constants';
import ModalLoading from '../ui-kit/ModalLoading/ModalLoading';

const { LIGHTS } = SYSTEM,
  TrafficLightDetailed = lazy(
    () => import('../TrafficLightDetailed/TrafficLightDetailed')
  );

const { CAMERAS } = SYSTEM,
  CameraDetailed = lazy(() => import('../CameraDetailed/CameraDetailed'));

const { DETECTORS } = SYSTEM,
  TlDetectors = lazy(
    () => import('../TrafficLightDetailed/TlDetectors/TlDetectors')
  );

interface DetailedSwitcherProps {
  detailedData: any;
}

function DetailedSwitcher(props: DetailedSwitcherProps) {
  const { detailedData } = props;
  const { system, id } = detailedData;

  switch (system) {
    case LIGHTS:
      return (
        <Suspense fallback={<ModalLoading />}>
          <TrafficLightDetailed id={id} system={system} />
        </Suspense>
      );
    case CAMERAS:
      return (
        <Suspense fallback={<ModalLoading />}>
          <CameraDetailed id={id} system={system} />
        </Suspense>
      );
    case DETECTORS:
      return (
        <Suspense fallback={<ModalLoading />}>
          <TlDetectors system={system} id={id} />
        </Suspense>
      );
    default:
      return null;
  }
}

export default DetailedSwitcher;
