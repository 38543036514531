import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { HiMinusSm, HiPlus } from 'react-icons/hi';
import { TbZoomReset } from 'react-icons/tb';

import { ZOOM } from '../../../constants/mapConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import ButtonIco from '../../ui-kit/ButtonIco/ButtonIco';
import ButtonsBlock from '../../ui-kit/ButtonsBlock/ButtonsBlock';
import Popover from '../../ui-kit/Popover/Popover';

import styles from './ZoomBtns.module.scss';

const PLACEMENT = 'left';
const ALIENATE = {
  content: 'Отдалить',
};
const BRING_CLOSER = {
  content: 'Приблизить',
};
const RESET = {
  content: 'К начальному масштабу',
};

const ZoomBtns: FC = () => {
  const { isConstructor } = rootStore.constructorStore;
  const {
    mapButtonsTipsDelay,
    isInfoPanel,
    isCollapseInfoPanel,
    isDebugMode,
    regionData,
  } = rootStore.uiStore;
  const { setMapData, currentZoom } = rootStore.mapDataStore;
  const zoomStep =
    isConstructor || isDebugMode ? ZOOM.STEP_CONSTRUCTOR : ZOOM.STEP;

  const disabledMinZoom = regionData?.initialZoom ?? ZOOM.MIN;

  const handleZoom = (step: number) => {
    const newZoom = currentZoom + step;

    if (newZoom <= ZOOM.MAX && newZoom >= disabledMinZoom) {
      setMapData('currentZoom', newZoom);
    }
  };

  const increaseZoom = () => handleZoom(zoomStep);
  const decreaseZoom = () => handleZoom(-zoomStep);
  const resetZoom = () => setMapData('currentZoom', disabledMinZoom);

  const zoomStyles = classNames({
    [styles.zoom]: true,
    [styles.systemsOffset]: !isCollapseInfoPanel && isInfoPanel,
    [styles.constructorOffset]: isConstructor,
  });

  const popOverProps = {
    placement: PLACEMENT,
    // mouseEnterDelay: 2.2,
    tipsDelay: mapButtonsTipsDelay,
  };

  const isIncreaseDisabled = currentZoom + zoomStep > ZOOM.MAX;
  const isDecreaseDisabled = currentZoom - zoomStep < disabledMinZoom;
  const isResetDisabled = Math.floor(currentZoom) === disabledMinZoom;

  return (
    <div className={zoomStyles}>
      <ButtonsBlock borderRadius={6} className={styles.buttonBox}>
        <ButtonIco onClick={increaseZoom} disabled={isIncreaseDisabled}>
          <Popover content={BRING_CLOSER.content} {...popOverProps}>
            <HiPlus />
          </Popover>
        </ButtonIco>

        <ButtonIco onClick={decreaseZoom} disabled={isDecreaseDisabled}>
          <Popover content={ALIENATE.content} {...popOverProps}>
            <HiMinusSm />
          </Popover>
        </ButtonIco>
      </ButtonsBlock>

      {!isResetDisabled && (
        <div className={styles.reset}>
          <ButtonIco onClick={resetZoom}>
            <Popover content={RESET.content} {...popOverProps}>
              <TbZoomReset />
            </Popover>
          </ButtonIco>
        </div>
      )}
    </div>
  );
};

export default observer(ZoomBtns);
