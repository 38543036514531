import { Form, Switch } from 'antd';
import { FC } from 'react';

import { ISettingsProps } from '../../../ts/models/profileSettings.model';
import Popover from '../../ui-kit/Popover/Popover';

import styles from './DefaultSetting.module.scss';

const DefaultSetting: FC<ISettingsProps> = ({
  popOverProps,
  popoverContent,
  isReload,
  name,
  label,
  handleProfileHybrid,
}) => {
  return (
    <Popover {...popOverProps} content={popoverContent}>
      <Form.Item
        label={`${label}${isReload ? '*' : ''}`}
        name={name}
        valuePropName="checked"
        className={styles.settingsItem}
      >
        <Switch
          size="small"
          onChange={(val) =>
            handleProfileHybrid && handleProfileHybrid(name, val)
          }
        />
      </Form.Item>
    </Popover>
  );
};

export default DefaultSetting;
