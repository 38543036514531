import { useEffect, useState } from 'react';

import { IInfoPanelData } from '../../../stores/uiStore/uiStore';

import useInfoData from './useInfoData';

const useInfoDataPanelLogic = (infoData: N<IInfoPanelData>) => {
  const [prevInfoData, setPrevInfoData] = useState<N<IInfoPanelData>>(null);

  const { setInfoData } = useInfoData();

  useEffect(() => {
    if (prevInfoData && !infoData) {
      const timeoutId = setTimeout(() => {
        setInfoData(infoData);
      }, 500);

      return () => {
        clearTimeout(timeoutId);
      };
    }

    setInfoData(infoData);
    setPrevInfoData(infoData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoData]);

  return null;
};

export default useInfoDataPanelLogic;
