import { VERSION } from '../../../../constants/versions';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

const ADMIN = VERSION + '/admin';
const SYSTEMS = ADMIN + '/systems';
const DEVICES = '/devices/';

const devices = {
  getExistSystemDevicesList: (regions: any, subsystems: any, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.post(`${SYSTEMS + DEVICES}`, {
        regions,
        subsystems,
        headers: getAppPathHeaders(appPath),
      });

      return res.data;
    }),
  getExternalSystemDevicesList: (
    systemId: string,
    subsystemId: any,
    regionId: string | number,
    appPath: string
  ) =>
    tryCatchWrapper(async () => {
      const res = await http.get(`${SYSTEMS}/${systemId}/devices`, {
        params: {
          subsystemId: subsystemId,
          regionId,
        },
        headers: getAppPathHeaders(appPath),
      });

      return res.data;
    }),
  add: (id: string, deviceType: string, body: any, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.post(
        `${SYSTEMS}/${id}${DEVICES + deviceType}`,
        body,
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      return res;
    }),
  updateDevice: (
    systemId: string,
    deviceType: string,
    deviceId: number,
    body: { reasonDsc: string },
    appPath: string
  ) =>
    tryCatchWrapper(async () => {
      const res = await http.put(
        `${SYSTEMS}/${systemId}${DEVICES + deviceType}/${deviceId}`,
        body,
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      return res;
    }),
  delete: (
    systemId: string,
    deviceType: string,
    deviceId: number,
    data: { reasonDsc: string },
    appPath: string
  ) =>
    tryCatchWrapper(async () => {
      const res = await http.delete(
        `${SYSTEMS}/${systemId}${DEVICES + deviceType}/${deviceId}`,
        {
          data,
          headers: getAppPathHeaders(appPath),
        }
      );

      return res;
    }),
};

export default devices;
