import about from './about/about';
import groups from './groups';
import systems from './systems';

const adminApi = {
  groups,
  systems,
  about,
};

export default adminApi;
