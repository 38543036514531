import { VERSION } from '../../../../constants/versions';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

const ADMIN = VERSION + '/admin';
const SYSTEMS = ADMIN + '/systems';
const DEVICES_CM = '/devices/cm';

const camerasApi = {
  add: (id: number, body: any, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.post(`${SYSTEMS}/${id}${DEVICES_CM}`, body, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
};

export default camerasApi;
