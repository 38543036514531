const STATUS_CODES: Record<number, string> = {
  200: 'Успешно',
  201: 'Создано',
  500: 'Серверная ошибка',
  400: 'Клиентская ошибка',
  401: 'Нет авторизации',
  403: 'Нет доступа',
  404: 'Не найдено',
};

export const getStatusCodeDescription = (code: number): string => {
  return STATUS_CODES[code] ? STATUS_CODES[code] : '';
};
