import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useContext } from 'react';

import { findById } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import { IFeaturesArr } from '../../Map/helpers/getFeaturesCluster';
import useDinamicOverlays from '../../Map/hooks/useDinamicOverlays';
import MapContext from '../../Map/MapContext';

import styles from './InfoPopup.module.scss';

interface IInfoPopup {
  isCrosshairPopup: boolean;
}

const InfoPopup: FC<IInfoPopup> = ({ isCrosshairPopup }) => {
  const { map }: any = useContext(MapContext);

  const { tls, isBigZoom, isLargeZoom, center } = rootStore.mapDataStore;
  const { infoData } = rootStore.uiStore;
  const { isConstructor } = rootStore.constructorStore;

  const {
    elementRefs,
    isRemovedOverlays,
    featuresInExtent,
    isReadyElementRefs,
  } = useDinamicOverlays({
    map,
    featuresWithOverlays: [],
    isShowOverlay: isBigZoom,
    center,
    isConstructor,
    isInsertFirstOverlay: true,
  });

  const infoPopupStyle = classNames({
    [styles.infoPopup]: true,
    [styles.infoPopupLarge]: isLargeZoom,
  });

  const infoPopupChosenFeatureStyle = classNames({
    [styles.infoPopup]: true,
    [styles.infoPopupChosen]: isCrosshairPopup,
  });

  if (
    !(
      isReadyElementRefs &&
      featuresInExtent.length &&
      isBigZoom &&
      isRemovedOverlays &&
      elementRefs.length
    )
  )
    return null;

  return (
    <div>
      {featuresInExtent.map((el: IFeaturesArr, i: number) => {
        const id = el.feature.get('id');

        return (
          <div
            className={
              id === infoData?.id ? infoPopupChosenFeatureStyle : infoPopupStyle
            }
            key={`info${i}`}
            ref={elementRefs[i]}
          >
            <p>{findById(tls, id)?.extId ?? id}</p>
          </div>
        );
      })}
    </div>
  );
};

export default observer(InfoPopup);
