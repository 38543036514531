import { Button, Modal } from 'antd';
import React from 'react';

import ImportForm from '../ImportFrom/ImportFrom';

interface ModalImportProps {
  modalOpen: any;
  importedData: any;
  abortImport: any;
  importRequest: any;
  importLoading: any;
  fileName: string;
}

const ModalImport: React.FC<ModalImportProps> = (props: ModalImportProps) => {
  const {
    modalOpen,
    importedData,
    abortImport,
    importRequest,
    importLoading,
    fileName,
  } = props;

  return (
    <Modal
      title={`Импорт данных таблицы из файла: "${fileName}"`}
      open={modalOpen}
      width={1200}
      closable={false}
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={importLoading}
          onClick={importRequest}
        >
          Импортировать
        </Button>,
        <Button
          key="back"
          danger
          onClick={abortImport}
          disabled={importLoading}
        >
          Отмена
        </Button>,
      ]}
    >
      {importedData && <ImportForm importedData={importedData} />}
    </Modal>
  );
};

export default ModalImport;
