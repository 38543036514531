import { createContext, useState } from 'react';

import { IInfoPanelData } from '../../stores/uiStore/uiStore';

import InfoPanel from './InfoPanel';

interface IInfoDataContext {
  infoDataPanel: N<IInfoPanelData>;
  setInfoDataPanel: SetState<N<IInfoPanelData>>;
}

export const InfoDataContext = createContext<IInfoDataContext>({
  infoDataPanel: null,
  setInfoDataPanel: (value: N<IInfoPanelData>) => value,
});

const InfoPanelWithContext = () => {
  const [infoDataPanel, setInfoDataPanel] = useState<N<IInfoPanelData>>(null);

  return (
    <InfoDataContext.Provider
      value={{
        infoDataPanel,
        setInfoDataPanel,
      }}
    >
      <InfoPanel />
    </InfoDataContext.Provider>
  );
};

export default InfoPanelWithContext;
