import getImagePostfix from '../../../../helpers/getImagePostfix';
import getImageUrl from '../../../../helpers/getImageUrl';
import cacheStore from '../../../../stores/cacheStore/cacheStore';
import { TlMode } from '../../../../ts/models/tl.model';

const getImg = (tkt: number | string, extId: N<number>, mode: TlMode) => {
  const { cachedImages, setCachedImages } = cacheStore;
  const type = getImagePostfix(mode) ?? tkt;

  if (`${extId}_${type}` in cachedImages) {
    return cachedImages[`${extId}_${type}`];
  }
  const url = getImageUrl(extId, type);

  setCachedImages(`${extId}_${type}`, url);

  return url;
};

export default getImg;
