import { Feature } from 'ol';
import { Point } from 'ol/geom';

import {
  TRAFFIC_LIGHTS_STATUS_SETS,
  TStatusCategory,
} from '../../../constants/constants';
import { IInfoPanelData } from '../../../stores/uiStore/uiStore';

export const AlarmOverlaysColors = {
  ERRORS: 'var(--danger)',
  REMOTE_CONTROL: 'var(--antd-blue)',
  WORK: 'var(--green200)',
  NOT_ACTIVE: 'var(--white600)',
} as const;

export const getColorStatusOverlay = (
  features: Feature<Point>[],
  infoData: N<IInfoPanelData>
) => {
  const color = features.reduce(
    (acc: U<ValueOf<typeof AlarmOverlaysColors>>, item) => {
      const TL_STATUS = Object.keys(
        TRAFFIC_LIGHTS_STATUS_SETS
      ) as TStatusCategory[];

      const AlarmOverlayName = TL_STATUS.reduce(
        (acc: N<TStatusCategory>, el: TStatusCategory) => {
          if (!TRAFFIC_LIGHTS_STATUS_SETS[el].isAlarm) return acc;

        const isActive = TRAFFIC_LIGHTS_STATUS_SETS[el].mode.some(
          (alarmNumber) =>
            alarmNumber === item.get('mode') && item.get('id') !== infoData?.id
        );

        if (isActive) {
          return el;
        }

        return acc;
      },
      null
    );

      if (AlarmOverlayName) {
        return AlarmOverlaysColors[AlarmOverlayName];
      }

    return acc;
  }, undefined);

  return color;
};
