import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';

import videoWallApi from '../../../../../../api/videoWall/videoWallApi';
import { filterArrayByKey } from '../../../../../../helpers/filterArrayByKey';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { ListType } from '../../../../../../ts/models/videoWall.model';

import List from './FormCamerasLIst/List';

import styles from './SavedForms.module.scss';

const NOT_LIST_TEXT = 'Создайте профиль';
const ADD_BTN = 'Создать новый';

const SavedForms = () => {
  const { savedVideoLists, setVideoKeysValues, createNewVideoLIst } =
    rootStore.videoWallPanelStore;

  const fetchVideoList = useCallback(async () => {
    const res = await videoWallApi.getVideoList();

    if (!res?.profiles) return;

    setVideoKeysValues({ savedVideoLists: res.profiles });
  }, [setVideoKeysValues]);

  useEffect(() => {
    if (!savedVideoLists.length) fetchVideoList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchVideoList]);

  if (!savedVideoLists.length)
    return <p className={styles.text}>{NOT_LIST_TEXT}</p>;

  const [globalList, myList] = filterArrayByKey(savedVideoLists, 'isShared');

  return (
    <div className={styles.wrapper}>
      <div className={styles.addBtn}>
        <Button type="primary" onClick={createNewVideoLIst}>
          {ADD_BTN}
        </Button>
      </div>

      <List profiles={myList} type={ListType.personal} />
      <List profiles={globalList} type={ListType.public} />
    </div>
  );
};

export default observer(SavedForms);
