import { uniqueId } from 'lodash';

export const parsingTableData = (data: any) => {
  const newData = {
    dataRows: data.dataRows
      ? data.dataRows.map((item: any) => {
          item.key = uniqueId('_');

          return item;
        })
      : [],
    headerItems: data.headerItem
      ? data.headerItem.map((item: any) => {
          item.dataIndex = item.referens;

          return item;
        })
      : [],
  };

  return newData;
};
