import { toJS } from 'mobx';
import { ColInfo, utils, writeFileXLSX } from 'xlsx';

import { DataType } from '../../../../ts/models/table.model';

const MIN_WIDTH = 10;

const getRowsLengthArray = (data: DataType[]) => {
  const widths: any = {};

  data.forEach((item, i) => {
    Object.keys(item).forEach((key) => {
      const valueLength = String(item[key as keyof DataType]).length;

      if (!widths[key] || widths[key] < valueLength) {
        widths[key] = valueLength;
      }
    });
  });

  return Object.keys(widths).reduce((acc: ColInfo[], key) => {
    acc.push({ wch: Math.max(widths[key], key.length, MIN_WIDTH) });

    return acc;
  }, []);
};

const deleteEmptyProperty = (value: DataType) => {
  const isNotToTable = (value: unknown) => {
    return (
      typeof value !== 'boolean' &&
      typeof value !== 'number' &&
      typeof value !== 'string'
    );
  };

  Object.keys(value).forEach((key) => {
    if (
      isNotToTable(value[key as keyof DataType]) ||
      key === 'key' ||
      key === 'collaboration'
    ) {
      //@ts-ignore
      delete value[key];
    }
  });
};

const getDataForExport = (data: DataType[]) => {
  return data.map((item) => {
    const newItem = {
      ...item,
    };

    deleteEmptyProperty(newItem);

    return newItem;
  });
};

const getExportDataHeaders = (data: DataType) => {
  return Object.keys(data).map((key) => key.toLocaleUpperCase());
};

export const exportFile = (data: DataType[], type: string, title: string) => {
  const currentData = getDataForExport(toJS(data));
  const ws = utils.json_to_sheet(currentData);

  ws['!cols'] = getRowsLengthArray(currentData);
  utils.sheet_add_aoa(ws, [getExportDataHeaders(currentData[0])]);
  const wb = utils.book_new();

  utils.book_append_sheet(wb, ws, title);
  writeFileXLSX(wb, `${title}.${type}`);

  return;
};
