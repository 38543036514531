import { FC } from 'react';

import styles from './InfoBox.module.scss';

interface InfoBoxProps {
  title: string;
  children: JSX.Element;
}

const InfoBox: FC<InfoBoxProps> = ({ title, children }) => {
  return (
    <div className={styles.infoBox}>
      <h3>{title}</h3>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default InfoBox;
