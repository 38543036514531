import { observer } from 'mobx-react-lite';
import { MapBrowserEvent } from 'ol';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { FC, useContext, useEffect, useState } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { System } from '../../../ts/enums/enums';
import { getHit } from '../../Map/helpers/handlers';
import useClusters from '../../Map/hooks/useClusters';
import MapContext from '../../Map/MapContext';
import ChartClusterLayer from '../../ui-kit/MapLayers/ClusterLayers/ChartClusterLayer';
import { updateLights } from '../helpers';
import { getFeatures } from '../helpers/getFeatures';
import { IClusterLayers } from '../Mapper.model';

const TL_IDX = 1;

const ClusterLayers: FC<IClusterLayers> = ({
  newFeatures,
  setNewFeatures,
  setIsCrosshairPopup,
}) => {
  const { map }: any = useContext(MapContext);
  const [data, setData] = useState<any>(null);
  const [FeaturesWithClusters, setFeaturesWithClusters] =
    useState<N<Feature<Point>[]>>(null);

  const {
    tls,
    mapIconsData,
    clusterFeatures,
    isClusterOpen,
    setMapData,
    getErrorsTl,
  } = rootStore.mapDataStore;

  const { isConstructor } = rootStore.constructorStore;

  const {
    infoData,
    isAdjacentPhaseCircles,
    isPhaseCircle,
    markers,
    isOpenInfoPanel,
    isDetailedForm,
    clickedCartographyObj,
  } = rootStore.uiStore;

  const { featuresSelect, setHitFeatures } = useClusters({
    map,
    markers,
    infoData,
    clusterFeatures,
    isConstructor,
    isOpenInfoPanel,
    isDetailedForm,
    isClusterOpen,
    clickedCartographyObj,
  });

  useEffect(() => {
    getErrorsTl(FeaturesWithClusters, featuresSelect);
  }, [newFeatures, getErrorsTl, FeaturesWithClusters, featuresSelect]);

  useEffect(() => {
    if (!map) return;

    const handleClick = (e: MapBrowserEvent<any>) => {
      const hit = getHit(e, map);

      const featuresClick = map.forEachFeatureAtPixel(
        e.pixel,
        (feature: Feature) => feature.get('features')
      );

      setHitFeatures(hit ? featuresClick : []);
    };

    map.on('click', handleClick);

    return () => {
      map.un('click', handleClick);
    };
  }, [map, setHitFeatures]);

  const isPhaseCirclePopupTl =
    isPhaseCircle && infoData?.system === System.Lights;

  useEffect(() => {
    if (isConstructor) {
      setData([]);

      return;
    }

    if (!isPhaseCircle) {
      const newFeatures = getFeatures(mapIconsData);

      setData(newFeatures);
    }
  }, [isConstructor, isPhaseCircle, mapIconsData]);

  useEffect(() => {
    // Delete trafficLights icon for overlay
    if (data?.length && isPhaseCirclePopupTl) {
      setMapData('isClusterOpen', false);
      const newLights = updateLights(
        tls,
        mapIconsData[TL_IDX],
        infoData,
        isAdjacentPhaseCircles
      );
      const newData = [...data];

      newData[TL_IDX].features = newLights;
      setData(newData);
    }
  }, [mapIconsData, isPhaseCirclePopupTl, infoData, updateLights]); // eslint-disable-line

  useEffect(() => {
    if (data) {
      const newFeatures: Feature[] = [];

      data.forEach(({ features, isFeatures }: any) => {
        if (isFeatures) {
          newFeatures.push(...features);
        }
      });

      setNewFeatures(newFeatures);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!data) {
    return;
  }

  const chartProps = {
    features: newFeatures,
    setFeaturesWithClusters,
    setIsCrosshairPopup,
    setHitFeatures,
  };

  if (isPhaseCirclePopupTl) {
    const { isFeatures, features } = data[TL_IDX];

    chartProps.features = features;

    return isFeatures && <ChartClusterLayer {...chartProps} />;
  }

  return <ChartClusterLayer {...chartProps} />;
};

export default observer(ClusterLayers);
