import { observer } from 'mobx-react-lite';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import Cluster from 'ol/source/Cluster';
import SelectCluster from 'ol-ext/interaction/SelectCluster';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import { FC, useContext, useEffect, useMemo, useState } from 'react';

import {
  CLUSTERS,
  EMPTY_DURATION_CLUSTER,
} from '../../../../constants/mapClusterConstants';
import { CENTERING } from '../../../../constants/mapConstants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { MapActions } from '../../../../stores/uiStore/uiStore';
import { System } from '../../../../ts/enums/enums';
import MapContext from '../../../Map/MapContext';

import { SELECT_INTERACTIONS, Z_INDEX } from './constants/constants';
import { addFeaturesCluster } from './helpers/addFeaturesCluster';
import {
  getFeatureStyle,
  getStyleForCluster,
  IStyleCache,
} from './helpers/clusterStyles';
import { getEmptyFeature } from './helpers/getEmptyFeature';
import { getSelectInteraction } from './helpers/getSelectInteraction';
import {
  checkIfTwoDimensional,
  generateNewClusterSource,
  generateAnimatedClusters,
} from './helpers/helpers';
import { setCenterObjCartography } from './helpers/setCenterObjCartography';

const { DISTANCE, LOCATION_DISTANCE } = CLUSTERS;

interface ChartClusterLayerProps {
  features: Feature<Point>[];
  setFeaturesWithClusters: SetState<N<Feature[]>>;
  setIsCrosshairPopup: SetState<boolean>;
  setHitFeatures: SetState<U<Feature[]>>;
}

export interface IClusterSource {
  clusterAnimated: N<AnimatedCluster>;
  cluster: N<Cluster>;
}

const ChartClusterLayer: FC<ChartClusterLayerProps> = ({
  features,
  setFeaturesWithClusters,
  setIsCrosshairPopup,
  setHitFeatures,
}: ChartClusterLayerProps) => {
  const { map }: any = useContext(MapContext);
  const {
    isClusters,
    mapIconsSize,
    infoData,
    isPhaseCircle,
    isCenterDependsOnPanels,
    isInfoPanel,
    clickedCartographyObj,
    isNeedOpenedCluster,
    setInfoData,
  } = rootStore.uiStore;
  const {
    currentZoom,
    setClusters,
    isBigZoom,
    isLargeZoom,
    isClusterOpen,
    selectedFeature,
    clusterFeatures,
  } = rootStore.mapDataStore;

  const [clusterSource, setClusterSource] = useState<IClusterSource>({
    clusterAnimated: null,
    cluster: null,
  });

  const isTwoDimensional = checkIfTwoDimensional(features);
  const distance = isTwoDimensional ? LOCATION_DISTANCE : DISTANCE;

  const isOpen = useMemo(
    () => isNeedOpenedCluster && isClusterOpen,
    [isClusterOpen, isNeedOpenedCluster]
  );

  useEffect(() => {
    if (!map) return;

    // Исходный соурс
    const cluster = generateNewClusterSource();
    const clusterAnimated = generateAnimatedClusters(cluster);

    map.addLayer(clusterAnimated);
    clusterAnimated.setZIndex(Z_INDEX);
    setClusterSource({ clusterAnimated, cluster });

    return () => {
      if (map) {
        map.removeLayer(clusterAnimated);
      }
    };
  }, [map]);

  useEffect(() => {
    if (!map) return;

    const selectInteraction = new SelectCluster(SELECT_INTERACTIONS);

    map.addInteraction(selectInteraction);
  }, [map]);

  useEffect(() => {
    if (!map || !selectedFeature) return;

    const durDif = isCenterDependsOnPanels ? CENTERING.ANIMATION_DURATION : 0;
    const duration = EMPTY_DURATION_CLUSTER + durDif;

    const timeoutId = setTimeout(() => {
      const selectInteraction = getSelectInteraction(map);
      const sourceSelect = selectInteraction?.getLayer();
      const isEmptyCluster =
        clusterFeatures.length &&
        !sourceSelect?.getSource()?.getFeatures()?.length;

      if (!isEmptyCluster) return;
      selectInteraction?.selectCluster(selectedFeature);
    }, duration);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [clusterFeatures, isCenterDependsOnPanels, map, selectedFeature]);

  useEffect(() => {
    if (!map) return;

    const sourceSelect = getSelectInteraction(map);

    if (isNeedOpenedCluster && !sourceSelect) {
      setInfoData(null);
      const selectAnim = new SelectCluster({
        ...SELECT_INTERACTIONS,
      });

      map.addInteraction(selectAnim);

      return;
    }

    if (!isNeedOpenedCluster && sourceSelect) {
      sourceSelect.clear();
      map.removeInteraction(sourceSelect);
    }
  }, [map, isNeedOpenedCluster, setInfoData]);

  useEffect(() => {
    if (!map || !isOpen) return;

    const sourceSelect = getSelectInteraction(map)?.getLayer();

    sourceSelect?.setStyle((feature) => getFeatureStyle(feature, infoData));
  }, [infoData, map, isOpen]);

  useEffect(() => {
    if (!map) return;
    const isCluster = selectedFeature?.get('features')?.length > 1;
    const selectInteraction = getSelectInteraction(map);

    if (
      !isCluster ||
      !selectInteraction ||
      !selectedFeature ||
      !isCenterDependsOnPanels
    )
      return;

    const timeoutId = setTimeout(() => {
      selectInteraction.selectCluster(selectedFeature);
    }, CENTERING.ANIMATION_DURATION);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInfoPanel]);

  useEffect(() => {
    const isChangeCluster =
      infoData && infoData.mapAction === MapActions.Center && isClusterOpen;

    const isNeedClsCluster =
      !infoData ||
      (infoData?.system === System.Lights && isPhaseCircle) ||
      !infoData?.coordFeatureOnCluster;

    if (map && !isChangeCluster && isNeedClsCluster && !clickedCartographyObj) {
      getSelectInteraction(map)?.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, infoData, isPhaseCircle, clickedCartographyObj]);

  useEffect(() => {
    if (!map || !clusterSource.cluster || !clickedCartographyObj) return;

    setHitFeatures(undefined);

    setCenterObjCartography({
      map,
      clickedCartographyObj,
      clusterSource: clusterSource.cluster,
      clusterFeatures,
      setIsCrosshairPopup,
    });
  }, [
    map,
    clusterSource,
    clickedCartographyObj,
    setIsCrosshairPopup,
    clusterFeatures,
    setHitFeatures,
  ]);

  useEffect(() => {
    if (!clusterSource.cluster) return;

    clusterSource.cluster.setDistance(isClusters ? distance : 0);
  }, [isClusters, distance, clusterSource]);

  useEffect(() => {
    if (!map || !clusterSource.cluster) return;

    const source = clusterSource.cluster.getSource();

    if (!source) return;

    if (!features.length) return source.clear();

    addFeaturesCluster(source, features);
  }, [clusterSource, features, map]);

  useEffect(() => {
    if (!clusterSource.clusterAnimated) return;

    const styleCache: IStyleCache = {
      cluster: [],
      feature: [],
    };

    clusterSource.clusterAnimated.setStyle((feature) => {
      const features: Feature<Point>[] = feature.get('features');

      const idFeature = features[0].get('id');
      const isCluster = features.length > 1;
      const isSelectedFeature = infoData?.id === idFeature;

      if (isSelectedFeature && !isCluster) return getEmptyFeature();

      return getStyleForCluster(
        feature,
        infoData,
        mapIconsSize,
        isBigZoom,
        isLargeZoom,
        styleCache
      );
    });
  }, [
    clusterSource,
    isBigZoom,
    isLargeZoom,
    map,
    mapIconsSize,
    infoData,
    isNeedOpenedCluster,
  ]);

  useEffect(() => {
    const features = clusterSource.cluster?.getFeatures();

    if (!map || !features) return;

    const featuresCl = features.map((item) => item?.get('features'));

    setFeaturesWithClusters(features);
    setClusters(featuresCl);
  }, [
    clusterSource,
    map,
    setClusters,
    currentZoom,
    setFeaturesWithClusters,
    features,
    isClusters,
  ]);

  return null;
};

export default observer(ChartClusterLayer);
