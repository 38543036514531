import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { CameraProjectionProps } from '../../models/camera.model';
import CameraCompact from '../CameraCompact/CameraCompact';
import ConstructorCameraExtended from '../CameraExtended/CameraExtended';
import CameraWrapper from '../CameraWrapper/CameraWrapper';
import { MIN_ICO_SIZE } from '../constants/constants';

const CameraProjection: FC<CameraProjectionProps> = ({
  camera,
  rate,
  onClick,
  isExtended = false,
}) => {
  if (!camera) return null;

  const calcVal = (val: number) => Math.round(Number(val) * rate);
  const curSize = calcVal(camera.size);
  const size = curSize < MIN_ICO_SIZE ? MIN_ICO_SIZE : curSize;
  const props = {
    camera,
    calcVal,
    size,
    onClick,
  };

  if (isExtended) {
    return (
      <CameraWrapper {...props}>
        <ConstructorCameraExtended {...props} />
      </CameraWrapper>
    );
  }

  return (
    <CameraWrapper {...props}>
      <CameraCompact {...props} />
    </CameraWrapper>
  );
};

export default observer(CameraProjection);
