import { ITableConfigSettings } from '../ts/models/table.model';

export const TABLE_CONFIG_SETTINGS: ITableConfigSettings = {
  height: {
    large: 1080,
    middle: 703,
  },
  users: {
    large: 10,
    middle: 7,
  },
  groups: {
    large: 4,
    middle: 3,
  },
  settings: {
    large: 5,
    middle: 5,
  },
  fullScreen: {
    large: 13,
    middle: 10,
  },
};

export const CELLS_WIDTH = {
  xs: 150,
  s: 200,
};

export const MESSAGES = {
  SUCCESS_CONFIG_UPDATE: 'Доступ успешно изменен',
  SUCCESS_CONFIGS_LOAD: 'Список настроек успешно загружен',
};
