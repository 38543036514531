import { Segmented } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import Popover from '../../../ui-kit/Popover/Popover';

import { VIDEO_TABS_OPTIONS } from './constats';

import styles from './VideoWallPanelTabs.module.scss';

const VideoPanelTabs = () => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const [segment, setSegment] = useState<SegmentedValue>('saveSettings');
  const { setVideoKeysValues } = rootStore.videoWallPanelStore;

  const videoTabs = VIDEO_TABS_OPTIONS.map((el, i) => {
    const newEl = { ...el };

    newEl.label = (
      <Popover content={el.tabName} tipsDelay={interfaceTipsDelay}>
        {el.label}
      </Popover>
    );

    return newEl;
  });

  useEffect(() => {
    setVideoKeysValues({ segment: segment });
  }, [segment, setVideoKeysValues]);

  return (
    <div className={styles.wrapper}>
      <Segmented
        value={segment}
        onChange={setSegment}
        className={styles.segmented}
        options={videoTabs}
      />
    </div>
  );
};

export default observer(VideoPanelTabs);
