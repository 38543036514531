import Feature from 'ol/Feature';

export const moveFeatures = (features: Feature[], offset: number) =>
  features.reduce((res: Feature[], feature) => {
    const geometry = feature.getGeometry();

    if (geometry) {
      geometry.translate(offset, -offset);

      res.push(feature);
    }

    return res;
  }, []);

export const cloneFeatures = (features: Feature[]) =>
  features &&
  features.reduce((res: any, feature: Feature) => {
    res.push(feature.clone());

    return res;
  }, []);
