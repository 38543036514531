import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import rootStore from '../../../stores/rootStore/rootStore';
import Popover from '../../ui-kit/Popover/Popover';

import { MAIN_BTNS } from './constants/constants';

import styles from './MainBlock.module.scss';

const MainBlock = () => {
  const { isPanel, panelType, switchPanel, interfaceTipsDelay } =
    rootStore.uiStore;
  const { hasAccess } = rootStore.userDataStore;

  return (
    <div className={styles.wrapper}>
      {MAIN_BTNS.map(({ name, tip, code, className, Component }) => {
        const isActive = isPanel && name === panelType;

        if (!hasAccess(code)) return null;

        const btnStyle = classNames({
          [styles.mainBtn]: true,
          [styles.active]: isActive,
        });

        const icoStyle = classNames({
          [styles[className]]: true,
          [styles.active]: isActive,
        });

        return (
          <Popover
            content={tip}
            key={name as string}
            placement="right"
            tipsDelay={interfaceTipsDelay}
          >
            <button className={btnStyle} id={name} onClick={switchPanel}>
              <Component className={icoStyle} />
            </button>
          </Popover>
        );
      })}
    </div>
  );
};

export default observer(MainBlock);
