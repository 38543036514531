import { Menu, Spin } from 'antd';
import type { SpinProps, MenuProps } from 'antd/lib';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { IRequestItem } from '../../ts/TestingPanel.model';

import styles from './MenuSelect.module.scss';

export interface IMenuProps {
  menuItems: IRequestItem[];
  isError: boolean;
  isLoading: boolean;
}

const spinProps: SpinProps = {
  size: 'default',
  className: styles.spinner,
};

const MenuSelect: FC<IMenuProps> = ({ menuItems, isError, isLoading }) => {
  const { setPickedRequest } = rootStore.testingPanelStore;

  const handleSelect = (selected: any) => {
    const selectedData = selected.item.props;

    const pickedRequest: IRequestItem = {
      key: selectedData.key,
      label: selectedData.label,
      title: selectedData.title,
      href: selectedData.href,
      method: selectedData.method,
      status: selectedData.status,
      body: selectedData.body,
      children: null,
    };

    setPickedRequest(pickedRequest);
  };

  const menuSystemsProps: MenuProps = {
    items: menuItems,
    mode: 'inline',
    className: styles.systemsMenu,
  };

  return (
    <div className={styles.menuWrapper}>
      {isLoading && <Spin {...spinProps} />}
      {isError && <p>Ошибка загрузки меню</p>}
      <Menu
        {...menuSystemsProps}
        onSelect={(selected) => handleSelect(selected)}
      />
    </div>
  );
};

export default observer(MenuSelect);
