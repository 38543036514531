import { INITIAL_MARKERS } from '../../constants/initialSettingsConstants';
import rootStore from '../../stores/rootStore/rootStore';
import UIStore from '../../stores/uiStore/uiStore';

const { uiStore } = rootStore;

type MarkersKeys = keyof typeof uiStore.markers;
type UIStoreKeys = keyof UIStore;

type MarkersValue = ValueOf<typeof INITIAL_MARKERS>;
type UIStoreValue = ValueOf<typeof UIStore>;

export type UIAndMarkersKeys = MarkersKeys | UIStoreKeys;
export type UIAndMarkersValues = MarkersValue | UIStoreValue;

export const isMarkerKey = (key: UIAndMarkersKeys): key is MarkersKeys => {
  return Object.keys(INITIAL_MARKERS).includes(key);
};

export const isMarkerValue = (
  key: MarkersKeys,
  value: MarkersValue | UIStoreValue
): value is MarkersValue => {
  return typeof INITIAL_MARKERS[key] === typeof value;
};

export const isUIStoreValue = (
  key: UIStoreKeys,
  value: MarkersValue | UIStoreValue
): value is UIStoreValue => {
  return typeof uiStore[key] === typeof value;
};

export interface IPopoverProps {
  content: string;
  placement: string;
  tipsDelay: any;
}
export type IhandleProfileHybrid = (
  name: UIAndMarkersKeys,
  value: UIStore[keyof typeof uiStore]
) => void;

export interface ISettingsProps {
  popOverProps: IPopoverProps;
  name: UIAndMarkersKeys;
  popoverContent: string | JSX.Element;
  isReload: boolean;
  handleProfileHybrid?: IhandleProfileHybrid;
  label: string;
}

export interface ITips {
  interfaceTipsDelay: string;
  mapTipsDelay: string;
  mapButtonsTipsDelay: string;
  profileTipsDelay: string;
}
