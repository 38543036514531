import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import useDinamicOverlays from '../../Map/hooks/useDinamicOverlays';
import MapContext from '../../Map/MapContext';

import styles from './AlarmPopup.module.scss';

const AlarmPopup = () => {
  const { map }: any = useContext(MapContext);

  const { isAlarmAlert } = rootStore.uiStore;

  const { errorsTl, isLargeZoom, center } = rootStore.mapDataStore;

  const {
    elementRefs,
    isRemovedOverlays,
    featuresInExtent,
    isReadyElementRefs,
  } = useDinamicOverlays({
    map,
    isShowOverlay: isAlarmAlert,
    featuresWithOverlays: errorsTl || [],
    center,
    isOverlaysForAllFeatures: false,
  });

  if (
    !(
      isReadyElementRefs &&
      featuresInExtent.length &&
      isRemovedOverlays &&
      errorsTl
    )
  )
    return null;

  const overlayStyle = classNames({
    [styles.alarmPopup]: true,
    [styles.alarmPopupLarge]: isLargeZoom,
  });

  return (
    <div>
      {featuresInExtent.map((el, i) => {
        const { colorAlarm } = el;

        return (
          <div
            className={overlayStyle}
            style={{ borderColor: colorAlarm }}
            key={`alarms${i}`}
            ref={elementRefs[i]}
          ></div>
        );
      })}
    </div>
  );
};

export default observer(AlarmPopup);
