import React from 'react';

import { ReactComponent as CrashesIco } from '../../../../../assets/icons/ic_crashes.svg';
import { ReactComponent as EquipmentPerfomanceIco } from '../../../../../assets/icons/ic_equipment performance.svg';
import { ReactComponent as AverageSpeedIco } from '../../../../../assets/icons/ic_speed.svg';
import { ReactComponent as TrafficIntensityIco } from '../../../../../assets/icons/ic_traffic_intencity.svg';
import { ReactComponent as ViolationsIco } from '../../../../../assets/icons/ic_violations.svg';

export const PARAMS_DATA = [
  {
    title: 'средняя скорость движения',
    subtitle: '',
    ico: <AverageSpeedIco />,
    amount: 3.7,
    subAmount: '',
  },
  {
    title: 'интенсивность потока',
    subtitle: '',
    ico: <TrafficIntensityIco />,
    amount: 300,
    subAmount: 'авт/ч',
  },
  {
    title: 'количество аварий',
    subtitle: '(в т.ч. пострадавшие/жертвы)',
    ico: <CrashesIco />,
    amount: 10,
    subAmount: 25,
    victimsAmount: 5,
  },
  {
    title: 'количество правонарушений',
    subtitle: '',
    ico: <ViolationsIco />,
    amount: 115,
    subAmount: '',
  },
  {
    title: 'работоспособность оборудования',
    subtitle: '',
    ico: <EquipmentPerfomanceIco />,
    amount: 95,
    isPercents: true,
    subAmount: '1254 шт.',
  },
  {
    title: 'индекс эффективности',
    subtitle: '',
    ico: <AverageSpeedIco />,
    amount: 1,
  },
];
