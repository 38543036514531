import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import { FC, useEffect, useState } from 'react';

import { SECOND, SYSTEM } from '../../../../constants/constants';
import { findById } from '../../../../helpers/findBy';
import getSystemTitle from '../../../../helpers/getSystemTitle';
import parseTLMode from '../../../../helpers/parseTLMode';
import rootStore from '../../../../stores/rootStore/rootStore';
import { System } from '../../../../ts/enums/enums';
import { COMMANDS_CONTENT } from '../../../TrafficLightDetailed/TlManagement/constants/constants';
import { colorFromType } from '../../../ui-kit/MapLayers/ClusterLayers/helpers/colors';
import { POPUP_OFFSET } from '../../constants/constants';

import styles from './TooltipContent.module.scss';

interface IClusters {
  system: string;
  length: number;
}

interface ITooltipContent {
  isTooltip: boolean;
}

const { LIGHTS } = SYSTEM;

const TooltipContent: FC<ITooltipContent> = ({ isTooltip }) => {
  const { tooltipData, isClusters, mapIconsSize, mapTipsDelay } =
    rootStore.uiStore;
  const [isContent, setContent] = useState(false);

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (tooltipData && mapTipsDelay !== 'Отк') {
      timerId = setTimeout(() => setContent(true), mapTipsDelay * SECOND);
    } else {
      setContent(false);
    }

    return () => timerId && clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipData]);

  if (!tooltipData || !isContent) {
    return null;
  }

  const { id, system, clustersFeatures } = tooltipData;
  const { systemsObj, isClusterizationZoom, tls } = rootStore.mapDataStore;

  if (!isTooltip) return null;

  let caption = '';
  let toolTipStyle = '';
  let systemItem = null;
  let name = null;
  let mode = null;
  let clusters: IClusters[] = [];

  if (system === System.Clusters) {
    clusters = clustersFeatures.reduce(
      (acc: IClusters[], value: Feature<Geometry>) => {
        const system = value.get('system');
        const isNewSystem = acc.some((el: IClusters) => el?.system === system);

        if (isNewSystem) return acc;

        const length = clustersFeatures.reduce(
          (accN: number, it: Feature) =>
            accN + Number(it.get('system') === system),
          0
        );

        const item = { system, length };

        return acc?.length ? [...acc, item] : [item];
      },
      []
    );
  }

  if (system === LIGHTS) {
    const { tlStatus } = findById(tls, id);

    mode = tlStatus?.mode;
    name = findById(COMMANDS_CONTENT, mode)?.body || 'Режим неизвестен';
  }

  systemItem = id && systemsObj[system]?.find((el: any) => el.id === id);

  caption = systemItem?.caption ?? systemItem?.highway;
  toolTipStyle = styles.tooltip;

  const getOffset = () => {
    if (!isClusters && isClusterizationZoom) {
      return POPUP_OFFSET.SMALL;
    }
    if (system === System.Streams) {
      return POPUP_OFFSET.TINY;
    }
    const rate = mapIconsSize < 1 ? mapIconsSize : 1;

    return POPUP_OFFSET.DEFAULT * rate;
  };
  const isActualExist = (objArray: any[], objId: number) =>
    objArray && objArray.find((el) => el.id === objId);

  if (clusters?.length) {
    return (
      <>
        <div className={toolTipStyle} style={{ bottom: `${getOffset()}px` }}>
          <span className={styles.tooltipTitleClusters}>Группа объектов</span>
          <hr className={styles.line}></hr>
          {clusters.map((item: any) => (
            <div className={styles.clustersRow} key={uniqueId()}>
              <div
                className={styles.typeFeature}
                style={{ backgroundColor: colorFromType(item.system) }}
              />
              <span>
                - {item.system}: {item.length}
              </span>
            </div>
          ))}
          <div className={styles.arrow} />
        </div>

        <div
          className={styles.arrowShadow}
          style={{ bottom: `${getOffset()}px` }}
        />
      </>
    );
  }

  if (!isActualExist(systemsObj[system], id) || !systemItem) {
    return null;
  }

  return (
    <>
      <div className={toolTipStyle} style={{ bottom: `${getOffset()}px` }}>
        <span className={styles.tooltipTitle}>
          {getSystemTitle(system, system === LIGHTS ? systemItem.extId : id)}
        </span>

        {caption ? (
          <span className={styles.tooltipTitle}>{`(${caption})`}</span>
        ) : null}

        {system === LIGHTS ? (
          <>
            <hr className={styles.line}></hr>
            <span className={styles.tooltipTitleTl}>
              <span
                className={styles.status}
                style={{ backgroundColor: parseTLMode(mode)?.color }}
              ></span>
              <span> {name}</span>
            </span>
          </>
        ) : null}

        {systemItem?.info ? (
          <div className={styles.info}>
            <hr />

            {systemItem.info}
          </div>
        ) : null}

        <div className={styles.arrow} />
      </div>

      <div
        className={styles.arrowShadow}
        style={{ bottom: `${getOffset()}px` }}
      />
    </>
  );
};

export default observer(TooltipContent);
