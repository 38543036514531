/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Switch } from 'antd';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';

import { sliceStr } from '../../../helpers/formatString';

import styles from './Accordion.module.scss';

interface AccordionProps {
  title: string;
  data: any;
  setIsMarkers: (title: string) => void;
  onClick: any;
  activeId: number;
  isDisabled: boolean;
  isOpen?: boolean;
  isNeedScroll?: boolean;
}

function Accordion(props: AccordionProps) {
  const {
    title,
    data,
    setIsMarkers,
    onClick,
    activeId,
    isDisabled,
    isOpen,
    isNeedScroll,
  } = props;
  const [isCollapsed, setIsCollapsed] = useState(true),
    handleCollapse = () => isDisabled && setIsCollapsed(!isCollapsed),
    handleMarkers = () => setIsMarkers(title);

  const blocksRefsArray = useRef<HTMLButtonElement[]>([]);

  const addToRefs = useCallback((el: N<HTMLButtonElement>, index: number) => {
    if (!el || blocksRefsArray.current.includes(el)) return;
    blocksRefsArray.current.splice(index, 0, el);
  }, []);

  const executeScroll = (index: U<number>) => {
    if (!index) return;

    const activeElement = blocksRefsArray.current.find(
      ({ id }) => id === index.toString()
    );

    const timeoutId = setTimeout(() => {
      activeElement?.scrollIntoView({ behavior: 'smooth' });
    });

    return () => {
      clearTimeout(timeoutId);
    };
  };

  useEffect(() => {
    if (!isNeedScroll) return;

    executeScroll(activeId);
  }, [activeId, isNeedScroll]);

  useEffect(() => {
    if (!isDisabled) {
      setIsCollapsed(true);
    }
  }, [isDisabled, setIsCollapsed]);

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsCollapsed(isOpen);
    }
  }, [isOpen]);

  const isOpenBtnStyle = classNames({
      [styles.collapseBtn]: true,
      [styles.isAccordionOpen]: !isCollapsed,
    }),
    itemsStyle = classNames({
      [styles.items]: true,
      [styles.isOpen]: !isCollapsed,
      [styles.isHidden]: isCollapsed,
    });

  return (
    <section className={styles.container}>
      <div className={styles.header} onClick={handleCollapse}>
        <button className={isOpenBtnStyle}>
          <IoIosArrowForward />
        </button>

        <div className={styles.headerContent}>
          <div className={styles.info}>
            <h5 className={styles.title}>{title}</h5>

            <span className={styles.amount}>{data.length}</span>
          </div>

          <Switch
            checked={isDisabled}
            onChange={(value: boolean, e: any) => {
              e.stopPropagation();
              handleMarkers();
            }}
            size="small"
          />
        </div>
      </div>

      <div className={itemsStyle} onClick={onClick} role="presentation">
        <ul className={styles.list}>
          {data.map(({ id, highway, caption, extId, statusColor }: any) => (
            <li key={id}>
              <button
                className={activeId === id ? styles.activeRaw : undefined}
                id={id}
                ref={(ele) => {
                  addToRefs(ele, id);
                }}
              >
                <span id={`idx ${id}`}>{extId ?? id}</span>

                {sliceStr(highway ?? caption ?? '')}

                {statusColor ? (
                  <div
                    className={styles.trafficlightStatus}
                    style={{ backgroundColor: statusColor }}
                  ></div>
                ) : null}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default Accordion;
