import mapObjectsApi from '../../api/mapObjects/mapObjectsApi';
import { MapObjectType } from '../../ts/models/mapObject.model';

const service = {
  async getObjects(type: MapObjectType, regionId: number) {
    const data = await mapObjectsApi.fetchFullObject(type, regionId);

    return { data: data ?? [], isOk: Boolean(data?.length) };
  },
};

export default service;
