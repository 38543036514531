import { notification } from 'antd';
import { ZodError } from 'zod';

import NOTICE from '../constants/notificationConstants';
import { sendError } from '../helpers/sendError';

export interface Err {
  name: string;
  response: string;
  message: MessageChannel;
}

interface IParams {
  ignoreCodes?: number[];
  errorMessage?: string;
  errorDescription?: string;
}

const tryCatchWrapper = async function (f: any, params?: IParams) {
  try {
    return await f();
  } catch (e: any) {
    if (e?.name === 'AxiosError') {
      const res = e?.response;
      const message = res?.data?.message || e.message;

      if (params?.ignoreCodes?.includes(res.status)) {
        return res;
      }

      notification.error({
        message: params?.errorMessage ?? 'Ошибка сервера',
        description: params?.errorDescription ?? message,
      });

      sendError('Ошибка Сервера', e);

      return;
    }

    if (e instanceof ZodError) {
      notification.error(NOTICE.INCORRECT_DATA_TYPES);

      sendError('Ошибка Zod', e);
    }

    console.error(e);
  }
};

export default tryCatchWrapper;
