import { isEqual } from 'lodash';
import { makeAutoObservable, action, flow } from 'mobx';
import { Feature } from 'ol';
import type { Coordinate } from 'ol/coordinate';
import { getCenter } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import { Point } from 'ol/geom';
import { fromLonLat, toLonLat } from 'ol/proj';

import { IRegionData } from '../../api/serverObjects/info/info.zod';
import { IFeaturesArr } from '../../components/Map/helpers/getFeaturesCluster';
import { formatGeoLonLat } from '../../components/Mapper/helpers';
import { deleteSelectedClusterAlarm } from '../../components/Mapper/helpers/deleteSelectedClusterAlarm';
import { getErrorsObj } from '../../components/Mapper/helpers/getErrorsObj';
import { getFilteredTLLinkedDevices } from '../../components/TrafficLightDetailed/helpers/getFilteredTLLinkedDevices';
import { SYSTEM, EVENTS } from '../../constants/constants';
import { ZOOM } from '../../constants/mapConstants';
import data from '../../data/data';
import geoDataLeftlane from '../../data/geoData_leftlane.json';
import geoDataRightlane from '../../data/geoData_rightlane.json';
import parseTLMode from '../../helpers/parseTLMode';
import { System } from '../../ts/enums/enums';
import { ICamera } from '../../ts/models/camera.model';
import { BasicMapData } from '../../ts/models/mapObject.model';
import { TL } from '../../ts/models/tl.model';
import checkCycleTime from '../helpers/checkCycleTime';
import getWidgetsData from '../helpers/combineWgData';
import { getArrayIdsFeatures } from '../helpers/getArrayIdsFeatures';
import setObjectProperty from '../helpers/setObjectProperty';
import RootStore from '../rootStore/rootStore';

import { IWSItem, MapDataArrays, TLRes } from './mapDataStore.model';
import service from './mapDataStore.service';

const {
  LIGHTS,
  DETECTORS,
  CAMERAS,
  SPECIAL_TRANSPORT,
  PUBLIC_TRANSPORT,
  METEO,
} = SYSTEM;

const { RTA, ROAD_WORKS, ACTIVITIES, TRAFFIC_RESTRICTIONS } = EVENTS;
const RATE = 34;

class MapDataStore {
  rootStore;
  systemKey = '';
  tls: TL[] = [];
  cameras: ICamera[] = [];
  detectors: any[] = [];
  streams: Feature[] = [];
  currentZoom = ZOOM.INITIAL;
  coordinates: Coordinate = [0, 0];
  publicTransport = data.publicTransport;
  specialTransport = data.specialTransport;
  roadWorks = data.roadWorks;
  rta = data.rta;
  meteo = data.meteo;
  tlCurrentTacts: any[] = [];
  wheelZoom = 0;
  errorsTl: IFeaturesArr[] | null = [];
  clusters: Feature[][] = [];
  clusterFeatures: Feature<Point>[] = [];
  isClusterOpen = false;
  center: Coordinate = [];
  searchedArea = '';
  searchedValue = '';
  percentLoad = 0;
  selectedCamera: N<ICamera> = null;
  selectedFeature: N<Feature<Point>> = null;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      fetchTrafficLights: flow.bound,
      fetchDetectors: flow.bound,
      fetchCameras: flow.bound,
      fetchStreams: action.bound,
      getCoordinatesById: action.bound,
    });
    this.rootStore = rootStore;
  }

  setMapData = <K extends keyof this, T extends this[K]>(key: K, data: T) => {
    return (this[key] = data);
  };

  setSystemsData({ object, detect, camera }: any) {
    this.tls = object;
    this.detectors = detect;
    this.cameras = camera;
  }

  *fetchTrafficLights(regionId: number) {
    const { data, isOk }: TLRes = yield service.getObjects('tl', regionId);

    !isOk && this.rootStore.uiStore.setKeyValue('isTlsDataFetched', false);

    this.setPercent(200);

    const correctedData = checkCycleTime(data);

    console.log('tls', correctedData);
    const trafficLightsData = correctedData.map((el) => ({
      ...el,
      extId: el.idMgpAPI,
      highway: el.featureOGS.properties.highway,
      latitude: el.tlGeneral.imagesPoint?.geometry.coordinates[0][1],
      longitude: el.tlGeneral.imagesPoint?.geometry.coordinates[0][0],
      mode: el.tlStatus.mode,
      linkedDevices: getFilteredTLLinkedDevices(el.linkedDevices, el.id),
    }));

    this.tls = trafficLightsData;
  }

  *fetchDetectors(regionId: number) {
    const { data } = yield service.getObjects('dt', regionId);

    this.setPercent(400);

    this.detectors = data;
  }

  *fetchCameras(regionId: number) {
    const { data } = yield service.getObjects('cm', regionId);

    this.setPercent(600);
    this.cameras = data;
  }

  fetchDataByRegion = (regionData: IRegionData) => {
    const regionId = regionData.id;

    this.rootStore.videoWallPanelStore.clearAllData();
    this.coordinates = regionData.mapCenterCoords;
    this.rootStore.uiStore.infoData = null;
    this.fetchTrafficLights(regionId);
    this.fetchDetectors(regionId);
    this.fetchCameras(regionId);
  };

  setClusters = (features: Feature[][]) => {
    this.clusters = features.reduce((acc: Feature[][], value) => {
      value.length > 1 && acc.push(value);

      return acc;
    }, []);
  };

  setPercent = (time: number) => {
    setTimeout(() => {
      const newValue = this.percentLoad + RATE;

      this.setMapData('percentLoad', newValue);
    }, time);
  };

  getErrorsTl = (
    FeaturesWithClusters: N<Feature<Point>[]>,
    featuresSelect: N<IFeaturesArr[]>
  ) => {
    if (!FeaturesWithClusters) return;

    const infoData = this.rootStore.uiStore.infoData;

    const errorsFeatures = getErrorsObj(FeaturesWithClusters, infoData);

    if (featuresSelect?.length && infoData) {
      errorsFeatures.push(...featuresSelect);
    }

    const errorsAlarmsFeatures = deleteSelectedClusterAlarm(errorsFeatures);

    const isDeleteAlarm =
      !errorsAlarmsFeatures.length ||
      this.rootStore.constructorStore.isConstructor ||
      !this.rootStore.uiStore.isAlarmAlert ||
      (this.rootStore.uiStore.isPhaseCircle && infoData);

    if (isDeleteAlarm) {
      return (this.errorsTl = []);
    }

    const idArrayNew = getArrayIdsFeatures(errorsAlarmsFeatures);
    const idArrayOld = getArrayIdsFeatures(this.errorsTl);

    if (!isEqual(idArrayNew, idArrayOld)) {
      this.errorsTl = errorsAlarmsFeatures;
    }
  };

  get widgetsFromMap() {
    const { tls, detectors, cameras } = this;

    return getWidgetsData({
      tls,
      detectors,
      cameras,
    });
  }

  fetchStreams() {
    const featuresRight = new GeoJSON().readFeatures(
      formatGeoLonLat(geoDataRightlane, undefined)
    );
    const featuresLeft = new GeoJSON().readFeatures(
      formatGeoLonLat(geoDataLeftlane, undefined)
    );

    this.streams = [...featuresRight, ...featuresLeft].map((feature) => {
      feature.set('system', System.Streams);

      return feature;
    });
  }

  get isClusterizationZoom() {
    return this.currentZoom < ZOOM.CLUSTERS_BORDER;
  }

  get scale() {
    if (this.currentZoom < ZOOM.FAR) {
      return 'far';
    } else if (ZOOM.MEDIUM < this.currentZoom && this.currentZoom > ZOOM.FAR) {
      return 'aboveMedium';
    } else if (
      ZOOM.MEDIUM > this.currentZoom &&
      this.currentZoom < ZOOM.CLOSE
    ) {
      return 'belowMedium';
    }

    return 'close';
  }

  get isBigZoom() {
    return this.currentZoom >= ZOOM.BIG;
  }

  get isLargeZoom() {
    return this.currentZoom >= ZOOM.LARGE;
  }

  get isCrossroadBorder() {
    return this.currentZoom >= ZOOM.CROSSROAD_BORDER;
  }

  get filteredSystems() {
    if (!this.searchedArea) return this.systems;

    return this.systems.filter((item) => item.title === this.searchedArea);
  }

  getStreamsData = () =>
    this.streams.reduce((res: BasicMapData[], feature: Feature) => {
      const extent = feature?.getGeometry()?.getExtent();

      if (extent) {
        const [longitude, latitude] = toLonLat(getCenter(extent));

        //unused on project, but maybe will use on future
        //@ts-ignore
        res.push({
          id: feature.get('id'),
          caption: feature.get('name'),
          longitude,
          latitude,
        });
      }

      return res;
    }, []);

  get systems() {
    const { uiStore } = this.rootStore;

    const {
      isLights,
      isDetectors,
      isCameras,
      isSpecialTransport,
      isPublicTransport,
      isMeteo,
    } = uiStore.markers;

    const tlsData = this.tls.map((tl) => {
      const { color } = parseTLMode(tl.tlStatus.mode);

      tl.statusColor = color;

      return tl;
    });

    return [
      {
        title: CAMERAS,
        data: this.cameras,
        isMarkers: isCameras,
      },
      {
        title: LIGHTS,
        data: tlsData,
        isMarkers: isLights,
      },
      {
        title: DETECTORS,
        data: this.detectors,
        isMarkers: isDetectors,
      },
      {
        title: SPECIAL_TRANSPORT,
        data: [],
        isMarkers: isSpecialTransport,
      },
      {
        title: PUBLIC_TRANSPORT,
        data: [],
        isMarkers: isPublicTransport,
      },
      {
        title: METEO,
        data: [],
        isMarkers: isMeteo,
      },
    ];
  }

  get systemsObj() {
    return {
      [LIGHTS]: this.tls,
      [DETECTORS]: this.detectors,
      [CAMERAS]: this.cameras,
      [System.Streams]: this.streamsData.data,
      [PUBLIC_TRANSPORT]: this.publicTransport,
      [SPECIAL_TRANSPORT]: this.specialTransport,
      [METEO]: this.meteo,
      [ROAD_WORKS]: this.roadWorks,
      [RTA]: this.rta,
    };
  }

  get systemsArray() {
    return [
      ...this.tls,
      ...this.detectors,
      ...this.cameras,
      ...this.publicTransport,
      ...this.meteo,
      ...this.specialTransport,
      ...this.rta,
      ...this.roadWorks,
    ];
  }

  get streamsData() {
    return {
      title: System.Streams,
      data: this.getStreamsData(),
      isMarkers: this.rootStore.uiStore.markers.isStreams,
    };
  }

  get events() {
    const { isRta, isRoadWorks, isActivities, isTrafficRestrictions } =
      this.rootStore.uiStore.markers;

    return [
      {
        title: RTA,
        data: [],
        isMarkers: isRta,
      },
      {
        title: ROAD_WORKS,
        data: [],
        isMarkers: isRoadWorks,
      },
      {
        title: ACTIVITIES,
        data: [],
        isMarkers: isActivities,
      },
      {
        title: TRAFFIC_RESTRICTIONS,
        data: [],
        isMarkers: isTrafficRestrictions,
      },
    ];
  }

  get mapIconsData() {
    return [...this.systems, ...this.events];
  }

  getCoordinatesById(id: number) {
    const activeItem = this.systemsArray.find((el) => el['id'] === id);

    if (activeItem?.longitude && activeItem?.latitude) {
      return fromLonLat([activeItem?.longitude, activeItem?.latitude]);
    }
  }

  getMapData = (systemTitle: string) => {
    switch (systemTitle) {
      case LIGHTS:
        return this.tls;
      case DETECTORS:
        return this.detectors;
      case CAMERAS:
        return this.cameras;
      case System.Streams:
        return this.streams;
      case PUBLIC_TRANSPORT:
        return this.publicTransport;
      case SPECIAL_TRANSPORT:
        return this.specialTransport;
      case METEO:
        return this.meteo;
      case ROAD_WORKS:
        return this.roadWorks;
      case RTA:
        return this.rta;
      default:
        return [];
    }
  };

  getById = <K>(id: number, systemKey: Extract<K, MapDataArrays>) => {
    return this[systemKey].find((el: IWSItem) => el.id === id);
  };

  addItemWS<K, T>(type: Extract<K, MapDataArrays>, id: number, item: T) {
    const currentObject = this[type].find((el: IWSItem) => el.id === id);

    if (!currentObject) {
      this[type] = [...this[type], item] as this[typeof type];
    }
  }

  deleteItemWS<K>(type: Extract<K, MapDataArrays>, id: number) {
    const currentObject = this[type].find((el: IWSItem) => el.id === id);

    if (currentObject) {
      const { infoData, setInfoData } = this.rootStore.uiStore;

      if (infoData?.id === id) {
        setInfoData(null);
      }

      this[type] = this[type].filter((el: IWSItem) => el.id !== id);
    }
  }

  updateItemWS<K>(
    type: Extract<K, MapDataArrays>,
    id: number,
    path: string[],
    value: any
  ) {
    const currentObject = this[type].find((el: IWSItem) => el.id === id);

    if (!currentObject) return;

    if (path.at(-1) === 'mode') {
      const newTL = [...this.tls];
      const propsId = id;
      const idx = newTL.findIndex(({ id }) => id === propsId);

      newTL[idx].mode = value;
      newTL[idx].tlStatus.mode = value;

      return (this.tls = newTL);
    }

    setObjectProperty(currentObject, path, value);
  }
}

export default MapDataStore;
