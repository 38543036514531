import { ReactNode } from 'react';

type Access = 'accessNJSGroups' | 'accessWebGroups' | 'accessWebUsers' | string;

type CurrentIcons = Record<Access, JSX.Element>;

export interface IAdminsPanelTree {
  key: Access;
  label: string | ReactNode;
  icon?: ReactNode;
  children?: IAdminsPanelTree[];
  icontype?: string;
}

export const addIconInAdminPanel = (
  array: IAdminsPanelTree[],
  currentIcons: CurrentIcons
) => {
  array.forEach((item) => {
    item.icontype
      ? (item.icon = currentIcons[item.icontype])
      : (item.icon = currentIcons[item.key]);

    if (item.children) {
      item.icontype
        ? (item.icon = currentIcons[item.icontype])
        : (item.icon = currentIcons[item.key]);

      return addIconInAdminPanel(item.children, currentIcons);
    }
  });

  return array;
};

export const addDefaultCityChildren = (cityName: string) => {
  const children = [
    {
      label: 'Светофорные объекты',
      key: `SO${cityName}`,
      icontype: 'trafficLight',
      children: [],
    },
    {
      label: 'Детекторы Транспорта',
      key: `DT${cityName}`,
      icontype: 'detector',
      children: [],
    },
    {
      label: 'Метеостанции',
      key: `Meteo${cityName}`,
      icontype: 'meteo',
      children: [],
    },
    {
      label: 'Камеры видеонаблюдения',
      key: `CV${cityName}`,
      icontype: 'video',
      children: [],
    },
  ];

  return children;
};

export const addSystemChildren = (key: string) => {
  const children = [
    {
      label: 'Список объектов',
      key: `${key}data`,
    },
  ];

  return children;
};

export const createSecondRootKeys = (menu: IAdminsPanelTree[]) => {
  const newSecondRootKeys: string[] = [];

  menu.forEach((item) => {
    if (item.children) {
      item.children.forEach((item) => newSecondRootKeys.push(item.key));
    }
  });

  return newSecondRootKeys;
};
