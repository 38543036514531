import { FC } from 'react';

import { TooltipProps } from '../../Mapper/Mapper.model';

import TooltipContent from './TooltipContent/TooltipContent';

import styles from './Tooltip.module.scss';

const Tooltip: FC<TooltipProps> = ({ tooltipRef, isTooltip }) => {
  return (
    <div ref={tooltipRef} className={styles.tooltipContainer}>
      <TooltipContent isTooltip={isTooltip} />
    </div>
  );
};

export default Tooltip;
