import { VERSION } from '../../../../constants/versions';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

const ADMIN = VERSION + '/admin';
const SYSTEM = ADMIN + '/systems/';
const SYS_SETTINGS = SYSTEM + 'settings/';

const settings = {
  getSystemSettings: (id: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(SYS_SETTINGS + id, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
};

export default settings;
