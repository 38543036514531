import { notification } from 'antd';
import { uniqueId } from 'lodash';
import { useState } from 'react';

import http from '../../../api/http';
import { getModuleItem } from '../../../api_';
import { TABLE_CSV_SEPARATOR } from '../../../constants/constants';
import rootStore from '../../../stores/rootStore/rootStore';

const useImport = () => {
  const { moduleData, importedData, setKeyValue } = rootStore.uiStore;

  const [modalOpen, setModalOpen] = useState<any>();
  const [importLoading, setImportLoading] = useState(false);

  const getAdaptedFieldsKeysByIndex = (data: any, header: any) => {
    return data.map((rowArray: any) => {
      const obj: any = {};

      rowArray.forEach((el: any, index: number) => {
        const referens: any = header[index];

        obj[referens] = el;
      });

      return obj;
    });
  };

  const getAdaptedFieldsKeysByHeaderItem = (data: any, headerItem: any) => {
    return data.map((rowObj: any) => {
      const obj = {} as any;

      for (const key in rowObj) {
        if (key === 'id') {
          obj.id = uniqueId('importRow_');
          continue;
        }

        const { referens }: any = headerItem.find(
          (el: any) => el.importFieldName === key
        );

        if (!referens) {
          return console.error('referense not found, u should have fixed it.');
        }

        obj[referens] = rowObj[key];
      }

      return obj;
    });
  };

  const scvDataToJson = (text: any) => {
    //split text by line breaks
    let dataArray: any[] = text.split('\r\n');

    //filter empty and header(first) rows
    dataArray = dataArray.filter((el) => el !== '');

    //split rows to arrays
    dataArray = dataArray.map((el) => el.split(TABLE_CSV_SEPARATOR));

    //remove escaping quotes
    dataArray = dataArray.map((elArray) => {
      return elArray.map((el: any) => el.toString().replace(/"/g, ''));
    });

    const header = dataArray.shift();
    //correlates column keys with elements and forms an array of objects
    const adaptedRowsArray = getAdaptedFieldsKeysByIndex(dataArray, header);

    addImportedRows(adaptedRowsArray);
  };

  //compare file and tables headers, whether the data is same
  const isSameTableHeaders = (headerItem: any, newData: any) => {
    const newKeys = Object.keys(newData[0]);
    let jsonImportFieldNameCount = 0;
    let fileImportFieldNameCount = 0;

    for (let i = 0; i < headerItem.length; i++) {
      const importFieldName = headerItem[i].importFieldName;

      if (importFieldName) {
        jsonImportFieldNameCount++;
      }

      const hasFieldName = newKeys.find((el) => el === importFieldName);

      if (hasFieldName) {
        fileImportFieldNameCount++;
      }
    }

    return jsonImportFieldNameCount === fileImportFieldNameCount;
  };

  const addImportedRows = (newData: any) => {
    if (!moduleData) return;

    const headerItem = moduleData.headerItem;
    const isSame = isSameTableHeaders(headerItem, newData);

    if (!isSame) {
      notification.error({
        message: 'Ошибка импорта',
        description: 'Данные из файла не подходят под требования формы',
      });

      return;
    }

    const adaptedNewData = getAdaptedFieldsKeysByHeaderItem(
      newData,
      headerItem
    );

    setKeyValue('importedData', {
      title: moduleData.title,
      code: moduleData.code,
      headerItem: moduleData.headerItem,
      data: adaptedNewData,
    });
    setModalOpen(true);
  };

  const importRequest = async () => {
    setImportLoading(true);
    try {
      const request = await http.post('/tables/row/import', {
        data: importedData,
      });

      if (request) {
        const response = request.data;

        notification.success({
          message: 'Импорт выполнен',
          description: `Данные из файла успешно импортированны, внесенно строк ${response.insertCount} из ${importedData?.data.length}`,
        });

        const code = moduleData?.code;

        refreshTable(code);
        abortImport();
      }
    } catch (e) {
      notification.error({
        message: 'Ошибка импорта',
        description:
          'Данные из файла не были загруженны в базу, повторите попытку',
      });
    } finally {
      setImportLoading(false);
    }
  };

  const refreshTable = async (code: any) => {
    setKeyValue('isModalLoading', true);
    setKeyValue('selectedModulItems', [code]);
    try {
      const newTableData = await getModuleItem(code);

      newTableData && setKeyValue('moduleData', newTableData);
    } catch (e: any) {
      const message = e?.response?.data?.message;

      setKeyValue('moduleData', null);
      notification.error({ message: 'Ошибка сервера', description: message });
    } finally {
      setKeyValue('isModalLoading', false);
    }
  };

  const abortImport = () => {
    setKeyValue('importedData', null);
    setModalOpen(false);
  };

  return {
    modalOpen,
    setModalOpen,
    addImportedRows,
    scvDataToJson,
    abortImport,
    importRequest,
    importLoading,
  };
};

export default useImport;
