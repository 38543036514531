import { FC } from 'react';

import { System } from '../../../../ts/enums/enums';
import { switchIco } from '../../../DetailedMain/helpers/switchHeaderIco';

import styles from './PointerMarker.module.scss';

interface PointerMarkerProps {
  colorWithMode: string;
  system: U<System>;
}

const PointerMarker: FC<PointerMarkerProps> = ({ colorWithMode, system }) => {
  return (
    <>
      <div
        className={styles.crosshairPopupMain}
        style={{
          backgroundColor: colorWithMode,
          borderColor: colorWithMode,
        }}
      >
        {switchIco(system)}
      </div>

      <div
        className={styles.crosshairPopupArrow}
        style={{ borderTopColor: colorWithMode }}
      />
      <div className={styles.crosshairPopupPoint} />
    </>
  );
};

export default PointerMarker;
