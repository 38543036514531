import { isEqual } from 'lodash';
import { Overlay } from 'ol';
import { RefObject, useEffect, useState } from 'react';

import createRefs from '../../../helpers/createRefs';
import { createOverlays } from '../../Mapper/helpers';
import { getFeaturesExtent } from '../../Overlays/InfoPopup/helpers/getFeaturesExtent';
import { activateMapOverlays } from '../helpers/activateMapOverlays';
import { IFeaturesArr } from '../helpers/getFeaturesCluster';
import { getFeaturesOverlaysInExtent } from '../helpers/getFeaturesOverlaysInExtent';
import { addOverlays } from '../helpers/handlers';
import { useDinamicOverlaysProps } from '../Map.model';

const useDinamicOverlays = ({
  map,
  featuresWithOverlays,
  isShowOverlay = true,
  center,
  isConstructor = false,
  isInsertFirstOverlay = false,
  isOverlaysForAllFeatures = true,
}: useDinamicOverlaysProps) => {
  const [elementRefs, setElementRefs] = useState<RefObject<HTMLDivElement>[]>(
    []
  );
  const [featuresInExtent, setFeaturesInExtent] = useState<IFeaturesArr[]>([]);
  const [overlays, setOverlays] = useState<Overlay[]>([]);
  const [isRemovedOverlays, setIsRemovedOverlays] = useState<boolean>(false);
  const [isReadyElementRefs, setIsReadyElementRefs] = useState<boolean>(false);
  const [isCreateOverlays, setIsCreateOverlays] = useState<boolean>(false);

  // removed old overlays
  useEffect(() => {
    if (!map || !overlays.length) {
      setIsRemovedOverlays(true);

      return;
    }

    const isRemove =
      (!isCreateOverlays && !isRemovedOverlays) || !isShowOverlay;

    if (!featuresInExtent.length) {
      overlays.forEach((item) => {
        map.removeOverlay(item);
      });

      setIsRemovedOverlays(true);
      setOverlays([]);

      return;
    }

    if (isRemove) {
      overlays.forEach((item) => {
        map.removeOverlay(item);
      });

      setIsRemovedOverlays(true);
      setOverlays([]);
    }
  }, [
    isShowOverlay,
    featuresInExtent,
    map,
    overlays,
    isRemovedOverlays,
    isCreateOverlays,
  ]);

  // reset features
  useEffect(() => {
    if (!isShowOverlay || isConstructor) {
      setFeaturesInExtent([]);
    }
  }, [isConstructor, isShowOverlay]);

  // preparation Features
  useEffect(() => {
    if (!map || !isShowOverlay || isConstructor) return;

    const features = getFeaturesExtent(map);

    if (!features) return;

    const featuresOverlaysExtent = getFeaturesOverlaysInExtent(
      featuresWithOverlays,
      features.featuresCl,
      isOverlaysForAllFeatures
    );

    if (!isEqual(featuresInExtent, featuresOverlaysExtent)) {
      setFeaturesInExtent(featuresOverlaysExtent);
      setIsRemovedOverlays(false);
      setIsReadyElementRefs(false);
      setIsCreateOverlays(false);
    }
  }, [
    featuresInExtent,
    isShowOverlay,
    map,
    isConstructor,
    center,
    featuresWithOverlays,
    isOverlaysForAllFeatures,
  ]);

  // create dinamic refs
  useEffect(() => {
    if (featuresInExtent.length && isShowOverlay && isRemovedOverlays) {
      const refs = createRefs(
        featuresInExtent.length
      ) as RefObject<HTMLDivElement>[];

      setElementRefs(refs);
    }
    setIsReadyElementRefs(true);
  }, [featuresInExtent, isShowOverlay, isRemovedOverlays]);

  // create overlays
  useEffect(() => {
    if (elementRefs.length) {
      setIsCreateOverlays(true);
      elementRefs[0].current &&
        setOverlays(
          createOverlays({
            elements: elementRefs,
            insertFirst: isInsertFirstOverlay,
          })
        );
    }
  }, [elementRefs, isInsertFirstOverlay]);

  // activate Overlays
  useEffect(() => {
    if (!map || !featuresInExtent.length) return;

    const activateResult = activateMapOverlays(
      featuresInExtent,
      overlays,
      elementRefs
    );

    activateResult &&
      overlays.length &&
      addOverlays(map, { dinamicOverlays: overlays });
  }, [elementRefs, featuresInExtent, overlays, map]);

  return {
    elementRefs,
    isRemovedOverlays,
    featuresInExtent,
    isReadyElementRefs,
  };
};

export default useDinamicOverlays;
