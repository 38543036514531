import { Button, Form, InputNumber, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { FILE_TYPE, FOLDER_LIST, SYSTEM_TYPE } from '../constants/constants';
import translateCode from '../helpers/translateCode';

import { InitFormKeys, IPhaseInitForm } from './InitForm.model';

import styles from './InitForm.module.scss';

const ConstructorPhaseInitForm: FC<IPhaseInitForm> = ({ setFolder }) => {
  const { phases, setPhase } = rootStore.constructorStore;

  const onFinish = (values: Record<InitFormKeys, string>) => {
    const { system, tlEputsId, fileType, folder } = values;

    setFolder(folder);

    phases.forEach(({ code, phaseNumber }, i) => {
      const { postfix } = translateCode(code, phaseNumber);
      const fileName = system + tlEputsId + postfix + fileType;

      setPhase(folder, fileName, i);
    });
  };

  return (
    <Form layout="vertical" className={styles.initForm} onFinish={onFinish}>
      <Form.Item className={styles.initFormItem} name="folder" label="папка:">
        <Select
          placeholder="папка"
          options={FOLDER_LIST}
          className={styles.select}
        />
      </Form.Item>
      <Form.Item className={styles.initFormItem} name="system" label="система:">
        <Select
          placeholder="система"
          options={SYSTEM_TYPE}
          className={styles.select}
        />
      </Form.Item>
      <Form.Item
        className={styles.initFormItem}
        name="tlEputsId"
        label="номер:"
      >
        <InputNumber
          min={0}
          placeholder="СО №"
          className={styles.tlEputsIdInput}
        />
      </Form.Item>
      <Form.Item
        className={styles.initFormItem}
        name="fileType"
        label="тип файла:"
      >
        <Select
          placeholder="тип файла"
          options={FILE_TYPE}
          className={styles.select}
        />
      </Form.Item>
      <Form.Item className={styles.submitBtn}>
        <Button type="primary" htmlType="submit">
          <AiOutlineCheck />
        </Button>
      </Form.Item>
    </Form>
  );
};

export default observer(ConstructorPhaseInitForm);
