import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import { COLORS } from '../../../constants/colorsConstants';
import { SYSTEM } from '../../../constants/constants';
import { findById } from '../../../helpers/findBy';
import parseTLMode from '../../../helpers/parseTLMode';
import rootStore from '../../../stores/rootStore/rootStore';
import { System } from '../../../ts/enums/enums';
import { ICrosshairPopupProps } from '../../Mapper/Mapper.model';

import CircleMarkerPopup, {
  CircleMarkerPopupProps,
} from './CircleMarkerPopup/CircleMarkerPopup';
import PointerMarker from './PointerMarker/PointerMarker';

import styles from './MarkerPopup.module.scss';

const ANIMATION_DURATION = 10;

const MarkerPopup: FC<ICrosshairPopupProps> = ({
  crosshairPopupRef,
  isPhaseCircle,
  isCrosshairPopup,
}) => {
  const { infoData } = rootStore.uiStore;
  const { tls } = rootStore.mapDataStore;
  const [isAnim, setIsAnim] = useState(false);

  useEffect(() => {
    if (infoData && crosshairPopupRef.current?.style.display === 'block') {
      setIsAnim(false);
      setTimeout(() => {
        setIsAnim(true);
      }, ANIMATION_DURATION);
    }
  }, [crosshairPopupRef, infoData]);

  const mode = findById(tls, infoData?.id)?.tlStatus?.mode;

  const colorWithMode =
    infoData?.system === SYSTEM.LIGHTS
      ? parseTLMode(mode).color
      : COLORS.PRIMARY;

  const containerStyles = classNames({
    [styles.container]: true,
    [styles.animation]: isAnim,
  });

  const isCluster = infoData?.system === System.Clusters;

  const isShow =
    (!isPhaseCircle || infoData?.system !== System.Lights) &&
    isCrosshairPopup &&
    !isCluster;

  const markerProps: CircleMarkerPopupProps = {
    system: infoData?.system,
    colorWithMode,
  };

  const mainContent = infoData?.coordFeatureOnCluster ? (
    <CircleMarkerPopup {...markerProps} />
  ) : (
    <PointerMarker {...markerProps} />
  );

  return (
    <div className={containerStyles} ref={crosshairPopupRef}>
      {isShow ? mainContent : null}
    </div>
  );
};

export default observer(MarkerPopup);
