import { observer } from 'mobx-react-lite';
import { CSSProperties, FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';

import styles from './PhaseCircleImgWrapper.module.scss';

interface ImgProps {
  children?: U<JSX.Element>;
  style: CSSProperties;
}

const PhaseCircleImgWrapper: FC<ImgProps> = ({ style, children }) => {
  const { isUnderCircleFill } = rootStore.uiStore;

  const zIndex = isUnderCircleFill ? -1 : 0;

  return (
    <div className={styles.imgContainer} style={{ zIndex }}>
      <div className={styles.imgWrapper} style={style}>
        {children}
      </div>
    </div>
  );
};

export default observer(PhaseCircleImgWrapper);
