import Feature from 'ol/Feature';

import { MAP_ICONS_COLORS } from '../../../../../constants/colorsConstants';
import { SYSTEM } from '../../../../../constants/constants';
import { System } from '../../../../../ts/enums/enums';

const { LIGHTS, DETECTORS, CAMERAS } = SYSTEM;

const { LIGHTS_COLOR, DETECTORS_COLOR, CAMERAS_COLOR, STREAMS } =
  MAP_ICONS_COLORS;

/**
 * По типу объекта вернём его цвет
 * @param {string} featureType
 * @returns
 */
export function colorFromType(featureType: System) {
  switch (featureType) {
    case LIGHTS:
      return LIGHTS_COLOR;
    case DETECTORS:
      return DETECTORS_COLOR;
    case CAMERAS:
      return CAMERAS_COLOR;
    case System.Streams:
      return STREAMS;
    default:
      return STREAMS;
  }
}

export function listOfColors(arrFeatures: Feature[]) {
  const colorsList: string[] = [];
  const dataList: number[] = [];

  arrFeatures.forEach((oneFeature) => {
    const featureType: System = oneFeature.get('system');
    const oneColor = colorFromType(featureType);

    if (colorsList.indexOf(oneColor) === -1) {
      colorsList.push(oneColor);
      dataList.push(1);
    }
  });

  return { colors: colorsList, data: dataList };
}
