import { FC } from 'react';

import styles from './TextItem.module.scss';

interface TextItemProps {
  label: string;
  text: string | number;
  children?: JSX.Element;
}

const TextItem: FC<TextItemProps> = (props) => {
  const { label, text, children } = props;

  return (
    <div className={styles.textItem}>
      <p className={styles.label}>{label}</p>
      <p className={styles.text}>{text}</p>
      {children}
    </div>
  );
};

export default TextItem;
