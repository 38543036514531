import {
  StyleProvider,
  legacyLogicalPropertiesTransformer,
} from '@ant-design/cssinjs';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';

import App from './components/App/App';
import AppError from './components/AppError/AppError';

import './assets/styles/index.scss';

const container = document.getElementById('root'),
  root = createRoot(container as any); // CreateRoot(container!) if you use TypeScript

root.render(
  <ErrorBoundary fallback={<AppError />}>
    <StyleProvider
      transformers={[legacyLogicalPropertiesTransformer]}
      hashPriority="high"
    >
      <App />
    </StyleProvider>
  </ErrorBoundary>
);
