import type { Coordinate } from 'ol/coordinate';
import { fromLonLat } from 'ol/proj';
import { useEffect, useState } from 'react';

import { WIDTH } from '../../../constants/constants';
import { CENTERING, ZOOM } from '../../../constants/mapConstants';
import {
  handleCenter,
  handleCenterZoom,
  handleZoom,
} from '../helpers/handlers';
import handleView from '../helpers/handleView';
import { UseZoomAndCenterProps } from '../Map.model';

const { SIDEBAR, PANEL_MAIN, PANEL_INFO } = WIDTH;
const { MAX, MIN, LESS_THEN_WHEEL_STEP, WHEEL_DURATION } = ZOOM;
const OFFSET = (PANEL_INFO - SIDEBAR) / 2;
const OFFSET_BACK = (SIDEBAR - PANEL_INFO) / 2;

const useZoomAndCenter = ({
  map,
  isInfoPanel,
  isPanel,
  centerZoom,
  center,
  wheelZoom,
  currentZoom,
  infoData,
  isCenterDependsOnPanels,
  isCollapseInfoPanel,
}: UseZoomAndCenterProps) => {
  const [wasCollapsed, setWasCollapsed] = useState(false);

  useEffect(() => handleCenterZoom(map, centerZoom), [centerZoom]); // eslint-disable-line
  useEffect(() => handleZoom(map, currentZoom), [currentZoom]); // eslint-disable-line
  useEffect(() => handleCenter(map, center), [center]); // eslint-disable-line

  // handle center depending on the info panel
  useEffect(() => {
    setWasCollapsed(!isInfoPanel && isCollapseInfoPanel);
  }, [isInfoPanel, isCollapseInfoPanel]);

  useEffect(() => {
    if (!map || !isCenterDependsOnPanels) return;
    const { resolution, center, view } = handleView(map);

    if (!resolution || !center) return;

    let offset = isInfoPanel && !isCollapseInfoPanel ? OFFSET : OFFSET_BACK;

    if (isCollapseInfoPanel) {
      offset = !wasCollapsed && isInfoPanel ? OFFSET_BACK : 0;
    }

    view.animate({
      duration: CENTERING.ANIMATION_DURATION,
      center: [center[0] + offset * resolution, center[1]],
    });
  }, [isInfoPanel, isCollapseInfoPanel]); // eslint-disable-line

  // handle center depending on the main panel
  useEffect(() => {
    if (!map || !isCenterDependsOnPanels) return;
    const { resolution, center, view } = handleView(map);
    const offset =
      Number(isPanel ? -PANEL_MAIN - SIDEBAR : PANEL_MAIN + SIDEBAR) / 2;

    if (!resolution || !center) return;

    view.animate({
      duration: CENTERING.ANIMATION_DURATION,
      center: [center[0] + offset * resolution, center[1]],
    });
  }, [isPanel]); // eslint-disable-line

  // handle zoom & center by wheel above popup
  useEffect(() => {
    if (!map || !wheelZoom) return;
    const { resolution, view } = handleView(map);

    if (!resolution) return;

    const isNotBorder = {
      max: currentZoom < MAX - LESS_THEN_WHEEL_STEP,
      min: currentZoom > MIN + LESS_THEN_WHEEL_STEP,
    };
    const curZoom =
      (!isNotBorder.max && MAX) || (!isNotBorder.min && MIN) || currentZoom;
    const fCenter = infoData?.coordinate && fromLonLat(infoData.coordinate);
    const offset =
      (Number(isInfoPanel && PANEL_INFO) -
        SIDEBAR -
        Number(isPanel && PANEL_MAIN)) /
      2;

    const isZoomBigger = wheelZoom - currentZoom > 0;
    const rate = isZoomBigger
      ? Number(isNotBorder.max)
      : -Number(isNotBorder.min);
    const rateCenter = isZoomBigger ? resolution / 2 : resolution * 2;

    const isCentering = fCenter && rate;
    const center: U<Coordinate> = isCentering
      ? [fCenter[0] + offset * rateCenter, fCenter[1]]
      : undefined;

    view.animate({
      zoom: curZoom + rate,
      center,
      duration: WHEEL_DURATION,
    });
  }, [wheelZoom]); // eslint-disable-line

  return null;
};

export default useZoomAndCenter;
