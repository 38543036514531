import { makeAutoObservable } from 'mobx';

import {
  IAdminGroup,
  IAdminModalContent,
  IAdminSettingConfigData,
  ISystemData,
  IPvalid,
} from '../../components/Admin/models/adminTypes.model';
import { ITableColumn } from '../../ts/models/table.model';
import RootStore from '../rootStore/rootStore';

class AdminPanelStore {
  rootStore;
  isAdmins = false;
  adminTableType: N<string> = null;
  adminSettingUsers: N<[]> = null;
  isModalOpen = false;
  modalContent: N<IAdminModalContent> = null;
  isModalLoading = false;
  deleteUserId = null;
  adminSettingConfigData: N<IAdminSettingConfigData[]> = null;
  adminSettingConfigHeader: N<ITableColumn[]> = null;
  groupId: N<number | string> = null;
  adminPanelKeys: N<string[]> = null;
  adminAboutData: any = null;
  isUserUpdate = false;
  adminModalType = 'default';
  adminSettingGroups: N<IAdminGroup[]> = null;
  systemData: N<ISystemData> = null;
  basicSettings = null;
  otherSettings = null;
  displayHeight = 1028;
  menu = null;
  isFormChange = false;
  subId: N<string> = null;
  regionId = '';
  isAddSystem = false;
  tableFormPValid: N<IPvalid> = null;
  isDeleteSystem = false;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }

  setIsAdmins = (isAdmins: boolean) => {
    this.isAdmins = isAdmins;
  };

  setAdminsData = <K extends keyof this, T extends this[K]>(
    key: K,
    value: T
  ) => {
    this[key] = value;
  };

  get isAdminLoad() {
    return !!this.adminTableType;
  }

  get selectedAdminItems() {
    if (this.adminTableType) {
      return [this.adminTableType];
    }

    return [];
  }

  get adminSystemsId() {
    if (this.adminTableType) {
      return String(this.adminTableType).split('#')[1];
    }

    return null;
  }

  get validationParams() {
    if (this.systemData) {
      return this.systemData.pvalid;
    }

    return null;
  }
}
export default AdminPanelStore;
