import AdminPanelStore from '../adminPanelStore/adminPanelStore';
import ChannelsDataStore from '../channelsDataStore/channelsDataStore';
import ConstructorStore from '../constructorStore/constructorStore';
import DetailedStore from '../detailedStore/detailedStore';
import DocumentsStore from '../documentsStore/documentsStore';
import MapDataStore from '../mapDataStore/mapDataStore';
import TestingPanelStore from '../testingPanelStore/testingPanelStore';
import UIStore from '../uiStore/uiStore';
import UserDataStore from '../userDataStore/userDataStore';
import VideoWallPanelStore from '../videoWallPandelStore/videoWallPanelStore';

class RootStore {
  mapDataStore;
  userDataStore;
  uiStore;
  constructorStore;
  adminPanelStore;
  channelsDataStore;
  videoWallPanelStore;
  testingPanelStore;
  documentsStore;
  detailedStore;

  constructor() {
    this.mapDataStore = new MapDataStore(this);
    this.userDataStore = new UserDataStore(this);
    this.uiStore = new UIStore(this);
    this.constructorStore = new ConstructorStore(this);
    this.adminPanelStore = new AdminPanelStore(this);
    this.channelsDataStore = new ChannelsDataStore(this);
    this.videoWallPanelStore = new VideoWallPanelStore(this);
    this.testingPanelStore = new TestingPanelStore(this);
    this.documentsStore = new DocumentsStore(this);
    this.detailedStore = new DetailedStore(this);
  }
}

export default new RootStore();
