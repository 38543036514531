import { notification } from 'antd';
import { useEffect } from 'react';

import api from '../api';
import http from '../api/http';
import NOTICE from '../constants/notificationConstants';
import { VERSION } from '../constants/versions';
import { getCookiesFromObject } from '../helpers/getCookiesFromObject';
import rootStore from '../stores/rootStore/rootStore';
import { IGroup } from '../stores/userDataStore/userDataStore';
import { UserRoleScales } from '../ts/enums/userData';

import useRemoteSettings from './useRemoteSettings';

const useAuth = () => {
  const { userDataStore, uiStore } = rootStore;
  const { user } = userDataStore;
  const { applySettings } = useRemoteSettings();

  useEffect(() => {
    if (user) {
      applySettings(user.jprofile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  http.interceptors.request.use((config: any) => {
    const accessToken: any = rootStore.userDataStore?.accessToken;

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;

      config.headers.Cookies = getCookiesFromObject({
        regionId: uiStore.regionData?.id ?? 0,
        timeZone: -new Date().getTimezoneOffset() / 60,
      });
    }

    return config;
  });

  http.interceptors.response.use(
    (config) => config,
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response.status === 401 &&
        originalRequest &&
        !error.config._isRetry
      ) {
        originalRequest._isRetry = true;

        try {
          const refreshToken = userDataStore.refreshToken;

          if (!refreshToken) {
            throw new Error('Отсутствует refreshToken');
          }

          const request = await api.user.auth.refreshToken(
            refreshToken,
            'useAuth'
          );
          const response = request.data;

          userDataStore.setTokens(response.tokens, userDataStore.isRemember);

          return http.request(originalRequest);
        } catch (e) {
          notification.warning({
            message: 'Пожалуйста войдите в аккаунт',
            description: 'Срок действия авторизации истёк',
          });
          userDataStore.resetUserData();
        }
      }

      throw error;
    }
  );

  const checkAuthHandler = async () => {
    try {
      const accessToken = userDataStore.accessToken;
      const isRemember = userDataStore.isRemember;

      if (!accessToken && !isRemember) return;

      const response = await http.get(`${VERSION}/user/info`);
      const { user } = response.data;
      const accessEls = user?.jsonb_info?.accesswebelements;

      if (!accessEls || !accessEls.length) {
        return notification.error(NOTICE.USER_ACCESS_ERROR);
      }

      const groupsDataResponse = await api.serverObjects.infoApi.fetchGroups();
      const groupsData = groupsDataResponse.data;

      if (!groupsData || !groupsData.groups.length) {
        return notification.error(NOTICE.SYSTEM_GROUPS_ERROR);
      }

      const webGroups = groupsData.groups;
      let roleValue;
      let scaleValue = 0;

      user?.groups.forEach((userGroup: number) => {
        webGroups.forEach((webGroup: IGroup) => {
          if (userGroup === Number(webGroup.id)) {
            if (scaleValue < UserRoleScales[webGroup.code])
              scaleValue = UserRoleScales[webGroup.code];

            roleValue = UserRoleScales[scaleValue];
          }
        });
      });

      if (!roleValue) {
        notification.error(NOTICE.USER_ACCESS_ERROR);
        throw new Error(
          'User groups do not match the available application groups'
        );
      }

      userDataStore.setKeyValue('webSystemId', groupsData.systemId);
      userDataStore.setKeyValue('serverSystemId', groupsData.serverSystemId);
      userDataStore.setKeyValue('groups', groupsData.groups);
      userDataStore.setKeyValue('role', roleValue);
      userDataStore.setUserData(user);
    } catch (e) {
      userDataStore.resetUserData();
    } finally {
      userDataStore.setKeyValue('isAppReady', true);
    }
  };

  const loginHandler = async (
    login: string,
    password: string,
    isRemember: boolean
  ) => {
    try {
      const request = await api.user.auth.authorization(
        login,
        password,
        'login'
      );
      const response = request.data;

      userDataStore.setTokens(response.tokens, isRemember);

      return true;
    } catch (e: any) {
      userDataStore.resetUserData();

      if (e?.response?.status !== 400) {
        return undefined;
      }

      return false;
    }
  };

  return { loginHandler, checkAuthHandler };
};

export default useAuth;
