import { FC } from 'react';

import { switchIco } from '../../../DetailedMain/helpers/switchHeaderIco';

import styles from './WidgetHeader.module.scss';

interface WidgetHeaderProps {
  title: string;
}

const WidgetHeader: FC<WidgetHeaderProps> = ({ title }) => (
  <header className={styles.header}>
    <div className={styles.title}>
      <div className={styles.ico}>{switchIco(title)}</div>
      <h3>{title}</h3>
    </div>
  </header>
);

export default WidgetHeader;
