import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';

import { getStyle } from './getStyle';

export interface MarkersItem {
  id: number;
  longitude: number;
  latitude: number;
  mode: number;
}

const getMarkersData = (data: MarkersItem[]) =>
  data.map((item) => ({
    id: item.id,
    lonLat: [item.longitude, item.latitude],
    mode: item.mode,
  }));

const addMarkers = (data: MarkersItem[], set: string) => {
  if (!data) return [];

  const features = getMarkersData(data).map(({ id, lonLat, mode }) => {
    const feature = new Feature({
      geometry: new Point(fromLonLat(lonLat)),
      style: getStyle(set),
      id: id,
      system: set,
      mode,
    });

    return feature;
  });

  return features;
};

export default addMarkers;
