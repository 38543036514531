import { isNumber } from 'lodash';
import type { Coordinate } from 'ol/coordinate';
import { fromLonLat, toLonLat } from 'ol/proj';
import { useCallback, useEffect } from 'react';

// import { CLUSTERS } from '../../../constants/mapClusterConstants';
// import { ZOOM } from '../../../constants/mapConstants';
import setDisplayStyle from '../../../helpers/setDisplayStyle';
import rootStore from '../../../stores/rootStore/rootStore';
import {
  infoPanelTypeContent,
  MapActions,
} from '../../../stores/uiStore/uiStore';
import { System } from '../../../ts/enums/enums';
import { InfoPanelProps } from '../Map.model';

const useInfoPanel = ({
  map,
  popups,
  refs,
  infoData,
  setMapCenterZoom,
  setMapCenter,
  setIsCrosshairPopup,
}: InfoPanelProps) => {
  const { setInfoData, setKeyValue } = rootStore.uiStore;

  const handleInfoPanel = useCallback(
    (
      id: number | string,
      coordinates: Coordinate,
      isFromMap: boolean,
      system: System,
      coordFeatureOnCluster?: N<Coordinate>
    ) => {
      const { crosshairPopup, phaseCirclePopup } = popups;

      if (isFromMap && coordinates) {
        setInfoData(
          {
            id,
            coordinate: toLonLat(coordinates),
            system,
            coordFeatureOnCluster,
          },
          MapActions.NoActions
        );
      }

      const coordFeature = coordFeatureOnCluster ?? coordinates;

      phaseCirclePopup?.setPosition(coordinates);
      crosshairPopup?.setPosition(coordFeature);
      setDisplayStyle([refs.crosshairPopupRef], 'block');

      if (system === System.Clusters) {
        setKeyValue('infoPanelType', infoPanelTypeContent.ClusterContent);

        return;
      }

      setKeyValue('infoPanelType', infoPanelTypeContent.SystemsContent);
    },
    [popups, refs, setInfoData, setKeyValue]
  );

  useEffect(() => {
    if (map && infoData?.id && infoData?.coordinate) {
      const coordinates = fromLonLat(infoData.coordinate);
      // const resolution =
      //   currentZoom < ZOOM.CLUSTERS_BORDER
      //     ? CLUSTERS.CLUSTERS_BORDER_RESOLUTION
      //     : map.getView().getResolution();
      //workaround so that the first time you click on a mapping object, an incorrect center calculation occurs
      // const offsetX = handleCenterOffset({ resolution });
      // const center = [coordinates[0] + offsetX, coordinates[1]];

      switch (infoData.mapAction) {
        case MapActions.CenterZoom:
          setMapCenterZoom(coordinates);
          break;
        case MapActions.Center:
          setMapCenter(coordinates);
          isNumber(infoData.id) &&
            setKeyValue('clickedCartographyObj', {
              id: infoData.id,
              system: infoData.system,
            });
          break;
        default:
          break;
      }

      handleInfoPanel(
        infoData.id,
        coordinates,
        false,
        infoData.system,
        infoData.coordFeatureOnCluster
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoData, map]);

  return { handleInfoPanel };
};

export default useInfoPanel;
