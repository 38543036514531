import React, { FC } from 'react';

import styles from './Counter.module.scss';

interface ICounter {
  count: number;
}

const Counter: FC<ICounter> = ({ count }) => {
  if (!count) return null;

  return <div className={styles.wrapper}>{count}</div>;
};

export default Counter;
