import { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';

import { getTimeWithTimeZone } from './getTimeWithTimeZone';

export const formatedDataOnUtc = (selectedDates: Dayjs[], timeZone: number) => {
  const formatedDates = selectedDates.map(
    (date) => getTimeWithTimeZone({ timeZone, dateJS: date }).dateFormatted
  );

  return formatedDates;
};
