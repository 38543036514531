import { Button, Switch } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { CONTROL_ACCESS_DEVICES } from '../../../../constants/constants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { IInfoPanelData } from '../../../../stores/uiStore/uiStore';
import { TLDetailedCode } from '../../../../ts/enums/userData';
import { DeviceControlAccess } from '../../../../ts/models/mapObject.model';
import Popover from '../../../ui-kit/Popover/Popover';

import styles from './InfoBtns.module.scss';

const TL_MAIN_TAB = '1';
const TL_MANAGMENT_TAB = '7';

interface IInfoBtns {
  isLights: boolean;
  infoData: IInfoPanelData;
  systemItem: any;
  controlAccess: DeviceControlAccess;
}

const InfoBtns: FC<IInfoBtns> = ({
  isLights,
  infoData,
  systemItem,
  controlAccess,
}) => {
  const {
    setKeyValue,
    setInfoData,
    setIsNot,
    isShowPhaseCircle,
    isTlsDataFetched,
    interfaceTipsDelay,
  } = rootStore.uiStore;
  const { hasAccess } = rootStore.userDataStore;

  const isControlAccessRead =
    controlAccess !== CONTROL_ACCESS_DEVICES.NotSupportControl;

  const isManagmentAccess =
    hasAccess(TLDetailedCode.Management) && isControlAccessRead;
  const isDetailed =
    hasAccess(TLDetailedCode.isDetailed) &&
    isTlsDataFetched &&
    !systemItem?.info;

  const openDetails = () => {
    setIsNot('isDetailedForm', true);
    setIsNot('isInfoPanel', false);
    setKeyValue('activeKey', TL_MAIN_TAB);
    setKeyValue('isShowPhaseCircle', false);
  };
  const openManagment = () => {
    setIsNot('isDetailedForm', true);
    setKeyValue('activeKey', TL_MANAGMENT_TAB);
  };

  const popoverProps = {
    tipsDelay: interfaceTipsDelay,
    content: 'Открыть',
    placement: 'top',
  };

  const phaseCircleText = isShowPhaseCircle
    ? 'отключить'
    : 'приблизиться к СО и включить';
  const phaseCircleTooltip = `${phaseCircleText} фазовый круг`;

  return (
    <div className={styles.buttonsBlock}>
      {isDetailed && (
        <Popover
          {...popoverProps}
          content={`${popoverProps.content} подробную информацию о СО`}
        >
          <Button
            type="primary"
            className={styles.detailsBtn}
            onClick={openDetails}
          >
            Подробнее
          </Button>
        </Popover>
      )}
      {isLights && isManagmentAccess && (
        <Popover
          {...popoverProps}
          content={`${popoverProps.content} управление СО`}
        >
          <Button
            type="primary"
            className={styles.detailsBtn}
            onClick={() => openManagment()}
          >
            Управление СО
          </Button>
        </Popover>
      )}
      {isLights && (
        <Popover {...popoverProps} content={phaseCircleTooltip}>
          <div className={styles.phaseButton}>
            <p>Фазовый круг:</p>
            <Switch
              checked={isShowPhaseCircle}
              onChange={(value: boolean) => {
                setKeyValue('isShowPhaseCircle', value);
                value && setInfoData(infoData);
              }}
            />
          </div>
        </Popover>
      )}
    </div>
  );
};

export default observer(InfoBtns);
