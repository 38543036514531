import { Drawer } from 'antd';
import { FormInstance } from 'antd/lib';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import { IQueryParamItem } from '../../../../ts/models/table.model';

import OpenBtn from './OpenBtn/OpenBtn';
import SearchForm from './SearchForm/SearchForm';

import styles from './TableServerSearch.module.scss';

const Title = () => (
  <p className={styles.title}>Настройка серверной фильтрации</p>
);

interface ITableServerSearch {
  queryParams: IQueryParamItem[];
  handleSumbitServerSearch: (form: FormInstance) => Promise<void>;
  limitIsExceeded: U<boolean>;
}

const TableServerSearch: FC<ITableServerSearch> = ({
  queryParams,
  handleSumbitServerSearch,
  limitIsExceeded,
}) => {
  const [open, setOpen] = useState(false);
  const { isQueryParamsError } = rootStore.uiStore;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const formProps = {
    queryParams,
    onClose,
    handleSumbitServerSearch,
  };

  const openBtnsProps = {
    showDrawer,
    queryParams,
    limitIsExceeded,
  };

  if (isQueryParamsError) return null;

  return (
    <div className={styles.searchedRow}>
      <OpenBtn {...openBtnsProps} />
      <Drawer
        title={<Title />}
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
        getContainer={false}
        className={styles.drawer}
        headerStyle={{ color: 'var(--font-color)' }}
      >
        <SearchForm {...formProps} />
      </Drawer>
    </div>
  );
};

export default observer(TableServerSearch);
