import { Spin } from 'antd';
import { FC, useEffect, useState } from 'react';

import { DEFAULT_CROSSROAD_COLORS } from '../../../../constants/colorsConstants';
import { TLCrossroad } from '../../../../ts/models/tl.model';
import { ANIMATION_TIME } from '../../constants/constants';
import setDisplay from '../../helpers/setDisplay';
import { useCrossroadImg } from '../../hooks';
import PhaseCircleImg from '../../PhaseCircleImg/PhaseCircleImg';

import styles from './PhaseCircleCrossroad.module.scss';

const { CROSSROAD_TRANSITION } = ANIMATION_TIME;
const { MAIN, MAIN_TRANSITION, CROSSWALK, CROSSWALK_TRANSITION } =
  DEFAULT_CROSSROAD_COLORS;

interface CircleCrossroad {
  rate: number;
  phaseIdx: number;
  tlCrossroad: TLCrossroad;
  id: number;
}

const PhaseCircleCrossroad: FC<CircleCrossroad> = ({
  rate,
  phaseIdx,
  tlCrossroad,
  id,
}) => {
  const {
    positionXY: [offsetX, offsetY],
    size,
    angle,
    color,
    colorTransition,
    crosswalkColor,
    crosswalkColorTransition,
    strokeWidth,
    isBlinkingTransitionTact = true,
  } = tlCrossroad;

  const tactType = ((phaseIdx + 1) % 2) as 0 | 1;
  const curColor = tactType
    ? color ?? MAIN
    : colorTransition ?? MAIN_TRANSITION;
  const curCrosswalkColor = tactType
    ? crosswalkColor ?? CROSSWALK
    : crosswalkColorTransition ?? CROSSWALK_TRANSITION;
  const [phaseEl, setPhaseEl] = useState<N<HTMLElement>>();
  const { data, isLoading } = useCrossroadImg({
    id,
    color: curColor,
    crosswalkColor: curCrosswalkColor,
    strokeWidth,
  });

  useEffect(() => {
    const phIdx = Math.ceil((phaseIdx + 1) / 2);
    const phase = document.getElementById(`${id}-${phIdx}-phase`);
    let timerId: U<NodeJS.Timeout> = undefined;

    if (phaseEl) phaseEl.style.display = 'none';

    if (phase) {
      let val = 'inline';

      if (isBlinkingTransitionTact && !tactType) {
        timerId = setInterval(() => {
          phase.style.display = val;
          val = setDisplay(val === 'none');
        }, CROSSROAD_TRANSITION);
      }
      phase.style.display = val;
      setPhaseEl(phase);
    }

    return () => timerId && clearInterval(timerId);
  }, [phaseIdx, data, isBlinkingTransitionTact, tactType]); // eslint-disable-line

  if (isLoading) {
    return (
      <div className={styles.imgContainer}>
        <Spin size="large" />
      </div>
    );
  }

  if (!data) return null;

  const calcVal = (val: number) => Math.round(val * rate);

  const style = {
    top: calcVal(offsetY),
    left: calcVal(offsetX),
    width: calcVal(size),
    height: calcVal(size),
    transform: `translate(-50%,-50%) rotate(${angle}deg)`,
    overflow: 'visible',
  };

  return <PhaseCircleImg data={data} style={style} />;
};

export default PhaseCircleCrossroad;
