import { PHASE_CIRCLE_COLORS } from '../../../constants/colorsConstants';
import { TL_CIRCLE_ZOOM } from '../../../constants/constants';
import { DEFAULT_CIRCLE } from '../../../constants/constructorConstants';

const { GREEN, ORANGE } = PHASE_CIRCLE_COLORS;
const { DIAMETER, BORDER_WIDTH, HUE } = DEFAULT_CIRCLE;

export const POPUP_OFFSET = {
  DEFAULT: 30,
  SMALL: 20,
  TINY: 15,
};

export const FAKE_PHASES_DATA = [
  { name: 'prePhase1', value: 5, color: ORANGE },
  { name: 'phase1', value: 20, color: GREEN },
  { name: 'prePhase2', value: 5, color: ORANGE },
  { name: 'phase2', value: 20, color: GREEN },
  { name: 'prePhase3', value: 5, color: ORANGE },
  { name: 'phase3', value: 20, color: GREEN },
  { name: 'prePhase4', value: 5, color: ORANGE },
  { name: 'phase4', value: 20, color: GREEN },
];

export const NO_PHASES_CIRCLE = new Array(4).fill({
  name: 'empty',
  value: 100,
  color: GREEN,
});

export const INITIAL_FORMATTED_DATA = {
  stop: 1,
  phase: 0,
  animation: null,
};

const { START, END } = TL_CIRCLE_ZOOM;

export const INITIAL_ZOOM_TACTS = {
  range: [START, END] as XY,
  diameter: DIAMETER,
  borderWidth: BORDER_WIDTH,
  hue: HUE,
  other: '',
  tlTactsImagesIndex: 0,
};

export const ANIMATION_TIME = {
  CROSSROAD_TRANSITION: 500,
};

export const LANE_LENGTH_MAX = 150;
