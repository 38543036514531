import type { FeatureLike } from 'ol/Feature';
import { Fill } from 'ol/style';

import { CLUSTERS } from '../../../../../constants/mapClusterConstants';
import { getFeatureStyle } from '../helpers/clusterStyles';

const { POINT_RADIUS } = CLUSTERS;

export const Z_INDEX = 10;

export const SELECT_INTERACTIONS = {
  pointRadius: POINT_RADIUS,
  featureStyle: (features: FeatureLike) => getFeatureStyle(features),
  style: null,
  selectCluster: true,
  animate: true,
  animationDuration: CLUSTERS.ANIMATION_DURATION,
};

export const CIRCLE_RADIUS = 15;
export const TRANSPARENT_FEATURE = [0, 0, 0, 0.01];

export const fillEmpty = new Fill({
  color: [0, 0, 0, 0.01],
});
