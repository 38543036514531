import { CircleElEnum } from '../../../ts/enums/tl';

const { Crosswalk } = CircleElEnum;

interface IData {
  type: CircleElEnum;
}

const sortByCrosswalk = <T extends IData>(data: T[]) =>
  [...data].sort((el) => (el.type === Crosswalk ? -1 : 1));

export default sortByCrosswalk;
