import { AiOutlineMenuUnfold } from 'react-icons/ai';
import { MdHttp } from 'react-icons/md';

import { IRequestItem } from '../ts/TestingPanel.model';

const CURRENT_ICONS_MENU: Record<string, JSX.Element> = {
  menu: <AiOutlineMenuUnfold color="var(--font-color)" />,
  request: <MdHttp color="var(--font-color)" size={20} />,
};

export const addIconInMenu = (array: IRequestItem[]) => {
  array.forEach((item) => {
    item.icon = CURRENT_ICONS_MENU.request;

    if (item.children) {
      item.icon = CURRENT_ICONS_MENU.menu;

      return addIconInMenu(item.children);
    }
  });

  return array;
};
