import { notification } from 'antd';
import { useEffect, useState } from 'react';

import api from '../../../api';
import { IRegionData } from '../../../api/serverObjects/info/info.zod';
import NOTICE from '../../../constants/notificationConstants';
import { sendError } from '../../../helpers/sendError';
import { IUserData } from '../../../stores/userDataStore/userDataStore';

interface useRegionsProps {
  user: N<IUserData>;
  resetUserData: () => void;
  handlePickRegion: (region: IRegionData) => void;
}

const useRegions = (props: useRegionsProps) => {
  const { user, resetUserData, handlePickRegion } = props;

  const [availableRegions, setAvailableRegions] = useState<
    IRegionData[] | null
  >(null);
  const [erroredRegionsId, setErroredRegionsId] = useState<number[]>([]);

  const handleSavedRegionId = async () => {
    try {
      const savedRegionId: number = localStorage?.regionId;

      if (!savedRegionId || !user) return false;

      const avalibleIds = user?.regions_id;

      if (!avalibleIds.includes(Number(savedRegionId))) return false;

      const regionData = await api.serverObjects.infoApi.fetchRegionById(
        savedRegionId
      );

      handlePickRegion(regionData);

      return true;
    } catch (e: any) {
      return false;
    }
  };

  const loadAvailableRegionsList = async () => {
    if (!user) return;

    const avalibleIds: number[] = user?.regions_id;

    if (!avalibleIds || !avalibleIds.length) {
      notification.warning(NOTICE.USER_AVALIBLE_REGIONS_UNDEFINED);

      return resetUserData();
    }

    const loadedRegions: IRegionData[] = [];
    const erroredRegions: number[] = [];

    for (const regionId of avalibleIds) {
      try {
        const regionData = await api.serverObjects.infoApi.fetchRegionById(
          regionId
        );

        loadedRegions.push(regionData);
      } catch (e: any) {
        erroredRegions.push(regionId);
        notification.error(NOTICE.REGIONS_LOADING_ERROR);
        sendError(NOTICE.REGIONS_LOADING_ERROR.message, e);
      }
    }

    setAvailableRegions(loadedRegions);
    setErroredRegionsId(erroredRegions);
  };

  const checkSaveRegions = async () => {
    const haveSaved = await handleSavedRegionId();

    if (haveSaved) return;
    loadAvailableRegionsList();
  };

  useEffect(() => {
    if (availableRegions?.length !== 1) return;
    if (erroredRegionsId.length) return;

    handlePickRegion(availableRegions[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableRegions, erroredRegionsId]);

  useEffect(() => {
    if (!user) return;

    checkSaveRegions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return { availableRegions, erroredRegionsId };
};

export default useRegions;
