import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../../../stores/rootStore/rootStore';
import { IVideoLIstItem } from '../../../../../../../../stores/videoWallPandelStore/videoWallPanelStore.model';
import { getChangeVideoListParams } from '../../../../../../../VideoWall/helpers/helpers';

import styles from './ListKeyButton.module.scss';

interface IListKeyButton {
  listKey: string;
  cameras: IVideoLIstItem[];
  id: number;
}

const ListKeyButton: FC<IListKeyButton> = ({ listKey, cameras, id }) => {
  const { selectedVideoId, changeVideoList, savedVideoLists } =
    rootStore.videoWallPanelStore;

  const handleChangeVideoList = () => {
    const { isChange } = getChangeVideoListParams(savedVideoLists, id);

    changeVideoList(cameras, listKey, id, isChange);
  };

  const selectedListStyles = classNames({
    [styles.selectedList]: true,
    [styles.isActive]: id === selectedVideoId,
  });

  const isActive = id === selectedVideoId;
  const btnType = isActive ? 'primary' : 'default';

  if (listKey.length > 15)
    return (
      <Tooltip title={listKey} placement="leftTop">
        <Button
          onClick={handleChangeVideoList}
          type={id === selectedVideoId ? 'primary' : 'default'}
          className={selectedListStyles}
        >
          {listKey.slice(0, 15) + '...'}
        </Button>
      </Tooltip>
    );

  return (
    <Button
      onClick={handleChangeVideoList}
      type={btnType}
      className={selectedListStyles}
    >
      {listKey}
    </Button>
  );
};

export default observer(ListKeyButton);
