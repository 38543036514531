import React from 'react';

import Confirm from '../Confirm/Confirm';

interface ConfirmSwitcherProps {
  onClick: () => void;
  isConfirm: boolean | undefined;
  children: any;
}

const ConfirmSwitcher: React.FC<ConfirmSwitcherProps> = (
  props: ConfirmSwitcherProps
) => {
  const { onClick, isConfirm, children } = props;

  if (!isConfirm) return children;

  return <Confirm onConfirm={onClick}>{children}</Confirm>;
};

export default ConfirmSwitcher;
