import { TRAFFIC_LIGHTS_STATUS_SETS } from '../../constants/constants';

interface ILocalId {
  id: number;
}

interface IItemTL {
  id: number;
  mode?: number;
}

interface IWidgetsData {
  tls: any[];
  detectors: any[];
  cameras: any[];
}

const initLocal = (locals: ILocalId[]) => {
    const localIDs = locals.map((el: ILocalId) => el.id);

    return localIDs?.map((localID: number) => ({
      ...locals.find((cTL: ILocalId) => localID === cTL.id),
    }));
  },
  combineTLsWgData = (locals: ILocalId[]) => {
    if (!locals) {
      return;
    }

    const initTLsLocal: any = initLocal(locals);

    const workTLsNum = initTLsLocal.filter((itemTL: IItemTL) =>
      TRAFFIC_LIGHTS_STATUS_SETS.WORK.mode.includes(itemTL.mode as number)
    ).length;
    const widgetTLs = {
      total: initTLsLocal.length,
      working: workTLsNum,
      ratio: Math.round((workTLsNum * 100) / initTLsLocal.length),
      errors: initTLsLocal.filter(
        (itemTL: IItemTL) =>
          itemTL.mode &&
          TRAFFIC_LIGHTS_STATUS_SETS.ERRORS.mode.includes(itemTL.mode)
      ).length,
      disactive: initTLsLocal.filter(
        (itemTL: IItemTL) =>
          itemTL.mode &&
          TRAFFIC_LIGHTS_STATUS_SETS.NOT_ACTIVE.mode.includes(itemTL.mode)
      ).length,
    };

    return { widgetTLs };
  },
  combineDTsWgData = (locals: ILocalId[]) => {
    if (!locals) {
      return;
    }

    const initDLsLocal = initLocal(locals),
      workDLsNum = initDLsLocal.length,
      widgetDTs = {
        total: initDLsLocal.length,
        working: workDLsNum,
        ratio: Math.round((workDLsNum * 100) / initDLsLocal.length),
        errors: 0,
        disactive: initDLsLocal.length - workDLsNum,
      };

    return { widgetDTs };
  },
  combineCMRsWgData = (locals: ILocalId[]) => {
    if (!locals) {
      return;
    }

    const initCMRsLocal = initLocal(locals),
      workCMRsNum = initCMRsLocal.length,
      widgetCMRs = {
        total: initCMRsLocal.length,
        working: workCMRsNum,
        ratio: Math.round((workCMRsNum * 100) / initCMRsLocal.length),
        errors: 0,
        disactive: initCMRsLocal.length - workCMRsNum,
      };

    return { widgetCMRs };
  },
  getWidgetsData = ({ tls, detectors, cameras }: IWidgetsData) => ({
    ...combineTLsWgData(tls),
    ...combineDTsWgData(detectors),
    ...combineCMRsWgData(cameras),
  });

export default getWidgetsData;
