import { Button, Form, Input, Select, Spin } from 'antd';
import { SpinProps } from 'antd/lib';
import TextArea from 'antd/lib/input/TextArea';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import {
  formItemLayout,
  REGION_RULES,
  SUBSYSTEM_RULES,
  SYSTEMTYPES_RULES,
  SYSTEM_NAME_RULES,
} from '../../../../constants/adminConstants';

import { useAbout } from './hooks/useAbout';
import { useMenuHandlers } from './hooks/useMenuHandlers';

import styles from './MenuEditForm.module.scss';

interface IMenuEditForm {
  type: string;
}

const SUBMIT_BTN_TEXT: OWKS<string> = {
  add: 'Добавить',
  delete: 'Удалить',
};

const appPath = 'componets/Panel/AdminPAnel/MenuEditForm';

const MenuEditForm: FC<IMenuEditForm> = ({ type }) => {
  const [form] = Form.useForm();

  const {
    getRegions,
    getSubSystems,
    getSystemTypes,
    isLoading,
    loadState,
    setLoadState,
  } = useAbout(appPath);

  const {
    handleChangeRegion,
    handleChangeSubSystem,
    handleChangeSystemsTypes,
    handleInputChange,
    handleResetForm,
    handleSubmitForm,
    isTextArea,
    isInput,
  } = useMenuHandlers({
    form,
    getSubSystems,
    setLoadState,
    loadState,
    getSystemTypes,
    appPath,
  });

  const spinProps: SpinProps = {
    size: 'default',
    className: styles.spin,
  };

  useEffect(() => {
    getRegions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form className={styles.form} form={form} {...formItemLayout} size="large">
      {isLoading && !loadState.regions && <Spin {...spinProps} />}
      {loadState.regions && (
        <Form.Item label="Выберите регион" name="region" rules={REGION_RULES}>
          <Select placeholder="Выберите регион" onChange={handleChangeRegion}>
            {loadState.regions.map(({ id, name }) => (
              <Select.Option value={id} key={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {isLoading && loadState.regions && !loadState.subsystems && (
        <Spin {...spinProps} />
      )}
      {loadState.subsystems && (
        <Form.Item
          label="Выберите ситему"
          name="subsystems"
          rules={SUBSYSTEM_RULES}
        >
          <Select
            placeholder="Выберите ситему"
            onChange={handleChangeSubSystem}
          >
            {loadState.subsystems.map(({ id, name }) => (
              <Select.Option value={id} key={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {isLoading && loadState.subsystems && <Spin {...spinProps} />}
      {loadState.systemtypes && (
        <Form.Item
          label="Выберите тип ситемы"
          name="systemtypes"
          rules={SYSTEMTYPES_RULES}
        >
          <Select
            placeholder="Выберите тип ситемы"
            onChange={handleChangeSystemsTypes}
          >
            {loadState.systemtypes.map(({ id, name }) => (
              <Select.Option value={id} key={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {isInput && (
        <Form.Item
          label="Введите название"
          name="name"
          rules={SYSTEM_NAME_RULES}
        >
          <Input placeholder="Введите название" onChange={handleInputChange} />
        </Form.Item>
      )}
      {isTextArea && (
        <Form.Item label="Описание" name="dsc">
          <TextArea rows={4} />
        </Form.Item>
      )}
      {isTextArea && (
        <div className={styles.btnWrapper}>
          <Button type="primary" onClick={handleSubmitForm}>
            {SUBMIT_BTN_TEXT[type]}
          </Button>
          <Button type="primary" onClick={handleResetForm}>
            Отменить
          </Button>
        </div>
      )}
    </Form>
  );
};

export default observer(MenuEditForm);
