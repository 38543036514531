import { Button, Popconfirm } from 'antd';
import { FC, useState } from 'react';
import { BsDownload } from 'react-icons/bs';

import { DataType, ITableColumn } from '../../../../ts/models/table.model';
import useExport from '../hooks/useCsvExport';

import ChangeExportType from './ChangeExportType/ChangeExportType';
import { exportFile } from './helpers';

interface IExport {
  data: DataType[];
  headers: ITableColumn[];
  title: string;
}
const TEXT = 'Экспортировать данные';

const Export: FC<IExport> = ({ data, headers, title }) => {
  const { exportData } = useExport({
    dataRows: data,
    headerItems: headers,
  });
  const [type, setType] = useState('xlsx');

  const handleExport = () => {
    type === 'xlsx' && exportFile(data, type, title);
    type === 'csv' && exportData();
  };

  return (
    <Popconfirm
      placement="topLeft"
      title={<ChangeExportType setType={setType} />}
      onConfirm={handleExport}
      okText="Экпортировать"
      cancelText="Отмена"
      icon={<BsDownload style={{ color: 'var(--antd-blue)' }} />}
    >
      <Button>{TEXT}</Button>
    </Popconfirm>
  );
};

export default Export;
